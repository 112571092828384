/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import React from 'react';

const SvgInvites = props => (
  <svg
    id="invites_svg__Layer_1"
    data-name="Layer 1"
    viewBox="0 0 16.62 17.26"
    {...props}
  >
    <defs>
      <style />
    </defs>
    <path d="M16.61,7.34a.15.15,0,0,0,0-.07.3.3,0,0,0,0-.1l0-.09s0,0,0,0,0,0,0,0l0,0L13.33,4.23V3.68a.5.5,0,0,0-.5-.5h-.71L8.64.12A.51.51,0,0,0,8,.12L4.49,3.18h-.7a.5.5,0,0,0-.5.5v.55L.17,7l0,0,0,0s0,0,0,0a.36.36,0,0,0,0,.09l0,.1v.07H0v9.42a.5.5,0,0,0,.5.5H16.12a.5.5,0,0,0,.5-.5V7.34Zm-1.29,0-2,1.48V5.56Zm-7,5.21-4-3V4.18h8V9.53Zm0-11.36,2.3,2H6Zm-5,7.63-2-1.48,2-1.75ZM1,16.26V8.34L3.29,10h0L8,13.55a.54.54,0,0,0,.3.1.57.57,0,0,0,.3-.1L13.32,10h0l2.29-1.7v7.92Z" />
  </svg>
);

export default SvgInvites;
