/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import _flow from 'lodash/flow';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Typography, withStyles } from '@material-ui/core';
import {
  getLastFailure,
  getLicensedContentByContentType,
  getLicensedProducts,
  isLoading,
  selectUsers,
  selectGroups
} from '../../selectors';
import {
  getProducts,
  isLoading as contentAccessIsLoading
} from '../../../../components/Dashboard/ContentAccess/selectors';
import UserTable from './UserTable';
import {
  dismissErrorAction,
  fetchLicensedContent,
  fetchLicensedProducts,
  queryUsersByCustomer,
  updateUser,
  fetchGroups,
  inviteUser,
  resendInvite,
  searchUsers,
  deleteUser,
  createGroup,
  updateGroup,
  deleteGroup
} from '../../actions';
import {
  getUserGroups,
  readUserDetails,
  queryUserDetailsByGroups
} from '../../services';
import {
  batchUpdateContentAccess,
  getContentRightsByUserId,
  getProductRightsForUserId,
  updateContentAccess
} from '../../../../components/Dashboard/ContentAccess/services';
import UserDialog from './UserDialog';
import AssignContentDialog from './AssignContentDialog';
import ManageGroupsDialog from './ManageGroupsDialog';
import InviteUserDialog from './InviteUserDialog';
import ErrorPopup from './ErrorPopup';
import { getCustomers } from '../../../../components/Dashboard/CustomerManagement/selectors';
import { queryCustomersAction } from '../../../../components/Dashboard/CustomerManagement/actions';
import { queryProducts } from '../../../../components/Dashboard/ContentAccess/actions';

import _map from 'lodash/map';
import _mapValues from 'lodash/mapValues';
import _isEqual from 'lodash/isEqual';
import _includes from 'lodash/includes';
import _reduce from 'lodash/reduce';
import _cloneDeep from 'lodash/cloneDeep';
import { arrayToObjectById } from '../../../../utilities/arrayObjectUtils';
import {
  selectCustomerId,
  selectSessionUserRoles
} from '../../../session-user/selectors';
import DeleteUserDialog from './DeleteUserDialog';
import * as roleConstants from '../../../../constants/roles';
import * as constants from './constants';

export class AccountManagementContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editDialogOpen: false,
      editUser: {},
      inviteUserModel: {
        firstName: '',
        lastName: '',
        userName: '',
        roles: [],
        customer: '',
        groups: [],
        aresRoles: []
      },
      deleteDialogOpen: false,
      deleteUserId: '',
      deleteUsername: '',
      assignDialogOpen: false,
      inviteUserDialogOpen: false,
      groupsCanDelete: null,
      userContentAccess: {
        contentRightsByProductAndType: {},
        productRights: {}
      },
      selectedCustomer: undefined,
      selectedContentGroupCustomer: '',
      selectedGroupsForAssignContent: []
    };
  }
  componentDidUpdate(lastProps, lastState) {
    const { isLoading: wasLoading } = lastProps;
    const { isLoading: currentlyLoading } = this.props;
    const groupsForManageGroupsDialog = [];
    const customer =
      this.state.selectedContentGroupCustomer.length > 0
        ? this.state.selectedContentGroupCustomer
        : this.props.sessionCustomerId;

    if (this.state.editDialogOpen) {
      if (wasLoading && !currentlyLoading) {
        if (!this.props.lastFailure) {
          this.handleUserClose();
        }
      }
    }
    if (
      !_isEqual(this.props.customers, lastProps.customers) ||
      lastProps.licensedContentByContentType === undefined ||
      lastProps.licensedProducts === undefined
    ) {
      const isCmt = _includes(
        this.props.sessionUserRoles,
        roleConstants.CMT_ADMIN
      );
      if (isCmt) {
        const customers = this.props.customers
          ? Object.keys(this.props.customers)
          : [];
        this.props.fetchLicensedContent(customers);
        this.props.fetchLicensedProducts(customers);
      } else {
        this.props.fetchLicensedContent([this.props.sessionCustomerId]);
        this.props.fetchLicensedProducts([this.props.sessionCustomerId]);
      }
    }
    if (
      !_isEqual(
        lastState.selectedContentGroupCustomer,
        this.state.selectedContentGroupCustomer
      )
    ) {
      const groupsPerCustomer =
        customer && this.props.groups[customer]
          ? Object.values(this.props.groups[customer])
          : [];
      for (let i = 0; i < groupsPerCustomer.length; i++) {
        const groupsToQuery = [];
        groupsToQuery.push(groupsPerCustomer[i].id);
        queryUserDetailsByGroups(groupsToQuery)
          .then(userDetails => {
            const group = groupsPerCustomer[i];
            userDetails.length > 0
              ? (group.canDelete = false)
              : (group.canDelete = true);
            groupsForManageGroupsDialog.push(group);
          })
          .catch(error => {
            console.error(error);
          });
      }
      this.setState({
        groupsCanDelete: groupsForManageGroupsDialog
      });
    }
    if (!_isEqual(lastProps.groups, this.props.groups)) {
      const groupsPerCustomer = customer
        ? Object.values(this.props.groups[customer])
        : [];
      for (let i = 0; i < groupsPerCustomer.length; i++) {
        const group = groupsPerCustomer[i];
        const existingGroup = this.state.groupsCanDelete
          ? this.state.groupsCanDelete.find(
              existingGroup => existingGroup.id === group.id
            )
          : null;
        if (!existingGroup || existingGroup === undefined) {
          //existing group was updated
          const groupsToQuery = [];
          groupsToQuery.push(groupsPerCustomer[i].id);
          queryUserDetailsByGroups(groupsToQuery)
            .then(userDetails => {
              userDetails && userDetails.length > 0
                ? (group.canDelete = false)
                : (group.canDelete = true);
            })
            .catch(error => {
              console.error(error);
            });
        } else {
          group.canDelete = existingGroup.canDelete;
        }
        groupsForManageGroupsDialog.push(group);
      }
      this.setState({
        groupsCanDelete: groupsForManageGroupsDialog
      });
    }
  }

  componentDidMount() {
    const {
      queryUsersByCustomer,
      pagination,
      sessionCustomerId,
      sessionUserRoles,
      fetchLicensedContent,
      fetchLicensedProducts
    } = this.props;
    const isCmt = _includes(
      this.props.sessionUserRoles,
      roleConstants.CMT_ADMIN
    );
    const isCustomerAdmin = _includes(
      sessionUserRoles,
      roleConstants.CUSTOMER_ADMIN
    );
    if (isCustomerAdmin && !isCmt) {
      this.props.fetchGroups(sessionCustomerId);
    }
    if (isCmt) {
      this.props.fetchGroups('');
      //TODO: get sortOrder and sortCategory from ui
      queryUsersByCustomer(
        '',
        pagination.page,
        pagination.perPage,
        pagination.defaultSortCategory,
        pagination.defaultSortOrder
      );
    } else {
      queryUsersByCustomer(
        sessionCustomerId,
        pagination.page,
        pagination.perPage,
        pagination.defaultSortCategory,
        pagination.defaultSortOrder
      );
    }
    if (this.props.customers === undefined) {
      this.props.queryCustomers();
    }
    if (this.props.productsById === undefined) {
      this.props.queryProducts();
    }
    if (
      this.props.licensedContentByContentType === undefined ||
      this.props.licensedProducts === undefined
    ) {
      if (isCmt) {
        const customers = this.props.customers
          ? Object.keys(this.props.customers)
          : [];
        fetchLicensedContent(customers);
        fetchLicensedProducts(customers);
      } else {
        fetchLicensedContent([sessionCustomerId]);
        fetchLicensedProducts([sessionCustomerId]);
      }
    }
  }

  handleUserSave = user => this.props.updateUser(user);
  handleUserClose = () => this.setState({ editDialogOpen: false });
  handleAssignContentClose = () => {
    this.setState({
      assignDialogOpen: false,
      selectedUser: '',
      selectedGroupsForAssignContent: []
    });
  };

  handleInviteUserDialogClose = () => {
    this.setState({
      inviteUserDialogOpen: false
    });
  };

  handleInviteUserSubmit = (invitedUser, authentication) => {
    this.props.inviteUser(invitedUser, authentication);
    this.handleInviteUserDialogClose();
  };

  handleManageGroupsDialogClose = () => {
    this.setState({
      createGroupOpen: false
    });
  };

  handleDeleteDialogClose = () => {
    this.setState({ deleteDialogOpen: false });
  };

  handleCloseError = () => {
    this.props.dismissError();
  };

  handleCloseContentAccessError = () => {
    this.setState({ contentAccessError: undefined });
  };

  handleSelectedContentGroupCustomer = selectedContentGroupCustomer => {
    this.setState({
      selectedContentGroupCustomer
    });
  };

  getUserContentAndProductRights = (userId, userCustomer) => {
    const {
      licensedProducts,
      licensedContentByContentType,
      productsById
    } = this.props;
    return Promise.all([
      getProductRightsForUserId(userId),
      getContentRightsByUserId(userId)
    ]).then(([productAccess, contentRights]) => {
      const productRightsArray = _map(
        licensedProducts[userCustomer],
        product => {
          const newProduct = { ...product };
          productAccess.forEach(productRight => {
            if (newProduct.id === productRight.productId) {
              newProduct.userAccessType = 1;
            }
          });
          return newProduct;
        }
      );
      const productRights = arrayToObjectById(productRightsArray, 'id');
      const contentRightsByProductAndType = {};
      Object.values(productRights).forEach(product => {
        contentRightsByProductAndType[product.id] = {};
        productsById[product.id].contentTypes.forEach(contentType => {
          const contentRightsArray = _map(
            licensedContentByContentType[userCustomer][contentType.id],
            content => {
              const newContent = { ...content };
              contentRights.forEach(contentRight => {
                if (newContent.contentId === contentRight.contentId) {
                  newContent.userAccessType = contentRight.accessType;
                }
              });
              return newContent;
            }
          );
          contentRightsByProductAndType[product.id][
            contentType.name
          ] = arrayToObjectById(contentRightsArray, 'contentId');
        });
      });
      return { productRights, contentRightsByProductAndType };
    });
  };

  handleEditDialog = event => {
    const { currentTarget } = event;
    const { id, state } = currentTarget.dataset;
    let groupIds = [];
    const userCustomer = currentTarget.attributes['assign-customer']
      ? currentTarget.attributes['assign-customer'].value
      : '';
    this.getUserContentAndProductRights(id, userCustomer)
      .then(userContentAccess => {
        this.setState({
          userContentAccess
        });
      })
      .catch(err => console.error(err));

    this.setState({
      groupOptions: this.props.groups[userCustomer]
    });

    getUserGroups(id).then(group => {
      if (group) {
        groupIds = group.map(group => group.id);
      }
      readUserDetails(id)
        .then(user => {
          if (!user.products || !user.products.length) {
            user.products = [];
          }
          if (!user.productRoles || !user.productRoles.length) {
            user.productRoles = [];
          }
          user.groupIds = groupIds;
          this.setState({
            editUser: user,
            editDialogOpen: state === 'open'
          });
        })
        .catch(e => console.error(`Error reading user: ${e}`));
    });
  };

  handleInviteUserDialogOpen = () => {
    this.setState({
      inviteUserDialogOpen: true
    });
  };

  handleCreateGroupDialogOpen = () => {
    this.setState({
      createGroupOpen: true
      //fetch group or check in the state to see if group already there for chosen customer
      //this.state.selectedcustomer
    });
  };

  handleCreateGroupSubmit = groupName => {
    const { selectedContentGroupCustomer } = this.state;
    this.props.createGroup(
      groupName,
      selectedContentGroupCustomer.length > 0
        ? selectedContentGroupCustomer
        : this.props.sessionCustomerId
    );
  };

  handleSaveGroupSubmit = group => {
    this.props.updateGroup(group);
  };

  handleDeleteGroupSubmit = group => {
    this.props.deleteGroup(group);
  };

  handleSetGroupsForAssignContent = event => {
    const { value } = event.target;
    this.setState({
      selectedGroupsForAssignContent: value
    });
  };

  handleAssignContentDialogOpen = (event, selectedCustomer) => {
    const { currentTarget } = event;
    const userId = currentTarget.attributes['assign-id']
      ? currentTarget.attributes['assign-id'].value
      : '';
    const {
      licensedProducts,
      licensedContentByContentType,
      productsById,
      sessionUserRoles,
      sessionCustomerId,
      users
    } = this.props;
    if (userId && licensedProducts && licensedContentByContentType) {
      //single user content access assign
      const userCustomer = currentTarget.attributes['assign-customer']
        ? currentTarget.attributes['assign-customer'].value
        : '';
      this.getUserContentAndProductRights(userId, userCustomer)
        .then(userContentAccess =>
          this.setState({
            userContentAccess,
            assignDialogOpen: true,
            selectedUser: userId,
            assignDialogType: users[userId].userName
          })
        )
        .catch(err => console.error(`Fetching product rights failed: ${err}`));
    } else if (
      userId === '' &&
      licensedProducts &&
      licensedContentByContentType
    ) {
      //bulk content assign
      const isCmtAdmin = _includes(sessionUserRoles, roleConstants.CMT_ADMIN);
      const customer = isCmtAdmin ? selectedCustomer : sessionCustomerId;
      const contentRightsByProductAndType = {};
      _map(licensedProducts[customer], licensedProduct => {
        const contentRightsByType = {};
        _map(productsById[licensedProduct.id].contentTypes, contentType => {
          contentRightsByType[contentType.name] = _mapValues(
            licensedContentByContentType[customer][contentType.id],
            content => ({
              ...content,
              userAccessType: 0
            })
          );
        });
        contentRightsByProductAndType[licensedProduct.id] = contentRightsByType;
      });
      const clonedProductRights = _cloneDeep(licensedProducts[customer]);
      const clonedContentRights = _cloneDeep(contentRightsByProductAndType);
      const productRights = clonedProductRights ? clonedProductRights : {};
      const contentRights = clonedContentRights ? clonedContentRights : {};
      this.setState({
        selectedCustomer,
        groupOptions: this.props.groups[customer],
        assignDialogOpen: true,
        userContentAccess: {
          productRights,
          contentRightsByProductAndType: contentRights
        },
        assignDialogType: 'group'
      });
    }
  };

  handleProductChange = (e, productId) => {
    this.setState(prevState => {
      const newProductRights = {
        ...prevState.userContentAccess.productRights
      };
      newProductRights[productId].userAccessType = prevState.userContentAccess
        .productRights[productId].userAccessType
        ? 0
        : 1;
      let newContentRights =
        prevState.userContentAccess.contentRightsByProductAndType[productId];
      if (newProductRights[productId].userAccessType === 0) {
        newContentRights = _mapValues(newContentRights, contentTypeRights =>
          _mapValues(contentTypeRights, contentRight => ({
            ...contentRight,
            userAccessType: 0
          }))
        );
      }
      return {
        userContentAccess: {
          productRights: newProductRights,
          contentRightsByProductAndType: {
            ...prevState.userContentAccess.contentRightsByProductAndType,
            [productId]: newContentRights
          }
        }
      };
    });
  };
  handleContentChange = (e, productId, contentTypeName, contentRight) => {
    this.setState(prevState => {
      const newContentRightsByProductAndType = {
        ...prevState.userContentAccess.contentRightsByProductAndType 
      };

      const newContentRightsForProduct = {
        ...newContentRightsByProductAndType[productId]
      };

      const newContentRightsForType = {
        ...newContentRightsForProduct[contentTypeName]
      };

      const newContentRight = {
        ...newContentRightsForType[contentRight.contentId],
        userAccessType: (newContentRightsForType[contentRight.contentId].userAccessType + 1) % 3
      };

      newContentRightsForType[contentRight.contentId] = newContentRight;
      newContentRightsForProduct[contentTypeName] = newContentRightsForType;
      newContentRightsByProductAndType[productId] = newContentRightsForProduct;
      
      return {
        userContentAccess: {
          ...prevState.userContentAccess,
          contentRightsByProductAndType: newContentRightsByProductAndType
        }
      };

    });
  };

  handleSubmit = ({ productRights, contentRightsByProductAndType }) => {
    const {
      selectedUser,
      selectedCustomer,
      selectedGroupsForAssignContent
    } = this.state;
    const { sessionUserRoles, sessionCustomerId } = this.props;
    const arrayOfContents = Object.values(contentRightsByProductAndType);
    const accessibleContent = _map(arrayOfContents, content =>
      _map(Object.values(content), contentRights =>
        Object.values(contentRights).filter(
          contentRight => !!contentRight.userAccessType
        )
      )
    );
    const updatedProductRights = Object.values(productRights).filter(
      item => !!item.userAccessType
    );
    const newProductRights = _map(updatedProductRights, product => ({
      productId: product.id
    }));
    const newContentRights = [];
    accessibleContent.forEach(product => {
      product.forEach(type => {
        type.forEach(info => {
          newContentRights.push({
            accessType: info.userAccessType,
            contentId: info.contentId || ''
          });
        });
      });
    });
    const isCmtAdmin = _includes(sessionUserRoles, roleConstants.CMT_ADMIN);
    const customer = isCmtAdmin ? selectedCustomer : sessionCustomerId;
    //assign content per user
    if (selectedUser) {
      updateContentAccess(
        selectedUser,
        newContentRights,
        newProductRights
      ).catch(error => {
        const failedContent = error.message
          .split('%')[0]
          .slice(0, -1)
          .split(':')[1]
          .split(',');
        this.setState({
          contentAccessError: {
            errors: { failedContent },
            errorType: 'failedUpdateContentAccess'
          }
        });
      });
    } else if (selectedGroupsForAssignContent.length === 0) {
      //assign content per customer
      const userIdsWithoutCmtAdmin = _reduce(
        this.props.users,
        (r, v) =>
          _includes(v.roles, roleConstants.CMT_ADMIN) ? r : r.concat(v.id),
        []
      );
      this.batchUpdateContentAccessAndHandleError(
        userIdsWithoutCmtAdmin,
        newContentRights,
        newProductRights,
        customer
      );
    } else {
      //assign content per group(s)
      queryUserDetailsByGroups(selectedGroupsForAssignContent)
        .then(userDetails => {
          const userIdsWithoutCmtAdmin = _reduce(
            userDetails,
            (r, v) =>
              _includes(v.roles, roleConstants.CMT_ADMIN) ? r : r.concat(v.id),
            []
          );
          this.batchUpdateContentAccessAndHandleError(
            userIdsWithoutCmtAdmin,
            newContentRights,
            newProductRights,
            customer
          );
        })
        .catch(error => {
          console.error(error);
          this.setState({
            contentAccessError: {
              errors:
                'Could not get all users belonging to the selected groups',
              errorType: 'failedQueryUserDetailsByGroups'
            }
          });
        });
    }
    this.setState({
      assignDialogOpen: false,
      selectedUser: '',
      selectedGroupsForAssignContent: [],
      userContentAccess: {
        contentRightsByProductAndType: {},
        productRights: {}
      },
      assignDialogType: ''
    });
  };

  batchUpdateContentAccessAndHandleError = (
    userIdsWithoutCmtAdmin,
    newContentRights,
    newProductRights,
    customer
  ) => {
    batchUpdateContentAccess(
      userIdsWithoutCmtAdmin,
      newContentRights,
      newProductRights,
      customer
    ).catch(error => {
      if (
        error.message.includes(
          'content and products that failed due to lack of seats:'
        )
      ) {
        const formattedMessage = error.message
          .split('failed to update all or some content/products')[0]
          .split(':');
        const failedContent = formattedMessage[1]
          .split(', users with partial or no access rights updated')[0]
          .split(',');
        const failedUsers = formattedMessage[2].split(',');
        this.setState({
          contentAccessError: {
            errors: { failedUsers, failedContent },
            errorType: 'failedUpdateContentAccess'
          }
        });
      } else {
        const formattedMessage = error.message.split('%')[0];
        this.setState({
          contentAccessError: {
            errors: formattedMessage,
            errorType: 'generic'
          }
        });
      }
    });
  };

  handleDelete = () => {
    this.props.deleteUser(this.state.deleteUserId);
  };

  handleDeleteDialogOpen = (event, username) => {
    const { currentTarget } = event;
    const userId = currentTarget.attributes['assign-id']
      ? currentTarget.attributes['assign-id'].value
      : '';

    this.setState({
      deleteUserId: userId,
      deleteDialogOpen: true,
      deleteUsername: username
    });
  };

  render() {
    const {
      classes,
      searchUsers,
      sessionCustomerId,
      resendInvite,
      sessionUserRoles,
      queryUsersByCustomer,
      isLoading,
      isContentAccessLoading,
      groups,
      queryProductsList,
      lastFailure,
      licensedProducts,
      pagination,
      customers,
      users
    } = this.props;
    const { selectedContentGroupCustomer } = this.state;
    const isCmt = _includes(sessionUserRoles, roleConstants.CMT_ADMIN);
    const isCustomerAdmin = _includes(
      sessionUserRoles,
      roleConstants.CUSTOMER_ADMIN
    );
    return (
      <div className={classes.paper}>
        <Typography
          className={classes.header}
          display="block"
          variant="h4"
          component="h4"
        >
          Account Management
        </Typography>
        {this.state.createGroupOpen && (
          <ManageGroupsDialog
            open={true}
            groupList={this.state.groupsCanDelete}
            selectedContentGroupCustomer={selectedContentGroupCustomer}
            handleCreateSubmit={this.handleCreateGroupSubmit}
            handleManageGroupsDialogClose={this.handleManageGroupsDialogClose}
            handleSaveSubmit={this.handleSaveGroupSubmit}
            handleDeleteSubmit={this.handleDeleteGroupSubmit}
          />
        )}
        <UserTable
          licensedProducts={licensedProducts}
          users={users}
          pagination={pagination}
          customers={customers}
          userRoles={sessionUserRoles}
          sessionCustomerId={sessionCustomerId}
          handleEditDialog={this.handleEditDialog}
          setFilteredCustomerAndUsers={this.setFilteredCustomerAndUsers}
          handleAssignContentDialog={this.handleAssignContentDialogOpen}
          handleCreateGroupDialog={this.handleCreateGroupDialogOpen}
          handleSearchUsers={searchUsers}
          handlePagination={queryUsersByCustomer}
          handleInviteUserDialog={this.handleInviteUserDialogOpen}
          handleDeleteDialog={this.handleDeleteDialogOpen}
          resendInvite={resendInvite}
          isLoading={isLoading || isContentAccessLoading}
          roleOptions={
            isCmt ? constants.roleOptions : constants.customerAdminRoleOptions
          }
          handleSelectedContentGroupCustomer={
            this.handleSelectedContentGroupCustomer
          }
          groupsCanDelete={this.state.groupsCanDelete}
        />
        <UserDialog
          isCmt={isCmt}
          isCustomerAdmin={isCustomerAdmin}
          open={this.state.editDialogOpen}
          model={this.state.editUser}
          handleUserSave={this.handleUserSave}
          handleUserClose={this.handleUserClose}
          roleOptions={
            isCmt ? constants.roleOptions : constants.customerAdminRoleOptions
          }
          content={this.state.userContentAccess}
          productOptions={queryProductsList}
          groupOptions={this.state.groupOptions}
        />
        {this.state.assignDialogOpen && (
          <AssignContentDialog
            open={true}
            type={this.state.assignDialogType}
            content={this.state.userContentAccess}
            groupOptions={this.state.groupOptions}
            groups={this.state.selectedGroupsForAssignContent}
            handleSetGroupsForAssignContent={
              this.handleSetGroupsForAssignContent
            }
            handleAssignContentClose={this.handleAssignContentClose}
            handleProductChange={this.handleProductChange}
            handleContentChange={this.handleContentChange}
            handleSubmit={this.handleSubmit}
          />
        )}

        {this.state.deleteDialogOpen && (
          <DeleteUserDialog
            open={true}
            handleDeleteDialogClose={this.handleDeleteDialogClose}
            handleDelete={this.handleDelete}
            username={this.state.deleteUsername}
          />
        )}

        {this.state.inviteUserDialogOpen && (
          <InviteUserDialog
            open={true}
            model={this.state.inviteUserModel}
            roleOptions={
              isCmt ? constants.roleOptions : constants.customerAdminRoleOptions
            }
            isCmt={isCmt}
            isCustomerAdmin={isCustomerAdmin}
            customerOptions={
              isCmt
                ? customers
                : {
                    [sessionCustomerId]: customers[sessionCustomerId]
                  }
            }
            groupOptions={groups}
            productOptions={queryProductsList}
            handleInviteUserDialogClose={this.handleInviteUserDialogClose}
            handleInviteUserSubmit={this.handleInviteUserSubmit}
          />
        )}

        <ErrorPopup
          open={lastFailure !== undefined}
          error={lastFailure}
          closeError={this.handleCloseError}
        />
        <ErrorPopup
          open={this.state.contentAccessError !== undefined}
          error={this.state.contentAccessError}
          closeError={this.handleCloseContentAccessError}
        />
      </div>
    );
  }
}

function createRoleList(rolesList) {
  const roles = [];
  if (rolesList.includes(roleConstants.CMT_ADMIN)) {
    roles.push(roleConstants.CMT_ADMIN);
  }

  if (rolesList.includes(roleConstants.CUSTOMER_ADMIN)) {
    roles.push(roleConstants.CUSTOMER_ADMIN);
  }

  if (rolesList.includes(roleConstants.PLAYER)) {
    roles.push(roleConstants.PLAYER);
  }
  return roles;
}

const mapStateToProps = state => ({
  customers: getCustomers(state),
  isLoading: isLoading(state),
  sessionUserRoles: createRoleList(selectSessionUserRoles(state)),
  isContentAccessLoading: contentAccessIsLoading(state),
  lastFailure: getLastFailure(state),
  licensedProducts: getLicensedProducts(state),
  licensedContentByContentType: getLicensedContentByContentType(state),
  productsById: getProducts(state),
  queryProductsList: state.contentAccess.products,
  sessionCustomerId: selectCustomerId(state),
  users: selectUsers(state),
  groups: selectGroups(state),
  pagination: state.accountManagement.pagination
});
export const mapDispatchToProps = dispatch => ({
  createGroup: (groupName, customerId) => {
    dispatch(createGroup(groupName, customerId));
  },
  updateGroup: group => {
    dispatch(updateGroup(group));
  },
  deleteGroup: group => {
    dispatch(deleteGroup(group));
  },
  queryUsersByCustomer: (customerId, page, perPage, orderBy, order) => {
    dispatch(queryUsersByCustomer(customerId, page, perPage, orderBy, order));
  },
  fetchLicensedContent: customerIds => {
    dispatch(fetchLicensedContent(customerIds));
  },
  fetchLicensedProducts: customerIds => {
    dispatch(fetchLicensedProducts(customerIds));
  },
  updateUser: user => {
    dispatch(updateUser(user));
  },
  dismissError: () => {
    dispatch(dismissErrorAction());
  },
  queryCustomers: () => {
    dispatch(queryCustomersAction());
  },
  queryProducts: () => {
    dispatch(queryProducts());
  },
  fetchGroups: customerId => {
    dispatch(fetchGroups(customerId));
  },
  inviteUser: (user, authentication) => {
    dispatch(inviteUser(user, authentication));
  },
  resendInvite: user => {
    dispatch(resendInvite(user));
  },
  deleteUser: userId => {
    dispatch(deleteUser(userId));
  },
  searchUsers: (
    customerId,
    groupIds,
    searchStr,
    options,
    page,
    perPage,
    orderBy,
    order
  ) => {
    dispatch(
      searchUsers(
        customerId,
        groupIds,
        searchStr,
        options,
        page,
        perPage,
        orderBy,
        order
      )
    );
  }
});
export const styles = (theme: any) => ({
  paper: {
    borderTop: `1px solid ${theme.palette.black.main}`,
    borderBottom: `1px solid ${theme.palette.black.main}`,
    paddingTop: '25px',
    overflow: 'scroll',
    width: '100%',
    boxShadow: ' -5px -5px 20px 0px rgba(0,0,0,0.12);',
    marginLeft: '25px',
    paddingLeft: '25px'
  },
  userTableContainer: {
    width: '100%',
    height: '100%',
    borderBottom: '2px solid lightgray !important'
  },
  header: {
    color: theme.palette.primary.darkTurquoise
  },
  //hiding org chart for now
  orgChartContainer: {
    width: '100%',
    height: '0%',
    overflow: 'scroll'
  }
});
export default _flow(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(AccountManagementContainer);
