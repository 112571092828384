/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import type {
  SetSessionUserDetailsAction,
  SetLoadingStateAction,
  SetLastFailureAction,
  User,
  UpdateSessionUserPlayfabInfo
} from '../types';

export const getSessionUserPlayfabInfo = (
  state: SessionUserState,
  action: UpdateSessionUserPlayfabInfo
): $Shape<SessionUserState> => ({
  playFabData: action.payload.playerDataByProduct
});

export const setLoadingState = (
  state: SessionUserState,
  action: SetLoadingStateAction
): $Shape<SessionUserState> => ({
  isLoading: action.payload
});

export const setLastFailure = (
  state: SessionUserState,
  action: SetLastFailureAction
): $Shape<SessionUserState> => ({
  lastFailure: action.payload
});

export const getSessionUserDetails = (
  state: SessionUserState,
  action: SetLastFailureAction
): $Shape<SessionUserState> => ({
  userDetails: action.payload
});

export const setSessionUserDetails = (
  state: SessionUserState,
  action: SetSessionUserDetailsAction
): $Shape<User> => ({
  userDetails: action.payload
});
