/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

//@flow
import _reduce from 'lodash/reduce';
import _omit from 'lodash/omit';

import type {
  CreateLicenseAction,
  DeleteLicenseAction,
  LicenseManagementState,
  ReadLicenseAction,
  UpdateLicenseAction
} from '../types';

import { getLicenses } from './getters';

export const dismissError = () => ({
  lastFailure: undefined
});

export const setLastFailure = (state, action) => ({
  lastFailure: action.payload
});

export const createLicense = (
  state: LicenseManagementState,
  action: CreateLicenseAction
): $Shape<LicenseManagementState> => {
  const { payload: license } = action;
  const licenses = getLicenses(state);
  return {
    licenses: { ...licenses, [license.id]: license }
  };
};

export const readLicense = (
  state: LicenseManagementState,
  action: ReadLicenseAction
): $Shape<LicenseManagementState> => {
  const { payload: license } = action;
  const licenses = getLicenses(state);
  return { ...licenses, [license.id]: license };
};

export const queryLicenses = (
  state: LicenseManagementState,
  action: QueryLicensesAction
): $Shape<LicenseManagementState> => {
  const licenseList = action.payload;
  return {
    licenses: licenseList
      ? _reduce(
          licenseList,
          (r, v) => {
            const mappedContents = v.contentSubscription
              ? mapContentsByContent(v.contentSubscription)
              : {};
            const newV = {
              ...v,
              contentSubscription: mappedContents
            };
            r[v.id] = newV;
            return r;
          },
          {}
        )
      : {}
  };
};

const mapContentsByContent = contents =>
  _reduce(
    contents,
    (r, v) => ({
      ...r,
      [v.contentId]: v
    }),
    {}
  );

export const updateLicense = (
  state: LicenseManagementState,
  action: UpdateLicenseAction
): $Shape<LicenseManagementState> => {
  const licenses = getLicenses(state);
  const { payload: license } = action;
  return {
    licenses: { ...licenses, [license.id]: license }
  };
};

export const deleteLicense = (
  state: LicenseManagementState,
  action: DeleteLicenseAction
): $Shape<LicenseManagementState> => {
  const { payload: deletedLicenseId } = action;
  const licensesNotDeleted = _omit(getLicenses(state), deletedLicenseId);
  return {
    licenses: licensesNotDeleted
  };
};
