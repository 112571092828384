/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import * as colors from './colors.js';

export default {
  primary: {
    main: colors.colorFont,
    dark: colors.colorBlack,
    contrastText: colors.colorDarkTurquoise,
    logo: colors.colorDarkTurquoise,
    darker: colors.colorDarkBlack,
    greyDark: colors.colorDarkSlateGray,
    white: colors.colorWhite,
    darkTurquoise: colors.colorDarkTurquoise,
    lighterGray: colors.colorLighterGrey,
    lightSlateGray: colors.colorLightSlateGray,
    colorLightest: colors.colorLightest,
    slateBlack: colors.colorDarkSlateBlack,
    colorBorder: colors.colorBorder,
    gradientBackground: colors.colorGradientBackground,
    lightGradientBackground: colors.colorLightGradientBackground,
    lighterGradientBackground: colors.colorLighterGradientBackground,
    whiteShadow: colors.colorWhiteShadow,
    iconShadow: colors.colorIconShadowTurquoise,
    iconStroke: colors.colorIconStrokeTurquoise,
    red: colors.colorRed,
    orionOrange: colors.colorOrionOrange,
    colorWhiteOpacity: colors.colorWhiteOpacity
  },
  secondary: {
    main: colors.colorDarkTurquoise,
    darker: colors.colorRed
  },
  black: {
    main: colors.colorBlack
  },
  font: colors.colorOffWhite,
  background: {
    paper: colors.colorDarkSlateGray,
    appBar: colors.colorDarkSlateGray,
    default: colors.colorDarkSlateGray,
    darker: colors.colorOffWhiteDarker
  },
  table: {
    evenRow: colors.colorDarkSlateGray,
    oddRow: colors.colorLightSlateGray,
    border: colors.colorDarkSlateGray
  },
  mainNav: {
    font: colors.colorFont,
    background: colors.colorBlack
  },
  type: 'light'
};
