/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import {
  Dialog,
  DialogTitle,
  DialogContent,
  withStyles
} from '@material-ui/core';
import React, { Component } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import { IconButton, Button, Typography } from '@material-ui/core';

//import the right thing from services...

const styles = (theme: any) => ({
  title: {
    marginLeft: '0em',
    marginTop: '0em'
  },
  gridPadding: {
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '0px',
    paddingBottom: '0px'
  },
  dialogBoxPaper: {
    padding: '30px',
    margin: '20vh'
  },
  crossIcon: {
    position: 'absolute',
    top: 0,
    right: 0
  },
  centerWarning: {
    display: 'flex',
    justifyContent: 'center',
    padding: '20px 0 0 0'
  }
});

export class UserDialog extends Component<Props, State> {
  handleDelete = () => {
    this.props.handleDelete();
    this.handleDeleteDialogClose();
  };

  handleDeleteDialogClose = () => {
    this.props.handleDeleteDialogClose();
  };

  render() {
    const { classes, open } = this.props;
    return (
      <Dialog
        onClose={this.props.handleUserClose}
        open={open}
        aria-hidden={!open}
        PaperProps={{ classes: { root: classes.dialogBoxPaper } }}
      >
        <div>
          <IconButton
            className={classes.crossIcon}
            onClick={this.handleDeleteDialogClose}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent>
          <DialogTitle className={classes.title} disableTypography>
            {'Delete User'}
          </DialogTitle>
          <Typography variant="body2">
            Are you sure you want to delete {this.props.username}?
          </Typography>
          <div className={classes.centerWarning}>
            <WarningIcon fontSize="large" />
          </div>
          <div className={classes.centerWarning}>
            <Typography variant="body2">
              This action is not reversible!
            </Typography>
          </div>
          <div className={classes.centerWarning}>
            <Button onClick={this.handleDelete}>Yes</Button>
            <Button onClick={this.handleDeleteDialogClose}>No</Button>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles)(UserDialog);
