import PropTypes from 'prop-types';
import { getImageImport } from '../../utilities/imagePathUtil';
import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import Grow from '@material-ui/core/Grow';

const angle2 = '18px';
const styles = theme => ({
  highlighted: {
    filter: 'drop-shadow(0 0 1em #00000055)',
    transform: 'scale(1.1) !important',
    opacity: '1',
    zIndex: '1',
    '& img': {
      filter: 'grayscale(0%) !important'
    }
  },
  default: {
    transform: 'scale(1.0)',
    opacity: '1',
    filter: 'grayscale(0%)',
    '& img': {
      filter: 'grayscale(100%)'
    }
  },
  gameImage: {
    clipPath: 'polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%)',
    filter: 'grayscale(100%)',
    transition: '.5s filter',
    width: '120%',
    height: '100%',
    objectFit: 'cover'
  },
  singleImage: {
    clipPath: 'polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%)',
    filter: 'grayscale(100%)',
    transition: '.5s filter',
    maxHeight: '200px',
    height: '100%',
    marginLeft: '20%'
  },
  new: {
    position: 'relative',
    '&::after': {
      fontSize: '1.3em',
      textTransform: 'uppercase',
      letterSpacing: '.2em',
      padding: '.5em 1em',
      position: 'absolute',
      bottom: '-.5em',
      right: '.5em',
      content: '"New"',
      background: theme.palette.primary.darkTurquoise,
      clipPath:
        'polygon(calc(0% + ' +
        angle2 +
        ') 0%, 100% 0%, calc(100% - ' +
        angle2 +
        ') 100%, 0% 100%)'
    }
  }
});

export const CarouselImage = ({ product, classes, multiple, newTag }) => {
  const imageContainerRef = React.createRef();

  useEffect(() => {
    addAnimation(classes);
  }); // eslint-disable-next-line no-console

  const addAnimation = classes => {
    if (product.highlighted) {
      imageContainerRef.current.classList.add(classes.highlighted);
    } else {
      imageContainerRef.current.classList.remove(classes.highlighted);
    }
    if (newTag) {
      imageContainerRef.current.classList.add(classes.new);
    }
  };

  return product.highlighted ? (
    <Grow in>
      <div className={classes.default} ref={imageContainerRef}>
        <img
          className={multiple ? classes.gameImage : classes.singleImage}
          src={getImageImport(product.thumbUrl)}
          alt={product.title}
          data-test={`CarouselImage-img-${product.title}`}
        />
      </div>
    </Grow>
  ) : (
    <div className={classes.default} ref={imageContainerRef}>
      <img
        className={multiple ? classes.gameImage : classes.singleImage}
        src={getImageImport(product.thumbUrl)}
        alt={product.title}
        data-test={`CarouselImage-img-${product.title}`}
      />
    </div>
  );
};
export default withStyles(styles)(CarouselImage);

CarouselImage.propTypes = {
  classes: PropTypes.object,
  multiple: PropTypes.bool,
  newTag: PropTypes.bool,
  product: PropTypes.object.isRequired
};
