/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import React, { Component } from 'react';
import { Typography, Paper, withStyles } from '@material-ui/core';
import ProductBanner from '../../../Assets/ProductBanner.js';
import MediaQuery from 'react-responsive';
import InCytIcon from '../../../Assets/InCyt.js';
import ProductsTab from '../../ProductsTab/index';
import incytBanner from '../../../Assets/incytBanner.jpg';

export const styles = theme => ({
  root: {
    boxShadow:
      ' -5px -5px 20px 0px ' + theme.palette.primary.lightGradientBackground,
    marginLeft: '25px',
    paddingTop: '25px',
    paddingLeft: '25px',
    width: '100%'
  },
  container: {
    position: 'relative'
  },
  bottomleft: {
    width: '50%',
    height: '99.5%',
    top: '0em',
    position: 'absolute',
    display: 'flex',
    background:
      'linear-gradient(90deg, ' +
      theme.palette.primary.gradientBackground +
      ' 0%,' +
      theme.palette.primary.lightGradientBackground +
      ', ' +
      theme.palette.primary.darker +
      ' 100%)',
    right: 0,
    paddingLeft: '50%',
    flexDirection: 'column'
  },
  icon: {
    paddingRight: '0px',
    fill: '#00cbff',
    width: '3em',
    height: '3em'
  },
  borderStyle: {
    color: theme.palette.primary.colorBorder,
    borderColor: theme.palette.primary.colorBorder
  },
  header: {
    color: theme.palette.primary.darkTurquoise
  }
});

class InCyt extends Component<props, state> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedTab: undefined
    };
  }
  handleChange = (event, value) => {
    this.setState({ selectedTab: value });
  };
  windowResize = () => {
    const { classes } = this.props;
    this.eldisplay = window.innerWidth <= 768 ? classes.dispnone : '';
  };
  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.root}>
        <Typography className={classes.header} variant="h4" component="h2">
          {'CYBER AWARENESS CENTER'}
        </Typography>
        <hr className={classes.borderStyle} />
        <div className={classes.container}>
          <ProductBanner img={incytBanner} />
          <MediaQuery minWidth={768}>
            <div className={`${classes.bottomleft}`}>
              <ProductsTab
                description={
                  'inCyt, the game and learning tool, educates the entire enterprise workforce through concept-driven gameplay reflective of real-world cyber security offensive and defensive strategies and best practices practices.'
                }
                clickUrl={'/games/incyt'}
                checkBoxes={[
                  {
                    name: 'terms',
                    url:
                      'https://www.circadence.com/wp-content/uploads/2019/10/inCyt-EULA-MASTER.pdf',
                    description: 'Accept the Terms and Conditions'
                  }
                ]}
                termsURL={
                  'https://www.circadence.com/wp-content/uploads/2019/10/inCyt-EULA-MASTER.pdf'
                }
                icon={<InCytIcon className={classes.icon} />}
                data-test={`productsTab-incyt`}
              ></ProductsTab>
            </div>
          </MediaQuery>
        </div>
        <hr className={classes.borderStyle} />
      </Paper>
    );
  }
}
export default withStyles(styles)(InCyt);
