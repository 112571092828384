import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, { Component, Fragment } from 'react';

export class ConfirmationDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmationText: ''
    };
  }

  handleConfirmationText = event => {
    this.setState({
      confirmationText: event.target.value
    });
  };

  handleOpen = () => {
    this.setState({
      open: true
    });
  };

  handleClose = () => {
    const { data } = this.props;
    if (data) {
      this.props.action(data);
    } else {
      this.props.action();
    }
    this.setState({
      open: false
    });
  };

  handleCancel = () => {
    this.setState({
      open: false
    });
  };

  render() {
    const { confirmationText, open } = this.state;
    const {
      closeText,
      classes,
      description,
      title,
      showConfirmAction = false,
      confirmText
    } = this.props;
    return (
      <Fragment>
        <div onClick={() => this.handleOpen()}> {this.props.children} </div>
        <Dialog
          maxWidth={'md'}
          open={open}
          scroll={'paper'}
          classes={{
            root: classes.dialogRoot,
            paperScrollPaper: classes.dialogPaperScrollPaper
          }}
        >
          <DialogTitle className={classes.title}>{title}</DialogTitle>
          <DialogContent className={classes.content}>
            <div className={classes.contentDiv}>{description}</div>
            {showConfirmAction && (
              <div className={classes.confirmText}>
                <Typography>
                  {' '}
                  Type {confirmText.toUpperCase()} to confirm
                </Typography>
                <TextField
                  className={classes.confirmTextField}
                  required
                  onChange={event => this.handleConfirmationText(event)}
                />
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              data-test={'confirmationDialog-button-click-close'}
              size="small"
              onClick={() => this.handleCancel()}
            >
              {'Cancel'}
            </Button>
            <Button
              disabled={
                showConfirmAction
                  ? confirmationText.toLowerCase() !== confirmText.toLowerCase()
                  : false
              }
              data-test={'confirmationDialog-button-click-close'}
              size="small"
              onClick={() => this.handleClose()}
            >
              {closeText || 'Close'}
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const styles = theme => ({
  confirmText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  confirmTextField: {
    paddingLeft: '5px'
  },
  content: {
    color: theme.palette.primary.white,
    minWidth: 300,
    padding: 0,

    '& input, & select, & .Select-value-label, & textarea': {
      paddingLeft: '5px !important'
    },
    '& textarea': {
      paddingTop: '5px !important'
    },
    '& label': {
      paddingLeft: '5px',
      zIndex: 2
    }
  },
  contentDiv: {
    flex: 1,
    padding: theme.spacing.unit * 2
  },
  dialogRoot: {
    backgroundImage: theme.palette.primary.mainGradient,
    backgroundSize: 'cover'
  }
});

export default withStyles(styles)(ConfirmationDialog);
