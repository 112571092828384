/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import * as colors from './colors';
import { raisedButton } from './backgrounds';
import palette from './palette';
import { fade } from '@material-ui/core/styles/colorManipulator';

const overrides = {
  MuiAppBar: {
    colorPrimary: {
      backgroundColor: palette.background.appBar
    }
  },
  MuiButton: {
    root: {
      color: colors.colorDarkTurquoise,
      borderRadius: 0,
      letterSpacing: '.3em',
      padding: '0 .5em',
      marginTop: '0.5em',
      borderImage: raisedButton(
        colors.colorDarkTurquoise,
        colors.colorWhite,
        colors.colorDarkTurquoise
      ),
      borderImageSlice: '33%  fill',
      borderImageWidth: '8px 8px',
      '&:hover': {
        borderImage: raisedButton(
          colors.colorRed,
          colors.colorDark2,
          colors.colorRed
        ),
        borderImageSlice: '33%  fill',
        borderImageWidth: '8px 8px'
      },
      '&$disabled': {
        borderImage: raisedButton(
          colors.colorLightest,
          colors.colorLightest,
          colors.colorLightest
        ),
        borderImageSlice: '33%  fill',
        borderImageWidth: '8px 8px',
        boxShadow: 'none',
        backgroundColor: colors.colorFontDisabledBackground,
        '& span': {
          color: colors.colorFontDisabled + '!important'
        }
      }
    },
    label: {
      color: palette.primary.darkTurquoise,
      margin: '0',
      minHeight: '36px',
      '&:active': {
        transform: 'Scale(.95)'
      },
      '&:focus': {
        transform: 'Scale(.95)'
      }
    },
    flatPrimary: {},
    outlined: {
      border: 'solid 2px red',
      borderRadius: 0,
      '&$disabled': {
        borderImage: raisedButton(
          colors.colorLightest,
          colors.colorLightest,
          colors.colorLightest
        ),
        borderImageSlice: '33%  fill',
        borderImageWidth: '8px 8px',
        boxShadow: 'none'
      }
    },
    raised: {
      borderRadius: 0,
      height: '3em',
      letterSpacing: '.3em',
      marginTop: '1em',
      padding: '0 2em !important',
      clipPath:
        ' polygon( 0% 0%, 0% 0%, calc(100% - .6em) 0%, 100% .6em, 100% 100%, 100% 100%, 0% 100%, 0% 100%)',
      boxShadow: '0 0 0 0 !important',
      '&$disabled': {
        opacity: '.5 !important'
      },
      '& span': {
        color: 'black'
      }
    },
    raisedPrimary: {
      backgroundColor: colors.colorDarkTurquoise,
      border: '0px',
      '&:hover': {
        border: '0px',
        backgroundColor: 'rgb(0, 135, 131)'
      },
      '& span': {
        color: 'black'
      }
    },
    raisedSecondary: {}
  },
  MuiCardHeader: {
    title: {
      outline: 'none',
      border: 'none',
      background: 'none'
    }
  },
  MuiDialog: {
    paper: {
      borderRadius: '0',
      background: colors.colorDarkSlateGray,
      padding: '0px'
    },
    container: {
      height: '100vh'
    }
  },
  MuiFormHelperText: {
    error: {
      color: colors.colorRed
    }
  },
  MuiDialogTitle: {
    root: {
      paddingTop: '0px',
      paddingLeft: '0px',
      paddingRight: '50px',
      marginTop: '20px',
      marginLeft: '20px',
      color: palette.primary.white,
      textTransform: 'uppercase',
      fontSize: '1.3em',
      fontFamily: 'Barlow Condensed'
    }
  },
  MuiDialogContent: {
    root: {
      paddingBottom: '0px',
      paddingTop: '0px',
      marginTop: '0px',
      marginBottom: '0px',
      paddingLeft: '20px',
      paddingRight: '20px'
    }
  },
  MuiDialogActions: {
    action: {
      float: 'right'
    },
    root: {
      marginRight: '10px',
      marginTop: '36px',
      marginBottom: '10px',
      position: 'sticky !important'
    }
  },
  MuiDialogContentText: {
    root: {
      color: colors.colorWhite,
      textAlign: 'left',
      fontSize: '12px !important',
      marginTop: '20px'
    }
  },
  MuiFormLabel: {
    disabled: {
      color: fade(colors.colorWhite, 0.5) + '!important'
    },
    focused: {
      color: colors.colorDarkTurquoise + '!important'
    }
  },
  MuiMenuItem: {
    root: {
      color: colors.colorWhite,
      '&$selected': {
        backgroundColor: fade(colors.colorWhite, 0.7)
      },
      '&:hover': {
        backgroundColor: colors.colorDarkTurquoise + '!important'
      }
    }
  },
  MuiInput: {
    input: {
      paddingLeft: '.5em'
    },
    root: {
      width: '100%',
      // paddingLeft: '.4em',
      color: fade(colors.colorWhite, 0.7),
      border: '1px solid  ' + fade(colors.colorWhite, 0.5) + ' !important',
      '&$disabled': {
        color: fade(colors.colorWhite, 0.5),
        '&:hover': {
          borderWidth: '0 0 1px 0 !important'
        },
        borderWidth: '0 0 1px 0 !important',

        '&:before': {
          borderBottom: 'none !important'
        }
      },
      '&:focus': {
        color: colors.colorDarkTurquoise
      }
    },

    underline: {
      '&:before': {
        height: '100%',
        borderBottom: 'none'
      },

      '&:after': {
        marginLeft: '.2em',
        height: 'calc(100% - 1em)',
        marginBottom: '.5em',
        borderBottom: ' none !important',
        borderLeft: ' 2px solid ' + colors.colorDarkTurquoise + '  !important'
      },

      '&:hover': {
        border: '1px solid ' + fade(colors.colorDarkGrey, 0.42) + '  !important'
      },

      '&:hover:not($disabled):before': {
        marginLeft: '.2em',
        height: 'calc(100% - 1em)',
        marginBottom: '.5em',
        borderLeft:
          '1px solid ' + fade(colors.colorDarkTurquoise, 1) + '  !important',
        borderBottom: 'none !important'
      },

      '&:hover:not($disabled):after': {},
      '&$error:after': {
        borderLeft: ' 2px solid ' + colors.colorRed + '!important'
      }
    },
    multiline: {
      '&:after': {
        transformOrigin: '0% 50%'
      },
      '&$error:after': {
        top: '0'
      }
    }
  },
  MuiInputLabel: {
    root: {
      color: fade(colors.colorWhite, 0.7)
    },
    formControl: { transform: 'translate(.4em, 24px) scale(1)' }
  },
  MuiPaper: {
    root: {
      marginTop: '0px',
      marginRight: '0px',
      paddingRight: '18px',
      paddingleft: '0px',
      backgroundColor: colors.colorDarkSlateGray
    }
  },
  MuiSelect: {
    select: {}
  },
  //todo add variant
  MuiTable: {
    root: {
      borderCollapse: 'separate',
      borderSpacing: 0,
      borderColor: 'transparent'
    }
  },
  MuiTableBody: {
    root: {}
  },
  MuiTableCell: {
    root: {},
    body: {
      fontSize: '0.9em',
      color: palette.font
    },
    head: {
      fontSize: '0.8em',
      textTransform: 'uppercase',
      color: palette.font
      // backgroundColor: palette.table.evenRow
    },
    paddingCheckbox: {
      paddingLeft: '24px',
      paddingRight: '0px',
      marginRight: '0px'
    },
    //had to use this variant since 'alignleft was not working'
    paddingNone: {
      paddingLeft: '0px',
      paddingRight: '56px'
    }
  },
  MuiTablePagination: {
    caption: {
      color: colors.colorFont
    },
    select: {
      color: colors.colorFont
    },
    root: {
      paddingRight: '0px',
      marginRight: '40px',
      float: 'right'
    }
  },
  MuiTableRow: {
    root: {
      '&:nth-child(even)': {
        backgroundColor: palette.table.evenRow
      }
    },
    head: {
      backgroundColor: palette.table.evenRow
    },
    hover: {
      backgroundColor: palette.table.oddRow
    }
  },
  MuiTableSortLabel: {
    root: {
      color: palette.primary.main
    },
    active: {
      color: palette.primary.main
    }
  },
  MuiGrid: {
    'grid-xs-6': {
      flexGrow: 1,
      maxWidth: 'none',
      flexBasis: 'none'
    }
  },
  MuiSvgIcon: {
    root: {
      fill: palette.primary.white
    }
  },
  MuiTabs: {
    root: {
      backgroundColor: palette.background.appBar,
      color: palette.font,
      paddingBottom: '15px'
    }
  },
  MuiToolbar: {
    root: {
      backgroundColor: palette.background.paper
    }
  },

  MuiExpansionPanel: {
    root: {
      background: colors.colorLightSlateGray
    }
  },

  MuiTypography: {
    h3: {
      fontWeight: '400',
      margin: '1px 0 0 1px',
      color: colors.colorFont,
      background: fade(palette.black.main, 0.02),
      textTransform: 'uppercase',
      fontSize: '1.3em',
      padding: '.2em',
      minWidth: '200px',
      letterSpacing: '.3em',
      border: '5px solid ' + fade(palette.primary.colorLightest, 0.2)
    },
    h4: {
      fontWeight: '400',
      color: colors.colorFont,
      textTransform: 'uppercase',
      fontSize: '1.3em',
      padding: '.2em',
      letterSpacing: '.3em'
    },
    h5: {
      fontSize: '1.3em',
      textTransform: 'uppercase',
      letterSpacing: '.2em',
      color: colors.colorFont,
      padding: '.2em'
    },
    h6: {
      textTransform: 'uppercase',
      color: colors.colorFont,
      textAlign: 'left',
      fontSize: '1em'
    },
    subtitle1: {
      color: colors.colorFont,
      letterSpacing: '.1em',
      textTransform: 'uppercase',
      paddingLeft: '10px'
    },
    subtitle2: {
      letterSpacing: '.1em',
      textTransform: 'uppercase',
      color: colors.colorDarkTurquoise
    },
    body1: {
      fontFamily: 'Roboto',
      color: colors.colorOffWhite
    },
    body2: {
      fontFamily: 'Roboto',
      color: colors.colorOffWhite,
      fontWeight: '300'
    }
  },

  MuiCheckbox: {
    root: {
      '&$disabled': {
        '& span': {
          '& svg': {
            fill: colors.colorDarkGrey
          }
        }
      }
    }
  }
};

export default overrides;
