import React, { Component } from 'react';
import { Paper, Typography, withStyles } from '@material-ui/core';
import Carousel from '../../components/Carousel';
import { getProducts } from '../../components/Dashboard/ContentAccess/selectors';
import { queryProducts } from '../../components/Dashboard/ContentAccess/actions';
import { getProductRightsForUserId } from '../../components/Dashboard/ContentAccess/services';
import _flow from 'lodash/flow';
import { connect } from 'react-redux';
import _isEqual from 'lodash/isEqual';
import { msal } from '@circadence/auth';

const styles = theme => ({
  root: {
    boxShadow:
      '-5px -5px 20px 0px ' + theme.palette.primary.lightGradientBackground,
    marginLeft: '25px',
    paddingTop: '25px',
    paddingLeft: '25px',
    width: '100%'
  },
  container: {
    position: 'relative',
    height: '100%',
    padding: '0 0 0 8em',
    '& p': {
      maxWidth: '75ch'
    }
  },
  borderStyle: {
    color: theme.palette.primary.colorBorder,
    borderColor: theme.palette.primary.colorBorder
  },
  header: {
    color: theme.palette.primary.darkTurquoise
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    height: '100%',
    padding: '0 0 0 8em',
    position: 'relative',
    top: '0',
    left: 'calc(60% - 8em)',
    width: 'calc(40% + 8em)'
  }
});

class EssentialsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.productsByUserId || []
    };
  }
  componentDidMount() {
    if (this.props.products === undefined) {
      this.props.queryProducts();
    } else {
      this.handleData(this.props.products);
    }
  }

  componentDidUpdate = prevProps => {
    if (!_isEqual(prevProps.products, this.props.products)) {
      if (this.props.products) {
        this.handleData(this.props.products);
      }
    }
  };

  handleData = data => {
    const newProducts = [];
    getProductRightsForUserId(this.props.userId)
      .then(productAccess => {
        productAccess.forEach(productId => {
          if (
            data[productId.productId] &&
            data[productId.productId].productType === 'Cyberbridge Essentials'
          ) {
            newProducts.push(data[productId.productId]);
          }
        });
        this.setState({
          data: newProducts
        });
      })
      .catch(e => console.error(`Getting product rights failed: ${e}`));
  };

  render() {
    const { data } = this.state;
    const { classes } = this.props;
    return (
      <Paper className={classes.root}>
        <div>
          <Typography className={classes.header} variant="h4" component="h2">
            {'CYBERBRIDGE ESSENTIALS'}
          </Typography>
          <hr className={classes.borderStyle} />
          <div className={classes.container}>
            <Carousel data={data} />
          </div>
          <hr className={classes.borderStyle} />
        </div>
      </Paper>
    );
  }
}

const mapStateToProps = state => ({
  products: getProducts(state),
  userId: msal.selectIdentitySubject(state),
  productsByUserId: state.data
});

export const mapDispatchToProps = dispatch => ({
  queryProducts: () => {
    dispatch(queryProducts());
  }
});
export default _flow(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(EssentialsContainer);
