import _reduce from 'lodash/reduce';

export function arrayToObjectById(array, id) {
  return _reduce(
    array,
    (r, v) => ({
      ...r,
      [v[id]]: v
    }),
    {}
  );
}
