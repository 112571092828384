import React, { useState, useEffect } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import {
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Tooltip
} from '@material-ui/core';

export const EditGroupForm = props => {
  const {
    values: { displayName },
    group,
    errors,
    touched,
    handleChange,
    handleEditableClick,
    handleGroupNameDelete,
    handleGroupNameEdit,
    setFieldTouched,
    editedGroupId,
    existingGroups,
    classes
  } = props;

  const [editedId, setEditedId] = useState(editedGroupId);
  const [customError, setCustomError] = useState(null);
  const [editedDisplayName, setEditedDisplayName] = useState(group.displayName);

  useEffect(() => {
    setEditedId(editedGroupId);
  }, [editedGroupId]);

  useEffect(() => {
    setEditedDisplayName(group.displayName);
  }, [group]);

  const checkUnique = (range, property, value) =>
    !range.some(item => item[property] === value);

  const change = (name, e) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
    setEditedDisplayName(e.target.value);
    if (
      group.displayName !== e.target.value &&
      !checkUnique(existingGroups, 'displayName', e.target.value)
    ) {
      setCustomError('Group names must be unique.');
    } else {
      setCustomError(null);
    }
  };

  return (
    <TableRow
      key={group.id}
      onClick={event => handleEditableClick(event, group)}
    >
      <TableCell className={classes.buttonsColumn}>
        <div className={classes.flexContainer}>
          <Tooltip
            key={group.id}
            title={
              <p>
                {group.canDelete || group.canDelete === undefined
                  ? 'Delete group permanently'
                  : 'Users need to be reassigned to other groups'}
              </p>
            }
          >
            <div className={classes.buttonContainer}>
              <IconButton
                disabled={group.canDelete === false}
                className={classes.iconButton}
                classes={{ disabled: classes.disabled }}
                assign-id={group.id}
                onClick={event => handleGroupNameDelete(event, group)}
                data-test={`deleteGroup-${group.id}`}
                aria-label={`delete group ${group.displayName}`}
              >
                <DeleteIcon
                  className={
                    group.canDelete === false
                      ? classes.disabledIcon
                      : classes.icon
                  }
                />
              </IconButton>
            </div>
          </Tooltip>
          <Tooltip
            title={
              <p>
                {editedId !== group.id || Boolean(errors.displayName)
                  ? 'No Changes'
                  : 'Save changes'}
              </p>
            }
          >
            <div className={classes.buttonContainer}>
              <IconButton
                disabled={
                  displayName === group.displayName ||
                  Boolean(errors.displayName) ||
                  Boolean(customError)
                }
                className={classes.iconButton}
                classes={{ disabled: classes.disabled }}
                data-id={group.id}
                data-test={`groupSave-${group.id}`}
                aria-label={`save group ${group.displayName}`}
                onClick={event => handleGroupNameEdit(event, displayName)}
              >
                <SaveIcon
                  className={
                    displayName === group.displayName ||
                    Boolean(errors.displayName) ||
                    Boolean(customError)
                      ? classes.disabledIcon
                      : classes.icon
                  }
                />
              </IconButton>
            </div>
          </Tooltip>
        </div>
      </TableCell>
      <TableCell className={classes.groupsColumn}>
        <>
          {editedId && editedId === group.id && (
            <div className={classes.tableField}>
              <TextField
                autoFocus={true}
                error={
                  (touched.displayName && Boolean(errors.displayName)) ||
                  Boolean(customError)
                }
                fullWidth
                id={group.id}
                name="displayName"
                onChange={change.bind(null, 'displayName')}
                value={editedDisplayName}
                data-test={`displayName-${group.displayName}`}
                className={classes.newGroupNameTextField}
                helperText={
                  (touched.displayName && Boolean(errors.displayName)) ||
                  Boolean(customError)
                    ? touched.displayName && Boolean(errors.displayName)
                      ? errors.displayName
                      : customError
                    : ''
                }
              />
            </div>
          )}
          {(!editedId || editedId !== group.id) && (
            <div>{editedDisplayName}</div>
          )}
        </>
      </TableCell>
    </TableRow>
  );
};
