/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import React from 'react';

const SvgUsers = props => (
  <svg
    id="users_svg__Layer_1"
    data-name="Layer 1"
    viewBox="0 0 16.67 13.57"
    {...props}
  >
    <defs>
      <style />
    </defs>
    <path d="M12 6.63A3.63 3.63 0 009.9 0a3.55 3.55 0 00-1.57.37A3.58 3.58 0 006.77 0a3.63 3.63 0 00-2.06 6.63A6.77 6.77 0 000 13.07a.5.5 0 00.5.5.5.5 0 00.5-.5 5.77 5.77 0 1111.54 0 .5.5 0 00.5.5.5.5 0 00.5-.5 6.79 6.79 0 00-3.21-5.75 5.77 5.77 0 015.34 5.75.5.5 0 001 0A6.77 6.77 0 0012 6.63zm.58-3A2.64 2.64 0 019.9 6.27a2.7 2.7 0 01-.56-.08 3.62 3.62 0 000-5.12A2.68 2.68 0 019.9 1a2.64 2.64 0 012.64 2.63zm-8.41 0a2.64 2.64 0 112.6 2.64 2.64 2.64 0 01-2.64-2.64z" />
  </svg>
);

export default SvgUsers;
