import { REDUCER_NAME } from './reducer';
import * as getters from './reducer/getters';

export const getLastFailure = state =>
  getters.getLastFailure(state[REDUCER_NAME]);

export const isLoading = state => getters.isLoading(state[REDUCER_NAME]);

export const getReports = state => getters.getReports(state[REDUCER_NAME]);

export const getReportToken = state =>
  getters.getReportToken(state[REDUCER_NAME]);
