/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

//COLOR VARIABLES

// Orion
export const colorBlack = '#000000';
export const colorDarkBlack = '#232323';
export const colorDarkSlateBlack = '#202020';
export const colorDarkSlateGray = '#2a2a2a';
export const colorDarkTurquoise = '#00c1bc';
export const colorDark2 = '#454545';
export const colorDarkGrey = '#898989';
export const colorDarkest = '#111111';
export const colorLightSlateGray = '#393939';
export const colorGold = '#ff9e1b';
export const colorGrey = '#D6D6D6';
export const colorLightBlue = '#add8e6';
export const colorLightGrey = '#E0E0E0';
export const colorLighterGrey = '#515151';
export const colorLightest = '#f2f2f2';
export const colorMainAccent = '#005500';
export const colorOrionOrange = '#fc9a1b';
export const colorRed = '#ff5555';
export const colorOffWhite = '#fbfbfb';
export const colorOffWhiteLighter = '#fafafa';
export const colorOffWhiteMenu = '#f9f9f9';
export const colorOffWhiteDarker = '#f2f2f2';
export const colorOffWhiteBorder = '#e0e0e0';
export const colorFont = '#ffffff';
export const colorFontDisabled = '#3b3b3b';
export const colorFontDisabledBackground = '#d1d1d1';
export const colorWhite = '#ffffff';
export const colorBorder = 'rgba(255, 255, 255, 0.12)';
export const colorGradientBackground = 'rgba(32, 32, 32, 0)';
export const colorLightGradientBackground = 'rgba(0, 0, 0, 0.42)';
export const colorWhiteShadow = 'rgba(0,0,0,0.00)';
export const colorIconShadowTurquoise = 'rgba(0, 193, 188, 3.5)';
export const colorIconStrokeTurquoise = 'rgba(0,193,188,0.26)';
export const colorWhiteOpacity = 'rgba(255,255,255,0.20)';
export const colorLighterGradientBackground = 'rgba(242, 242, 239, 0)';
