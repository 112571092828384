/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import React, { Component } from 'react';
import { Typography, Paper, withStyles } from '@material-ui/core';
import ProductBanner from '../../../Assets/ProductBanner.js';
import AresIcon from '../../../Assets/Ares.js';
import ProductsTab from '../../ProductsTab/index';
import MediaQuery from 'react-responsive';
import aresBanner from '../../../Assets/aresBanner.jpg';
export const styles = theme => ({
  root: {
    boxShadow:
      '-5px -5px 20px 0px ' + theme.palette.primary.lightGradientBackground,
    marginLeft: '25px',
    paddingTop: '25px',
    paddingLeft: '25px',
    width: '100%'
  },
  container: {
    position: 'relative'
  },
  bottomleft: {
    width: '50%',
    height: '99.5%',
    top: '0em',
    position: 'absolute',
    display: 'flex',
    background:
      'linear-gradient(90deg, ' +
      theme.palette.primary.gradientBackground +
      ' 0%,' +
      theme.palette.primary.lightGradientBackground +
      ', ' +
      theme.palette.primary.darker +
      ' 100%)',
    right: 0,
    paddingLeft: '50%',
    flexDirection: 'column'
  },

  borderStyle: {
    color: theme.palette.primary.colorBorder,
    borderColor: theme.palette.primary.colorBorder
  },
  icon: {
    paddingRight: '0px',
    fill: theme.palette.primary.white,
    width: '2.5em',
    height: '2.5em'
  },
  header: {
    color: theme.palette.primary.darkTurquoise
  }
});
class Ares extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: undefined
    };
  }

  handleChange = (event, value) => {
    this.setState({ selectedTab: value });
  };
  windowResize = () => {
    const { classes } = this.props;
    this.eldisplay = window.innerWidth <= 768 ? classes.dispnone : '';
    console.log(window.innerWidth + '-----' + this.eldisplay);
  };
  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.root}>
        <div>
          <Typography className={classes.header} variant="h4" component="h2">
            {'PROJECT ARES'}
          </Typography>
          <hr className={classes.borderStyle} />
          <div className={classes.container}>
            <ProductBanner img={aresBanner} />
            <MediaQuery minWidth={768}>
              <div className={`${classes.bottomleft}`}>
                <ProductsTab
                  description={
                    'Project Ares provides cyber security teams, career changers, and individual practitioners with the opportunity to build skills and hone tactics in real-time with an immersive learning platform.'
                  }
                  clickUrl={'/games/ares'}
                  data-test={`Ares-View`}
                  icon={<AresIcon className={classes.icon} />}
                  links={[]}
                ></ProductsTab>
              </div>
            </MediaQuery>
          </div>
          <hr className={classes.borderStyle} />
        </div>
      </Paper>
    );
  }
}
export default withStyles(styles)(Ares);
