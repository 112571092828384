/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import React from 'react';

const SvgEssentials = props => (
  <svg
    id="essentials_svg__Layer_1"
    data-name="Layer 1"
    viewBox="0 0 16.62 17.27"
    {...props}
  >
    <defs>
      <style />
    </defs>
    <path
      className="cls-1"
      d="M8.31,17.27a.55.55,0,0,1-.25-.07.48.48,0,0,1-.25-.43V13.08L.25,8.71A.5.5,0,0,1,0,8.27a.52.52,0,0,1,.25-.43L2.76,6.39a.5.5,0,1,1,.5.87l-1.76,1,7.06,4.08a.52.52,0,0,1,.25.44v3.1L15.62,12V8.56L11.71,6.31a.49.49,0,0,1-.18-.68.51.51,0,0,1,.68-.19l4.16,2.4a.52.52,0,0,1,.25.43v4a.5.5,0,0,1-.25.43L8.56,17.2A.59.59,0,0,1,8.31,17.27Z"
    />
    <path
      className="cls-1"
      d="M5.48,15.85a.45.45,0,0,1-.25-.07l-5-2.87A.5.5,0,0,1,0,12.48V11.13a.5.5,0,0,1,1,0v1.06l4.73,2.73a.5.5,0,0,1-.25.93Z"
    />
    <path
      className="cls-1"
      d="M8.3,9a2.29,2.29,0,0,1-1.13-.29,1.12,1.12,0,0,1-.6-.94V1.21a1.13,1.13,0,0,1,.6-.93,2.41,2.41,0,0,1,2.26,0,1.14,1.14,0,0,1,.61.9V7.77a1.13,1.13,0,0,1-.61.94A2.26,2.26,0,0,1,8.3,9ZM7.57,7.76a.43.43,0,0,0,.11.09,1.42,1.42,0,0,0,1.24,0h0A.32.32,0,0,0,9,7.75V1.22s0,0-.12-.07a1.36,1.36,0,0,0-1.24,0c-.1.05-.12.1-.12.1V7.76Z"
    />
    <ellipse className="cls-1" cx="11.96" cy="9.06" rx="1.24" ry="0.73" />
  </svg>
);

export default SvgEssentials;
