export const setLastFailure = (state, action) => ({
  lastFailure: action.payload
});

export const setLoadingState = (state, action) => ({
  isLoading: action.payload
});

export const getReports = (state, action) => ({
  reports: action.payload
});

export const getReportToken = (state, action) => ({
  reportToken: action.payload
});
