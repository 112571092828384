/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

// @flow
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  withStyles,
  Table,
  TableBody
} from '@material-ui/core';
import React, { Component } from 'react';
import { Formik } from 'formik';
import CloseIcon from '@material-ui/icons/Close';
import _isEqual from 'lodash/isEqual';
import { newGroupSchema, groupSchema } from './GroupYupSchemas';
import { AddGroupForm } from './AddGroupForm';
import { EditGroupForm } from './EditGroupForm';

const styles = theme => ({
  addButton: {
    padding: 'auto',
    backgroundColor: theme.palette.primary.darkTurquoise,
    '&:hover': {
      transform: 'scale(1)',
      transition: '.3s all '
    },
    '&:disabled': {
      backgroundColor: theme.palette.primary.greyDark
    },
    alignSelf: 'baseline',
    marginTop: 10
  },
  buttonsColumn: {
    width: '30%',
    padding: 0
  },
  buttonContainer: {
    padding: 0,
    margin: 0
  },
  crossIcon: {
    position: 'absolute',
    top: 0,
    right: 0
  },
  dialogBoxPaper: {
    padding: '30px',
    margin: '20vh',
    maxHeight: '60vh',
    minWidth: '300px'
  },
  disabledIcon: {
    fill: theme.palette.primary.lighterGray
  },
  errorMessage: {
    size: '12px',
    color: theme.palette.primary.red
  },
  flexContainer: {
    display: 'flex',
    height: 'auto',
    justifyContent: 'space-evenly',
    alignItems: 'center'
  },
  groupsColumn: {
    width: '70%',
    padding: 0
  },
  icon: {},
  iconButton: {
    display: 'inline-block',
    paddingLeft: '5px',
    paddingRight: '5px'
  },
  newGroupNameTextField: {
    width: 180,
    maxWidth: 180,
    paddingBottom: 5
  },
  tableField: {
    display: 'flex',
    flexDirection: 'column',
    width: 180,
    maxWidth: 180
  },
  title: {
    marginLeft: '0em',
    marginTop: '0em'
  }
});

export class ManageGroupsDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groups: props.groupList.sort((a, b) =>
        a.displayName > b.displayName ? 1 : -1
      ),
      editedGroupId: null,
      editedGroupCustomer: '',
      editedGroupName: '',
      editedGroupNameValid: false,
      newGroupModel: { groupName: '' }
    };
  }

  componentDidUpdate(prevProps) {
    if (!_isEqual(prevProps.groupList, this.props.groupList)) {
      this.setState({
        editedGroupId: null,
        groups: this.props.groupList.sort((a, b) =>
          a.displayName > b.displayName ? 1 : -1
        ),
        editedGroupName: '',
        editedGroupCustomer: '',
        editedGroupNameValid: false
      });
    }
  }

  handleEditableClick = (event, group) => {
    this.setState({
      editedGroupId: group.id,
      editedGroupName: group.displayName,
      editedGroupCustomer: group.customer
    });
  };

  handleGroupNameEdit = (e, displayName) => {
    const editedGroup = {
      id: this.state.editedGroupId,
      displayName,
      customer: this.state.editedGroupCustomer
    };
    this.props.handleSaveSubmit(editedGroup);
  };

  handleGroupNameDelete = (event, group) => {
    this.props.handleDeleteSubmit(group);
  };

  handleSubmitCreateGroup = ({ groupName }, { resetForm }) => {
    this.props.handleCreateSubmit(groupName);
    resetForm({ groupName: '' });
  };

  render() {
    const { classes, open } = this.props;
    return (
      <Dialog
        onClose={this.props.handleManageGroupsDialogClose}
        open={open}
        aria-hidden={!open}
        PaperProps={{ classes: { root: classes.dialogBoxPaper } }}
      >
        <div>
          <IconButton
            className={classes.crossIcon}
            onClick={this.props.handleManageGroupsDialogClose}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent>
          <DialogTitle className={classes.title} disableTypography>
            Manage Groups
          </DialogTitle>

          <Formik
            key="newGroup"
            initialValues={this.state.newGroupModel}
            validationSchema={newGroupSchema}
            onSubmit={this.handleSubmitCreateGroup}
            enableReinitialize
          >
            {props => (
              <AddGroupForm
                {...props}
                classes={classes}
                existingGroups={this.state.groups}
              />
            )}
          </Formik>
          <Table>
            <TableBody>
              {this.state.groups.map(group => (
                <Formik
                  key={group.id}
                  initialValues={{ displayName: group.displayName }}
                  validationSchema={groupSchema}
                  enableReinitialize
                >
                  {props => (
                    <EditGroupForm
                      {...props}
                      classes={classes}
                      group={group}
                      existingGroups={this.state.groups}
                      editedGroupId={this.state.editedGroupId}
                      handleEditableClick={this.handleEditableClick}
                      handleGroupNameDelete={this.handleGroupNameDelete}
                      handleGroupNameEdit={this.handleGroupNameEdit}
                    />
                  )}
                </Formik>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles)(ManageGroupsDialog);
