/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

// @flow
import _map from 'lodash/map';
import _reduce from 'lodash/reduce';
import type { User } from './types';
import {
  GetPlayerDataRequest,
  GetUserGroupsRequest,
  QueryUserDetailsForCustomerRequest,
  QueryUserDetailsByGroupsRequest,
  ReadUserDetailsRequest,
  ReadUserDetailsFromUsernameRequest,
  GetConfigurationForUserRequest,
  RegisterUserRequest,
  RegisterUserLocalRequest,
  VerifyTokenRequest,
  InviteUserRequest,
  ResendInviteRequest,
  SearchUsersRequest,
  DeleteUserRequest,
  UpdateUserRequest,
  InvitationStatus as PBInvitationStatus,
  UserDetails as PBUserDetails,
  InvitedUser as PBInvitedUser,
  Pagination as PBPagination,
  Search as PBSearch,
  SearchOptions as PBSearchOptions,
  UserServiceClient,
  InviteUsersRequest,
  UserServicePromiseClient,
  StatusOption,
  SortCategory as PBSortCategory,
  SortType as PBSortType,
  AuthenticationType as PBAuthenticationType
} from 'user_grpc_web_pb';

import {
  GroupServicePromiseClient,
  CreateGroupRequest,
  QueryGroupsRequest,
  Group
} from 'group_grpc_web_pb';
import * as rolesConstants from '../../constants/roles';

const { protocol, host } = window.location;

const groupServiceClient = new GroupServicePromiseClient(
  `${protocol}//${host}`,
  null,
  null
);

const userServicePromiseClient = new UserServicePromiseClient(
  `${protocol}//${host}`,
  null,
  null
);

const userServiceClient = new UserServiceClient(
  `${protocol}//${host}`,
  null,
  null
);

const genericallyHandleResponse = (
  resolve: any => void,
  reject: Error => void,
  err: ?Error,
  response: any,
  convert: ?<T>(pbObject: any) => T
) => {
  if (err) {
    console.error(err.message || err);
    reject(err);
    return;
  }

  if (response === null || response === undefined) {
    reject(Error('Empty response from server.'));
    return;
  }

  resolve(convert ? convert(response) : null);
};

export function queryGroups(
  customerId: string,
  client: GroupServiceClient = groupServiceClient
): Promise<User> {
  const request = new QueryGroupsRequest();
  request.setCustomerId(customerId);
  return client
    .queryGroups(request)
    .then(response => _map(response.getGroupsList(), pbGroupToGroup));
}

export function createGroup(
  groupName,
  customerId,
  client: GroupServiceClient = groupServiceClient
): Promise<null> {
  const request = new CreateGroupRequest();
  const group = new Group();
  group.setCustomerId(customerId);
  group.setDisplayName(groupName ? groupName.trim() : '');
  request.setGroup(group);
  return client.createGroup(request).then(response => {
    const groupDetail = pbGroupDetailsToGroup(response.getGroup());
    return groupDetail;
  });
}

export function deleteGroup(
  id,
  client: GroupServiceClient = groupServiceClient
): Promise<null> {
  const group = new Group();
  group.setId(id);
  return client.deleteGroup(group);
}

export function updateGroup(
  id,
  displayName,
  client: GroupServiceClient = groupServiceClient
): Promise<null> {
  const group = new Group();
  group.setId(id);
  group.setDisplayName(displayName ? displayName.trim() : '');
  return client.updateGroup(group);
}

export function queryUserDetailsForCustomer(
  customerId,
  roleFilters,
  page,
  perPage,
  orderBy,
  order,
  client = userServicePromiseClient
) {
  const request = new QueryUserDetailsForCustomerRequest();
  request.setCustomerId(customerId);
  request.setPagination(
    paginationToPbPagination(page, perPage, orderBy, order)
  );
  return client.queryUserDetailsForCustomer(request).then(response => {
    const allUsers = _map(
      response.getUserDetailsListList(),
      pbUserDetailsToUserDetails
    );
    const pagination = pbPaginationToPagination(response.getPagination());
    const convertedAllUsers = _reduce(
      allUsers,
      (r, v) => {
        if (roleFilters.every(i => v.roles.includes(i))) {
          return [...r, v];
        } else return r;
      },
      []
    );
    return {
      users: convertedAllUsers,
      pagination
    };
  });
}

export function readUserDetails(
  b2cUserId: string,
  client: UserServiceClient = userServicePromiseClient
): Promise<User> {
  const request = new ReadUserDetailsRequest();
  request.setB2cUserId(b2cUserId);
  return client
    .readUserDetails(request)
    .then(response => pbUserDetailsToUserDetails(response.getUserDetails()));
}

export const readUserDetailsFromUsername = (
  username: string,
  client: UserServiceClient = userServiceClient
): Promise<User> =>
  new Promise((resolve, reject) => {
    const request = new ReadUserDetailsFromUsernameRequest();
    request.setUsername(username);
    client.readUserDetailsFromUsername(request, null, (err, response) => {
      genericallyHandleResponse(resolve, reject, err, response, r => {
        const userDetails = pbUserDetailsToUserDetails(r.getUserDetails());
        return userDetails;
      });
    });
  });

export function updateUser(
  userDetails: User,
  client: UserServiceClient = userServicePromiseClient
): Promise<null> {
  const request = new UpdateUserRequest();
  request.setUserDetails(userDetailsToPbUserDetails(userDetails));
  return client.updateUser(request);
}

export function inviteUser(
  user: User,
  authentication,
  client: UserServiceClient = userServicePromiseClient
): Promise<null> {
  const request = new InviteUserRequest();
  request.setInvitedUser(invitedUserToPbInvitedUser(user));
  request.setAuthentication(authenticationToPbAuthentication(authentication));
  return client
    .inviteUser(request)
    .then(response => pbUserDetailsToUserDetails(response.getUserDetails()));
}

export function inviteUsers(
  customerId,
  users: User,
  client: UserServiceClient = userServicePromiseClient
): Promise<null> {
  const request = new InviteUsersRequest();
  request.setCustomerId(customerId);
  const usersList = users.map(user => invitedUserToPbInvitedUser(user));
  request.setInvitedUsersList(usersList);
  return client.inviteUsers(request).then(response => {
    const invitedUsersDataMap = response.toObject().inviteUserResultsMap;
    return _reduce(invitedUsersDataMap, (r, v) => ({ [v[0]]: v[1], ...r }), {});
  });
}

export function registerUser(
  user: User,
  client: UserServiceClient = userServicePromiseClient
): Promise<null> {
  const request = new RegisterUserRequest();
  request.setUserDetails(userDetailsToPbUserDetails(user));
  return client.registerUser(request);
}

export const registerUserLocal = (
  user: User,
  signedToken: string,
  client: UserServiceClient = userServiceClient
): Promise<null> =>
  new Promise((resolve, reject) => {
    const request = new RegisterUserLocalRequest();
    request.setUserDetails(registerLocalUserDetailsToPbUserDetails(user));
    request.setSignedToken(signedToken);
    request.setPassword(user.password);
    client.registerUserLocal(request, null, (err, response) => {
      genericallyHandleResponse(resolve, reject, err, response, r =>
        r.toObject()
      );
    });
  });

export const verifyToken = (
  signedToken: string,
  client: UserServiceClient = userServiceClient
): Promise<null> =>
  new Promise((resolve, reject) => {
    const query = new VerifyTokenRequest();
    query.setSignedToken(signedToken);
    client.verifyToken(query, null, (err, response) => {
      genericallyHandleResponse(resolve, reject, err, response, r =>
        r.toObject()
      );
    });
  });

export const getPlayerData = (
  userId: string,
  client: UserServiceClient = userServicePromiseClient
): Promise<> => {
  const query = new GetPlayerDataRequest();
  query.setUserId(userId);
  return client.getPlayerData(query).then(response => {
    const productPlayerDataMap = response.toObject().productPlayerDataMapMap;
    return _reduce(
      productPlayerDataMap,
      (r, v) => ({ [v[0]]: pbPlayerDataToPlayerData(v[1]), ...r }),
      {}
    );
  });
};

export function getUserGroups(
  userId: string,
  client: UserServiceClient = userServicePromiseClient
): Promise<> {
  const query = new GetUserGroupsRequest();
  query.setB2cUserId(userId);
  return client
    .getUserGroups(query)
    .then(response => response.toObject().groupsList)
    .catch(err => {
      console.error(err);
    });
}

export const getConfigurationForUser = (
  username: string,
  client: UserServiceClient = userServiceClient
): Promise<null> =>
  new Promise((resolve, reject) => {
    const request = new GetConfigurationForUserRequest();
    request.setUsername(username);
    client.getConfigurationForUser(request, null, (err, response) => {
      genericallyHandleResponse(resolve, reject, err, response, r => {
        const userConfig = pbGetConfigurationForUserToUserConfig(
          r.getUserConfig()
        );
        return userConfig;
      });
    });
  });

export const resendInvite = (
  inviteUser,
  client: UserServiceClient = userServicePromiseClient
) => {
  const request = new ResendInviteRequest();
  request.setInvitedUser(invitedUserToPbInvitedUser(inviteUser));
  return client
    .resendInvite(request)
    .then(response => pbUserDetailsToUserDetails(response.getUserDetails()));
};

export function searchUsers(
  customerId: string,
  groupIds: string[],
  searchStr: string,
  options,
  page,
  perPage,
  orderBy,
  order,
  client: UserServiceClient = userServicePromiseClient
): Promise<null> {
  const request = new SearchUsersRequest();
  request.setSearch(searchToPbSearch(customerId, groupIds, searchStr, options));
  request.setPagination(
    paginationToPbPagination(page, perPage, orderBy, order)
  );
  return client.searchUsers(request).then(response => {
    const allUsers = _map(
      response.getUserDetailsListList(),
      pbUserDetailsToUserDetails
    );
    const pagination = pbPaginationToPagination(response.getPagination());
    return {
      users: allUsers,
      pagination
    };
  });
}

export function queryUserDetailsByGroups(
  groupIds,
  client: UserServiceClient = userServicePromiseClient
) {
  const request = new QueryUserDetailsByGroupsRequest();
  request.setGroupIdsList(groupIds);
  return client
    .queryUserDetailsByGroups(request)
    .then(response =>
      _map(response.getUserDetailsList(), pbUserDetailsToUserDetails)
    );
}

function searchToPbSearch(customerId, groupIds, value, options) {
  const pbSearch = new PBSearch();
  pbSearch.setCustomerId(customerId);
  pbSearch.setValue(value);
  pbSearch.setOptions(optionsPbOptions(options));
  pbSearch.setGroupIdsList(groupIds);
  return pbSearch;
}

function optionsPbOptions(options) {
  const pbOptions = new PBSearchOptions();
  pbOptions.setRoleCmtAdmin(options.cmtAdmin);
  pbOptions.setRoleCustomerAdmin(options.customerAdmin);
  pbOptions.setRolePlayer(options.player);
  pbOptions.setAresPlayer(options.aresPlayer);
  pbOptions.setAresTrainer(options.aresTrainer);
  pbOptions.setAresNone(options.aresNone);
  pbOptions.setStatus(StatusOption[options.status]);
  return pbOptions;
}

export function deleteUser( /////study this func
  userId: string,
  client: UserServiceClient = userServicePromiseClient
): Promise<null> {
  const request = new DeleteUserRequest();
  request.setB2cUserId(userId);
  return client.deleteUser(request);
}

///////////////////////////
// Utility and conversions
///////////////////////////

/**
 * Turns something that looks like a pbUser into a javascript object representing a user.
 * @param u
 * @return { User }
 */
function createRoleList(isCmtAdmin, isCustomerAdmin, isPlayer) {
  const roles = [];
  if (isCmtAdmin) {
    roles.push(rolesConstants.CMT_ADMIN);
  }

  if (isCustomerAdmin) {
    roles.push(rolesConstants.CUSTOMER_ADMIN);
  }

  if (isPlayer) {
    roles.push(rolesConstants.PLAYER);
  }
  return roles;
}

function createAresRolesList(isAresPlayer, isAresTrainer) {
  const roles = [];
  if (isAresTrainer) {
    roles.push(rolesConstants.ARES_TRAINER);
  }

  if (isAresPlayer) {
    roles.push(rolesConstants.ARES_PLAYER);
  }
  return roles;
}

function pbGetConfigurationForUserToUserConfig(pbUserConfig) {
  return {
    signUpPolicy: pbUserConfig.getSignUpPolicy(),
    signInPolicy: pbUserConfig.getSignInPolicy(),
    appScope: pbUserConfig.getAppScope(),
    clientId: pbUserConfig.getClientId(),
    authority: pbUserConfig.getAuthority(),
    domainHint: pbUserConfig.getDomainHint()
  };
}

function pbGroupToGroup(group) {
  return {
    id: group.getId(),
    displayName: group.getDisplayName(),
    customer: group.getCustomerId()
  };
}

function pbUserDetailsToUserDetails(user: any) {
  return {
    id: user.getB2cUserId(),
    customerId: user.getCustomerId(),
    userName: user.getUsername(),
    firstName: user.getFirstName(),
    lastName: user.getLastName(),
    groupIds: _map(user.getGroupIdsList(), id => id.toString()),
    roles: createRoleList(
      user.getIsCmtAdmin(),
      user.getIsCustomerAdmin(),
      user.getIsPlayer()
    ),
    aresRoles: createAresRolesList(
      user.getIsAresPlayer(),
      user.getIsAresTrainer()
    ),
    isAdult: user.getIsAdult(),
    agreedTerms: user.getAgreedTerms(),
    agreedPrivacyPolicy: user.getAgreedPrivacyPolicy(),
    invitationStatus: pbInvitationStatusToInvitationStatus(
      user.getInvitationStatus()
    ),
    lastInviteTime: secondsSinceEpochToDate(user.getLastInviteTime())
  };
}

function pbGroupDetailsToGroup(group: any) {
  return {
    id: group.getId(),
    displayName: group.getDisplayName(),
    customer: group.getCustomerId()
  };
}

function pbInvitationStatusToInvitationStatus(user) {
  switch (user) {
    case 1:
      return 'PENDING';
    case 2:
      return 'ACCEPTED';
    case 3:
      return 'NOTINVITED';
    default:
      return 'INVALID';
  }
}

function authenticationToPbAuthentication(authentication) {
  switch (authentication) {
    case 'LOCAL':
      return PBAuthenticationType.LOCAL;
    case 'NON_LOCAL':
      return PBAuthenticationType.NON_LOCAL;
    default:
      return PBAuthenticationType.INVALID_AUTH;
  }
}

function invitedUserToPbInvitedUser(user) {
  const pbInvitedUser = new PBInvitedUser();
  pbInvitedUser.setFirstName(user.firstName ? user.firstName.trim() : '');
  pbInvitedUser.setLastName(user.lastName ? user.lastName.trim() : '');
  pbInvitedUser.setCustomerId(user.customer);
  pbInvitedUser.setUsername(user.userName ? user.userName.trim() : '');
  pbInvitedUser.setGroupIdsList(user.groups);
  pbInvitedUser.setIsCmtAdmin(user.roles.includes(rolesConstants.CMT_ADMIN));
  pbInvitedUser.setIsCustomerAdmin(
    user.roles.includes(rolesConstants.CUSTOMER_ADMIN)
  );
  pbInvitedUser.setIsPlayer(user.roles.includes(rolesConstants.PLAYER));
  pbInvitedUser.setIsAresTrainer(
    user.aresRoles.includes(rolesConstants.ARES_TRAINER)
  );
  pbInvitedUser.setIsAresPlayer(
    user.aresRoles.includes(rolesConstants.ARES_PLAYER)
  );
  return pbInvitedUser;
}

function registerLocalUserDetailsToPbUserDetails(user) {
  const pbUserDetails = new PBUserDetails();
  pbUserDetails.setCustomerId(user.customerId);
  pbUserDetails.setUsername(user.userName);
  pbUserDetails.setIsAdult(user.isAdult);
  pbUserDetails.setAgreedTerms(user.agreedTerms);
  pbUserDetails.setAgreedPrivacyPolicy(user.agreedPrivacyPolicy);
  return pbUserDetails;
}

function userDetailsToPbUserDetails(user) {
  const pbUserDetails = new PBUserDetails();
  pbUserDetails.setB2cUserId(user.id);
  pbUserDetails.setUsername(user.userName ? user.userName.trim() : '');
  pbUserDetails.setFirstName(user.firstName ? user.firstName.trim() : '');
  pbUserDetails.setLastName(user.lastName ? user.lastName.trim() : '');
  pbUserDetails.setIsCmtAdmin(user.roles.includes(rolesConstants.CMT_ADMIN));
  pbUserDetails.setIsCustomerAdmin(
    user.roles.includes(rolesConstants.CUSTOMER_ADMIN)
  );
  pbUserDetails.setIsPlayer(user.roles.includes(rolesConstants.PLAYER));
  pbUserDetails.setIsAresTrainer(
    user.aresRoles.includes(rolesConstants.ARES_TRAINER)
  );
  pbUserDetails.setIsAresPlayer(
    user.aresRoles.includes(rolesConstants.ARES_PLAYER)
  );
  pbUserDetails.setCustomerId(user.customerId);
  pbUserDetails.setIsAdult(user.isAdult);
  pbUserDetails.setAgreedTerms(user.agreedTerms);
  pbUserDetails.setAgreedPrivacyPolicy(user.agreedPrivacyPolicy);
  pbUserDetails.setGroupIdsList(user.groupIds);
  pbUserDetails.setInvitationStatus(
    invitationStatusToPbInvitationStatus(user.invitationStatus)
  );
  return pbUserDetails;
}

function invitationStatusToPbInvitationStatus(status) {
  switch (status) {
    case 'PENDING':
      return PBInvitationStatus.PENDING_ACCEPTANCE;
    case 'ACCEPTED':
      return PBInvitationStatus.ACCEPTED;
    case 'INVALID':
      return PBInvitationStatus.UNKNOWN_STATUS;
    default:
      return PBInvitationStatus.UNKNOWN_STATUS;
  }
}

function sortCategoryToPbSortCategory(category) {
  switch (category) {
    case 'userNameSortable':
      return PBSortCategory.SORT_USERNAME;
    case 'firstNameSortable':
      return PBSortCategory.SORT_FIRSTNAME;
    case 'lastNameSortable':
      return PBSortCategory.SORT_LASTNAME;
    case 'customerNameSortable':
      return PBSortCategory.SORT_CUSTOMER;
    case 'lastInviteTimeSortable':
      return PBSortCategory.SORT_LAST_INVITE_TIME;
    default:
      return PBSortCategory.SORT_CATEGORY_INVALID;
  }
}

function sortOrderToPbSortOrder(type) {
  switch (type) {
    case 'asc':
      return PBSortType.SORT_TYPE_ASCENDING;
    case 'desc':
      return PBSortType.SORT_TYPE_DESCENDING;
    default:
      return PBSortType.SORT_TYPE_INVALID;
  }
}

function paginationToPbPagination(page, perPage, sortCategory, sortOrder) {
  const pbPagination = new PBPagination();
  pbPagination.setPage(page);
  pbPagination.setPerPage(perPage);
  pbPagination.setSortCategory(sortCategoryToPbSortCategory(sortCategory));
  pbPagination.setSortOrder(sortOrderToPbSortOrder(sortOrder));
  return pbPagination;
}

function pbPaginationToPagination(pagination) {
  return {
    page: pagination.getPage(),
    perPage: pagination.getPerPage(),
    count: pagination.getCount()
    //TODO
    // sortCategory: pbSortCategoryToSortCategory(pagination.getSortCategory());
  };
}

function pbPlayerDataToPlayerData(playerData) {
  return {
    displayName: playerData.displayName,
    experience: playerData.experience,
    lastLogin: playerData.lastLogin.seconds
  };
}

function secondsSinceEpochToDate(unixTimestamp) {
  let months_arr;
  months_arr = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];
  const date = new Date(unixTimestamp * 1000);
  const year = date.getFullYear();
  const month = months_arr[date.getMonth()];
  const day = date.getDate();
  let hours = date.getHours();
  const minutes = '0' + date.getMinutes();
  const ampm = hours >= 12 ? ' PM' : ' AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  const formattedTime =
    month +
    ' ' +
    day +
    ' ' +
    year +
    ' ' +
    hours +
    ':' +
    minutes.substr(-2) +
    ampm;
  if (year < 2019) {
    return '';
  }

  return formattedTime;
}
