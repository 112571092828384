/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import type {
  ContentAccessState,
  GetContentAction,
  QueryProductsAction
} from '../types';
import _reduce from 'lodash/reduce';
import { getContentByProductId } from './getters';

export const queryProducts = (
  state: ContentAccessState,
  action: QueryProductsAction
): $Shape<ContentAccessState> => {
  const { payload } = action;
  return {
    products: payload
      ? _reduce(
          payload,
          (r, v) => {
            r[v.id] = v;
            return r;
          },
          {}
        )
      : {}
  };
};

export const getAllContent = (
  state: ContentAccessState,
  action: GetContentAction
): $Shape<ContentAccessState> => {
  const { content } = action.payload;
  const uniqueProducts = [...new Set(content.map(obj => obj.productId))];
  const newContent = [];
  uniqueProducts.forEach(prod => {
    const contents = content.filter(
      item => item.productId.indexOf(prod) !== -1
    );
    newContent.push({
      [prod]: contents
    });
  });
  const oldContentByProductId = getContentByProductId(state);
  const contentObj = Object.assign({}, ...newContent.map(obj => ({ ...obj })));
  return {
    contentByProductId: {
      ...oldContentByProductId,
      ...contentObj
    }
  };
};

export const getContent = (
  state: ContentAccessState,
  action: GetContentAction
): $Shape<ContentAccessState> => {
  const { content, productId } = action.payload;
  const productContent = content
    ? _reduce(
        content,
        (r, v) => {
          r[v.id] = v;
          return r;
        },
        {}
      )
    : {};
  const oldContentByProductId = getContentByProductId(state);
  return {
    contentByProductId: {
      ...oldContentByProductId,
      [productId]: productContent
    }
  };
};

export const setLoadingState = (
  state: ContentAccessState,
  action: SetLoadingStateAction
): $Shape<ContentAccessState> => ({
  isLoading: action.payload
});

export const setLastFailure = (
  state: ContentAccessState,
  action: SetLastFailureAction
): $Shape<ContentAccessState> => ({
  lastFailure: action.payload
});

export const dismissError = (): $Shape<ContentAccessState> => ({
  lastFailure: undefined
});
