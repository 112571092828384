/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import React, { Component } from 'react';
import Catalog from '../../Catalog/Catalog.view';
import { withStyles, Typography } from '@material-ui/core';
import CustomerCard from './CustomerCard';
import CustomerForm from './CustomerForm';
import { Formik } from 'formik';
import CustomerInfoForm from './CustomerInfoForm';
import { customerValidationSchema } from './CustomerValidationSchema';
import { connect } from 'react-redux';
import { getCustomers, getNotDeletedAdminsByCustomer } from '../selectors';
import {
  queryCustomersAction,
  updateCustomer,
  deleteCustomer,
  createCustomer,
  queryCustomerAdminsAction
} from '../actions';
import _flow from 'lodash/flow';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import { dismissErrorAction } from '../actions';
import { getLastFailure } from '../selectors';
import { getLicenses } from '../../LicenseManagement/selectors';
import { queryLicenseForCustomerAction } from '../../LicenseManagement/actions';
import {
  setDefaultLicense,
  unsetDefaultLicense
} from '../../../../components/Dashboard/LicenseManagement/services';
import { customerAdminRoleOptions } from '../../../../modules/accountManagement/ui/AccountManagement/constants';

export class CustomerManagement extends Component {
  state = {
    selectedCardId: undefined,
    createCardDialogOpen: false
  };

  componentDidMount() {
    if (this.props.licensesById === undefined) {
      this.props.queryLicenseForCustomer(''); //empty string for customerId arg b/c cmt wants all customer licenses
    }
    if (this.props.customerById === undefined) {
      this.props.queryCustomers();
    }
    this.props.queryAdmins();
  }

  displayCustomerCard = customer => (
    <CustomerCard
      key={customer.id || 0}
      customer={customer}
      hide={customer.hide}
      selectedCardId={this.state.selectedCardId}
      handleSelectCard={this.handleSelectCard}
      handleDeleteCard={this.handleDeleteCustomer}
      data-test={`customerCard-customer-${customer}`}
    />
  );

  displayCustomerEditForm = customer => {
    const admins = customer ? this.props.adminsByCustomer[customer.id] : [];
    const customerLicenses = customer
      ? _filter(
          this.props.licensesById,
          license => license.customerId === customer.id
        )
      : [];
    const defaultLicense = _find(
      customerLicenses,
      license => license.isDefaultLicense
    );
    return (
      customer && (
        <CustomerForm
          customer={customer}
          admins={admins}
          updateCustomer={this.handleUpdateCustomer}
          data-test={`editCustomerForm-customer-${customer}`}
          licenses={customerLicenses}
          defaultLicense={defaultLicense}
          setDefaultLicense={this.handleSetDefaultLicense}
          roleOptions={customerAdminRoleOptions}
        />
      )
    );
  };

  displayCreateCustomerForm = () => {
    const newCustomer = {
      name: '',
      id: '',
      accountType: '',
      authenticationType: '',
      defaultGroup: '',
      notes: ''
    };
    return (
      <Formik
        validationSchema={customerValidationSchema}
        initialValues={newCustomer}
        onSubmit={this.handleCreateCustomer}
        enableReinitialize
      >
        {props => (
          <CustomerInfoForm
            {...props}
            editable={true}
            data-test={`createNewCustomerForm`}
          />
        )}
      </Formik>
    );
  };

  handleDialogClose = () => {
    this.setState({ createCardDialogOpen: false });
  };

  handleDialogOpen = () => {
    this.setState({ createCardDialogOpen: true });
  };

  handleSelectCard = event => {
    const { id } = event.currentTarget;

    this.setState(state => ({
      selectedCardId: state.selectedCardId !== id ? id : undefined
    }));
  };

  selectCard = cardId => {
    this.setState({ selectedCardId: cardId });
  };

  handleDeleteCustomer = customer => {
    this.props.deleteCustomer(customer);
  };

  handleCreateCustomer = customer => {
    this.props.createCustomer(customer);
    this.handleDialogClose();
  };

  handleUpdateCustomer = customer => {
    this.props.updateCustomer(customer);
  };

  handleSetDefaultLicense = event => {
    const licenseId = event.target.value;
    const customerId = event.currentTarget.dataset.customer;
    if (event.target.value === 'no-default-license') {
      //the following is to determine the current default license in order to unset it
      const customerLicenses = _filter(
        this.props.licensesById,
        license => license.customerId === customerId
      );
      const defaultLicense = _find(
        customerLicenses,
        license => license.isDefaultLicense
      );
      unsetDefaultLicense(defaultLicense.id).then(() =>
        this.props.queryLicenseForCustomer('')
      );
    } else {
      setDefaultLicense(licenseId).then(() =>
        this.props.queryLicenseForCustomer('')
      );
    }
  };

  sortCustomers = customerById =>
    customerById
      ? Object.values(customerById).sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        )
      : null;

  render() {
    const { customerById, classes } = this.props;
    const { selectedCardId } = this.state;
    return (
      <div className={classes.paper}>
        <Typography className={classes.header} variant="h4" component="h4">
          Customer Management
        </Typography>
        <Catalog
          catalogList={this.sortCustomers(customerById)}
          displayCard={this.displayCustomerCard}
          displayEditForm={this.displayCustomerEditForm}
          selectedCardId={selectedCardId}
          selectCard={this.selectCard}
          displayCreateCard={this.displayCreateCustomerForm}
          handleCreateCard={this.handleCreateCustomer}
          createCardDialogOpen={this.state.createCardDialogOpen}
          handleDialogOpen={this.handleDialogOpen}
          handleDialogClose={this.handleDialogClose}
          error={this.props.lastFailure}
          handleDismissError={this.props.dismissError}
        />
      </div>
    );
  }
}

export const styles = (theme: any) => ({
  paper: {
    borderTop: `1px solid ${theme.palette.black.main}`,
    borderBottom: `1px solid ${theme.palette.black.main}`,
    paddingTop: '25px',
    width: '100%',
    boxShadow: ' -5px -5px 20px 0px rgba(0,0,0,0.12);',
    marginLeft: '25px',
    paddingLeft: '25px'
  },
  cardDetails: {
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    color: theme.palette.primary.darkTurquoise
  }
});

export const mapStateToProps = state => ({
  customerById: getCustomers(state),
  adminsByCustomer: getNotDeletedAdminsByCustomer(state),
  lastFailure: getLastFailure(state),
  licensesById: getLicenses(state)
});

export const mapDispatchToProps = dispatch => ({
  queryCustomers: () => {
    dispatch(queryCustomersAction());
  },
  updateCustomer: customer => {
    dispatch(updateCustomer(customer));
  },
  deleteCustomer: customer => {
    dispatch(deleteCustomer(customer));
  },
  createCustomer: customer => {
    dispatch(createCustomer(customer));
  },
  queryAdmins: () => {
    dispatch(queryCustomerAdminsAction());
  },
  queryLicenseForCustomer: customerId => {
    dispatch(queryLicenseForCustomerAction(customerId));
  },
  dismissError: () => {
    dispatch(dismissErrorAction());
  }
});

export default _flow(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(CustomerManagement);
