/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import React from 'react';

const SvgCustomers = props => (
  <svg
    id="customers_svg__Layer_1"
    data-name="Layer 1"
    viewBox="0 0 16.66 13.57"
    {...props}
  >
    <defs>
      <style />
    </defs>
    <path d="M8.82 6.62A3.63 3.63 0 006.77 0a3.63 3.63 0 00-2.06 6.63A6.77 6.77 0 000 13.07a.5.5 0 00.5.5.5.5 0 00.5-.5 5.77 5.77 0 1111.54 0 .5.5 0 00.5.5.5.5 0 00.5-.5 6.8 6.8 0 00-4.72-6.45zm-4.69-3a2.64 2.64 0 112.64 2.65 2.64 2.64 0 01-2.64-2.64zM16.16 3.13h-1.64V1.5a.5.5 0 00-1 0v1.63h-1.64a.51.51 0 00-.5.5.5.5 0 00.5.5h1.64v1.64a.5.5 0 001 0V4.13h1.64a.5.5 0 00.5-.5.51.51 0 00-.5-.5z" />
  </svg>
);

export default SvgCustomers;
