/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

// @flow
import type { SessionUserState } from '../types';

// export const getSessionUserRoles = (state: SessionUserState) => state.roles;

export const getIsLoading = (state: SessionUserState) => state.isLoading;

export const getLastFailure = (state: SessionUserState) => state.lastFailure;

export const getSessionUserPlayFabData = (state: SessionUserState) =>
  state.playFabData;

export const getSessionUserRoles = (state: SessionUserState) =>
  state.userDetails.roles;

export const getSessionUserId = (state: SessionUserState) =>
  state.userDetails.id;

export const getUsername = (state: SessionUserState) =>
  state.userDetails.userName;

export const getCustomerId = (state: SessionUserState) =>
  state.userDetails.customerId;

export const getSessionUserDetails = (state: SessionUserState) =>
  state.userDetails;
