/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

/* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup';

const minimumChars = 1;
const maximumChars = 255;

export const licenseValidationSchema = Yup.object({
  name: Yup.string('Enter a license name')
    .trim()
    .required('A license name is required')
    .max(maximumChars, 'A name can have at most ${max} characters')
    .min(minimumChars, 'A name must have at least ${min} character'),
  startTime: Yup.string('Enter a license name').required(
    'A start time is required'
  ),
  endTime: Yup.string('Enter a license name').required(
    'An end time is required'
  ),
  customerId: Yup.string('Enter a customer name').required(
    'A customer is required'
  )
});
