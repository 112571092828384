/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

//@flow
import type { Dispatch } from 'redux';
import type {
  License,
  CreateLicenseAction,
  UpdateLicenseAction,
  DeleteLicenseAction,
  QueryLicensesAction
} from './types';
import * as licenseService from './services';

export const _createLicenseAction = (
  license: License
): CreateLicenseAction => ({
  type: 'LICENSE_MANAGEMENT/CREATE_LICENSE',
  payload: license
});

export const _updateLicenseAction = (
  license: License
): UpdateLicenseAction => ({
  type: 'LICENSE_MANAGEMENT/UPDATE_LICENSE',
  payload: license
});

export const _deleteLicenseAction = (
  licenseId: string
): DeleteLicenseAction => ({
  type: 'LICENSE_MANAGEMENT/DELETE_LICENSE',
  payload: licenseId
});

export const _queryLicenses = (licenses: License[]): QueryLicensesAction => ({
  type: 'LICENSE_MANAGEMENT/QUERY_LICENSES',
  payload: licenses
});

export const dismissErrorAction = () => ({
  type: 'LICENSE_MANAGEMENT/DISMISS_ERROR',
  payload: {}
});

export const setLastFailureAction = message => ({
  type: 'LICENSE_MANAGEMENT/SET_LAST_FAILURE',
  payload: message
});

/*****************
 * Async actions
 *****************/
const asyncTemplate = async (
  dispatch: Dispatch<*>,
  logic: () => Promise<*>,
  onErr: ?(Error) => Promise<*>
) => {
  try {
    await logic();
  } catch (err) {
    const message = err.message || `${err}`;
    dispatch(setLastFailureAction({ errorType: 'generic', errors: [message] }));
    onErr && onErr(message);
    console.error('error from service client: ', message);
  }
};

export const queryLicenseForCustomerAction = customerId => async (
  dispatch: Dispatch<*>
) => {
  if (customerId === '') {
    await asyncTemplate(dispatch, async () => {
      const licenses = await licenseService.queryLicense();
      dispatch(_queryLicenses(licenses));
    });
  } else {
    await asyncTemplate(dispatch, async () => {
      const licenses = await licenseService.queryLicenseForCustomer(customerId);
      dispatch(_queryLicenses(licenses));
    });
  }
};

export const createLicenseAction = (license: License) => async (
  dispatch: Dispatch<*>
) => {
  await asyncTemplate(dispatch, async () => {
    const validLicense = await licenseService.createLicense(license);
    dispatch(_createLicenseAction(validLicense));
  });
};

export const updateLicenseAction = (license: License) => async (
  dispatch: Dispatch<*>
) => {
  await asyncTemplate(dispatch, async () => {
    const validLicense = await licenseService.updateLicense(license);
    dispatch(_updateLicenseAction(validLicense));
  });
};

export const deleteLicenseAction = (license: License) => async (
  dispatch: Dispatch<*>
) => {
  await asyncTemplate(dispatch, async () => {
    await licenseService.deleteLicense(license.id);
    dispatch(_deleteLicenseAction(license.id));
  });
};
