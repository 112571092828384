/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import type { Dispatch } from 'redux';
import type {
  Customer,
  CreateCustomerAdminAction,
  UpdateCustomerAdminAction,
  DeleteCustomerAdminAction,
  CreateCustomerAction,
  UpdateCustomerAction,
  DeleteCustomerAction,
  SetLastFailureAction,
  SetLoadingStateAction,
  QueryCustomersAction,
  QueryCustomerAdminsAction
} from './types';
import type { User } from '../../../modules/accountManagement/types';
import * as customerServices from './services';
import * as userServices from '../../../modules/accountManagement/services';
import * as roleConstants from '../../../constants/roles';

/*******************
 * Private actions - these are actions that should not be directly invoked by the user but are part of other
 * async actions.
 *******************/

export const _createCustomerAdminAction = (
  admin: User
): CreateCustomerAdminAction => ({
  type: 'CUSTOMER_MANAGEMENT/CREATE_CUSTOMER_ADMIN',
  payload: admin
});

export const _updateCustomerAdminAction = (
  admin: User
): UpdateCustomerAdminAction => ({
  type: 'CUSTOMER_MANAGEMENT/UPDATE_CUSTOMER_ADMIN',
  payload: admin
});

export const _deleteCustomerAdminAction = (
  adminId: string,
  customerId: string
): DeleteCustomerAdminAction => ({
  type: 'CUSTOMER_MANAGEMENT/DELETE_CUSTOMER_ADMIN',
  payload: adminId,
  customerId
});

export const _createCustomerAction = (
  customer: Customer
): CreateCustomerAction => ({
  type: 'CUSTOMER_MANAGEMENT/CREATE_CUSTOMER',
  payload: customer
});

export const _updateCustomerAction = (
  customer: Customer
): UpdateCustomerAction => ({
  type: 'CUSTOMER_MANAGEMENT/UPDATE_CUSTOMER',
  payload: customer
});

export const _deleteCustomerAction = (
  customerId: string
): DeleteCustomerAction => ({
  type: 'CUSTOMER_MANAGEMENT/DELETE_CUSTOMER',
  payload: customerId
});

export const _setLoadingStateAction = (
  value: boolean
): SetLoadingStateAction => ({
  type: 'CUSTOMER_MANAGEMENT/SET_LOADING_STATE',
  payload: value
});

export const _completeQueryCustomersAction = (
  customers: Customer[]
): QueryCustomersAction => ({
  type: 'CUSTOMER_MANAGEMENT/QUERY_CUSTOMERS',
  payload: customers
});

export const _completeQueryCustomerAdminsAction = (
  admins: User[]
): QueryCustomerAdminsAction => ({
  type: 'CUSTOMER_MANAGEMENT/QUERY_CUSTOMER_ADMINS',
  payload: admins
});

export const _readCustomer = customer => ({
  type: 'CUSTOMER_MANAGEMENT/READ_CUSTOMER',
  payload: customer
});

/*****************
 * Actions
 *****************/

export const dismissErrorAction = () => ({
  type: 'CUSTOMER_MANAGEMENT/DISMISS_ERROR',
  payload: {}
});

export const setLastFailureAction = (
  message: ?string
): SetLastFailureAction => ({
  type: 'CUSTOMER_MANAGEMENT/SET_LAST_FAILURE',
  payload: message
});

/*****************
 * Async actions
 *****************/

const asyncTemplate = async (
  dispatch: Dispatch<*>,
  logic: () => Promise<*>,
  onErr: ?(Error) => Promise<*>
) => {
  dispatch(_setLoadingStateAction(true));
  try {
    await logic();
  } catch (err) {
    const message = err.message || `${err}`;
    dispatch(setLastFailureAction({ errorType: 'generic', errors: [message] }));
    onErr && onErr(message);
    console.error('error from service client: ', message);
  }
  dispatch(_setLoadingStateAction(false));
};

export const queryCustomersAction = () => async (dispatch: Dispatch<*>) => {
  await asyncTemplate(dispatch, async () => {
    const customers = await customerServices.queryCustomer();
    dispatch(_completeQueryCustomersAction(customers));
  });
};

export const readCustomer = () => async dispatch => {
  await asyncTemplate(dispatch, async () => {
    const customers = await customerServices.readCustomer();
    dispatch(_readCustomer(customers));
  });
};

export const createCustomer = (customer: Customer) => async (
  dispatch: Dispatch<*>
) => {
  await asyncTemplate(dispatch, async () => {
    const validCustomer = await customerServices.createCustomer(customer);
    dispatch(_createCustomerAction(validCustomer));
  });
};

export const updateCustomer = (customer: Customer) => async (
  dispatch: Dispatch<*>
) => {
  await asyncTemplate(dispatch, async () => {
    const validCustomer = await customerServices.updateCustomer(customer);
    dispatch(_updateCustomerAction(validCustomer));
  });
};

export const deleteCustomer = (customer: Customer) => async (
  dispatch: Dispatch<*>
) => {
  await asyncTemplate(dispatch, async () => {
    await customerServices.deleteCustomer(customer.id);
    dispatch(_deleteCustomerAction(customer.id));
  });
};

export const queryCustomerAdminsAction = () => async (
  dispatch: Dispatch<*>
) => {
  await asyncTemplate(dispatch, async () => {
    const page = 1;
    const maxPerPage = 200;
    const userDetails = await userServices.queryUserDetailsForCustomer(
      '',
      [roleConstants.CUSTOMER_ADMIN],
      page,
      maxPerPage
    );
    const totalPages = Math.ceil(userDetails.pagination.count / maxPerPage);
    for (let i = 2; i < totalPages; i++) {
      const results = await userServices.queryUserDetailsForCustomer(
        '',
        [roleConstants.CUSTOMER_ADMIN],
        i,
        maxPerPage
      );
      results.users.forEach(item => {
        userDetails.users.push(item);
      });
    }
    dispatch(_completeQueryCustomerAdminsAction(userDetails.users));
  });
};

export const updateAdmin = admin => async (dispatch: Dispatch<*>) => {
  await asyncTemplate(dispatch, async () => {
    await userServices.updateUser(admin);
    dispatch(_updateCustomerAdminAction(admin));
  });
};
