/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import React, { Component } from 'react';
import {
  withStyles,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  ExpansionPanel,
  Typography,
  Button
} from '@material-ui/core';
import ContentSubscription from './ContentSubscription';
import ProductSubscription from './ProductSubscription';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { connect } from 'react-redux';
import {
  getProducts,
  getContentByProductId,
  getLastFailure,
  isLoading
} from '../selectors';
import { getLicenses } from '../../LicenseManagement/selectors';
import { updateLicenseAction } from '../../LicenseManagement/actions';
import _reduce from 'lodash/reduce';
import _map from 'lodash/reduce';
import _flow from 'lodash/flow';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import _filter from 'lodash/filter';
import _findIndex from 'lodash/findIndex';
import _cloneDeep from 'lodash/cloneDeep';

export class ContentAccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shouldContentDisplay: false,
      license: _cloneDeep(props.license),
      isSaveDisabled: this.isSaveDisabledByProduct(),
      newContentSubscription: {}
    };
  }

  componentDidUpdate = prevProps => {
    if (!_isEqual(this.props.license, prevProps.license)) {
      this.setState({
        license: this.props.license,
        newContentSubscription: {}
      });
    }
  };

  shouldContentDisplay = shouldContentDisplay => {
    const indexOfProduct = _findIndex(
      this.props.license.productSubscriptions,
      sub => sub.productId === shouldContentDisplay
    );

    this.setState({
      shouldContentDisplay: indexOfProduct !== -1
    });
  };

  isSaveDisabledByProduct = () =>
    _map(this.props.productsById, (r, v) => ({ ...r, [v.id]: true }), {});

  doesExceedMaxNumUsers = (value, productName) => {
    const productSubscriptionsByProduct = _reduce(
      this.state.license.productSubscriptions,
      (r, v) => ({ ...r, [v.productId]: v }),
      {}
    );
    return (
      Number(value) <= productSubscriptionsByProduct[productName].maxNumOfUsers
    );
  };

  render() {
    const {
      productsById = {},
      contentByProductId,
      classes,
      isEditable
    } = this.props;
    const { shouldContentDisplay, isSaveDisabled, license } = this.state;
    const productSubscriptionsByProduct = _reduce(
      license.productSubscriptions,
      (r, v) => ({ ...r, [v.productId]: v }),
      {}
    );
    return Object.values(productsById).map(product => {
      const contentByType = this.contentByType(
        contentByProductId[product.id],
        product.id
      );
      const showPanel = isEditable
        ? true
        : !!productSubscriptionsByProduct[product.id];
      return (
        <div key={product.id}>
          {showPanel && (
            <ExpansionPanel
              defaultExpanded
              classes={{ root: classes.expansionPanel }}
              key={product.id}
              data-test={`expansion-panel-product-${product.id}`}
            >
              {isEditable && (
                <Button
                  disabled={isSaveDisabled[product.id]}
                  onClick={() => this.updateLicense(product.id)}
                  className={classes.saveButton}
                  data-test={`save-license-button-${this.state.license}`}
                >
                  Save
                </Button>
              )}
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                data-id="edit-content-access"
              >
                <Typography
                  className={classes.header}
                  variant="h4"
                  component="h4"
                >
                  {product.title} Content Access
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.expansionDetails}>
                <ProductSubscription
                  productSubscription={
                    productSubscriptionsByProduct[product.id]
                      ? productSubscriptionsByProduct[product.id]
                      : {}
                  }
                  product={product}
                  shouldContentDisplay={this.shouldContentDisplay}
                  updateProductSubscription={this.updateProductSubscription}
                  isEditable={isEditable}
                />
                {!_isEmpty(contentByType) &&
                  (shouldContentDisplay ||
                    productSubscriptionsByProduct[product.id]) && (
                    <ContentSubscription
                      contentByType={contentByType}
                      contentSubscription={license.contentSubscription}
                      doesExceedMaxNumUsers={this.doesExceedMaxNumUsers}
                      updateContentSubscription={this.updateContentSubscription}
                      product={product}
                      isEditable={isEditable}
                    />
                  )}
              </ExpansionPanelDetails>
            </ExpansionPanel>
          )}
        </div>
      );
    });
  }

  updateContentSubscription = (newContentSubscription, product) => {
    //Store content changes in state under newContentSubscription until save
    this.setState(prevState => ({
      isSaveDisabled: {
        ...prevState.isSaveDisabled,
        [product.id]: false
      },
      newContentSubscription
    }));
  };

  updateProductSubscription = (newProductSubscription, product) => {
    this.setState(prevState => {
      let productSubscriptions;
      let newContents =
        newProductSubscription.productId === product.id &&
        !_isEmpty(prevState.newContentSubscription)
          ? prevState.newContentSubscription
          : prevState.license.contentSubscription;
      const indexOfProductSub = _findIndex(
        prevState.license.productSubscriptions,
        sub => sub.productId === newProductSubscription.productId
      );
      if (newProductSubscription.maxNumOfUsers === undefined) {
        productSubscriptions = _filter(
          prevState.license.productSubscriptions,
          prodSub => prodSub.productId !== newProductSubscription.productId
        );
        newContents = {};
      } else {
        if (indexOfProductSub < 0) {
          prevState.license.productSubscriptions.push(newProductSubscription);
        } else {
          prevState.license.productSubscriptions.splice(
            indexOfProductSub,
            1,
            newProductSubscription
          );
        }
        // eslint-disable-next-line prefer-destructuring
        productSubscriptions = prevState.license.productSubscriptions;
      }
      return {
        license: {
          ...prevState.license,
          productSubscriptions,
          contentSubscription: newContents
        },
        isSaveDisabled: {
          ...prevState.isSaveDisabled,
          [product.id]: false
        }
      };
    });
  };

  updateLicense = productId => {
    const { updateLicense, license } = this.props;
    let newLicense = _cloneDeep(license);
    if (!_isEmpty(this.state.newContentSubscription)) {
      newLicense = {
        ...newLicense,
        contentSubscription: this.state.newContentSubscription
      };
      this.setState({
        license: newLicense
      });
    }
    const newLicenseIdx = newLicense.productSubscriptions.findIndex(
      product => product.productId === productId
    );
    const stateLicenseIdx = this.state.license.productSubscriptions.findIndex(
      product => product.productId === productId
    );

    // removing product subscription
    if (stateLicenseIdx === -1) {
      newLicense.productSubscriptions.splice(newLicenseIdx, 1);
      if (productId === 'ares') {
        newLicense.contentSubscription = [];
      }
    }
    // adding product subscription
    else if (newLicenseIdx === -1)
      newLicense.productSubscriptions.push(
        this.state.license.productSubscriptions[stateLicenseIdx]
      );
    // changing existing product subscription
    else
      newLicense.productSubscriptions[
        newLicenseIdx
      ] = this.state.license.productSubscriptions[newLicenseIdx];
    updateLicense(newLicense);
    this.setState(prevState => ({
      isSaveDisabled: {
        ...prevState.isSaveDisabled,
        [productId]: true
      }
    }));
  };

  contentByType = (content, productId) => {
    const { productsById } = this.props;
    return content
      ? _reduce(
          content,
          (r, v, k) => {
            if (productsById[productId].contentTypes.length > 0) {
              productsById[productId].contentTypes.forEach(product => {
                if (v.contentType.toString() === product.id) {
                  r[product.name] = {
                    ...r[product.name],
                    [k]: v
                  };
                }
              });
            }
            return r;
          },
          {}
        )
      : {};
  };
}

export const mapDispatchToProps = dispatch => ({
  updateLicense: license => {
    dispatch(updateLicenseAction(license));
  }
});

export const mapStateTopProps = state => ({
  productsById: getProducts(state),
  contentByProductId: getContentByProductId(state),
  isLoading: isLoading(state),
  lastFailure: getLastFailure(state),
  licenses: getLicenses(state)
});

const styles = theme => ({
  expansionPanel: {
    padding: '0px',
    margin: '0px',
    marginBottom: '25px'
  },
  expansionDetails: {
    display: 'block'
  },
  saveButton: {
    float: 'right',
    position: 'sticky',
    top: '0px',
    right: '20px'
  },
  header: {
    color: theme.palette.primary.darkTurquoise
  }
});

export default _flow(
  connect(mapStateTopProps, mapDispatchToProps),
  withStyles(styles)
)(ContentAccess);
