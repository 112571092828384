/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

/* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup';

const minimumChars = 1;
const maximumChars = 64;

export const customerValidationSchema = Yup.object({
  name: Yup.string('Enter a customer name')
    .trim()
    .required('A name is required')
    .max(maximumChars, 'A name can have at most ${max} characters')
    .min(minimumChars, 'A name must have at least ${min} character'),
  accountType: Yup.string().required('Account Type is required'),
  authenticationType: Yup.string().required('Authentication is required'),
  defaultGroup: Yup.string('Enter a default group name')
    .trim()
    .required('A default group name is required')
    .max(
      maximumChars,
      'A default group name can have at most ${max} characters'
    )
    .min(
      minimumChars,
      'A default group name must have at least ${min} character'
    )
});
