/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import type { Customer } from './types';
import {
  CustomerServicePromiseClient,
  CustomerServiceClient,
  CreateCustomerRequest,
  QueryCustomerRequest,
  UpdateCustomerRequest,
  DeleteCustomerRequest,
  ReadCustomerRequest,
  Customer as PBCustomer,
  AccountType as PbAccountType,
  AuthenticationType as PbAuthenticationType
} from 'customer_grpc_web_pb';
import { createGroup } from '../../../modules/accountManagement/services';

import _map from 'lodash/map';

const { protocol, host } = window.location;
const customerServicePromiseClient = new CustomerServicePromiseClient(
  `${protocol}//${host}`,
  null,
  null
);

const customerServiceClient = new CustomerServiceClient(
  `${protocol}//${host}`,
  null,
  null
);

const genericallyHandleResponse = (
  resolve: any => void,
  reject: Error => void,
  err: ?Error,
  response: any,
  convert: ?<T>(pbObject: any) => T
) => {
  if (err) {
    console.error(err.message || err);
    reject(err);
    return;
  }

  if (response === null || response === undefined) {
    reject(Error('Empty response from server.'));
    return;
  }

  resolve(convert ? convert(response) : null);
};

export const queryCustomer = (
  client: CustomerServiceClient = customerServicePromiseClient
): Promise<Customer[]> => {
  const request = new QueryCustomerRequest();
  return client
    .queryCustomer(request)
    .then(response => _map(response.getCustomerList(), pbCustomerToCustomer));
};

export const createCustomer = (
  customer: $Shape<Customer>,
  client: CustomerServiceClient = customerServicePromiseClient
): Promise<Customer> => {
  const request = new CreateCustomerRequest();
  request.setCustomer(customerToPbCustomer(customer));
  return client.createCustomer(request).then(response => {
    const customer = pbCustomerToCustomer(response.getCustomer());
    createGroup(customer.defaultGroup, customer.id);
    return customer;
  });
};

export const updateCustomer = (
  customer: $Shape<Customer>,
  client: CustomerServiceClient = customerServicePromiseClient
): Promise<Customer> => {
  const request = new UpdateCustomerRequest();
  request.setCustomer(customerToPbCustomer(customer));
  return client
    .updateCustomer(request)
    .then(response => pbCustomerToCustomer(response.getCustomer()));
};

export const deleteCustomer = (
  id: string,
  client: CustomerServiceClient = customerServicePromiseClient
): Promise<null> => {
  const request = new DeleteCustomerRequest();
  request.setId(id);
  return client.deleteCustomer(request);
};

export const readCustomer = (
  customerId: string,
  client: CustomerServiceClient = customerServiceClient
): Promise<null> =>
  new Promise((resolve, reject) => {
    const query = new ReadCustomerRequest();
    query.setId(customerId);
    client.readCustomer(query, null, (err, response) => {
      genericallyHandleResponse(resolve, reject, err, response, r => {
        const customer = pbCustomerToCustomer(r.getCustomer());
        return customer;
      });
    });
  });

const pbCustomerToCustomer = (pbCustomer): $Shape<Customer> => ({
  id: pbCustomer.getId(),
  name: pbCustomer.getName(),
  defaultGroup: pbCustomer.getDefaultGroup(),
  authenticationType: convertAuthenticationType(pbCustomer.getAuthentication()),
  accountType: convertAccountType(pbCustomer.getAccount()),
  config: pbCustomerConfigToConfig(pbCustomer.getConfig()),
  notes: pbCustomer.getNotes()
});

const convertAuthenticationType = authType => {
  for (const [key, value] of Object.entries(PbAuthenticationType)) {
    if (authType === value) return key;
  }
};

const convertAccountType = accountType => {
  for (const [key, value] of Object.entries(PbAccountType)) {
    if (accountType === value) return key;
  }
};
const pbCustomerConfigToConfig = (
  pbCustomerConfigToConfig
): $Shape<Customer> => ({
  signUpPolicy: pbCustomerConfigToConfig.getSignUpPolicy(),
  signInPolicy: pbCustomerConfigToConfig.getSignInPolicy(),
  appScope: pbCustomerConfigToConfig.getAppScope(),
  clientId: pbCustomerConfigToConfig.getClientId(),
  authority: pbCustomerConfigToConfig.getAuthority(),
  domainHint: pbCustomerConfigToConfig.getDomainHint()
});

const customerToPbCustomer = (customer: Customer): PBCustomer => {
  const pbCustomer = new PBCustomer();
  if (customer.name !== '') {
    pbCustomer.setName(customer.name.trim());
  }
  pbCustomer.setId(customer.id);
  pbCustomer.setAccount(PbAccountType[customer.accountType]);
  pbCustomer.setAuthentication(
    PbAuthenticationType[customer.authenticationType]
  );
  pbCustomer.setDefaultGroup(customer.defaultGroup);
  pbCustomer.setNotes(customer.notes);
  return pbCustomer;
};
