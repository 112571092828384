import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { Typography, withStyles } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import PropTypes from 'prop-types';
import _isEqual from 'lodash/isEqual';
import Launch from '../Launch';
import CarouselImage from './CarouselImage.view';

const styles = theme => ({
  '@keyframes right': {
    ' 0%': { transform: 'translateX(-3%)' },
    '1%': { transform: 'translateX(-3%)' },

    '100%': { transform: 'translateX(0)' }
  },
  '@keyframes left': {
    ' 0%': { transform: 'translateX(-3%)' },
    '1%': { transform: 'translateX(-3%)' },

    '100%': { transform: 'translateX(0)' }
  },
  root: {
    position: 'relative',
    width: '60%',
    height: '100%'
  },
  group: {
    position: 'relative',
    left: '-10%',
    width: '150%',
    height: '100%',
    padding: '3em 0',
    display: 'flex',
    '&>div': {
      transformOrigin: '70% 70%',
      opacity: '.5',
      fontSize: '18px',
      flex: '0 1 200px',
      transition: '5.s transform, .3s opacity, .3s filter',
      width: '25%',
      filter: `drop-shadow(${theme.palette.primary.dark})`,
      alignSelf: 'stretch'
    }
  },
  single: {
    position: 'relative',
    left: '-10%',
    width: '150%',
    height: '100%',
    padding: '3em 0',
    display: 'flex',
    '&>div': {
      transformOrigin: '70% 70%',
      opacity: '.5',
      fontSize: '18px',
      flex: '1',
      transition: '5.s transform, .3s opacity, .3s filter',
      width: '25%',
      filter: `drop-shadow(${theme.palette.primary.dark})`,
      alignSelf: 'stretch'
    }
  },
  buttons: {
    position: 'absolute',
    bottom: 'calc(50%)',
    width: '50%',
    display: 'flex',
    justifyContent: 'space-between',
    zIndex: '2'
  },
  button: {
    background: theme.palette.primary.darkTurquoise,
    '&:hover': {
      transform: 'scale(1)',
      transition: '.3s all '
    }
  },
  disabledButton: {
    background: theme.palette.primary.lighterGray,
    '&:hover': {
      transform: 'scale(1)',
      transition: '.3s all '
    }
  },
  gameCenterInfo: {
    top: '0',
    left: 'calc(100% - 5em)',
    width: 'calc(40% + 8em)',
    height: '100%',
    display: 'flex',
    padding: '0 0 0 8em',
    position: 'absolute',
    background:
      'linear-gradient(90deg, rgba(34, 34, 34, 0) 0%,rgba(34, 34, 34, 0.9) 30%,rgba(34, 34, 34, 1) 100%)',
    flexDirection: 'column',
    justifyContent: 'flex-end'
  },
  borderStyle: {
    color: theme.palette.primary.colorBorder,
    borderColor: theme.palette.primary.colorBorder
  }
});

class Carousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: this.props.data
    };
  }

  rotate = (arr, direction) =>
    direction ? arr.push(arr.shift()) : arr.unshift(arr.pop());

  handleClick = direction => {
    //direction: 0 = left / 1 = right
    const { items } = this.state;
    const newItems = [...items];
    newItems.forEach(item => {
      item.highlighted = false;
    });
    this.rotate(newItems, direction);
    newItems[1].highlighted = true;
    this.setState({
      items: newItems,
      selected: newItems[1]
    });
  };

  componentDidUpdate = prevProps => {
    const { data } = this.props;
    if (!_isEqual(prevProps.data, data)) {
      if (data.length > 1) {
        data[1].highlighted = true;
        this.setState({
          selected: data[1],
          items: data
        });
      } else {
        data[0].highlighted = true;
        this.setState({
          selected: data[0],
          items: data
        });
      }
    }
  };

  getLinkUrl = () => {
    const { selected } = this.state;
    if (selected) {
      const url = selected.title.toLowerCase().replace(/\s/g, '');
      return `/games/${url}`;
    }
  };

  render() {
    const { classes } = this.props;
    const { selected, items } = this.state;
    return (
      <div className={classes.root}>
        <div className={items.length > 1 ? classes.group : classes.single}>
          {Object.values(items).map(product => (
            <CarouselImage
              key={product.title}
              product={product}
              data-test={`CarouselImage-${product.title}`}
              multiple={items.length > 1}
            />
          ))}
        </div>
        <div className={classes.buttons}>
          <IconButton
            className={
              items.length > 1 ? classes.button : classes.disabledButton
            }
            disabled={items.length <= 1}
            onClick={() => this.handleClick(0)}
            data-test={`CarouselImage-Button-left`}
          >
            <ChevronLeftIcon />
          </IconButton>
          <IconButton
            className={
              items.length > 1 ? classes.button : classes.disabledButton
            }
            disabled={items.length <= 1}
            onClick={() => this.handleClick(1)}
            data-test={`CarouselImage-Button-right`}
          >
            <ChevronRightIcon />
          </IconButton>
        </div>

        {selected && (
          <div className={classes.gameCenterInfo}>
            <div className={classes.textArea}>
              <Typography variant="h5">{selected.title}</Typography>
              <hr className={classes.borderStyle} />
            </div>
            <Launch link={this.getLinkUrl()} />
          </div>
        )}
      </div>
    );
  }
}

Carousel.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(Carousel);
