/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

// @flow
import type { AccountManagementState, UsersById } from '../types';

export const isLoading = (state: AccountManagementState) => state.isLoading;

export const getLastFailure = (state: AccountManagementState) =>
  state.lastFailure;

export const getRoleOptions = (state: AccountManagementState) =>
  state.roleOptions;

export const getUsers = (state: AccountManagementState = {}): UsersById =>
  state.users;

export const getCustomer = (state: AccountManagementState): ?string =>
  state.customer;

export const getLicensedContentByContentType = (
  state: AccountManagementState
): ?string => state.licensedContent;

export const getLicensedProducts = (state: AccountManagementState): ?string =>
  state.licensedProducts;

export const getGroups = (state: AccountManagementState): ?string =>
  state.groups;
