/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import React, { Component } from 'react';
import {
  TextField,
  Checkbox,
  Grid,
  withStyles,
  Typography,
  Tooltip
} from '@material-ui/core';
import _isEqual from 'lodash/isEqual';
import _debounce from 'lodash/debounce';

export class ProductSubscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productSubscription: props.productSubscription
    };
  }

  componentDidUpdate = prevProps => {
    if (
      !_isEqual(this.props.productSubscription, prevProps.productSubscription)
    ) {
      this.setState({
        productSubscription: this.props.productSubscription
      });
    }
  };

  debounceUpdateProductSubscription = _debounce(
    this.props.updateProductSubscription,
    500
  );

  isWithinRange = number => number <= 1000000000 && number >= 0;

  updateTextField = event => {
    const { product } = this.props;

    const onlyNumbers = event.currentTarget.value
      ? event.currentTarget.value.replace(/[^0-9]/g, '')
      : '';

    if (this.isWithinRange(Number(onlyNumbers))) {
      this.setState(
        prevState => ({
          productSubscription: {
            ...prevState.productSubscription,
            maxNumOfUsers: onlyNumbers
          }
        }),
        () => {
          this.debounceUpdateProductSubscription(
            this.state.productSubscription,
            product
          );
        }
      );
    }
  };

  updateCheckbox = target => {
    const { product, shouldContentDisplay } = this.props;

    if (target.checked) {
      this.setState(
        {
          productSubscription: {
            productId: product.id,
            maxNumOfUsers: 0
          }
        },
        () => {
          this.debounceUpdateProductSubscription(
            this.state.productSubscription,
            product
          );
          shouldContentDisplay(target.checked);
        }
      );
    } else {
      this.setState(
        prevState => ({
          productSubscription: {
            productId: prevState.productSubscription.productId,
            maxNumOfUsers: undefined
          }
        }),
        () => {
          this.debounceUpdateProductSubscription(
            this.state.productSubscription,
            product
          );
          shouldContentDisplay(target.checked);
        }
      );
    }
  };

  render() {
    const { classes, product, isEditable } = this.props;
    const { productSubscription } = this.state;

    return (
      <Grid container spacing={0}>
        <Grid item xs={12} align="center">
          <Typography className={classes.header} variant="h4" component="h4">
            {`${product.title} Product Subscription`}
          </Typography>
        </Grid>

        <Grid item xs={1} align="center">
          <Tooltip
            title={
              productSubscription.maxNumOfUsers >= 0
                ? 'available'
                : 'unavailable'
            }
          >
            <Checkbox
              id={product.id}
              checked={productSubscription.maxNumOfUsers >= 0}
              role="checkbox"
              className={classes.checkbox}
              onChange={event => this.updateCheckbox(event.currentTarget)}
              data-test={`product-checkbox-${product.id}`}
              disabled={!isEditable}
            />
          </Tooltip>
        </Grid>
        <Grid item xs={3} align="center">
          <TextField
            id={`${product.id}-maxNumOfUsers`}
            name="maxNumOfUsers"
            label="Max Num of Users"
            type={'text'}
            disabled={
              productSubscription.maxNumOfUsers === undefined || !isEditable
            }
            value={
              productSubscription.maxNumOfUsers >= 0
                ? productSubscription.maxNumOfUsers
                : ''
            }
            onChange={this.updateTextField}
            data-test={`product-maxNumOfUsers-${product.id}`}
          />
        </Grid>

        <Grid item xs className={classes.productName}>
          {product.title}
        </Grid>
      </Grid>
    );
  }
}

export const styles = (theme: any) => ({
  productName: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '2% 0 0 2%',
    color: theme.palette.primary.white
  },
  checkbox: {
    marginTop: '10%'
  },
  header: {
    color: theme.palette.primary.darkTurquoise
  }
});

export default withStyles(styles)(ProductSubscription);
