/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import { Tabs, Tab, Button, Checkbox } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import MediaQuery from 'react-responsive';
import palette from '../../styles/themes/dashboard/palette';
import FormControlLabel from '@material-ui/core/FormControlLabel';

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: '5px 15px' }}>
      {props.children}
    </Typography>
  );
}
TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};
const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: 'transparent',
    width: '60%',
    paddingLeft: '20%'
    //align: 'right'
  },
  button: {
    marginRight: 'auto',
    fontSize: '1em',
    backgroundColor: theme.palette.primary.darkSlateGray,
    border: '.5px ' + theme.palette.primary.colorOrange,
    '&:hover': {
      backgroundColor: theme.palette.primary.darkSlateGray,
      border: '.5px' + theme.palette.primary.colorOrange
    }
  },
  gtitle: {
    borderBottom: '1px solid'
  },
  logo: {
    height: '2.5em',
    marginTop: '.5em'
  },
  linkSpan: {
    display: 'block',
    marginTop: '5px'
  },
  link: {
    color: palette.primary.darkTurquoise,
    fontSize: '1em'
  },
  btnLaunchDisable: {
    backgroundColor: '#d1d1d1 !important'
  },
  appBar: {
    background: 'transparent !important',
    boxShadow: ['none']
  },
  tabtitle: {
    color: theme.palette.primary.contraster
  },

  tab: {
    backgroundColor: theme.palette.primary.lighterGradientBackground
  },
  taboverflow: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block'
  }
});
class ProductTabs extends Component {
  constructor(props) {
    super(props);
    this.toggleCheckbox = this.toggleCheckbox.bind(this);
    this.state = {
      value: 0,
      checkBoxesState: {},
      verifyChecks: false
    };
  }

  componentDidMount = () => {
    const { checkBoxes } = this.props;
    const newCheckboxes = {};
    if (checkBoxes) {
      checkBoxes.map(
        cb =>
          (newCheckboxes[cb.name] = {
            name: cb.name,
            isChecked: false
          })
      );

      this.setState({
        checkBoxesState: newCheckboxes
      });
    }
  };

  verifyCheckboxes = () => {
    const { checkBoxesState } = this.state;
    let count = 0;
    Object.values(checkBoxesState).forEach(check => {
      if (check.isChecked) {
        count = count + 1;
      }
    });

    if (count === Object.values(checkBoxesState).length) {
      this.setState({
        verifyChecks: true
      });
    } else {
      this.setState({
        verifyChecks: false
      });
    }
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  toggleCheckbox = name => {
    this.setState(
      {
        checkBoxesState: {
          ...this.state.checkBoxesState,
          [name]: {
            ...this.state.checkBoxesState[name],
            isChecked: !this.state.checkBoxesState[name].isChecked
          }
        }
      },
      () => {
        this.verifyCheckboxes();
      }
    );
  };
  render() {
    const {
      classes,
      description,
      clickUrl,
      checkBoxes = [],
      icon,
      links = [],
      launchMessage
    } = this.props;
    const { value, checkBoxesState, verifyChecks } = this.state;
    return (
      <div className={classes.root}>
        <AppBar
          position="static"
          className={classes.appBar}
          style={{ padding: '0 15px' }}
        >
          <Tabs
            className={classes.tab}
            value={value}
            onChange={this.handleChange}
          >
            <Tab icon={icon}></Tab>
          </Tabs>
        </AppBar>
        {value === 0 && (
          <TabContainer>
            <MediaQuery minWidth={801}>
              <Typography variant="body1" gutterBottom>
                {description}
              </Typography>
            </MediaQuery>
            <MediaQuery maxWidth={800}>
              <Typography
                variant="body1"
                gutterBottom
                className={classes.taboverflow}
              >
                {description}
              </Typography>
            </MediaQuery>
            {checkBoxes.length > 0 &&
              checkBoxes.map(checkbox => {
                if (checkBoxesState[checkbox.name]) {
                  return (
                    <FormControlLabel
                      key={checkbox.name}
                      control={
                        <Checkbox
                          color="primary"
                          onChange={() => this.toggleCheckbox(checkbox.name)}
                          checked={checkBoxesState[checkbox.name].isChecked}
                        />
                      }
                      label={
                        <p>
                          {checkbox.name !== 'terms' && checkbox.description}

                          {checkbox.name === 'terms' && (
                            <a
                              className={classes.link}
                              target={'_blank'}
                              href={checkbox.url}
                              rel="noopener noreferrer"
                            >
                              {checkbox.description}
                            </a>
                          )}
                        </p>
                      }
                    />
                  );
                }
                return undefined;
              })}
            <Button
              color="primary"
              variant={'contained'}
              className={
                checkBoxes.length > 0 && !verifyChecks
                  ? classes.btnLaunchDisable
                  : classes.button
              }
              onClick={() => window.open(`${clickUrl}`, '_blank')}
              disabled={checkBoxes.length > 0 && !verifyChecks}
            >
              {launchMessage ? launchMessage : 'Launch Game'}
            </Button>
            {links.length > 0 &&
              links.map(link => (
                <span key={link.name} className={classes.linkSpan}>
                  <a
                    className={classes.link}
                    target={'_blank'}
                    href={link.url}
                    rel="noopener noreferrer"
                  >
                    {link.name}
                  </a>
                </span>
              ))}
          </TabContainer>
        )}
      </div>
    );
  }
}
ProductTabs.propTypes = {
  checkBoxes: PropTypes.array,
  classes: PropTypes.object,
  clickUrl: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.any,
  links: PropTypes.array
};
export default withStyles(styles)(ProductTabs);
