/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import React from 'react';
import { withStyles, TextField } from '@material-ui/core';

const styles = theme => ({
  dropdown: {
    maxHeight: 250
  },
  nameFieldLeft: {
    fullWidth: false,
    width: 'calc(50% - 5px)',
    marginRight: '5px'
  },
  nameFieldRight: {
    fullWidth: false,
    width: 'calc(50% - 5px)',
    marginLeft: '5px'
  },
  buttonStyle: {
    marginTop: '13px',
    marginRight: '10px'
  },
  form: {
    marginBottom: 30,
    marginTop: 30
  },
  divider: {
    backgroundColor: theme.palette.primary.darkTurquoise,
    marginTop: '20px'
  }
});

export const AdminForm = props => {
  const {
    values: { userName, roles },
    errors,
    touched,
    handleSubmit,
    handleChange,
    setFieldTouched,
    classes,
    roleOptions
  } = props;

  const change = (name, e) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  const translateRoles = roles =>
    roles
      .map(role => roleOptions[role])
      .filter(Boolean)
      .join(', ');

  return (
    <form
      style={{ width: '100%' }}
      onSubmit={handleSubmit}
      className={classes.form}
    >
      <TextField
        name="userName"
        helperText={touched.userName ? errors.userName : ''}
        error={touched.username && Boolean(errors.userName)}
        label="Username"
        value={userName}
        onChange={change.bind(null, 'userName')}
        className={classes.nameFieldLeft}
        data-test={`adminForm-textField-${userName}`}
        disabled
        fullWidth
      />
      <TextField
        name="roles"
        helperText={touched.roles ? errors.roles : ''}
        error={touched.roles && Boolean(errors.roles)}
        label="Roles"
        value={translateRoles(roles)}
        className={classes.nameFieldLeft}
        fullWidth
        disabled
      />
    </form>
  );
};

export default withStyles(styles)(AdminForm);
