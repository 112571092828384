/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

/* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup';

const minimumChars = 1;
const maximumChars = 64;

export const newGroupSchema = Yup.object({
  groupName: Yup.string('Enter a group name')
    .trim()
    .required('Group name is required')
    .max(maximumChars, 'A group name can have at most ${max} characters')
    .min(minimumChars, 'A group name must have at least ${min} character')
});

export const groupSchema = Yup.object({
  displayName: Yup.string('Enter a group name')
    .trim()
    .required('Group name is required')
    .max(maximumChars, 'A group name can have at most ${max} characters')
    .min(minimumChars, 'A group name must have at least ${min} character')
});
