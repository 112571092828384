import React from 'react';
import {
  FormControlLabel,
  TextField,
  Checkbox,
  Button,
  withStyles
} from '@material-ui/core';

export const RegistrationForm = props => {
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    isValid,
    isSubmitting,
    setFieldTouched,
    classes,
    password
  } = props;

  const change = (name, e) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        id="userName"
        name="userName"
        helperText={touched.userName ? errors.userName : ''}
        error={touched.userName && Boolean(errors.userName)}
        label="Username"
        value={values.userName}
        onChange={change.bind(null, 'userName')}
        disabled
        fullWidth
      />
      {password && (
        <>
          <TextField
            id="password"
            name="password"
            type="password"
            helperText={touched.password ? errors.password : ''}
            error={touched.password && Boolean(errors.password)}
            label="password"
            value={values.password}
            onChange={change.bind(null, 'password')}
            fullWidth
          />
          <TextField
            id="confirmPassword"
            name="confirmPassword"
            type="password"
            helperText={touched.confirmPassword ? errors.confirmPassword : ''}
            error={touched.confirmPassword && Boolean(errors.confirmPassword)}
            label="confirm password"
            value={values.confirmPassword}
            onChange={change.bind(null, 'confirmPassword')}
            fullWidth
          />
        </>
      )}
      <FormControlLabel
        key={'isAdult'}
        className={classes.checkbox}
        control={
          <Checkbox
            id="isAdult"
            color="primary"
            onChange={change.bind(null, 'isAdult')}
            checked={values.isAdult}
            value={'isAdult'}
          />
        }
        label={
          <p>
            I am at least 18 years old, or an adult guardian is registering on
            my behalf.
          </p>
        }
      />
      <FormControlLabel
        key={'agreedPrivacyPolicy'}
        className={classes.checkbox}
        control={
          <Checkbox
            id={'agreedPrivacyPolicy'}
            color="primary"
            onChange={change.bind(null, 'agreedPrivacyPolicy')}
            checked={values.agreedPrivacyPolicy}
            value={'agreedPrivacyPolicy'}
          />
        }
        label={
          <p>
            Accept the{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://circadence.com/privacy-policy"
              className={classes.link}
            >
              Privacy Policy
            </a>
          </p>
        }
      />
      <FormControlLabel
        key={'agreedTerms'}
        className={classes.checkbox}
        control={
          <Checkbox
            id={'agreedTerms'}
            color="primary"
            onChange={change.bind(null, 'agreedTerms')}
            checked={values.agreedTerms}
            value={'agreedTerms'}
          />
        }
        label={
          <p>
            Accept the{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://circadence.com/terms-and-conditions"
              className={classes.link}
            >
              Terms and Conditions
            </a>
          </p>
        }
      />
      <Button
        style={{ marginBottom: '20px' }}
        type="submit"
        fullWidth
        color="primary"
        disabled={!isValid || isSubmitting}
      >
        Submit
      </Button>
    </form>
  );
};

const styles = theme => ({
  checkbox: {
    width: '100%'
  },
  link: {
    color: theme.palette.primary.logo
  }
});

export default withStyles(styles)(RegistrationForm);
