/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import React from 'react';
import {
  Button,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  withStyles
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

export const styles = () => ({
  select: {
    marginTop: '8px',
    marginRight: '10px',
    width: '100%'
  },
  fullWidth: {
    width: '100%'
  }
});
export const CustomerInfoForm = props => {
  const {
    values: { name, accountType, authenticationType, defaultGroup, notes },
    errors,
    touched,
    handleSubmit,
    handleChange,
    isValid,
    setFieldTouched,
    classes,
    editable
  } = props;
  const change = (name, e) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };
  return (
    <form onSubmit={handleSubmit} className={classes.fullWidth}>
      <TextField
        id="name"
        name="name"
        helperText={touched.name ? errors.name : ''}
        error={touched.name && Boolean(errors.name)}
        label="Name"
        value={name}
        onChange={change.bind(null, 'name')}
        data-test={`customerInfoForm-textField-name`}
        fullWidth
      />
      <FormControl className={classes.select}>
        <InputLabel id="select-accountType">Account Type</InputLabel>
        <Select
          className={classes.select}
          id="accountType"
          name="accountType"
          value={accountType}
          placeholder={'accountType'}
          data-test={`customerInfoForm-textField-accountType`}
          onChange={change.bind(null, 'accountType')}
          disabled={!editable}
          fullWidth
        >
          <MenuItem
            key={'organization'}
            label={'organization'}
            value={'ORGANIZATION'}
          >
            Organization
          </MenuItem>
          <MenuItem
            disabled
            key={'individual'}
            label={'individual'}
            value={'INDIVIDUAL'}
          >
            Individual
          </MenuItem>
        </Select>
        {touched.accountType && Boolean(errors.accountType) && (
          <FormHelperText
            id="accountType"
            error={touched.accountType && Boolean(errors.accountType)}
          >
            {errors.accountType}
          </FormHelperText>
        )}
      </FormControl>
      <FormControl className={classes.select}>
        <InputLabel id="select-authenticationType">
          Authentication Type
        </InputLabel>
        <Select
          className={classes.select}
          id="authenticationType"
          name="authenticationType"
          value={authenticationType}
          disabled={!editable}
          placeholder={'authenticationType'}
          data-test={`customerInfoForm-textField-authenticationType`}
          onChange={change.bind(null, 'authenticationType')}
          fullWidth
        >
          <MenuItem key={'local'} label={'local'} value={'LOCAL'}>
            Local
          </MenuItem>
          <MenuItem
            disabled
            key={'nonLocal'}
            label={'nonLocal'}
            value={'NON_LOCAL'}
          >
            Non-Local
          </MenuItem>
        </Select>
        {touched.authenticationType && Boolean(errors.authenticationType) && (
          <FormHelperText
            id="authenticationType"
            error={
              touched.authenticationType && Boolean(errors.authenticationType)
            }
          >
            {errors.authenticationType}
          </FormHelperText>
        )}
      </FormControl>
      <TextField
        id="defaultGroup"
        name="defaultGroup"
        helperText={touched.defaultGroup ? errors.defaultGroup : ''}
        error={touched.defaultGroup && Boolean(errors.defaultGroup)}
        label="Default Group name"
        value={defaultGroup}
        disabled={!editable}
        onChange={change.bind(null, 'defaultGroup')}
        data-test={`customerInfoForm-textField-defaultGroup`}
        fullWidth
      />
      <TextField
        multiline={true}
        id="notes"
        name="notes"
        helperText={touched.notes ? errors.notes : ''}
        error={touched.notes && Boolean(errors.notes)}
        label="Notes"
        value={notes}
        onChange={change.bind(null, 'notes')}
        data-test={`customerInfoForm-textField-notes`}
        fullWidth
      />

      <Button
        style={{ marginBottom: '20px' }}
        type="submit"
        fullWidth
        color="primary"
        disabled={!isValid}
        data-test={`customerInfoForm-button-save`}
      >
        Save
      </Button>
    </form>
  );
};

export default withStyles(styles)(CustomerInfoForm);
