/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import _filter from 'lodash/filter';
import _mapValues from 'lodash/mapValues';

import { REDUCER_NAME } from './reducer';
import * as getters from '../CustomerManagement/reducer/getters';
import { createSelector } from 'reselect';

import type {
  AdminsByCustomer,
  CustomerManagementState,
  CustomersById
} from './types';

type GlobalState = {
  [REDUCER_NAME]: CustomerManagementState
};

export const getCustomers = (state: GlobalState): CustomersById =>
  getters.getCustomers(state[REDUCER_NAME]);

export const getAdminsByCustomer = (state: GlobalState): AdminsByCustomer =>
  getters.getAdminsByCustomer(state[REDUCER_NAME]);

export const getNotDeletedAdminsByCustomer = createSelector(
  [getAdminsByCustomer],
  (adminsByCustomer): AdminsByCustomer =>
    _mapValues(adminsByCustomer, admins => _filter(admins, a => !a.isDeleted))
);

export const isLoading = (state: GlobalState): boolean => {
  getters.isLoading(state[REDUCER_NAME]);
};

export const getCustomersAsHtmlOptions = (
  state: GlobalState
): [[string, string]] => {
  const customersById = getters.getCustomers(state[REDUCER_NAME]);

  return _mapValues(customersById, (k, v) => [v, k.name]);
};

export const getLastFailure = (state: GlobalState): ?string =>
  getters.getLastFailure(state[REDUCER_NAME]);
