/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import React, { Component } from 'react';
import Catalog from '../../Catalog/Catalog.view';
// eslint-disable-next-line
import { Typography, withStyles } from '@material-ui/core';
import LicenseCard from './LicenseCard';
import LicenseForm from './LicenseForm';
import { Formik } from 'formik';
import { licenseValidationSchema } from './LicenseValidationSchema';
import LicenseInfoForm from './LicenseInfoForm';
import { connect } from 'react-redux';
import { getLastFailure, getLicenses } from '../selectors';
import {
  createLicenseAction,
  deleteLicenseAction,
  dismissErrorAction,
  queryLicenseForCustomerAction,
  updateLicenseAction
} from '../actions';
import {
  setDefaultLicense,
  unsetDefaultLicense
} from '../../../../components/Dashboard/LicenseManagement/services';
import * as roleConstants from '../../../../constants/roles';

import _flow from 'lodash/flow';
import { getCustomers } from '../../CustomerManagement/selectors';
import { queryCustomersAction } from '../../CustomerManagement/actions';
import _reduce from 'lodash/reduce';
import { queryProductsAndContent } from '../../ContentAccess/actions';
import {
  getContentByProductId,
  getProducts
} from '../../ContentAccess/selectors';
import _isEmpty from 'lodash/isEmpty';
import {
  selectSessionUserRoles,
  selectCustomerId
} from '../../../../modules/session-user/selectors';

export class LicenseManagement extends Component {
  state = {
    selectedCardId: undefined,
    customerOptions: [],
    createCardDialogOpen: false
  };

  componentDidMount() {
    if (this.props.licensesById === undefined) {
      if (this.props.isCmtAdmin) {
        this.props.queryLicenseForCustomer(''); //empty string arg to query for all customers
      } else {
        this.props.queryLicenseForCustomer(this.props.sessionCustomerId);
      }
    }
    if (this.props.customers === undefined) {
      this.props.queryCustomers();
    }
    if (this.props.productsById === undefined) {
      this.props.queryProductsAndContent();
    }
    if (_isEmpty(this.props.contentByProductId)) {
      this.props.queryProductsAndContent();
    }
  }

  displayLicenseCard = license => {
    const { customers, isCmtAdmin } = this.props;
    const customerName =
      customers && customers[license.customerId]
        ? customers[license.customerId].name
        : '';
    return (
      <LicenseCard
        key={license.id}
        license={license}
        customerName={customerName}
        hide={license.hide}
        selectedCardId={this.state.selectedCardId}
        handleSelectCard={this.handleSelectCard}
        handleDeleteCard={this.handleDeleteCard}
        handleSetDefaultLicense={this.handleSetDefaultLicense}
        handleUnsetDefaultLicense={this.handleUnsetDefaultLicense}
        data-test={`LicenseCard-license-${license}`}
        isCmt={isCmtAdmin}
      />
    );
  };

  displayEditLicenseForm = license => {
    const customerOptions = _reduce(
      this.props.customers,
      (r, v, k) => [...r, [k, v.name]],
      []
    );
    return (
      <LicenseForm
        license={license}
        updateLicense={this.handleUpdateLicense}
        customerOptions={customerOptions}
        data-test={`editLicenseForm-license-${license}`}
        isCmtAdmin={this.props.isCmtAdmin}
      />
    );
  };

  displayCreateLicenseForm = () => {
    const newLicense = {
      name: '',
      id: '',
      productSubscriptions: [],
      contentSubscription: {}
    };
    const customerOptions = _reduce(
      this.props.customers,
      (r, v, k) => [...r, [k, v.name]],
      []
    );
    return (
      <Formik
        enableReinitialize
        validationSchema={licenseValidationSchema}
        initialValues={newLicense}
        onSubmit={this.handleCreateLicense}
      >
        {props => (
          <LicenseInfoForm
            {...props}
            customerOptions={customerOptions}
            data-test={`createLicenseForm`}
            isCmt={this.props.isCmtAdmin}
          />
        )}
      </Formik>
    );
  };

  handleDialogClose = () => {
    this.setState({ createCardDialogOpen: false });
  };

  handleDialogOpen = () => {
    this.setState({ createCardDialogOpen: true });
  };

  handleSelectCard = event => {
    const { id } = event.currentTarget;
    if (id !== this.state.selectedCardId) {
      this.setState({ selectedCardId: id });
    } else {
      this.setState({ selectedCardId: undefined });
    }
  };

  selectCard = cardId => {
    this.setState({ selectedCardId: cardId });
  };

  handleCreateLicense = license => {
    this.props.createLicense(license);
    this.handleDialogClose();
  };

  handleDeleteCard = license => {
    this.props.deleteLicense(license);
  };

  handleSetDefaultLicense = license => {
    setDefaultLicense(license.id).then(() =>
      this.props.queryLicenseForCustomer('')
    );
  };

  handleUnsetDefaultLicense = license => {
    unsetDefaultLicense(license.id).then(() =>
      this.props.queryLicenseForCustomer('')
    );
  };

  handleUpdateLicense = license => {
    this.props.updateLicense(license);
  };

  sortLicenses = licenses =>
    licenses
      ? Object.values(licenses).sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        )
      : null;

  render() {
    const { classes, licensesById, isCmtAdmin } = this.props;
    const { selectedCardId } = this.state;
    return (
      <div className={classes.paper}>
        <Typography className={classes.header} variant="h4" component="h4">
          License Management
        </Typography>
        <Catalog
          catalogList={this.sortLicenses(licensesById)}
          displayCard={this.displayLicenseCard}
          displayEditForm={this.displayEditLicenseForm}
          selectedCardId={selectedCardId}
          selectCard={this.selectCard}
          displayCreateCard={this.displayCreateLicenseForm}
          handleCreateCard={this.handleCreateLicense}
          createCardDialogOpen={this.state.createCardDialogOpen}
          handleDialogOpen={this.handleDialogOpen}
          handleDialogClose={this.handleDialogClose}
          error={this.props.lastFailure}
          handleDismissError={this.props.dismissError}
          canCreateNewCard={isCmtAdmin}
        />
      </div>
    );
  }
}

export const styles = (theme: any) => ({
  paper: {
    width: '100%',
    borderTop: `1px solid ${theme.palette.black.main}`,
    borderBottom: `1px solid ${theme.palette.black.main}`,
    paddingTop: '25px',
    boxShadow: ' -5px -5px 20px 0px rgba(0,0,0,0.12);',
    marginLeft: '25px',
    paddingLeft: '25px'
  },
  cardDetails: {
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    color: theme.palette.primary.darkTurquoise
  }
});
function isCmtAdmin(rolesList) {
  return rolesList.includes(roleConstants.CMT_ADMIN);
}

export const mapStateToProps = state => ({
  licensesById: getLicenses(state),
  customers: getCustomers(state),
  productsById: getProducts(state),
  contentByProductId: getContentByProductId(state),
  lastFailure: getLastFailure(state),
  isCmtAdmin: isCmtAdmin(selectSessionUserRoles(state)),
  sessionCustomerId: selectCustomerId(state)
});

export const mapDispatchToProps = dispatch => ({
  queryLicenseForCustomer: customerId => {
    dispatch(queryLicenseForCustomerAction(customerId));
  },
  updateLicense: license => {
    dispatch(updateLicenseAction(license));
  },
  deleteLicense: license => {
    dispatch(deleteLicenseAction(license));
  },
  createLicense: license => {
    dispatch(createLicenseAction(license));
  },
  queryCustomers: () => {
    dispatch(queryCustomersAction());
  },
  queryProductsAndContent: () => {
    dispatch(queryProductsAndContent());
  },
  dismissError: () => {
    dispatch(dismissErrorAction());
  }
});

export default _flow(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(LicenseManagement);
