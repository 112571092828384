/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import React from 'react';

const SvgLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 301.7 126">
    <style></style>
    <radialGradient
      id="SVGID_1_"
      cx="69.671"
      cy="93.319"
      r="14.894"
      gradientTransform="matrix(1 0 0 1.011 0 -1.029)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" />
      <stop offset=".998" stopOpacity="0" />
    </radialGradient>
    <path
      d="M92.1 96.1c-6-4.8-13.9-7.5-22.3-7.5s-16.3 2.6-22.3 7.5h-.6l.1.7h.8c5.6-4.6 13.4-7.4 22-7.4s16.4 2.8 22 7.4h.6v-.7h-.3z"
      opacity=".51"
      fill="url(#SVGID_1_)"
    />
    <linearGradient
      id="SVGID_2_"
      gradientUnits="userSpaceOnUse"
      x1="69.787"
      y1="35.085"
      x2="69.787"
      y2="103.573"
    >
      <stop offset=".156" stopColor="#f5e5d4" />
      <stop offset=".282" stopColor="#eadbca" />
      <stop offset=".503" stopColor="#ccc0b1" />
      <stop offset=".791" stopColor="#9d9488" />
      <stop offset="1" stopColor="#767067" />
    </linearGradient>
    <path
      d="M48.2 86.9c-3.4 2-6.4 4.3-9 6.9 2.2 2.2 5.3 3.3 8.5 2.9 5.6-4.6 13.4-7.4 22-7.4s16.4 2.8 22 7.4c3.2.3 6.3-.7 8.5-2.9-13.3-13.5-34.6-16.9-52-6.9z"
      fill="url(#SVGID_2_)"
    />
    <linearGradient
      id="SVGID_3_"
      gradientUnits="userSpaceOnUse"
      x1="87.046"
      y1="35.085"
      x2="87.046"
      y2="103.573"
    >
      <stop offset=".156" stopColor="#f5e5d4" />
      <stop offset=".282" stopColor="#eadbca" />
      <stop offset=".503" stopColor="#ccc0b1" />
      <stop offset=".791" stopColor="#9d9488" />
      <stop offset="1" stopColor="#767067" />
    </linearGradient>
    <path
      d="M100.5 79.7c-6.4-2.7-12.4-7.9-16.5-15-4.1-7.2-5.6-15-4.7-22-1.2-3.1-3.8-5.5-7-6.3-5 18.4 2.7 38.6 20 48.6 3.4 2 6.9 3.4 10.5 4.3 1-3.2.1-6.9-2.3-9.6z"
      fill="url(#SVGID_3_)"
    />
    <linearGradient
      id="SVGID_4_"
      gradientUnits="userSpaceOnUse"
      x1="52.525"
      y1="35.085"
      x2="52.525"
      y2="103.573"
    >
      <stop offset=".156" stopColor="#f5e5d4" />
      <stop offset=".282" stopColor="#eadbca" />
      <stop offset=".503" stopColor="#ccc0b1" />
      <stop offset=".791" stopColor="#9d9488" />
      <stop offset="1" stopColor="#767067" />
    </linearGradient>
    <path
      d="M68.7 47.8c0-3.9-.5-7.7-1.5-11.3-3.1.8-5.7 3.2-7 6.3.9 7-.6 14.8-4.7 22-4.1 7.1-10.1 12.3-16.5 15-2.4 2.7-3.3 6.4-2.4 9.8 18.5-5 32.1-21.8 32.1-41.8z"
      fill="url(#SVGID_4_)"
    />
    <linearGradient
      id="SVGID_5_"
      gradientUnits="userSpaceOnUse"
      x1="69.831"
      y1="87.834"
      x2="69.831"
      y2="80.779"
    >
      <stop offset=".001" />
      <stop offset=".423" stopColor="#0c0c0c" />
      <stop offset="1" stopColor="#242424" />
    </linearGradient>
    <path
      d="M100.3 93.8s.1 0 0 0c-13.4-13.6-34.8-16.9-52.1-6.9-3.3 1.9-6.3 4.3-8.9 6.9 9.3-5.1 19.8-7.8 30.4-7.8 10.8 0 21.3 2.7 30.6 7.8z"
      fill="url(#SVGID_5_)"
    />
    <linearGradient
      id="SVGID_6_"
      gradientUnits="userSpaceOnUse"
      x1="68.179"
      y1="42.984"
      x2="100.124"
      y2="93.52"
    >
      <stop offset=".001" stopColor="#430000" />
      <stop offset=".548" stopColor="#e02827" />
      <stop offset=".613" stopColor="#db2726" />
      <stop offset=".686" stopColor="#ce2322" />
      <stop offset=".763" stopColor="#b71e1d" />
      <stop offset=".843" stopColor="#981615" />
      <stop offset=".924" stopColor="#700b0b" />
      <stop offset="1" stopColor="#430000" />
    </linearGradient>
    <path
      d="M72.3 36.5c-5 18.4 2.7 38.6 20 48.6 3.4 2 6.9 3.4 10.5 4.3-18.7-11.5-30-31.6-30.5-52.9z"
      fill="url(#SVGID_6_)"
    />
    <linearGradient
      id="SVGID_7_"
      gradientUnits="userSpaceOnUse"
      x1="70.677"
      y1="43.506"
      x2="43.634"
      y2="88.971"
    >
      <stop offset="0" stopColor="#cf6920" />
      <stop offset=".102" stopColor="#df7d1f" />
      <stop offset=".232" stopColor="#ed8e1f" />
      <stop offset=".371" stopColor="#f5991e" />
      <stop offset=".534" stopColor="#f89c1e" />
      <stop offset=".658" stopColor="#f4971e" />
      <stop offset=".798" stopColor="#e98a1f" />
      <stop offset=".945" stopColor="#d77320" />
      <stop offset="1" stopColor="#cf6920" />
    </linearGradient>
    <path
      d="M36.7 89.4c18.5-4.8 32.1-21.6 32.1-41.6 0-3.9-.5-7.7-1.5-11.3-.6 22-12.4 41.8-30.6 52.9z"
      fill="url(#SVGID_7_)"
    />
    <linearGradient
      id="SVGID_8_"
      gradientUnits="userSpaceOnUse"
      x1="73.896"
      y1="39.462"
      x2="39.248"
      y2="92.534"
    >
      <stop offset="0" stopColor="#231f20" />
      <stop offset=".536" stopColor="#231f20" stopOpacity="0" />
      <stop offset="1" stopColor="#231f20" />
    </linearGradient>
    <path
      d="M36.7 89.4c18.5-4.8 32.1-21.6 32.1-41.6 0-3.9-.5-7.7-1.5-11.3-.6 22-12.4 41.8-30.6 52.9z"
      opacity=".53"
      fill="url(#SVGID_8_)"
    />
    <g>
      <radialGradient
        id="SVGID_9_"
        cx="69.013"
        cy="70.442"
        r="20.283"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset="1" stopColor="#fff" stopOpacity="0" />
      </radialGradient>
      <path
        d="M69.7 82c10.9 0 21.6 4.3 29.4 11.8-1.7 1.4-3.8 2.2-6.1 2.2h-.8c-6-4.8-13.9-7.5-22.4-7.5-8.4 0-16.4 2.6-22.4 7.5h-.8c-2.3 0-4.4-.8-6.1-2.2 2.5-2.4 5.2-4.5 8.2-6.2 6.4-3.7 13.7-5.6 21-5.6m0-.9c-7.3 0-14.7 1.9-21.5 5.8-3.4 2-6.4 4.3-9 6.9 1.9 1.9 4.6 3 7.4 3 .4 0 .8 0 1.2-.1 5.6-4.6 13.4-7.4 22-7.4s16.4 2.8 22 7.4c.4 0 .8.1 1.2.1 2.8 0 5.4-1.1 7.4-3-8.2-8.2-19.3-12.7-30.7-12.7z"
        fill="url(#SVGID_9_)"
      />
      <radialGradient
        id="SVGID_10_"
        cx="69.013"
        cy="70.442"
        r="20.283"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset="1" stopColor="#fff" stopOpacity="0" />
      </radialGradient>
      <path
        d="M72.9 37.5c2.4.9 4.4 2.8 5.5 5.3-.9 7.4.8 15.3 4.8 22.3 4 6.9 9.9 12.4 16.7 15.3 1.9 2.2 2.8 5.2 2.3 8-3.3-1-6.5-2.3-9.5-4-16.2-9.5-24.3-28.6-19.8-46.9m-.6-1.1C67.3 54.8 75 75 92.3 85c3.4 2 6.9 3.4 10.5 4.3.9-3.4 0-7.1-2.4-9.8-6.4-2.7-12.4-7.9-16.5-15-4.1-7.2-5.6-15-4.7-22-1.1-2.9-3.7-5.2-6.9-6.1z"
        fill="url(#SVGID_10_)"
      />
      <radialGradient
        id="SVGID_11_"
        cx="69.013"
        cy="70.442"
        r="20.283"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset="1" stopColor="#fff" stopOpacity="0" />
      </radialGradient>
      <path
        d="M66.6 37.5c.8 3.4 1.3 6.8 1.3 10.2 0 18.9-12.5 35.4-30.6 40.6-.5-2.8.4-5.8 2.3-8C46.4 77.4 52.3 72 56.3 65s5.8-14.9 4.8-22.3c1.1-2.3 3.1-4.3 5.5-5.2m.6-1c-3.1.8-5.7 3.2-7 6.3.9 7-.6 14.8-4.7 22-4.1 7.1-10.1 12.3-16.5 15-2.4 2.7-3.3 6.4-2.4 9.8 18.5-4.9 32.1-21.7 32.1-41.7 0-4.1-.5-7.8-1.5-11.4z"
        fill="url(#SVGID_11_)"
      />
    </g>
    <defs>
      <filter
        id="Adobe_OpacityMaskFilter"
        filterUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="140"
        height="126"
      >
        <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
      </filter>
    </defs>
    <mask
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="140"
      height="126"
      id="SVGID_12_"
    >
      <g filter="url(#Adobe_OpacityMaskFilter)">
        <linearGradient
          id="SVGID_13_"
          gradientUnits="userSpaceOnUse"
          x1="69.12"
          y1="25.219"
          x2="69.12"
          y2="106.919"
        >
          <stop offset="0" stopColor="#fff" />
          <stop offset="1" stopColor="#231f20" />
        </linearGradient>
        <path fill="url(#SVGID_13_)" d="M28.3 25.2H110v81.7H28.3z" />
      </g>
    </mask>
    <g opacity=".4" mask="url(#SVGID_12_)">
      <image width="140" height="126" overflow="visible" />
    </g>
    <g>
      <path
        fill="white"
        d="M232.9 56.8c-.1-.4-.6-.7-1.1-.7-.5 0-1 .2-1.1.7l-5.9 19.3v.2c0 .5.6.8 1.1.8.3 0 .7-.1.7-.5l1.3-4.3h7.9l.2.7 1.1 3.5c.1.3.4.5.7.5.5 0 1.1-.4 1.1-.8V76l-6-19.2zm-4.6 13.8l3.5-11.4 3.2 10.7.2.8h-6.9zM271.9 56.3h-11.4c-.5 0-.9.4-.9.9s.4.9.9.9h11.4c.5 0 .9-.4.9-.9s-.4-.9-.9-.9zM271.9 65.9h-11.4c-.5 0-.9.4-.9.9s.4.9.9.9h11.4c.5 0 .9-.4.9-.9-.1-.6-.4-.9-.9-.9zM271.9 75.4h-11.4c-.5 0-.9.4-.9.9s.4.9.9.9h11.4c.5 0 .9-.4.9-.9-.1-.5-.4-.9-.9-.9zM250.1 67.4c2.5-.4 4.4-2 4.4-5.5 0-4.3-2.8-5.6-6.1-5.6h-9.2c-.5 0-.9.4-.9.9s.4.9.9.9h9.2c2.5 0 4.3 1.1 4.3 3.9 0 2.9-1.7 4-4.3 4h-5.5c-.4 0-.8.4-.8.8s.4.8.8.8h5.3l4.4 8.5c.2.5.5 1 1 1s1.1-.5 1.1-1c0-.1 0-.3-.1-.4l-4.5-8.3zM284.2 65.6c-2.5-1-4.8-2.1-4.8-4 0-2.1 2-3.8 4.5-3.8 1.5 0 2.9.6 3.7 1.7.3.4.8.4 1.2.1.4-.3.4-.8.1-1.2-1.2-1.4-3.1-2.3-5.1-2.3-3.4 0-6.3 2.5-6.3 5.6 0 3.4 3.9 4.8 5.9 5.6 2.2.8 4.8 2.6 4.8 4.6 0 2.1-2 3.8-4.5 3.8-1.5 0-2.9-.6-3.8-1.7-.3-.4-.8-.4-1.2-.2-.4.3-.4.8-.2 1.2 1.2 1.5 3.1 2.4 5.1 2.4 3.4 0 6.3-2.5 6.3-5.6.2-.9-.2-4.1-5.7-6.2z"
      />
      <g>
        <path
          fill="white"
          d="M115.7 56.6c0-.3.2-.5.5-.5h5.1c3.2 0 5.7 1.6 5.7 6v.3c0 4.4-2.5 6-5.7 6h-4.6v8.4c0 .3-.3.5-.5.5s-.5-.2-.5-.5V56.6zm1.1.6v10.3h4.6c2.9 0 4.6-1.6 4.6-5v-.3c0-3.4-1.7-4.9-4.6-4.9h-4.6zM130.6 56.6c0-.3.2-.5.5-.5h4.9c3.2 0 6 1.3 6 5.5 0 3.7-2.2 5.2-4.9 5.5l4.8 9.3c.1.1.1.2.1.3 0 .3-.2.5-.5.5-.4 0-.6-.6-.8-1l-4.6-9.1h-4.3v9.6c0 .3-.3.5-.5.5-.3 0-.5-.2-.5-.5V56.6zm1.1.6v9h4.3c2.9 0 4.9-1.3 4.9-4.5s-2-4.5-4.9-4.5h-4.3zM145.2 71.6v-9.4c0-4.4 2.5-6 5.7-6s5.7 1.6 5.7 6v9.4c0 4.4-2.5 6-5.7 6-3.2-.1-5.7-1.7-5.7-6zm10.3-9.5c0-3.4-1.7-4.9-4.6-4.9s-4.6 1.6-4.6 4.9v9.4c0 3.4 1.7 4.9 4.6 4.9s4.6-1.6 4.6-4.9v-9.4zM176.6 66.2h9.8c.3 0 .5.3.5.6 0 .2-.1.5-.5.5h-9.8v9.1h9.8c.3 0 .5.3.5.6 0 .2-.1.5-.5.5h-10.3c-.3 0-.5-.2-.5-.5V56.7c0-.3.3-.5.5-.5h10.3c.3 0 .5.3.5.5 0 .3-.2.6-.5.6h-9.8v8.9zM191.4 71.6c0 3.4 1.7 4.9 4.8 4.9 3.8 0 4.2-2.3 4.4-3.8 0-.3.1-.5.5-.5.3 0 .5.2.5.8 0 2.2-1.9 4.5-5.7 4.5-3.1 0-5.7-1.6-5.7-6v-9.4c0-4.4 2.6-6 5.8-6 3.7 0 5.6 2.3 5.6 4.6 0 .7-.2.8-.5.8-.5 0-.5-.3-.5-.5-.1-1.4-.8-3.8-4.6-3.8-3 0-4.7 1.6-4.7 4.9v9.5zM203.9 57.2c-.3 0-.5-.3-.5-.5 0-.3.2-.5.5-.5h11.4c.3 0 .5.2.5.5s-.1.6-.5.6h-5.1V77c0 .3-.3.5-.5.5-.3 0-.5-.2-.5-.5V57.2h-5.3zM170.4 56.1c-.3 0-.5.2-.5.5v14.9c0 3.4-1.7 4.9-4.6 4.9-2.6 0-4.6-1.2-4.6-3.8 0-.3-.3-.5-.6-.5s-.5.2-.5.6c0 2.7 2.1 4.8 5.7 4.8 3.2 0 5.7-1.6 5.7-6V56.7c-.1-.4-.4-.6-.6-.6z"
        />
      </g>
      <g>
        <path
          fill="white"
          d="M297.8 56.1c1.8 0 3.9 1.4 3.9 4.2 0 2.9-2.2 4.2-4 4.2-1.8 0-3.9-1.4-3.9-4.2 0-2.9 2.2-4.2 4-4.2zm0 .5c-2 0-3.4 1.5-3.4 3.6s1.3 3.6 3.3 3.6c2 0 3.4-1.5 3.4-3.6.1-2.1-1.3-3.6-3.3-3.6zm-1.2 1.3c0-.1.1-.2.2-.2h1.1c.7 0 1.4.3 1.4 1.4 0 .8-.4 1.2-.9 1.3l.8 1.9v.1c0 .2-.2.3-.3.3-.1 0-.2-.1-.2-.2l-.9-2h-.6v2c0 .1-.1.2-.3.2s-.3-.1-.3-.2v-4.6zm.5.3V60h.8c.5 0 .8-.2.8-.9 0-.6-.3-.9-.8-.9h-.8z"
        />
      </g>
    </g>
  </svg>
);

export default SvgLogo;
