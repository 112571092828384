import { Button, TextField } from '@material-ui/core';
import React from 'react';
import _isEmpty from 'lodash/isEmpty';

export const EditProfileForm = props => {
  const {
    values: {
      firstName,
      lastName,
      userName,
      customerId,
      roles,
      aresRoles,
      groupIds
    },
    touched,
    errors,
    handleSubmit,
    handleChange,
    setFieldTouched,
    getGroupNamesFromIds,
    getCustomerNameFromId,
    isValid
  } = props;

  const change = (name, e) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  return (
    <form
      onSubmit={handleSubmit}
      style={{
        width: '60%'
      }}
    >
      <TextField
        id="firstName"
        name="firstName"
        label="First Name"
        value={firstName}
        onChange={change.bind(null, 'firstName')}
        error={touched.firstName && Boolean(errors.firstName)}
        helperText={touched.firstName ? errors.firstName : ''}
        fullWidth
        disabled={false}
        data-test={`editProfile-textField-${firstName}`}
      />
      <TextField
        id="lastName"
        name="lastName"
        label="Last Name"
        value={lastName}
        onChange={change.bind(null, 'lastName')}
        error={touched.lastName && Boolean(errors.lastName)}
        helperText={touched.lastName ? errors.lastName : ''}
        fullWidth
        disabled={false}
        data-test={`editProfile-textField-${lastName}`}
      />
      <TextField
        id="userName"
        name="userName"
        label="Username"
        value={userName}
        fullWidth
        disabled={true}
        data-test={`editProfile-textField-${userName}`}
      />
      <TextField
        id="customerId"
        name="customerId"
        label="Customer"
        InputLabelProps={{ shrink: customerId ? true : false }}
        value={getCustomerNameFromId(customerId) || ''}
        fullWidth
        disabled={true}
        data-test={`editProfile-textField-${customerId}`}
      />
      <TextField
        id="roles"
        name="roles"
        label="Roles"
        value={roles}
        fullWidth
        disabled={true}
        data-test={`editProfile-textField-${roles}`}
      />
      <TextField
        id="aresRoles"
        name="aresRoles"
        label="Ares Roles"
        value={aresRoles}
        fullWidth
        disabled={true}
        data-test={`editProfile-textField-${aresRoles}`}
      />
      <TextField
        id="groupIds"
        name="groupIds"
        label="Groups"
        InputLabelProps={{ shrink: groupIds.length === 0 ? false : true }}
        value={getGroupNamesFromIds(groupIds) || ''}
        fullWidth
        disabled={true}
        data-test={`editProfile-textField-${groupIds}`}
      />
      <Button
        type="submit"
        fullWidth
        color="primary"
        disabled={!isValid || _isEmpty(touched)}
      >
        SAVE
      </Button>
    </form>
  );
};

export default EditProfileForm;
