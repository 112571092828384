import React, { Component } from 'react';
import _flow from 'lodash/flow';
import { connect } from 'react-redux';
import { Typography, withStyles } from '@material-ui/core';
import { queryReports, queryReportToken } from '../actions';
import { msal } from '@circadence/auth';
import {
  getReportToken,
  getReports,
  getLastFailure,
  isLoading
} from '../selectors';
import PowerbiEmbedded from 'react-powerbi';

export class Dendrite extends Component {
  componentDidMount = () => {
    this.props.getReports(
      this.props.sessionUserId,
      this.props.sessionCustomerId
    );
  };

  componentDidUpdate = () => {
    if (
      this.props.reports &&
      !this.props.reportToken &&
      !this.props.isLoading
    ) {
      this.props.getReportToken('267febf0-121d-47eb-b352-c162a4ee7454');
    }
  };

  render() {
    const { classes, reportToken } = this.props;

    return (
      <div className={classes.paper}>
        <Typography
          className={classes.header}
          display="block"
          variant="h4"
          component="h4"
        >
          Dendrite
          {this.props.reportToken && !this.props.isLoading && (
            <PowerbiEmbedded
              id={reportToken.embedReportId}
              embedUrl={reportToken.embedUrl}
              accessToken={reportToken.accessToken}
              pageName={`test`}
              embedType={`report`}
              tokenType={`Embed`}
              width="1200px"
              height="900px"
            />
          )}
        </Typography>
      </div>
    );
  }
}

export const styles = (theme: any) => ({
  paper: {
    borderTop: `1px solid ${theme.palette.black.main}`,
    borderBottom: `1px solid ${theme.palette.black.main}`,
    paddingTop: '25px',
    overflow: 'scroll',
    width: '100%',
    boxShadow: ' -5px -5px 20px 0px rgba(0,0,0,0.12);',
    marginLeft: '25px',
    paddingLeft: '25px'
  },
  header: {
    color: theme.palette.primary.darkTurquoise
  }
});

export const mapDispatchToProps = dispatch => ({
  getReportToken: reportId => {
    dispatch(queryReportToken(reportId));
  },
  getReports: (userId, customerId) => {
    dispatch(queryReports(userId, customerId));
  }
});
export const mapStateToProps = state => ({
  sessionCustomerId: msal.selectIdentityCustomerId(state),
  sessionUserId: msal.selectIdentitySubject(state),
  reportToken: getReportToken(state),
  reports: getReports(state),
  isLoading: isLoading(state),
  lastFailure: getLastFailure(state)
});

export default _flow(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(Dendrite);
