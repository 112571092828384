/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import React from 'react';
import { Formik } from 'formik';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  withStyles
} from '@material-ui/core';
import { licenseValidationSchema } from './LicenseValidationSchema';
import LicenseInfoForm from './LicenseInfoForm';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ContentAccess from '../../ContentAccess/ui/ContentAccess.container';

export const LicenseForm = ({
  classes,
  updateLicense,
  license,
  customerOptions,
  isCmtAdmin
}) =>
  license !== undefined &&
  license !== null && (
    <div className={classes.root}>
      <div className={classes.expansionDiv}>
        <ExpansionPanel
          defaultExpanded
          classes={{ root: classes.expansionPanel }}
          data-test={`licenseForm-license-${license.id}`}
        >
          <ExpansionPanelSummary
            className={classes.expansionPanelDetails}
            expandIcon={<ExpandMoreIcon />}
            id="edit-license"
          >
            <Typography className={classes.header} variant="h4" component="h4">
              Edit License Info
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.formDetails}>
            <Formik
              enableReinitialize
              validationSchema={licenseValidationSchema}
              initialValues={license}
              onSubmit={updateLicense}
            >
              {props => (
                <LicenseInfoForm
                  {...props}
                  customerOptions={customerOptions}
                  isCmt={isCmtAdmin}
                />
              )}
            </Formik>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ContentAccess license={license} isEditable={isCmtAdmin} />
      </div>
    </div>
  );

export const styles = theme => ({
  formDetails: {
    display: 'block'
  },
  root: {
    marginRight: '10px',
    marginBottom: '10px',
    width: '60%',
    minWidth: '600px',
    maxHeight: '90vh',
    overflow: 'scroll'
  },
  expansionDiv: {
    position: 'sticky',
    top: '0px'
  },
  expansionPanel: {
    padding: '0px',
    margin: '0px',
    marginBottom: '25px',
    background: theme.palette.primary.lightSlateGray
  },
  expansionPanelDetails: {
    background: theme.palette.primary.lightSlateGray
  },
  header: {
    color: theme.palette.primary.darkTurquoise
  }
});

export default withStyles(styles)(LicenseForm);
