/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */
import _flow from 'lodash/flow';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Typography, withStyles } from '@material-ui/core';
import { selectGroups } from '../../../selectors';
import { fetchGroups, inviteUsers } from '../../../actions';
import * as roleConstants from '../../../../../constants/roles';
import { getCustomers } from '../../../../../components/Dashboard/CustomerManagement/selectors';
import { queryCustomersAction } from '../../../../../components/Dashboard/CustomerManagement/actions';
import { queryProducts } from '../../../../../components/Dashboard/ContentAccess/actions';
import _includes from 'lodash/includes';
import {
  selectCustomerId,
  selectSessionUserRoles
} from '../../../../session-user/selectors';
import BulkImportDialog from '../BulkInviteDialog';
import EditableImportGrid from '../EditableImportGrid';
import { v1 as uuidv1 } from 'uuid';

export class InvitationPageView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editUser: {},
      inviteUserModel: {
        firstName: '',
        lastName: '',
        userName: '',
        roles: [],
        customer: '',
        groups: [],
        aresRoles: []
      },
      bulkInviteUserDialogOpen: true,
      selectedCustomer: undefined,
      filteredUsersByCustomer: undefined,
      importedRecords: [],
      failedRows: 0
    };
  }

  componentDidMount() {
    const { sessionCustomerId, sessionUserRoles } = this.props;
    const isCmt = _includes(
      this.props.sessionUserRoles,
      roleConstants.CMT_ADMIN
    );
    const isCustomerAdmin = _includes(
      sessionUserRoles,
      roleConstants.CUSTOMER_ADMIN
    );
    if (this.props.customers === undefined) {
      this.props.queryCustomers();
    }
    if (isCustomerAdmin && !isCmt) {
      this.props.fetchGroups(sessionCustomerId);
    }
    if (isCmt) {
      this.props.fetchGroups('');
    }
  }

  handleBulkInviteUsers = (customerId, users) => {
    this.props.inviteUsers(customerId, users);
  };

  handleBulkInviteUserDialogClose = () => {
    this.setState({
      bulkInviteUserDialogOpen: false
    });
  };

  handleBulkInviteSubmit = (
    importedRecords,
    failedRows,
    customerId,
    groupIds
  ) => {
    const newData = importedRecords.map(row => ({
      ...row,
      userName: row.email,
      validation: {},
      id: uuidv1(),
      customer: customerId,
      groups: groupIds
    }));
    this.setState({
      bulkInviteUserDialogOpen: false,
      importedRecords: newData,
      failedRows
    });
  };

  render() {
    const { classes } = this.props;
    const { importedRecords } = this.state;
    const isCmt = _includes(
      this.props.sessionUserRoles,
      roleConstants.CMT_ADMIN
    );
    const isCustomerAdmin = _includes(
      this.props.sessionUserRoles,
      roleConstants.CUSTOMER_ADMIN
    );
    return (
      <div className={classes.paper}>
        <Typography
          className={classes.header}
          display="block"
          variant="h4"
          component="h4"
        >
          Invitation Page
        </Typography>
        {this.props.customers && (
          <EditableImportGrid
            data={importedRecords}
            handleImportUsers={this.handleImportUsers}
            duplicateRows={this.state.failedRows}
            customerOptions={
              isCmt
                ? this.props.customers
                : {
                    [this.props.sessionCustomerId]: this.props.customers[
                      this.props.sessionCustomerId
                    ]
                  }
            }
            groupOptions={this.props.groups}
            customers={this.props.customers}
            sessionCustomerId={this.props.sessionCustomerId}
            sessionUserRoles={this.props.sessionUserRoles}
            isCmt={isCmt}
            handleBulkInviteUsers={this.handleBulkInviteUsers}
          />
        )}
        {this.state.bulkInviteUserDialogOpen && this.props.customers && (
          <BulkImportDialog
            open={true}
            model={this.state.inviteUserModel}
            isCmt={isCmt}
            isCustomerAdmin={isCustomerAdmin}
            customerOptions={
              isCmt
                ? this.props.customers
                : {
                    [this.props.sessionCustomerId]: this.props.customers[
                      this.props.sessionCustomerId
                    ]
                  }
            }
            groupOptions={this.props.groups}
            handleCloseDialog={this.handleBulkInviteUserDialogClose}
            handleBulkInviteSubmit={this.handleBulkInviteSubmit}
          />
        )}
      </div>
    );
  }
}

function createRoleList(rolesList) {
  const roles = [];
  if (rolesList.includes(roleConstants.CMT_ADMIN)) {
    roles.push(roleConstants.CMT_ADMIN);
  }

  if (rolesList.includes(roleConstants.CUSTOMER_ADMIN)) {
    roles.push(roleConstants.CUSTOMER_ADMIN);
  }

  if (rolesList.includes(roleConstants.PLAYER)) {
    roles.push(roleConstants.PLAYER);
  }
  return roles;
}

const mapStateToProps = state => ({
  customers: getCustomers(state),
  groups: selectGroups(state),
  sessionCustomerId: selectCustomerId(state),
  sessionUserRoles: createRoleList(selectSessionUserRoles(state))
});
export const mapDispatchToProps = dispatch => ({
  queryCustomers: () => {
    dispatch(queryCustomersAction());
  },
  queryProducts: () => {
    dispatch(queryProducts());
  },
  fetchGroups: customerId => {
    dispatch(fetchGroups(customerId));
  },
  inviteUsers: (customerId, users) => {
    dispatch(inviteUsers(customerId, users));
  }
});
export const styles = theme => ({
  paper: {
    borderTop: `1px solid ${theme.palette.black.main}`,
    borderBottom: `1px solid ${theme.palette.black.main}`,
    paddingTop: '25px',
    overflow: 'scroll',
    width: '100%',
    boxShadow: ' -5px -5px 20px 0px rgba(0,0,0,0.12);',
    marginLeft: '25px',
    paddingLeft: '25px'
  },
  userTableContainer: {
    width: '100%',
    height: '100%',
    borderBottom: '2px solid lightgray !important'
  },
  header: {
    color: theme.palette.primary.darkTurquoise
  },
  //hiding org chart for now
  orgChartContainer: {
    width: '100%',
    height: '0%',
    overflow: 'scroll'
  }
});
export default _flow(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(InvitationPageView);
