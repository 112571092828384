/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

/* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup';

export const loginValidationSchema = Yup.object({
  username: Yup.string('Enter a Username')
    .required('A username is required')
    .max(150, 'A username can have at most ${max} characters')
    .min(1, 'A username must have at least ${min} character')
    .matches(
      /^[a-zA-Z0-9_@+,-.]*$/,
      'A username can only contain alphanumeric characters'
    )
});
