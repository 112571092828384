/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

/* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup';

const EMAIL_SCHEMA = /^[a-zA-Z0-9_@+,\-.']*$/

export const registrationValidationSchema = Yup.object({
  userName: Yup.string('Enter a Username')
    .required('A username is required')
    .max(150, 'A username can have at most ${max} characters')
    .min(1, 'A username must have at least ${min} character')
    .matches(
      EMAIL_SCHEMA,
      'A username can only contain alphanumeric characters'
    ),
  isAdult: Yup.boolean().oneOf([true], 'Please verify your age'),
  agreedPrivacyPolicy: Yup.boolean().oneOf(
    [true],
    'Please accept the privacy policy'
  ),
  agreedTerms: Yup.boolean().oneOf(
    [true],
    'Please accept the terms and conditions'
  )
});

export const inviteRegistrationValidationSchema = Yup.object({
  userName: Yup.string('Enter a Username')
    .required('A username is required')
    .max(150, 'A username can have at most ${max} characters')
    .min(1, 'A username must have at least ${min} character')
    .matches(
      EMAIL_SCHEMA,
      'A username can only contain alphanumeric characters'
    ),
  password: Yup.string('Enter a Password')
    .required('A password is required')
    .max(150, 'A password can have at most ${max} characters')
    .min(8, 'A password must have at least ${min} character')
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
      'A password must contain at least one uppercase, lowercase, number and special character'
    ),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'Passwords must match!'
  ),
  isAdult: Yup.boolean().oneOf([true], 'Please verify your age'),
  agreedPrivacyPolicy: Yup.boolean().oneOf(
    [true],
    'Please accept the privacy policy'
  ),
  agreedTerms: Yup.boolean().oneOf(
    [true],
    'Please accept the terms and conditions'
  )
});
