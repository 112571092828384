import { Component } from 'react';
import { msal } from '@circadence/auth';
import { getSessionUserDetailsAction } from '../../modules/session-user/actions';
import { connect } from 'react-redux';

class SessionUser extends Component {
  componentDidMount() {
    const { sessionUserId, getSessionUserDetails } = this.props;
    if (sessionUserId) {
      getSessionUserDetails(sessionUserId);
    }
  }

  render() {
    return null;
  }
}

const mapStateToProps = state => ({
  sessionUserId: msal.selectIdentitySubject(state)
});

export const mapDispatchToProps = dispatch => ({
  getSessionUserDetails: sessionUserId => {
    dispatch(getSessionUserDetailsAction(sessionUserId));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(SessionUser);
