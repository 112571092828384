/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import React from 'react';

const CircadenceWhiteLogo = props => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 279 119"
  >
    <style type="text/css"></style>
    <path
      fill="white"
      d="M33.7,66.1l-1.6-1.6c-2.1,2-4.1,3.2-7.1,3.2c-4.8,0-8.5-4-8.5-9.1c0-5.1,3.6-9,8.5-9c3,0,5,1.3,6.9,3l1.7-1.8
	c-2.2-2.1-4.6-3.5-8.5-3.5c-6.4,0-11.1,5.1-11.1,11.3C14,65,18.7,70,25,70C29,70,31.5,68.4,33.7,66.1 M38.3,69.6h2.5V47.7h-2.5V69.6
	z M65.2,69.6l-6.7-8.9c3.4-0.6,5.9-2.7,5.9-6.4c0-3.9-3.1-6.5-7.9-6.5h-9.4v21.9h2.5v-8.5h6.3l6.3,8.5H65.2z M62,54.3
	c0,2.8-2.3,4.5-5.6,4.5h-6.7V50h6.8C59.9,50,62,51.6,62,54.3 M87,66.1l-1.6-1.6c-2.1,2-4.1,3.2-7.1,3.2c-4.8,0-8.5-4-8.5-9.1
	c0-5.1,3.6-9,8.5-9c3,0,5,1.3,6.9,3l1.7-1.8c-2.2-2.1-4.6-3.5-8.5-3.5c-6.4,0-11.1,5.1-11.1,11.3c0,6.3,4.7,11.3,11,11.3
	C82.2,70,84.7,68.4,87,66.1 M100.7,47.5h-2.3l-10,22H91l2.6-5.8h11.9l2.6,5.8h2.7L100.7,47.5z M104.5,61.6h-10l5-11.2L104.5,61.6z
	 M133.2,58.6c0-6.2-4.8-10.9-11.6-10.9H114v21.9h7.6C128.4,69.6,133.2,64.8,133.2,58.6 M130.6,58.7c0,4.9-3.5,8.6-9.1,8.6h-5.1V50
	h5.1C127.1,50,130.6,53.8,130.6,58.7 M153.3,47.7h-15.8v21.9h16v-2.3h-13.5v-7.7h11.9v-2.3h-11.9V50h13.3V47.7z M174.4,65.2
	l-13.8-17.5h-2.3v21.9h2.4V51.6l14.1,17.9h2V47.7h-2.4V65.2z M200.7,66.1l-1.6-1.6c-2.1,2-4.1,3.2-7.1,3.2c-4.8,0-8.5-4-8.5-9.1
	c0-5.1,3.6-9,8.5-9c3,0,5,1.3,6.9,3l1.7-1.8c-2.2-2.1-4.6-3.5-8.5-3.5c-6.4,0-11.1,5.1-11.1,11.3c0,6.3,4.7,11.3,11,11.3
	C195.9,70,198.4,68.4,200.7,66.1 M220.8,47.7H205v21.9h16v-2.3h-13.5v-7.7h11.9v-2.3h-11.9V50h13.3V47.7z"
    />
    <g>
      <polygon
        fill="white"
        points="252.7,57.1 262.9,57.1 261.1,52.4 256.8,52.1 258,44.3 255.7,38.3 	"
      />
      <polygon fill="white" points="252.4,36.9 248.5,35.1 251.1,47.2 	" />
      <polygon fill="white" points="248.1,83 252.2,81.1 251.1,70.6 	" />
      <polygon
        fill="white"
        points="244,34.3 240.2,40.4 243.8,51.6 234.1,50.5 230.1,57.1 250.6,57.1 	"
      />
      <polygon
        fill="white"
        points="252.8,60.2 263,60.2 261.2,64.9 257,65.3 258.1,73.1 255.8,79.1 	"
      />
      <polygon
        fill="white"
        points="244.1,82.9 240.5,77.2 243.9,65.7 233.3,65.5 230.2,60.2 250.7,60.2 	"
      />
    </g>
    <path
      fill="white"
      d="M263.1,44.7c0.2,0,0.3-0.1,0.3-0.4c0-0.2-0.1-0.3-0.3-0.3h-0.3v0.7H263.1z M262.3,43.7h0.8
	c0.4,0,0.7,0.2,0.7,0.6c0,0.3-0.2,0.5-0.4,0.5v0c0,0,0,0,0.1,0.1l0.4,0.8h-0.4l-0.4-0.8h-0.3v0.8h-0.4V43.7z M263,46.4
	c0.9,0,1.6-0.7,1.6-1.6c0-0.9-0.7-1.6-1.6-1.6c-0.9,0-1.5,0.7-1.5,1.6C261.5,45.7,262.1,46.4,263,46.4 M263,42.7c1.1,0,2,0.9,2,2
	c0,1.1-0.9,2-2,2c-1.1,0-1.9-0.9-1.9-2C261.1,43.6,261.9,42.7,263,42.7"
    />
  </svg>
);

export default CircadenceWhiteLogo;
