/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import type {
  CustomerManagementState,
  CreateCustomerAction,
  QueryCustomersAction,
  UpdateCustomerAction,
  DeleteCustomerAction,
  QueryCustomerAdminsAction,
  CreateCustomerAdminAction,
  UpdateCustomerAdminAction,
  DeleteCustomerAdminAction,
  SetLoadingStateAction,
  SetLastFailureAction
} from '../types';
import { getCustomers, getAdminsByCustomer } from './getters';
import _reduce from 'lodash/reduce';
import _omit from 'lodash/omit';
import _map from 'lodash/map';

export const setLoadingState = (
  state: CustomerManagementState,
  action: SetLoadingStateAction
): $Shape<CustomerManagementState> => ({
  isLoading: action.payload
});

export const setLastFailure = (
  state: CustomerManagementState,
  action: SetLastFailureAction
): $Shape<CustomerManagementState> => ({
  lastFailure: action.payload
});

export const dismissError = (): $Shape<CustomerManagementState> => ({
  lastFailure: undefined
});

export const createCustomer = (
  state: CustomerManagementState,
  action: CreateCustomerAction
): $Shape<CustomerManagementState> => {
  const { payload: customer } = action;
  const customers = getCustomers(state);
  return {
    customers: { ...customers, [customer.id]: customer }
  };
};

export const queryCustomers = (
  state: CustomerManagementState,
  action: QueryCustomersAction
): $Shape<CustomerManagementState> => {
  const customerList = action.payload;
  customerList.sort((a, b) => a.name.localeCompare(b.name));
  return {
    customers: customerList
      ? _reduce(
          customerList,
          (r, v) => {
            r[v.id] = v;
            return r;
          },
          {}
        )
      : {}
  };
};

export const updateCustomer = (
  state: CustomerManagementState,
  action: UpdateCustomerAction
): $Shape<CustomerManagementState> => {
  const customers = getCustomers(state);
  const { payload: customer } = action;
  return {
    customers: { ...customers, [customer.id]: customer }
  };
};

export const deleteCustomer = (
  state: CustomerManagementState,
  action: DeleteCustomerAction
): $Shape<CustomerManagementState> => {
  const { payload: deletedCustomerId } = action;
  const customersWithoutDeleted = _omit(getCustomers(state), deletedCustomerId);
  return {
    customers: customersWithoutDeleted
  };
};

export const queryAdminsByCustomer = (
  state: CustomerManagementState,
  action: QueryCustomerAdminsAction
): $Shape<CustomerManagementState> => {
  const { payload: adminList } = action;
  return {
    adminsByCustomer: adminList
      ? _reduce(
          adminList,
          (r, v) => {
            r[v.customerId] = r[v.customerId] ? [...r[v.customerId], v] : [v];
            return r;
          },
          {}
        )
      : {}
  };
};

export const createCustomerAdmin = (
  state: CustomerManagementState,
  action: CreateCustomerAdminAction
): $Shape<CustomerManagementState> => {
  const { payload } = action;
  // put id in adminsByCustomer,
  // add admin to users in account management
  const adminsByCustomer = getAdminsByCustomer(state);
  let adminsWithAdded = adminsByCustomer[payload.customer];
  adminsWithAdded = { ...adminsWithAdded, [payload.id]: payload };

  return {
    adminsByCustomer: {
      ...adminsByCustomer,
      [payload.customer]: adminsWithAdded
    }
  };
};

export const updateCustomerAdmin = (
  state: CustomerManagementState,
  action: UpdateCustomerAdminAction
): $Shape<CustomerManagementState> => {
  const { payload } = action;
  const adminsByCustomer = getAdminsByCustomer(state);

  const adminsWithEdit = _map(adminsByCustomer[payload.customer], admin => {
    if (admin.id === payload.id) {
      return payload;
    } else {
      return admin;
    }
  });

  return {
    adminsByCustomer: {
      ...adminsByCustomer,
      [payload.customer]: adminsWithEdit
    }
  };
};

export const deleteCustomerAdmin = (
  state: CustomerManagementState,
  action: DeleteCustomerAdminAction
): $Shape<CustomerManagementState> => {
  const { payload: deletedAdminId, customerId } = action;
  const adminsByCustomer = getAdminsByCustomer(state);
  const adminsWithoutDeleted = _map(adminsByCustomer[customerId], admin => {
    if (admin.id === deletedAdminId) {
      return { ...admin, isDeleted: true };
    } else {
      return admin;
    }
  });
  return {
    adminsByCustomer: {
      ...adminsByCustomer,
      [customerId]: adminsWithoutDeleted
    }
  };
};
