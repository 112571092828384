/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import React, { Component } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  withStyles
} from '@material-ui/core';
import { CSVLink } from 'react-csv';

export const styles = theme => ({
  title: {
    marginLeft: '0em',
    marginTop: '0em',
    textTransform: 'uppercase'
  },
  dismissButton: {
    marginTop: '3em'
  },
  dialogBoxPaper: {
    padding: '30px',
    margin: '30vh',
    maxHeight: '40vh'
  },
  fontText: {
    color: theme.palette.primary.white
  },
  fontLinkText: {
    color: theme.palette.primary.darkTurquoise
  },
  infoText: {
    marginBottom: '10px',
    marginTop: '10px'
  }
});

const supportEmail = 'support@circadence.com';

export class ErrorPopup extends Component<Props, State> {
  render() {
    const { open, error, closeError, classes } = this.props;
    let errors;
    if (error) {
      switch (error.errorType) {
        case 'generic':
          errors = <div className={classes.fontLinkText}>{error.errors}</div>;
          break;
        case 'Failed to get user configuration.':
          errors = (
            <div className={classes.fontLinkText}>
              A user with this username could not be found. Please contact
              Project Ares Customer Support at {supportEmail} for assistance.
            </div>
          );
          break;
        case 'ExpiredAccount':
          errors = (
            <div className={classes.fontLinkText}>
              Whoops! Looks like you haven't activated your account yet and that
              your activation link has expired. Please contact Project Ares
              Customer Support at {supportEmail} to request a new invitation.
            </div>
          );
          break;
        case 'PendingAccount':
          errors = (
            <div className={classes.fontLinkText}>
              Whoops! Looks like you haven't activated your account yet. Please
              check your email inbox (including Junk/Spam folders) for an
              activation link to get started! Please contact Project Ares
              Customer Support at {supportEmail} if you are unable to locate
              your activation email.
            </div>
          );
          break;
        case 'NotInvitedAccount': //this should not be possible to happen anymore since the 2020 migration
          errors = (
            <div className={classes.fontLinkText}>
              Whoops! Looks like you haven't activated your account yet and that
              your activation link has expired. Please contact Project Ares
              Customer Support at {supportEmail} to request a new invitation.
            </div>
          );
          break;
        case 'massImport':
          errors = (
            <div className={classes.fontText}>
              Failed to Create Users <br />
              <CSVLink
                data={error.errors}
                filename={'ImportUsers_Errorfile.csv'}
                className={classes.fontLinkText}
              >
                Download error log file
              </CSVLink>
            </div>
          );
          break;
        case 'failedQueryUserDetailsByGroups':
          errors = (
            <div className={classes.fontLinkText}>
              <div className={classes.infoText}>{error.errors}</div>
            </div>
          );
          break;
        case 'failedUpdateContentAccess':
          errors = (
            <div className={classes.fontLinkText}>
              {error.errors.failedUsers && (
                <div>
                  <div className={classes.infoText}>
                    These users were not updated:
                  </div>
                  {error.errors.failedUsers.map(name => (
                    <div key={'user-error-' + name}>{name}</div>
                  ))}
                </div>
              )}
              {error.errors.failedContent && (
                <div>
                  <div className={classes.infoText}>
                    The following content and/or products were not updated:
                  </div>
                  {error.errors.failedContent.map(content => (
                    <div key={'content-error-' + content}>{content}</div>
                  ))}
                </div>
              )}
            </div>
          );
          break;
        case 'failedBatchInvitations':
          errors = (
            <div className={classes.fontLinkText}>
              <div className={classes.infoText}>
                These invitations were not sent:
              </div>
              <div id="failedInvites">
                {error.errors.map(error => (
                  <div key={'invite-error-' + error.username}>
                    {error.username} : {error.errorMessage}
                  </div>
                ))}
              </div>
            </div>
          );
          break;
        case 'failedBatchRowValidation':
          errors = (
            <div className={classes.fontLinkText}>
              <div className={classes.infoText}>
                Please make sure there are no duplicates and all required fields
                are filled out.
              </div>
            </div>
          );
          break;
        case 'register-local-error':
          errors = (
            <div className={classes.fontText}>
              <div className={classes.infoText}>{error.errors}</div>
              <div className={classes.infoText}>
                If you have already activated your account, please log in{' '}
                <a className={classes.fontLinkText} href={'/login'}>
                  here
                </a>
                .
              </div>
              <div className={classes.infoText}>
                If you have not activated your account, please contact Project
                Ares Customer Support at {supportEmail} for assistance.
              </div>
            </div>
          );
          break;
        case 'Failed to load resource: the server responded with a status of 502 ()':
          errors = (
            <div className={classes.fontText}>
              <div className={classes.infoText}>{error.errors}</div>
              <div className={classes.infoText}>Error getting user.</div>
              <div className={classes.infoText}>
                There was an error loading the requested user. Please contact
                Project Ares Customer Support at {supportEmail} for assistance.
              </div>
            </div>
          );
          break;
        default:
          errors = (
            <div className={classes.fontText}>
              <div className={classes.infoText}>{error.errors}</div>
              <div className={classes.infoText}>
                There was an unexpected error with the request. Please contact
                Project Ares Customer Support at {supportEmail} for assistance.
              </div>
            </div>
          );
      }
    }
    return (
      <Dialog
        PaperProps={{ classes: { root: classes.dialogBoxPaper } }}
        onClose={closeError}
        open={open}
        aria-hidden={!open}
      >
        <DialogContent>
          <DialogTitle className={classes.title} disableTypography>
            Error
          </DialogTitle>
          {errors}
          <Button className={classes.dismissButton} onClick={closeError}>
            Dismiss
          </Button>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles)(ErrorPopup);
