import {
  TableCell,
  TableRow,
  TextField,
  MenuItem,
  Select,
  FormHelperText,
  InputLabel,
  Typography,
  withStyles
} from '@material-ui/core';
import React, { Component } from 'react';
import * as Yup from 'yup';
import _reduce from 'lodash/reduce';
import _map from 'lodash/map';
import _pick from 'lodash/pick';
import _isEmpty from 'lodash/isEmpty';
import {
  roleOptions,
  customerAdminRoleOptions,
  aresRoleOptions
} from '../AccountManagement/constants';
import * as rolesConstants from '../../../../constants/roles';

export const styles = theme => ({
  headerCell: {
    minWidth: '110px'
  },
  columnLabel: {
    whiteSpace: 'nowrap',
    float: 'left',
    height: '1em'
  },
  autoOverflow: {
    height: '750px'
  },
  flexDisplay: {
    display: 'flex'
  },
  flexBlock: {
    display: 'block'
  },
  errorText: {
    color: theme.palette.primary.red
  }
});

export class EditableImportGridRow extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.previousValidatedState = this.validateRow(this.props.row);
    this.state = {
      ...this.props.row
    };
  }

  handleChange = (e, updatedRowId, props) => {
    const { value, name } = e.target;
    const validation = this.validate(name, value);
    this.setState(state => {
      state[name] = value;
      state.validation[name] = validation !== true ? validation : '';
      if (
        name === 'userName' &&
        state.validation.duplicateUserName &&
        value !== this.props.row.userName
      ) {
        state.validation.duplicateUserName = false;
      }
      const validState = this.validateRow(state);
      if (this.previousValidatedState !== validState) {
        this.previousValidatedState = validState;
        props.handleChange(state, true);
      } else {
        props.handleChange(state, false);
      }
      return state;
    });
  };

  validate(fieldName, value) {
    const { validationSchema } = this.props;
    try {
      return (
        Yup.reach(validationSchema, fieldName).validateSync(value) === value
      );
    } catch (e) {
      return e.message;
    }
  }

  isRowValid(row) {
    return _reduce(
      Object.values(row.validation),
      (valid, value) => (value === '' || value === false) && valid,
      true
    );
  }

  validateRow = row => {
    const validationForfirstName = this.validate('firstName', row.firstName);
    row.validation.firstName =
      validationForfirstName !== true ? validationForfirstName : '';
    const validationForlastName = this.validate('lastName', row.lastName);
    row.validation.lastName =
      validationForlastName !== true ? validationForlastName : '';
    const validationForUserName = this.validate('userName', row.userName);
    row.validation.userName =
      validationForUserName !== true ? validationForUserName : '';
    const validationForRoles = this.validate('roles', row.roles);
    row.validation.roles =
      validationForRoles !== true ? validationForRoles : '';
    const validationForAresRoles = this.validate('aresRoles', row.aresRoles);
    row.validation.aresRoles =
      validationForAresRoles !== true ? validationForAresRoles : '';

    return this.isRowValid(row);
  };

  handleGroupOptions = () => {
    const { customerId, groupOptions } = this.props;
    const filteredGroups = _pick(groupOptions, customerId);
    const arrayOfGroups = _isEmpty(filteredGroups)
      ? {}
      : Object.values(filteredGroups[Object.keys(filteredGroups)]);
    return arrayOfGroups;
  };

  render() {
    const { classes, customerOptions, groupOptions } = this.props;
    const row = this.state;
    const groups = this.handleGroupOptions(groupOptions);
    const roleBasedRoleOptions = this.props.isCmt
      ? roleOptions
      : customerAdminRoleOptions;
    const allowedAresRoles = [
      rolesConstants.ARES_TRAINER,
      rolesConstants.ARES_PLAYER
    ];

    const filteredAresRoleOptions = Object.fromEntries(
      Object.entries(aresRoleOptions).filter(([key]) =>
        allowedAresRoles.includes(key)
      )
    );

    return (
      <TableRow key={row.id}>
        <TableCell>
          <div className={classes.flexDisplay}>
            <TextField
              fullWidth
              name="firstName"
              error={
                row.validation.firstName && row.validation.firstName !== ''
                  ? true
                  : false
              }
              helperText={row.validation.firstName}
              value={row.firstName}
              onChange={e => this.handleChange(e, row.id, this.props)}
            />
          </div>
        </TableCell>
        <TableCell>
          <div className={classes.flexDisplay}>
            <TextField
              fullWidth
              name="lastName"
              error={
                row.validation.lastName && row.validation.lastName !== ''
                  ? true
                  : false
              }
              helperText={row.validation.lastName}
              value={row.lastName}
              onChange={e => this.handleChange(e, row.id, this.props)}
            />
          </div>
        </TableCell>
        <TableCell>
          <div className={classes.flexDisplay}>
            <TextField
              fullWidth
              name="userName"
              error={
                (row.validation.userName && row.validation.userName !== '') ||
                row.validation.duplicateUserName
                  ? true
                  : false
              }
              helperText={
                row.validation.duplicateUserName
                  ? 'Duplicate Email'
                  : row.validation.userName
              }
              value={row.userName}
              onChange={e => this.handleChange(e, row.id, this.props)}
            />
          </div>
        </TableCell>

        <TableCell>
          <div className={classes.flexBlock}>
            <Select
              multiple
              name="roles"
              value={row.roles}
              error={
                row.validation.roles && row.validation.roles !== ''
                  ? true
                  : false
              }
              fullWidth
              onChange={e => this.handleChange(e, row.id, this.props)}
              margin="none"
            >
              {_map(roleBasedRoleOptions, (key, value) => (
                <MenuItem key={key} label={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
            {row.validation.roles ? (
              <FormHelperText>
                <Typography className={classes.errorText}>
                  {row.validation.roles}
                </Typography>
              </FormHelperText>
            ) : (
              <div style={{ display: 'None' }}></div>
            )}
          </div>
        </TableCell>
        <TableCell>
          <div className={classes.flexBlock}>
            <Select
              multiple
              name="aresRoles"
              value={row.aresRoles}
              error={
                row.validation.aresRoles && row.validation.aresRoles !== ''
                  ? true
                  : false
              }
              fullWidth
              onChange={e => this.handleChange(e, row.id, this.props)}
              margin="none"
            >
              {_map(filteredAresRoleOptions, (key, value) => (
                <MenuItem key={key} label={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
            {row.validation.aresRoles ? (
              <FormHelperText>
                <Typography className={classes.errorText}>
                  {row.validation.aresRoles}
                </Typography>
              </FormHelperText>
            ) : (
              <div style={{ display: 'None' }}></div>
            )}
          </div>
        </TableCell>
        <TableCell>
          <div className={classes.flexBlock}>
            <div className={classes.customer}>
              <InputLabel id="select-customer">Customer</InputLabel>
              <Select
                id="InvitationForm_customer"
                name="customer"
                value={row.customer}
                label="Customer"
                disabled
                onChange={event => this.handleCustomerChange(event)}
                fullWidth
              >
                {_map(customerOptions, customer => (
                  <MenuItem
                    key={customer.id}
                    label={customer.id}
                    value={customer.id}
                  >
                    {customer.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
        </TableCell>
        <TableCell>
          <div className={classes.groups}>
            <InputLabel id="select-group">Group</InputLabel>
            <Select
              multiple
              id="InvitationForm_group"
              name="group"
              value={row.groups}
              label="Group"
              fullWidth
              disabled
            >
              {_map(groups, group => (
                <MenuItem key={group.id} label={group.id} value={group.id}>
                  {group.displayName}
                </MenuItem>
              ))}
            </Select>
          </div>
        </TableCell>
      </TableRow>
    );
  }
}

export default withStyles(styles)(EditableImportGridRow);
