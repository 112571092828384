/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

/*  eslint no-underscore-dangle: 0 */
import type {
  UpdateSessionUserPlayfabInfo,
  User,
  SetSessionUserDetailsAction
} from './types';
import { Dispatch } from 'redux';
import * as services from '../accountManagement/services';

export const _setLoadingStateAction = (
  value: boolean
): SetLoadingStateAction => ({
  type: 'SESSION_USER/SET_LOADING_STATE',
  payload: value
});

export const setLastFailureAction = (
  message: ?string
): SetLastFailureAction => ({
  type: 'SESSION_USER/SET_LAST_FAILURE',
  payload: message
});

export const _completeFetchSessionUserPlayFabData = (
  playerDataByProduct
): UpdateSessionUserPlayfabInfo => ({
  type: 'SESSION_USER/SET_SESSION_USER_PLAYFAB_INFO',
  payload: { playerDataByProduct }
});

export const _getSessionUserDetails = userDetails => ({
  type: 'SESSION_USER/GET_USER_DETAILS',
  payload: userDetails
});

export const _setSessionUserDetails = (
  user: User
): SetSessionUserDetailsAction => ({
  type: 'SESSION_USER/SET_USER_DETAILS',
  payload: user
});

/*****************
 * Async actions
 *****************/

const asyncTemplate = async (
  dispatch: Dispatch<*>,
  logic: () => Promise<*>,
  onErr: ?(Error) => Promise<*>
) => {
  dispatch(_setLoadingStateAction(true));
  try {
    await logic();
  } catch (err) {
    const message = err.message || `${err}`;
    dispatch(setLastFailureAction([message]));
    onErr && onErr(message);
    console.error('error from service client: ', message);
  }
  dispatch(_setLoadingStateAction(false));
};

export const fetchSessionUserPlayFabData = (userId: string) => async (
  dispatch: Dispatch<*>
) => {
  await asyncTemplate(dispatch, async () => {
    const playFabData = await services.getPlayerData(userId);
    dispatch(_completeFetchSessionUserPlayFabData(playFabData));
  });
};

export const getSessionUserDetailsAction = (
  b2cId: string
) => async dispatch => {
  await asyncTemplate(dispatch, async () => {
    const userDetails = await services.readUserDetails(b2cId);
    dispatch(_getSessionUserDetails(userDetails));
  });
};

export const updateUser = (user: User) => async (dispatch: Dispatch<*>) => {
  await asyncTemplate(dispatch, async () => {
    await services.updateUser(user);
    dispatch(_setSessionUserDetails(user));
  });
};
