/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import React, { Component } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  withStyles
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Formik } from 'formik';
import InviteUserForm from './InviteUserForm';
import { inviteUserSchema } from './UserYupSchemas';
import _pick from 'lodash/pick';
import _isEmpty from 'lodash/isEmpty';

const styles = () => ({
  dialogBoxPaper: {
    padding: '30px',
    margin: '20vh',
    maxHeight: '60vh'
  },
  title: {
    marginLeft: '0em',
    marginTop: '0em'
  },
  crossIcon: {
    position: 'absolute',
    top: 0,
    right: 0
  },
  inviteDialog: {
    width: 540,
    minWidth: 540,
    minHeight: 360
  }
});

export class InviteUserDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCustomer: { authenticationType: 'INVALID' },
      groupOptions: this.props.groupOptions,
      model: this.props.model
    };
  }
  componentDidMount = () => {
    const { customerOptions, groupOptions } = this.props;
    if (Object.keys(customerOptions).length === 1) {
      this.setState({
        selectedCustomer: Object.values(customerOptions)[0],
        model: {
          firstName: '',
          lastName: '',
          email: '',
          roles: [],
          customer: Object.values(customerOptions)[0],
          groups: [],
          aresRoles: ['player']
        },
        groupOptions: Object.values(groupOptions[Object.keys(groupOptions)[0]])
      });
    } else {
      this.setState({
        selectedCustomer: {
          name: 'select customer',
          authenticationType: 'INVALID'
        }
      });
    }
  };

  handleSubmit = invitedUser => {
    const user = {
      ...invitedUser,
      customer: invitedUser.customer.id
    };
    this.props.handleInviteUserSubmit(
      user,
      this.state.selectedCustomer.authenticationType
    );
  };

  handleCustomerChange = customer => {
    const { isCmt, isCustomerAdmin, groupOptions } = this.props;
    if (isCmt) {
      const filteredGroups = _pick(groupOptions, customer.id);
      const arrayOfGroups = _isEmpty(filteredGroups)
        ? {}
        : Object.values(filteredGroups[Object.keys(filteredGroups)]);
      this.setState({
        groupOptions: arrayOfGroups,
        selectedCustomer: customer
      });
    } else if (isCustomerAdmin) {
      this.setState({
        groupOptions: _isEmpty(groupOptions)
          ? {}
          : Object.values(groupOptions[Object.keys(groupOptions)[0]]),
        selectedCustomer: customer
      });
    }
  };

  sortGroups = groupOptions =>
    Object.values(groupOptions).sort((a, b) =>
      a.displayName > b.displayName ? 1 : -1
    );

  render() {
    const {
      classes,
      open,
      handleInviteUserClose,
      customerOptions,
      roleOptions,
      isCmt,
      productRoleOptions,
      productOptions,
      isCustomerAdmin
    } = this.props;
    const { groupOptions, selectedCustomer, model } = this.state;
    return (
      <Dialog
        onClose={handleInviteUserClose}
        open={open}
        aria-hidden={!open}
        PaperProps={{ classes: { root: classes.dialogBoxPaper } }}
      >
        <div>
          <IconButton
            className={classes.crossIcon}
            onClick={this.props.handleInviteUserDialogClose}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent className={classes.inviteDialog}>
          <DialogTitle className={classes.title} disableTypography>
            {'Add User'}
          </DialogTitle>
          <Formik
            initialValues={model}
            validationSchema={inviteUserSchema}
            onSubmit={this.handleSubmit}
          >
            {props => (
              <InviteUserForm
                {...props}
                isCmt={isCmt}
                isCustomerAdmin={isCustomerAdmin}
                model={model}
                groupOptions={this.sortGroups(groupOptions)}
                customerOptions={customerOptions}
                roleOptions={roleOptions}
                authenticationType={selectedCustomer.authenticationType}
                handleCustomerChange={this.handleCustomerChange}
                productOptions={productOptions}
                productRoleOptions={productRoleOptions}
              />
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles)(InviteUserDialog);
