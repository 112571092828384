/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import type {
  CustomerManagementState,
  AnyCustomerManagementAction
} from '../types';
import * as fn from './functions';

export { REDUCER_NAME } from '../types';

const initialState: CustomerManagementState = {
  isLoading: false,
  lastFailure: undefined,
  customers: undefined,
  adminsByCustomer: undefined
};

export default (
  state: CustomerManagementState = initialState,
  action: AnyCustomerManagementAction
): CustomerManagementState => {
  let changes: $Shape<CustomerManagementState>;
  switch (action.type) {
    default:
      return state;

    case 'CUSTOMER_MANAGEMENT/CREATE_CUSTOMER':
      changes = fn.createCustomer(state, action);
      break;

    case 'CUSTOMER_MANAGEMENT/QUERY_CUSTOMERS':
      changes = fn.queryCustomers(state, action);
      break;

    case 'CUSTOMER_MANAGEMENT/UPDATE_CUSTOMER':
      changes = fn.updateCustomer(state, action);
      break;

    case 'CUSTOMER_MANAGEMENT/DELETE_CUSTOMER':
      changes = fn.deleteCustomer(state, action);
      break;

    case 'CUSTOMER_MANAGEMENT/QUERY_CUSTOMER_ADMINS':
      changes = fn.queryAdminsByCustomer(state, action);
      break;

    case 'CUSTOMER_MANAGEMENT/UPDATE_CUSTOMER_ADMIN':
      changes = fn.updateCustomerAdmin(state, action);
      break;

    case 'CUSTOMER_MANAGEMENT/DELETE_CUSTOMER_ADMIN':
      changes = fn.deleteCustomerAdmin(state, action);
      break;

    case 'CUSTOMER_MANAGEMENT/CREATE_CUSTOMER_ADMIN':
      changes = fn.createCustomerAdmin(state, action);
      break;

    case 'CUSTOMER_MANAGEMENT/SET_LAST_FAILURE':
      changes = fn.setLastFailure(state, action);
      break;

    case 'CUSTOMER_MANAGEMENT/DISMISS_ERROR':
      changes = fn.dismissError();
      break;

    case 'CUSTOMER_MANAGEMENT/SET_LOADING_STATE':
      changes = fn.setLoadingState(state, action);
      break;
  }

  return { ...state, ...changes };
};
