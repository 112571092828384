/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

// @flow
/*eslint no-use-before-define:0*/
export const REDUCER_NAME = 'accountManagement';

export type AccountManagementGlobalState = {
  accountManagement: AccountManagementState
};

export type GetGlobalState = () => { REDUCER_NAME: any };
export type UsersById = { [string]: User };
export const ROOT_KEY = '_root_';
export type AccountManagementState = {|
  isLoading: boolean,
  lastFailure: ?string,
  customer: ?string,
  users: UsersById,
  roleOptions: string[],
  groups: ?string
|};

export const StatusCode = {
  PENDING: 'P',
  ACTIVE: 'A',
  INACTIVE: 'I',
  REJECTED: 'R'
};

export type Timestamp = {|
  seconds: number,
  nanos: number
|};

export type StatusCodeValue = $Values<typeof StatusCode>;

export type User = {|
  id: string,
  userName: string,
  firstName: string,
  lastName: string,
  email: string,
  status: StatusCodeValue,
  isDeleted: boolean,
  updateTime: number,
  updatedBy: string,
  createdBy: string,
  roles: string[],
  customer: string,
  group: string,
  lastInviteTime: Timestamp
|};

export type Group = {|
  id: string,
  displayName: string,
  customerId: string
|};

export type CreateUserAction = {|
  type: 'ACCOUNT_MANAGEMENT/CREATE_USER',
  payload: { user: User, groupId: string }
|};

export type ImportUsersAction = {|
  type: 'ACCOUNT_MANAGEMENT/IMPORT_USERS',
  //payload: { users: User[], groupId: string }
  payload: { users: User[] }
|};

export type UpdateUserAction = {|
  type: 'ACCOUNT_MANAGEMENT/UPDATE_USER',
  payload: User
|};

export type BatchUpdateStatusAction = {|
  type: 'ACCOUNT_MANAGEMENT/BATCH_UPDATE_USERS',
  payload: { userIds: string[], status: StatusCodeValue }
|};

export type SendSetPasswordLinkAction = {|
  type: 'ACCOUNT_MANAGEMENT/SEND_SET_PASSWORD_LINK',
  payload: { ids: string[] }
|};

export type SetLoadingStateAction = {|
  type: 'ACCOUNT_MANAGEMENT/SET_LOADING_STATE',
  payload: boolean
|};

export type SetLastFailureAction = {|
  type: 'ACCOUNT_MANAGEMENT/SET_LAST_FAILURE',
  payload: ?string
|};

export type QueryUsersByCustomerAction = {|
  type: 'ACCOUNT_MANAGEMENT/QUERY_USERS_BY_CUSTOMER',
  payload: ?(User[])
|};

export type InvitedUserAction = {|
  type: 'ACCOUNT_MANAGEMENT/INVITED_USER',
  payload: ?(User[])
|};

export type ResendInviteAction = {|
  type: 'ACCOUNT_MANAGEMENT/RESEND_INVITE',
  payload: ?(User[])
|};

export type DeleteUserAction = {|
  type: 'ACCOUNT_MANAGEMENT/DELETE_USER',
  payload: string
|};

export type SearchUsersAction = {|
  type: 'ACCOUNT_MANAGEMENT/SEARCH_USERS',
  payload: ?(User[])
|};

export type FetchRolesOptionsAction = {|
  type: 'ACCOUNT_MANAGEMENT/FETCH_ROLES_OPTIONS_ACTION',
  payload: string[]
|};

export type FetchLicensedContentAction = {|
  type: 'ACCOUNT_MANAGEMENT/FETCH_LICENSED_CONTENT_ACTION',
  payload: { string: any }
|};

export type FetchLicensedProductsAction = {|
  type: 'ACCOUNT_MANAGEMENT/FETCH_LICENSED_PRODUCTS_ACTION',
  payload: { string: any }
|};

export type CreateGroupAction = {|
  type: 'ACCOUNT_MANAGEMENT/CREATE_GROUP',
  payload: Group
|};

export type DeleteGroupAction = {|
  type: 'ACCOUNT_MANAGEMENT/DELETE_GROUP',
  payload: Group
|};

export type UpdateGroupAction = {|
  type: 'ACCOUNT_MANAGEMENT/UPDATE_GROUP',
  payload: Group
|};

export type AnyAccountManagementAction =
  | CreateUserAction
  | ImportUsersAction
  | UpdateUserAction
  | DeleteUserAction
  | SetLastFailureAction
  | SetLoadingStateAction
  | SendSetPasswordLinkAction
  | FetchRolesOptionsAction
  | QueryUsersByCustomerAction
  | BatchUpdateStatusAction
  | CreateGroupAction
  | DeleteGroupAction
  | UpdateGroupAction;
