/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import type { ContentSubscription, License, LicenseInfo } from './types';
import {
  ContentSubscription as PBContentSubscription,
  CreateLicenseRequest,
  DeleteLicenseRequest,
  GetLicensedContentRequest,
  GetLicensedProductsRequest,
  License as PBLicense,
  LicenseServicePromiseClient,
  ProductSubscription as PBProductSubscription,
  QueryLicenseRequest,
  ReadLicenseRequest,
  QueryLicenseForCustomerRequest,
  UpdateLicenseRequest,
  SetDefaultLicenseRequest,
  UnsetDefaultLicenseRequest
} from 'license_grpc_web_pb';
import _map from 'lodash/map';
import _reduce from 'lodash/reduce';
import _sortBy from 'lodash/sortBy';
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb.js';
import { arrayToObjectById } from '../../../utilities/arrayObjectUtils.js';

const { protocol, host } = window.location;
const licenseServiceClient = new LicenseServicePromiseClient(
  `${protocol}//${host}`,
  null,
  null
);

export const queryLicense = (
  client: LicenseServiceClient = licenseServiceClient
): Promise<License[]> => {
  const request = new QueryLicenseRequest();
  return client
    .queryLicenseForCustomer(request)
    .then(response => _map(response.getLicenseList(), pbLicenseToLicense));
};

export const createLicense = (
  license: $Shape<License>,
  client: LicenseServiceClient = licenseServiceClient
): Promise<License> => {
  const request = new CreateLicenseRequest();
  request.setLicense(licenseToPbLicense(license));
  return client
    .createLicense(request)
    .then(response => pbLicenseToLicense(response.getLicense()));
};

export const updateLicense = (
  license: $Shape<License>,
  client: LicenseServiceClient = licenseServiceClient
): Promise<License> => {
  const request = new UpdateLicenseRequest();
  request.setLicense(licenseToPbLicense(license));
  return client
    .updateLicense(request)
    .then(response => pbLicenseToLicense(response.getLicense()));
};

export const deleteLicense = (
  id: string,
  client: LicenseServiceClient = licenseServiceClient
): Promise<null> => {
  const request = new DeleteLicenseRequest();
  request.setId(id);
  return client.deleteLicense(request);
};

export const readLicense = (
  id: string,
  client: LicenseServiceClient = licenseServiceClient
): Promise<License> => {
  const request = new ReadLicenseRequest();
  request.setId(id);
  return client
    .readLicense(request)
    .then(response => pbLicenseToLicense(response.getLicense()));
};

export const queryLicenseForCustomer = (
  customerId: string,
  client: LicenseServiceClient = licenseServiceClient
): Promise<LicenseInfo> => {
  const request = new QueryLicenseForCustomerRequest();
  request.setCustomerId(customerId);
  return client
    .queryLicenseForCustomer(request)
    .then(response => _map(response.getLicenseList(), pbLicenseToLicense));
};

export const getLicensedProducts = (
  id: string,
  client: LicenseServiceClient = licenseServiceClient
): Promise<License[]> => {
  const request = new GetLicensedProductsRequest();
  request.setCustomerId(id);
  return client.getLicensedProducts(request).then(response => {
    const productsObj = _map(response.getProductsList(), products =>
      pbLicensedProductsToLicensedProducts(products)
    );
    const sortedProductsObj = _sortBy(productsObj, products => products.name);
    return _reduce(sortedProductsObj, (r, v) => ({ ...r, [v.id]: v }), {});
  });
};

export const getLicensedContentByContentType = (
  id: string,
  client: LicenseServiceClient = licenseServiceClient
): Promise<License[]> => {
  const request = new GetLicensedContentRequest();
  request.setCustomerId(id);
  return client.getLicensedContent(request).then(response => {
    const contentObj = _map(response.getContentsList(), content =>
      pbLicensedContentToLicensedContent(content)
    );
    const sortedContentObj = contentObj.sort((a, b) =>
      a.name.localeCompare(b.name, 'en', { numeric: true })
    );
    return _reduce(
      sortedContentObj,
      (result, value) => ({
        ...result,
        [value.contentType]: result[value.contentType]
          ? { ...result[value.contentType], [value.contentId]: value }
          : { [value.contentId]: value }
      }),
      {}
    );
  });
};

export const setDefaultLicense = (
  licenseId: string,
  client: LicenseServiceClient = licenseServiceClient
) => {
  const query = new SetDefaultLicenseRequest();
  query.setLicenseId(licenseId);
  return client.setDefaultLicense(query);
};

export const unsetDefaultLicense = (
  licenseId: string,
  client: LicenseServiceClient = licenseServiceClient
) => {
  const query = new UnsetDefaultLicenseRequest();
  query.setLicenseId(licenseId);
  return client.unsetDefaultLicense(query);
};

const pbLicensedProductsToLicensedProducts = pbProducts => ({
  name: pbProducts.getName(),
  id: pbProducts.getProductId(),
  userAccessType: 0
});

const pbLicensedContentToLicensedContent = pbContent => ({
  name: pbContent.getName(),
  contentType: Number(pbContent.getContentType()),
  accessType: pbContent.getAccessType(),
  contentId: pbContent.getContentId(),
  userAccessType: pbContent.getAccessType() === 2 ? 2 : 0
});

const pbLicenseToLicense = (pbLicense: PBLicense): $Shape<License> => ({
  id: pbLicense.getId(),
  name: pbLicense.getName(),
  startTime: secondsSinceEpochToDate(pbLicense.getStartTime()),
  endTime: secondsSinceEpochToDate(pbLicense.getEndTime()),
  customerId: pbLicense.getCustomerId(),
  productSubscriptions: _map(
    pbLicense.getProductSubscriptionsList(),
    pbProductSubscriptionToProductSubscription
  ),
  contentSubscription: arrayToObjectById(
    _map(
      pbLicense.getContentSubscriptionList(),
      pbContentSubscriptionToContentSubscription
    ),
    'contentId'
  ),
  isDefaultLicense: pbLicense.getIsdefault()
});

// export const pbLicenseInfoToLicenseInfo = (
//   pbLicenseInfo: PBLicenseInfo
// ): $Shape<LicenseInfo> => {
//   const license = pbLicenseToLicense(pbLicenseInfo.getLicense());
//   return {
//     ...license,
//     isDefaultLicense: pbLicenseInfo.getIsDefaultLicense()
//   };
// };

const pbProductSubscriptionToProductSubscription = pbProductSubscription => ({
  productId: pbProductSubscription.getProductId(),
  maxNumOfUsers: pbProductSubscription.getMaxNumOfUsers()
});

const pbContentSubscriptionToContentSubscription = pbContentSubscription => ({
  accessType: pbContentSubscription.getAccessType(),
  contentId: pbContentSubscription.getContentId(),
  maxNumOfUsers: pbContentSubscription.getMaxNumOfUsers()
});

const licenseToPbLicense = (license: License): PBLicense => {
  const pbLicense = new PBLicense();
  if (license.isDefaultLicense) {
    pbLicense.setIsdefault(true);
  }
  if (license.id !== '') {
    pbLicense.setId(license.id); //empty on create
  }
  pbLicense.setCustomerId(license.customerId);
  if (license.name !== '') {
    pbLicense.setName(license.name.trim());
  }
  const pbContentSubscriptionList = _map(
    Object.values(license.contentSubscription),
    contentSubscriptionToPbContentSubscription
  );
  pbLicense.setContentSubscriptionList(pbContentSubscriptionList);

  const pbProductSubscriptionsList = _map(
    license.productSubscriptions,
    productSubscriptionToPbProductSubscription
  );
  pbLicense.setProductSubscriptionsList(pbProductSubscriptionsList);

  const startTime = dateToSecondsSinceEpoch(license.startTime);
  const startTimestamp = new Timestamp();
  startTimestamp.setSeconds(startTime);
  startTimestamp.setNanos((startTime % 1000) * 1e6);

  const endTime = dateToSecondsSinceEpoch(license.endTime);
  const endTimestamp = new Timestamp();
  endTimestamp.setSeconds(endTime);
  endTimestamp.setNanos((endTime % 1000) * 1e6);
  pbLicense.setStartTime(startTimestamp);
  pbLicense.setEndTime(endTimestamp);
  return pbLicense;
};

const productSubscriptionToPbProductSubscription = (
  productSubscription: ProductSubscription
) => {
  const pbProductSubscription = new PBProductSubscription();
  pbProductSubscription.setProductId(productSubscription.productId);
  pbProductSubscription.setMaxNumOfUsers(productSubscription.maxNumOfUsers);
  return pbProductSubscription;
};

const contentSubscriptionToPbContentSubscription = (
  contentSubscription: ContentSubscription
) => {
  const pbContentSubscription = new PBContentSubscription();
  pbContentSubscription.setAccessType(contentSubscription.accessType);
  pbContentSubscription.setMaxNumOfUsers(
    Number(contentSubscription.maxNumOfUsers)
  );
  pbContentSubscription.setContentId(contentSubscription.contentId);
  return pbContentSubscription;
};

const dateToSecondsSinceEpoch = date => {
  //date format "yyyy-mm-dd"
  const dateParts = date.split('-');
  return (
    new Date(dateParts[0], dateParts[1] - 1, dateParts[2]).getTime() / 1000.0
  );
};

const secondsSinceEpochToDate = seconds =>
  new Date(seconds * 1000).toLocaleDateString('fr-CA');
