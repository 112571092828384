/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

/* eslint-disable no-template-curly-in-string */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withStyles, Typography, Paper } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { updateUser } from '../../../modules/session-user/actions';
import { selectSessionUserDetails } from '../../../modules/session-user/selectors';
import { selectGroups } from '../../../modules/accountManagement/selectors';
import { fetchGroups } from '../../../modules/accountManagement/actions';
import { queryCustomersAction } from '../CustomerManagement/actions';
import EditProfileForm from './EditProfileForm';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _includes from 'lodash/includes';
import * as roleConstants from '../../../constants/roles';
import { getCustomers } from '../CustomerManagement/selectors';
import ErrorPopup from '../../../modules/accountManagement/ui/AccountManagement/ErrorPopup';

export const styles = theme => ({
  root: {
    boxShadow:
      ' -5px -5px 20px 0px ' + theme.palette.primary.lightGradientBackground,
    marginLeft: '25px',
    paddingTop: '25px',
    paddingLeft: '25px',
    width: '100%'
  },
  header: {
    color: theme.palette.primary.darkTurquoise
  }
});

const minimumChars = 1;
const maximumChars = 64;

const editUserProfileSchema = Yup.object({
  firstName: Yup.string('Enter a first name.')
    .trim()
    .required('First name is required')
    .max(maximumChars, 'A first name can have at most ${max} characters')
    .min(minimumChars, 'A first name must have at least ${min} character'),
  lastName: Yup.string('Enter a last name.')
    .trim()
    .required('Last name is required')
    .max(maximumChars, 'A last name can have at most ${max} characters')
    .min(minimumChars, 'A last name must have at least ${min} character')
});

export const EditProfileView = ({
  sessionUserDetails,
  groups,
  fetchGroups,
  updateUser,
  queryCustomers,
  customers,
  classes
}) => {
  const [model] = useState({ ...sessionUserDetails });
  const [error, setError] = useState('');

  //TODO: put these in a utility? idk
  const getCustomerNameFromId = id => {
    if (_isEmpty(customers)) {
      queryCustomers();
    } else {
      return customers[id].name;
    }
  };

  const getGroupNamesFromIds = ids => {
    const isCmt = _includes(sessionUserDetails.roles, roleConstants.CMT_ADMIN);
    if (_isEmpty(groups)) {
      if (!isCmt) {
        fetchGroups(sessionUserDetails.customerId);
      }
      if (isCmt) {
        fetchGroups('');
      }
    } else {
      return _map(
        ids,
        id => groups[sessionUserDetails.customerId][id].displayName
      );
    }
  };

  const handleSubmitProfile = user => {
    updateUser(user).catch(er => {
      setError(er.message);
    });
  };

  const dismissError = () => {
    setError('');
  };

  return (
    <Paper className={classes.root}>
      <Typography className={classes.header} variant="h4" component="h4">
        Edit Profile
      </Typography>
      <Formik
        initialValues={model}
        onSubmit={handleSubmitProfile}
        validationSchema={editUserProfileSchema}
      >
        {props => (
          <EditProfileForm
            getCustomerNameFromId={getCustomerNameFromId}
            getGroupNamesFromIds={getGroupNamesFromIds}
            {...props}
          />
        )}
      </Formik>
      <ErrorPopup
        open={error !== ''}
        error={{ errors: error, errorType: 'generic' }}
        closeError={dismissError}
      />
    </Paper>
  );
};

const mapStateToProps = state => ({
  customers: getCustomers(state),
  sessionUserDetails: selectSessionUserDetails(state),
  groups: selectGroups(state)
});

const mapDispatchToProps = dispatch => ({
  fetchGroups: customerId => {
    dispatch(fetchGroups(customerId));
  },
  queryCustomers: () => {
    dispatch(queryCustomersAction());
  },
  updateUser: user => {
    dispatch(updateUser(user));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(EditProfileView));
