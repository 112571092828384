/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import {
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper
} from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

export const styles = () => ({
  root: {
    width: '95%',
    overflowX: 'hidden',
    margin: 'auto'
  },
  table: {
    minWidth: 582
  }
});

function createData(FirstName, LastName, Email, Role, ProductRole) {
  return { FirstName, LastName, Email, Role, ProductRole };
}

const rows = [
  createData(
    'Mark',
    'Otto',
    'mark.otto@circadence.com',
    'customer_admin, player',
    'ares.trainer'
  ),
  createData(
    'Jane',
    'Doe',
    'jane.doe@gmail.com',
    'customer_admin, player',
    'ares.trainer'
  ),
  createData(
    'Amy',
    'Faye',
    'amy.faye@yahoo.com',
    'customer_admin, player',
    'ares.player'
  ),
  createData(
    'Larry',
    'Vera',
    'larry.vera@microsoft.com',
    'customer_admin, player',
    'ares.player'
  )
];

function SampleImportDialog(props) {
  const { classes } = props;

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>FirstName</TableCell>
            <TableCell>LastName</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Role</TableCell>
            <TableCell>ProductRole</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.FirstName}>
              <TableCell component="th" scope="row">
                {row.FirstName}
              </TableCell>
              <TableCell>{row.LastName}</TableCell>
              <TableCell>{row.Email}</TableCell>
              <TableCell>{row.Role}</TableCell>
              <TableCell>{row.ProductRole}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

SampleImportDialog.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SampleImportDialog);
