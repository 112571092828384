/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import type { ContentAccessState, ContentById, ProductsById } from './types';
import * as getters from './reducer/getters';
import { REDUCER_NAME } from './types';

type GlobalState = {
  [REDUCER_NAME]: ContentAccessState
};

export const getContentByProductId = (state: GlobalState): ContentById =>
  getters.getContentByProductId(state[REDUCER_NAME]);

export const getProducts = (state: GlobalState): ProductsById =>
  getters.getProducts(state[REDUCER_NAME]);

export const getLastFailure = (state: GlobalState): ?string =>
  getters.getLastFailure(state[REDUCER_NAME]);

export const isLoading = (state: GlobalState): boolean =>
  getters.isLoading(state[REDUCER_NAME]);
