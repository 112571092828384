/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import React, { Component } from 'react';
import CardList from './CardList.view';
// eslint-disable-next-line
import { withStyles, Button, Paper, TextField } from '@material-ui/core';
import NewCardDialog from './NewCardDialog';
import _pickBy from 'lodash/pickBy';
import _values from 'lodash/values';
import AddIcon from '@material-ui/icons/Add';
import ErrorPopup from '../../../modules/accountManagement/ui/AccountManagement/ErrorPopup';

export class Catalog extends Component {
  state = {
    createCardDialogOpen: false,
    filteredCatalogList: undefined
  };

  handleSearch = (event: SyntheticInputEvent<*>) => {
    const searchTerm = event.currentTarget.value;
    const unfiltered = this.props.catalogList;
    const filteredBySearch = _pickBy(
      unfiltered,
      catalogItem =>
        catalogItem.name.toLowerCase().search(searchTerm.toLowerCase()) !== -1
    );
    let newlySelectedCard = undefined;
    if (filteredBySearch[this.props.selectedCardId]) {
      newlySelectedCard = this.props.selectedCardId;
    } else if (Object.keys(filteredBySearch).length === 1) {
      newlySelectedCard = Object.keys(filteredBySearch)[0];
    }
    this.setState({
      filteredCatalogList: filteredBySearch
    });
    this.props.selectCard(newlySelectedCard);
  };

  render() {
    const {
      catalogList,
      displayCard,
      classes,
      selectedCardId,
      displayEditForm,
      displayCreateCard,
      createCardDialogOpen,
      handleDialogOpen,
      handleDialogClose,
      handleDismissError,
      canCreateNewCard = true,
      error
    } = this.props;
    const { filteredCatalogList } = this.state;
    const dataSet = _values(filteredCatalogList || catalogList);
    return (
      <>
        <div className={classes.catalog}>
          <div className={classes.leftColumn}>
            <div className={classes.cardControl}>
              <TextField
                placeholder={'search by name'}
                onChange={this.handleSearch}
                className={classes.searchBar}
                data-test={`searchCards-filteredCards-${filteredCatalogList}`}
              />
              {canCreateNewCard && (
                <Button
                  className={classes.createButton}
                  onClick={handleDialogOpen}
                  data-test={`createNewCardButton`}
                >
                  <AddIcon />
                </Button>
              )}
            </div>
            <div className={classes.cardListContainer}>
              <CardList cardList={dataSet} displayCard={displayCard} />
            </div>
          </div>
          {selectedCardId &&
            displayEditForm(
              catalogList.find(license => license.id === selectedCardId)
                ? catalogList.find(license => license.id === selectedCardId)
                : null
            )}
        </div>
        <NewCardDialog
          open={createCardDialogOpen}
          handleDialogClose={handleDialogClose}
          displayCreateCard={displayCreateCard}
        />
        <ErrorPopup
          open={error !== undefined}
          error={error}
          closeError={handleDismissError}
        />
      </>
    );
  }
}

export const styles = () => ({
  catalog: {
    display: 'flex'
  },
  cardListContainer: {
    overflowY: 'scroll',
    maxHeight: '90vh'
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: 500,
    marginRight: 30
  },
  createButton: {
    margin: 10,
    width: 170,
    height: 1
  },
  formPanel: {
    width: 900
  },
  cardControl: {
    display: 'flex',
    flexDirection: 'row'
  },
  searchBar: {
    margin: 10,
    width: 300
  }
});

export default withStyles(styles)(Catalog);
