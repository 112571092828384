/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import { StatusCode } from '../../types';
import _pick from 'lodash/pick';
import * as rolesConstants from '../../../../constants/roles';

export const SORT = {
  ASC: 'asc',
  DESC: 'desc'
};

export const columnModel = {
  userName: {
    key: 'userNameSortable',
    label: 'Username',
    sortable: true
  },
  firstName: {
    key: 'firstNameSortable',
    label: 'First Name',
    sortable: true
  },
  lastName: {
    key: 'lastNameSortable',
    label: 'Last Name',
    sortable: true
  },
  customerId: {
    key: 'customerNameSortable',
    label: 'Customer',
    sortable: false
  },
  roles: {
    key: 'roles',
    label: 'Roles',
    sortable: false
  },
  aresRoles: {
    key: 'aresRoles',
    label: 'Ares Roles',
    sortable: false
  },
  currentGroups: {
    key: 'currentGroups',
    label: 'Groups',
    sortable: false
  },
  lastInviteTime: {
    key: 'lastInviteTimeSortable',
    label: 'Last Invite Time',
    sortable: true
  }
};

export const userColumns = _pick(columnModel, [
  'userName',
  'firstName',
  'lastName',
  'id',
  'roles',
  'aresRoles',
  'lastInviteTime'
]);
export const cmtUserColumns = _pick(columnModel, [
  'userName',
  'firstName',
  'lastName',
  'id',
  'customerId',
  'roles',
  'aresRoles',
  'lastInviteTime'
]);

export const roleOptions = {
  [rolesConstants.CMT_ADMIN]: 'CMT Admin',
  [rolesConstants.CUSTOMER_ADMIN]: 'Customer Admin',
  [rolesConstants.PLAYER]: 'Player'
};

export const customerAdminRoleOptions = {
  [rolesConstants.CUSTOMER_ADMIN]: 'Customer Admin',
  [rolesConstants.PLAYER]: 'Player'
};

export const aresRoleOptions = {
  [rolesConstants.ARES_TRAINER]: 'Ares Trainer',
  [rolesConstants.ARES_PLAYER]: 'Ares Player',
  [rolesConstants.ARES_NONE]: 'No Ares Roles'
};

export const nonFederatedUserStatusOptions = {
  active: 'Active',
  pending: 'Pending',
  expired: 'Expired',
  notInvited: 'Not Invited'
};

export const federatedUserStatusOptions = {
  active: 'Active',
  pending: 'Pending'
};

const newUserStatuses = [StatusCode.PENDING, 'Pending'];

const unregisteredStatuses = [
  [StatusCode.PENDING, 'Pending'],
  [StatusCode.ACTIVE, 'Active'],
  [StatusCode.REJECTED, 'Rejected']
];

const unregisteredStatusesFilter = [
  [StatusCode.PENDING, 'Pending'],
  [StatusCode.REJECTED, 'Rejected']
];

const registeredStatuses = [
  [StatusCode.ACTIVE, 'Active'],
  [StatusCode.INACTIVE, 'Inactive']
];

const rejectedStatuses = [StatusCode.REJECTED, 'Rejected'];

const allSelectOption = '*';
export const StatusCodes = {
  allSelectOption,
  newUserStatuses,
  unregisteredStatuses,
  registeredStatuses,
  rejectedStatuses,
  registeredStatusFilters: [[allSelectOption, 'All'], ...registeredStatuses],
  unregisteredUserFilters: [
    [allSelectOption, 'All'],
    ...unregisteredStatusesFilter
  ],
  textsByCodes: {
    [StatusCode.PENDING]: 'Pending',
    [StatusCode.ACTIVE]: 'Active',
    [StatusCode.INACTIVE]: 'Inactive',
    [StatusCode.REJECTED]: 'Rejected'
  }
};
