/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import _values from 'lodash/values';
import _find from 'lodash/find';
import _has from 'lodash/has';
import React, { Component } from 'react';
import _includes from 'lodash/includes';
import _flow from 'lodash/flow';
import { connect } from 'react-redux';
import {
  Button,
  MenuItem,
  Select,
  Table,
  TableBody,
  TablePagination,
  TextField,
  withStyles
} from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import UserTableHead from './UserTableHead.jsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import _map from 'lodash/map';
import UserRow from './UserRow';
import { Link } from 'react-router-dom';
import {
  aresRoleOptions,
  nonFederatedUserStatusOptions,
  federatedUserStatusOptions
} from './constants';
import CloseIcon from '@material-ui/icons/Close';
import _isEqual from 'lodash/isEqual';
import * as roleConstants from '../../../../constants/roles';
import { selectGroups } from '../../selectors';

export const styles = theme => ({
  pagination: {
    width: '100%',
    padding: '0px',
    position: 'sticky',
    left: '0px',
    bottom: '0px'
  },
  searchBar: {
    margin: '10px 5px 10px 0',
    width: 260
  },
  searchBlock: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 8,
    marginRight: 5
  },
  rightBtn: {
    height: 34,
    minHeight: 34,
    marginTop: 10
  },
  assignContentToCustomer: {
    marginTop: '-6px',
    marginRight: 2,
    width: 260
  },
  alignBtn: {
    marginTop: 10,
    height: 34,
    minHeight: 34
  },
  customerSelect: {
    marginTop: '-6px',
    marginRight: 5,
    width: 140
  },
  closeIcon: {
    width: '0.8em',
    cursor: 'pointer',
    margin: '14px 2px 0 -2px'
  },
  circularProgressIcon: {
    position: 'fixed',
    top: '30%',
    left: '50%'
  },
  contentGroupSelection: {
    float: 'right',
    marginRight: 5
  },
  iconButtonStyle: {
    color: theme.palette.primary.white,
    display: 'inline-block',
    paddingLeft: '5px',
    paddingRight: '5px'
  },
  contentPadding: {
    paddingLeft: '55px'
  },
  inviteGroup: {
    display: 'inline-block',
    marginRight: 12
  },
  bulkInvite: {
    paddingBottom: '0px',
    paddingTop: '0px'
  },
  linkStyling: {
    textDecoration: 'none',
    color: theme.palette.primary.darkTurquoise
  }
});

export class UserTable extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      order: UserTable.ASC,
      orderBy: '',
      selectedUsers: {},
      filteredData: undefined,
      selectedCustomer: '',
      selectedGroups: [],
      selectedContentGroupCustomer: '',
      groupOptions: [],
      aresRoles: [],
      customerRoles: [],
      status: '',
      searchString: '',
      zeroIndexPage: props.pagination.page - 1,
      searchFlag: false,
      toggleSearchFilter: false,
      user: {},
      sessionAuthenticationType: !!props.customers
        ? props.customers[props.sessionCustomerId].authenticationType
        : ''
    };
  }

  componentDidUpdate = prevProps => {
    if (!_isEqual(prevProps.groups, this.props.groups)) {
      if (this.isRoleCustomerAdmin() && !this.isRoleCmtAdmin()) {
        this.setState({
          groupOptions: this.props.groups[this.props.sessionCustomerId]
        });
      }
    }
    if (!_isEqual(prevProps.customers, this.props.customers)) {
      if (this.props.customers[this.props.sessionCustomerId]) {
        this.setState({
          sessionAuthenticationType: this.props.customers[
            this.props.sessionCustomerId
          ].authenticationType
        });
      }
    }
  };

  componentDidMount() {
    const selectedCustomer =
      this.isRoleCustomerAdmin() && !this.isRoleCmtAdmin()
        ? this.props.sessionCustomerId
        : '';
    this.setState({
      selectedCustomer,
      toggleSearchFilter: this.isRoleCmtAdmin()
    });
  }

  translateCustomer = customerId => {
    if (this.props.customers) {
      const value = _find(this.props.customers, { id: customerId });

      return value ? (value.name ? value.name : '') : '';
    }
  };

  handleRequestSort = event => {
    const { handlePagination, pagination, handleSearchUsers } = this.props;
    const {
      searchFlag,
      selectedGroups,
      searchString,
      selectedCustomer,
      selectedContentGroupCustomer
    } = this.state;
    const orderBy = event.currentTarget.dataset.id;
    const order =
      event.currentTarget.dataset.order === UserTable.DESC
        ? UserTable.ASC
        : UserTable.DESC;
    this.setState({ order, orderBy, zeroIndexPage: 0 });
    if (searchFlag) {
      const options = this.createSearchOptions();
      handleSearchUsers(
        selectedCustomer,
        selectedGroups,
        searchString,
        options,
        1,
        pagination.perPage,
        orderBy,
        order
      );
    } else {
      const customer =
        selectedContentGroupCustomer.length > 0
          ? selectedContentGroupCustomer
          : selectedCustomer;
      handlePagination(customer, 1, pagination.perPage, orderBy, order);
    }
  };

  handleSearch = () => {
    const { pagination, sessionCustomerId, handleSearchUsers } = this.props;
    const {
      selectedCustomer,
      selectedGroups,
      searchString,
      order,
      orderBy
    } = this.state;
    const options = this.createSearchOptions();
    const customerId = this.isRoleCmtAdmin()
      ? selectedCustomer
      : sessionCustomerId;
    handleSearchUsers(
      customerId,
      selectedGroups,
      searchString,
      options,
      1,
      pagination.perPage,
      orderBy,
      order
    );
    this.setState({
      searchFlag: true,
      selectedContentGroupCustomer: '',
      zeroIndexPage: 0
    });
  };

  handleSearchInput = event => {
    this.setState({
      searchString: event.target.value
    });
  };

  handleOpenSearchFilters = () => {
    this.setState({
      toggleSearchFilter: true
    });
  };

  handleCloseSearchFilters = () => {
    if (this.isRoleCmtAdmin()) {
      this.setState({
        selectedCustomer: '',
        aresRoles: [],
        customerRoles: [],
        status: '',
        searchString: '',
        groupOptions: []
      });
    } else {
      this.setState({
        toggleSearchFilter: false
      });
    }
  };

  handleSearchFilterSelections = (event, field) => {
    const { value } = event.target;
    if (field === 'selectedCustomer') {
      this.setState({
        groupOptions: value === '' ? [] : this.props.groups[value],
        [field]: value
      });
    } else {
      this.setState({
        [field]: value
      });
    }
  };

  handleContentGroupSelection = event => {
    const {
      handlePagination,
      pagination,
      handleSelectedContentGroupCustomer
    } = this.props;
    const { selectedContentGroupCustomer, order, orderBy } = this.state;
    handleSelectedContentGroupCustomer(event.target.value);
    if (selectedContentGroupCustomer !== event.target.value) {
      handlePagination(
        event.target.value,
        1,
        pagination.perPage,
        orderBy,
        order
      );
      this.setState({
        selectedContentGroupCustomer: event.target.value,
        selectedCustomer: '',
        aresRoles: [],
        customerRoles: [],
        status: '',
        searchString: '',
        searchFlag: false
      });
    }
  };

  createSearchOptions = () => {
    const { customerRoles, aresRoles, status } = this.state;
    const options = {};
    options.cmtAdmin = customerRoles.includes('CMT Admin');
    options.customerAdmin = customerRoles.includes('Customer Admin');
    options.player = customerRoles.includes('Player');
    options.aresTrainer = aresRoles.includes('Ares Trainer');
    options.aresPlayer = aresRoles.includes('Ares Player');
    options.aresNone = aresRoles.includes('No Ares Roles');
    options.status = status === '' ? 'INVALID' : status.toUpperCase();
    return options;
  };

  handleChangePage = (event: SyntheticInputEvent<*> | null, page: number) => {
    const { handlePagination, pagination, handleSearchUsers } = this.props;
    const {
      searchFlag,
      selectedGroups,
      searchString,
      order,
      orderBy,
      selectedCustomer,
      selectedContentGroupCustomer
    } = this.state;
    if (searchFlag) {
      const options = this.createSearchOptions();
      handleSearchUsers(
        selectedCustomer,
        selectedGroups,
        searchString,
        options,
        page + 1,
        pagination.perPage,
        orderBy,
        order
      );
    } else {
      const customer =
        selectedContentGroupCustomer.length > 0
          ? selectedContentGroupCustomer
          : selectedCustomer;
      handlePagination(customer, page + 1, pagination.perPage, orderBy, order);
    }
    this.setState({ zeroIndexPage: page });
  };

  handleResendInvitation = user => {
    const updatedUserModel = {
      ...user,
      customer: user.customerId
    };
    this.props.resendInvite(updatedUserModel);
  };

  isRoleCustomerAdmin = () =>
    _includes(this.props.userRoles, roleConstants.CUSTOMER_ADMIN);

  isRoleCmtAdmin = () =>
    _includes(this.props.userRoles, roleConstants.CMT_ADMIN);

  validateAresRoles = role => {
    const { aresRoles } = this.state;
    switch (role) {
      case aresRoleOptions.trainer:
      case aresRoleOptions.player:
        return !!aresRoles.includes(aresRoleOptions.none);
      case aresRoleOptions.none:
        return !!(
          aresRoles.includes(aresRoleOptions.player) ||
          aresRoles.includes(aresRoleOptions.trainer)
        );
      default:
        return false;
    }
  };

  render() {
    const {
      order,
      orderBy,
      filteredData,
      selectedCustomer,
      selectedGroups,
      selectedContentGroupCustomer,
      aresRoles,
      customerRoles,
      status,
      searchString,
      selectedUsers,
      groupOptions,
      toggleSearchFilter,
      zeroIndexPage,
      sessionAuthenticationType
    } = this.state;
    const {
      classes,
      customers,
      users,
      handleEditDialog,
      isLoading,
      handleAssignContentDialog,
      handleCreateGroupDialog,
      handleInviteUserDialog,
      handleDeleteDialog,
      pagination,
      roleOptions
    } = this.props;
    const userData = _values(filteredData || users);
    const dataSet = _map(userData, data => {
      const translatedCustomer = this.translateCustomer(data.customerId);
      return {
        ...data,
        customerName: this.translateCustomer(data.customerId),
        customerNameSortable:
          translatedCustomer && translatedCustomer.toLowerCase(),
        userNameSortable: data.userName && data.userName.toLowerCase(),
        firstNameSortable: data.firstName && data.firstName.toLowerCase(),
        lastNameSortable: data.lastName && data.lastName.toLowerCase(),
        lastInviteTimeSortable: data.lastInviteTime
      };
    });
    return (
      <>
        <div>
          {(this.isRoleCustomerAdmin() || this.isRoleCmtAdmin()) && (
            <div className={classes.inviteGroup}>
              <Button
                className={classes.alignBtn}
                onClick={handleInviteUserDialog}
              >
                Single Invite +
              </Button>
              {!(
                sessionAuthenticationType === 'NON_LOCAL' &&
                this.isRoleCustomerAdmin() &&
                !this.isRoleCmtAdmin()
              ) && (
                <Button className={classes.alignBtn}>
                  <Link
                    className={classes.linkStyling}
                    to="/portal/invitation-page"
                  >
                    Bulk Invite
                  </Link>
                </Button>
              )}
              {this.isRoleCustomerAdmin() && !this.isRoleCmtAdmin() ? (
                <>
                  <Button
                    className={classes.alignBtn}
                    onClick={handleAssignContentDialog}
                  >
                    Assign Content
                  </Button>
                </>
              ) : (
                ''
              )}
              {!this.isRoleCmtAdmin() && this.props.groupsCanDelete && (
                <Button
                  className={classes.rightBtn}
                  onClick={handleCreateGroupDialog}
                >
                  Manage Groups
                </Button>
              )}
            </div>
          )}
          {this.isRoleCmtAdmin() && (
            <div className={classes.contentGroupSelection}>
              <FormControl className={classes.assignContentToCustomer}>
                <InputLabel id="select-customer">
                  Select Customer to...
                </InputLabel>
                <Select
                  className={classes.assignContentToCustomer}
                  id="InvitationForm_customer"
                  name="customerId"
                  value={selectedContentGroupCustomer}
                  placeholder={'Select Customer'}
                  onChange={event => this.handleContentGroupSelection(event)}
                  fullWidth
                >
                  {_map(this.props.customers, customer => (
                    <MenuItem
                      id={customer.id}
                      key={customer.id}
                      label={customer.id}
                      value={customer.id}
                    >
                      {customer.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <>
                {selectedContentGroupCustomer.length > 0 &&
                  this.props.licensedProducts[selectedContentGroupCustomer] && (
                    <Button
                      className={classes.rightBtn}
                      onClick={event =>
                        handleAssignContentDialog(
                          event,
                          selectedContentGroupCustomer
                        )
                      }
                    >
                      Assign Content
                    </Button>
                  )}
                {selectedContentGroupCustomer.length > 0 &&
                  this.props.groupsCanDelete && (
                    <Button
                      className={classes.rightBtn}
                      onClick={handleCreateGroupDialog}
                    >
                      Manage Groups
                    </Button>
                  )}
              </>
            </div>
          )}
        </div>
        <div className={classes.searchBlock}>
          <TextField
            className={classes.searchBar}
            placeholder={'Search User'}
            margin="normal"
            onChange={this.handleSearchInput}
            onFocus={this.handleOpenSearchFilters}
            onKeyDown={e => {
              if (e.key === 'Enter') this.handleSearch();
            }}
            value={searchString}
            data-test={`searchUsers`}
          />
          {toggleSearchFilter && (
            <>
              {this.isRoleCmtAdmin() && (
                <FormControl className={classes.customerSelect}>
                  <InputLabel id="select-customer">Customer</InputLabel>
                  <Select
                    className={classes.customerSelect}
                    id="InvitationForm_customer"
                    name="customerId"
                    value={selectedCustomer}
                    placeholder={'customer'}
                    onChange={event =>
                      this.handleSearchFilterSelections(
                        event,
                        'selectedCustomer'
                      )
                    }
                    fullWidth
                  >
                    <MenuItem key={'all'} value={''}></MenuItem>
                    {_map(this.props.customers, customer => (
                      <MenuItem
                        key={customer.id}
                        label={customer.id}
                        value={customer.id}
                      >
                        {customer.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              <FormControl className={classes.customerSelect}>
                <InputLabel id="select-customer">Groups</InputLabel>
                <Select
                  className={classes.customerSelect}
                  multiple={true}
                  name="groups"
                  label="groups"
                  placeholder={'Groups'}
                  value={selectedGroups}
                  onChange={event =>
                    this.handleSearchFilterSelections(event, 'selectedGroups')
                  }
                  fullWidth
                >
                  {_map(groupOptions, group => (
                    <MenuItem key={group.id} label={group.id} value={group.id}>
                      {group.displayName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl className={classes.customerSelect}>
                <InputLabel id="select-roles">Roles</InputLabel>
                <Select
                  className={classes.customerSelect}
                  id="search-roles"
                  name="roles"
                  value={customerRoles}
                  placeholder={'Roles'}
                  onChange={event =>
                    this.handleSearchFilterSelections(event, 'customerRoles')
                  }
                  multiple={true}
                  fullWidth
                >
                  {_map(roleOptions, role => (
                    <MenuItem key={role} label={role} value={role}>
                      {role}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl className={classes.customerSelect}>
                <InputLabel id="select-ares-roles">Ares Roles</InputLabel>
                <Select
                  className={classes.customerSelect}
                  id="search-ares-roles"
                  name="aresRoles"
                  value={aresRoles}
                  placeholder={'Ares Roles'}
                  onChange={event =>
                    this.handleSearchFilterSelections(event, 'aresRoles')
                  }
                  multiple={true}
                  fullWidth
                >
                  {_map(aresRoleOptions, role => (
                    <MenuItem
                      key={role}
                      label={role}
                      value={role}
                      disabled={this.validateAresRoles(role)}
                    >
                      {role}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl className={classes.customerSelect}>
                <InputLabel id="select-status">Status</InputLabel>
                <Select
                  className={classes.customerSelect}
                  id="search-status"
                  name="status"
                  value={status}
                  placeholder={'Status'}
                  onChange={event =>
                    this.handleSearchFilterSelections(event, 'status')
                  }
                  fullWidth
                >
                  <MenuItem key={'none'} value=""></MenuItem>
                  {_map(
                    sessionAuthenticationType === 'LOCAL' ||
                      this.isRoleCmtAdmin()
                      ? nonFederatedUserStatusOptions
                      : federatedUserStatusOptions,
                    (status, key) => (
                      <MenuItem key={status} label={status} value={key}>
                        {status}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
              <CloseIcon
                className={classes.closeIcon}
                onClick={this.handleCloseSearchFilters}
              />
            </>
          )}
          <Button
            className={classes.rightBtn}
            onClick={() => this.handleSearch()}
          >
            Search
          </Button>
        </div>
        {isLoading ? (
          <CircularProgress className={classes.circularProgressIcon} />
        ) : (
          <Table>
            <UserTableHead
              order={order}
              orderBy={orderBy}
              isCmtAdmin={this.isRoleCmtAdmin()}
              onRequestSort={this.handleRequestSort}
            />
            <TableBody>
              {dataSet.map(user => (
                <UserRow
                  key={user.id}
                  authType={
                    customers && customers[user.customerId]?.authenticationType
                  }
                  isSelected={_has(selectedUsers, user.id)}
                  viewerIsCmtAdmin={this.isRoleCmtAdmin()}
                  handleResendInvitation={this.handleResendInvitation}
                  user={user}
                  handleAssignContent={handleAssignContentDialog}
                  handleEdit={handleEditDialog}
                  customerName={user.customerName}
                  handleDelete={handleDeleteDialog}
                />
              ))}
            </TableBody>
          </Table>
        )}
        <TablePagination
          className={classes.pagination}
          count={pagination.count}
          component={'div'}
          rowsPerPage={pagination.perPage}
          page={zeroIndexPage}
          onChangePage={(event, page) => this.handleChangePage(event, page)}
          rowsPerPageOptions={[pagination.perPage]}
          backIconButtonProps={{
            'aria-label': 'Previous Page'
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page'
          }}
          tabIndex={0}
          aria-label={`Displaying users ${pagination.page} through ${pagination.perPage} of ${pagination.count}`}
          data-test={`tablePaginationRowsPerPage-${pagination.perPage}-Page-${pagination.page}`}
        />
      </>
    );
  }
}

const ASC = 'asc';
const DESC = 'desc';

UserTable.ASC = ASC;
UserTable.DESC = DESC;

const mapStateToProps = (state, props) => ({
  groups: selectGroups(state),
  groupsCanDelete: props.groupsCanDelete
});

export default _flow(connect(mapStateToProps), withStyles(styles))(UserTable);
