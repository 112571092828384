/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import EllipsisMenu from '../../../EllipsisMenu/EllipsisMenu.view';
import {
  Typography,
  Card,
  CardHeader,
  CardContent,
  withStyles
  // eslint-disable-next-line
} from '@material-ui/core';
import React from 'react';
import moment from 'moment';

export const LicenseCard = ({
  selectedCardId,
  license,
  customerName,
  handleSelectCard,
  handleDeleteCard,
  handleSetDefaultLicense,
  handleUnsetDefaultLicense,
  isCmt,
  classes,
  key,
  hide
}) => (
  <Card
    className={
      selectedCardId === license.id ? classes.selectedCard : classes.card
    }
    style={hide ? { visibility: 'hidden' } : { visibility: 'visible' }}
    id={license.id}
    key={key}
    onClick={handleSelectCard}
    data-test={`licenseCard-license-${license.id}`}
  >
    <div className={classes.cardDetails}>
      <CardHeader
        title={<Typography variant="h6"> {license.name} </Typography>}
        className={classes.header}
      />
      <CardContent>
        {isCmt && (
          <Typography className={classes.subText}>{customerName}</Typography>
        )}
        {license.isDefaultLicense && (
          <Typography className={classes.subText}> Default License</Typography>
        )}
        <Typography className={classes.subText}>
          {moment(license.endTime).format('MMMM Do, YYYY')}
        </Typography>
      </CardContent>
    </div>
    {isCmt && (
      <EllipsisMenu
        item={license}
        options={[
          {
            label: 'Delete',
            onClick: handleDeleteCard
          },
          {
            label: license.isDefaultLicense ? 'Remove Default' : 'Set Default',
            onClick: license.isDefaultLicense
              ? handleUnsetDefaultLicense
              : handleSetDefaultLicense
          }
        ]}
      />
    )}
  </Card>
);

export const styles = theme => ({
  card: {
    display: 'flex',
    margin: 10,
    '&:hover': {
      backgroundColor: theme.palette.primary.lightSlateGray
    },
    boxShadow: `0px 1px 3px 0px ${theme.palette.primary.colorWhiteOpacity}, 0px 1px 1px 0px ${theme.palette.primary.colorWhiteOpacity}, 0px 2px 1px -1px ${theme.palette.primary.colorWhiteOpacity}`
  },
  selectedCard: {
    margin: 10,
    display: 'flex',
    backgroundColor: theme.palette.primary.colorBorder,
    border: '1px solid white',
    boxShadow:
      'inset  0px 0px 10px ' +
      theme.palette.primary.darkTurquoise +
      ', 0px 0px 20px ' +
      theme.palette.primary.darkTurquoise +
      ', 0px 0px 10px ' +
      theme.palette.primary.darkTurquoise
  },
  logo: {
    width: 151
  },
  subText: {
    color: theme.palette.primary.darkTurquoise
  },
  header: {
    overflow: 'overlay',
    fontSize: '1.3rem'
  },
  cardDetails: {
    maxWidth: '75%'
  }
});

export default withStyles(styles)(LicenseCard);
