import * as grpc from 'grpc-web';
import * as b2c from './b2c';

export default function configureAuthorizedClient() {
  const originalUnaryCall = grpc.GrpcWebClientBase.prototype.unaryCall;
  grpc.GrpcWebClientBase.prototype.unaryCall = function(
    method,
    request,
    metadata,
    methodInfo
  ) {
    return b2c
      .getAccessToken()
      .then(token => {
        metadata = metadata || {};
        metadata.Authorization = `Bearer ${token.accessToken}`;
        return originalUnaryCall.call(
          this,
          method,
          request,
          metadata,
          methodInfo
        );
      })
      .catch(error => {
        console.error(`Error getting Access Token for grpc: ${error}.`);
        if (
          error.errorMessage &&
          error.errorMessage.includes(
            'Token renewal operation failed due to timeout'
          )
        ) {
          console.error('Redirecting to Login page...');
          return b2c.logout();
        }
      });
  };
}
