/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

export const CardList = ({ cardList, displayCard }) =>
  Object.values(cardList).map(card => displayCard(card));

export default CardList;
