/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import React from 'react';

const SvgLicenses = props => (
  <svg
    id="licenses_svg__Layer_1"
    data-name="Layer 1"
    viewBox="0 0 14.51 14.53"
    {...props}
  >
    <defs>
      <style />
    </defs>
    <path d="M14 2.14h-1.63V.5a.5.5 0 00-.5-.5.5.5 0 00-.5.5v1.64H9.73a.5.5 0 00-.5.5.5.5 0 00.5.5h1.64v1.64a.5.5 0 00.5.5.51.51 0 00.5-.5V3.14H14a.51.51 0 00.5-.5.5.5 0 00-.5-.5zM11.87 7.25a.5.5 0 00-.5.5v5.78H1V3.14h5.76a.5.5 0 000-1H.5a.5.5 0 00-.5.5V14a.5.5 0 00.5.5h11.37a.5.5 0 00.5-.5V7.75a.5.5 0 00-.5-.5z" />
    <path d="M9.43 5.42h-6.5a.5.5 0 00-.5.5.51.51 0 00.5.5h6.5a.5.5 0 00.5-.5.5.5 0 00-.5-.5zM9.43 7.83h-6.5a.51.51 0 00-.5.5.5.5 0 00.5.5h6.5a.5.5 0 00.5-.5.5.5 0 00-.5-.5zM9.43 10.25h-6.5a.51.51 0 00-.5.5.5.5 0 00.5.5h6.5a.5.5 0 00.5-.5.5.5 0 00-.5-.5z" />
  </svg>
);

export default SvgLicenses;
