/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import EllipsisMenu from '../../../EllipsisMenu/EllipsisMenu.view';
import { Card, CardHeader, withStyles } from '@material-ui/core';
import React, { Component, Fragment } from 'react';

class CustomerCard extends Component {
  render() {
    const {
      selectedCardId,
      customer,
      handleSelectCard,
      handleDeleteCard,
      classes,
      key,
      hide
    } = this.props;
    return (
      <Fragment>
        <Card
          className={
            selectedCardId === customer.id ? classes.selectedCard : classes.card
          }
          id={customer.id}
          key={key}
          style={hide ? { visibility: 'hidden' } : { visibility: 'visible' }}
          onClick={handleSelectCard}
          data-test={`customerCard-id-${customer.id}-name-${customer.name}`}
        >
          <div className={classes.cardDetails}>
            <CardHeader
              title={customer.name}
              className={classes.header}
              classes={{
                title: classes.cardTitle
              }}
            />
          </div>
          <EllipsisMenu
            item={customer}
            options={[
              {
                label: 'Delete',
                onClick: handleDeleteCard,
                data: customer,
                deleteConfirmation: {
                  description:
                    'Deleting a Customer will delete ALL users AND content access associated with it.',
                  title: 'Delete Customer'
                }
              }
            ]}
          />
        </Card>
      </Fragment>
    );
  }
}

export const styles = theme => ({
  card: {
    display: 'flex',
    margin: 10,
    '&:hover': {
      backgroundColor: theme.palette.primary.lightSlateGray
    },
    boxShadow: `0px 1px 3px 0px ${theme.palette.primary.colorWhiteOpacity}, 0px 1px 1px 0px ${theme.palette.primary.colorWhiteOpacity}, 0px 2px 1px -1px ${theme.palette.primary.colorWhiteOpacity}`
  },
  selectedCard: {
    margin: 10,
    display: 'flex',
    backgroundColor: theme.palette.primary.colorBorder,
    border: '1px solid white',
    boxShadow:
      'inset  0px 0px 10px ' +
      theme.palette.primary.darkTurquoise +
      ', 0px 0px 20px ' +
      theme.palette.primary.darkTurquoise +
      ', 0px 0px 10px ' +
      theme.palette.primary.darkTurquoise
  },
  logo: {
    width: 151
  },
  header: {
    overflow: 'hidden'
  },
  cardDetails: {
    maxWidth: '75%'
  },
  cardTitle: {
    color: theme.palette.primary.white
  }
});

export default withStyles(styles)(CustomerCard);
