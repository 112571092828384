/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { withStyles } from '@material-ui/core';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog.view';

const ITEM_HEIGHT = 48;

class EllipsisMenuView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null
    };
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleMenuClick = onClick => {
    this.handleClose();
    if (onClick) {
      onClick(this.props.item);
    }
  };

  render() {
    const { classes, options } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    return (
      <div className={classes.mainEllipsis}>
        <IconButton
          aria-label="More"
          aria-owns={open ? 'ellipsis-menu' : null}
          aria-haspopup="true"
          onClick={this.handleClick}
          className={classes.button}
        >
          <MoreVertIcon className={classes.icon} />
        </IconButton>
        <Menu
          id="ellipsis-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={this.handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: 200
            }
          }}
        >
          {options.map(option => {
            if (option.deleteConfirmation) {
              return (
                <MenuItem
                  className={classes.menuItem}
                  key={option.label}
                  onClick={() => this.handleClose}
                  data-test={`ellipsisMenu-MenuItem-${option.label}`}
                >
                  <ConfirmationDialog
                    key={option.label}
                    closeText={'delete'}
                    data={option.data}
                    description={option.deleteConfirmation.description}
                    title={option.deleteConfirmation.title}
                    action={option.onClick}
                    showConfirmAction={true}
                    confirmText={'Delete'}
                  >
                    {option.label}
                  </ConfirmationDialog>
                </MenuItem>
              );
            } else {
              return (
                <MenuItem
                  className={classes.menuItem}
                  key={option.label}
                  onClick={() => this.handleMenuClick(option.onClick)}
                  data-test={`ellipsisMenu-MenuItem-${option.label}`}
                >
                  {option.label}
                </MenuItem>
              );
            }
          })}
          }
        </Menu>
      </div>
    );
  }
}

EllipsisMenuView.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired
};

const styles = theme => ({
  menuItem: {
    font: theme.typography.fontFamily
  },
  mainEllipsis: {
    flexGrow: 100
  },
  button: {
    float: 'right'
  },
  icon: {
    fill: theme.palette.primary.darkTurquoise
  }
});

export default withStyles(styles)(EllipsisMenuView);
