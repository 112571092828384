/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

// @flow
/**
 * This file provides selectors for the selectors module. Some of these selectors are pass-throughs
 * for local getters, some will be memoized transformations based on those values. The roles and
 * responsibilities of this module are two-fold:
 * 1) To abstract the shape of the Redux store (of which reports will know nothing about) from the getters.
 * 2) To provide reshapings, transformations and various versions of data as it is necessitated by the views of the
 * host project (this project).
 */
import type { AccountManagementState, UsersById } from './types';
import { REDUCER_NAME } from './reducer';
import * as getters from './reducer/getters';

type GlobalState = {
  [REDUCER_NAME]: AccountManagementState
};

export const selectUsers = (state: GlobalState): UsersById =>
  getters.getUsers(state[REDUCER_NAME]);

export const getLastFailure = (state: GlobalState): ?string =>
  getters.getLastFailure(state[REDUCER_NAME]);

export const isLoading = (state: GlobalState): boolean =>
  getters.isLoading(state[REDUCER_NAME]);

export const getLicensedProducts = (state: GlobalState): string =>
  getters.getLicensedProducts(state[REDUCER_NAME]);

export const getLicensedContentByContentType = (state: GlobalState): string =>
  getters.getLicensedContentByContentType(state[REDUCER_NAME]);

export const selectGroups = (state: GlobalState): ?string =>
  getters.getGroups(state[REDUCER_NAME]);
