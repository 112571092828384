import React from 'react';
import _join from 'lodash/join';
import _map from 'lodash/map';
import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  withStyles
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import SendIcon from '@material-ui/icons/Send';
import DeleteIcon from '@material-ui/icons/Delete';
import * as constants from './constants';
import * as roleConstants from '../../../../constants/roles';

function AresRolesColumn({ user, contentPadding, tabIndex }) {
  return (
    <TableCell className={contentPadding} tabIndex={tabIndex}>
      {_join(
        _map(user.aresRoles, role => constants.aresRoleOptions[role]),
        ', '
      )}
    </TableCell>
  );
}

function RolesColumn({ user, contentPadding, tabIndex }) {
  return (
    <TableCell className={contentPadding} tabIndex={tabIndex}>
      {_join(
        _map(user.roles, role => constants.roleOptions[role]),
        ', '
      )}
    </TableCell>
  );
}

function UserRow({
  user,
  viewerIsCmtAdmin,
  isSelected,
  customerName,
  handleAssignContent,
  handleEdit,
  handleResendInvitation,
  classes,
  handleDelete,
  authType = ''
}) {
  return (
    <TableRow role="row" hover selected={isSelected}>
      <TableCell className={classes.iconColumn} tabIndex={0} padding="none">
        <Tooltip title={<p>{'Delete Permanently'}</p>}>
          <IconButton
            className={classes.iconButtonStyle}
            assign-id={user.id}
            onClick={event => handleDelete(event, user.userName)}
            data-test={`deleteUser-${user.id}`}
            aria-label={`delete user ${user.userName}`}
          >
            <DeleteIcon className={classes.iconFontSize} />
          </IconButton>
        </Tooltip>
        <Tooltip title={<p>{'Edit'}</p>}>
          <IconButton
            className={classes.iconButtonStyle}
            data-id={user.id}
            data-state="open"
            onClick={handleEdit}
            assign-customer={user.customerId}
            data-test={`userEdit-${user.id}`}
            aria-label={`edit user ${user.displayName}`}
          >
            <EditIcon className={classes.iconFontSize} />
          </IconButton>
        </Tooltip>
        {!user.roles.includes(roleConstants.CMT_ADMIN) && (
          <Tooltip title={<p>{'Content Access'}</p>}>
            <IconButton
              className={classes.iconButtonStyle}
              assign-id={user.id}
              assign-customer={user.customerId}
              onClick={handleAssignContent}
              data-test={`contentActionUser-${user.id}`}
              aria-label={`assign content access for ${user.displayName}`}
            >
              <VerifiedUserIcon className={classes.iconFontSize} />
            </IconButton>
          </Tooltip>
        )}
        {(user.invitationStatus === 'PENDING' ||
          user.invitationStatus === 'NOTINVITED') &&
          authType === 'LOCAL' && (
            <Tooltip
              title={
                user.invitationStatus === 'NOTINVITED' ? (
                  <p>{'Invite User'}</p>
                ) : (
                  <p>{'Resend'}</p>
                )
              }
            >
              <IconButton
                className={classes.iconButtonStyle}
                assign-id={user.id}
                onClick={() => handleResendInvitation(user)}
                data-test={`resendInvitation-${user.id}`}
                aria-label={`Resend Invitation for ${user.displayName}`}
              >
                <SendIcon
                  className={
                    user.invitationStatus === 'NOTINVITED'
                      ? classes.inactiveButtonStyle
                      : classes.iconFontSize
                  }
                />
              </IconButton>
            </Tooltip>
          )}
      </TableCell>
      <TableCell className={classes.contentPadding} tabIndex={1}>
        {user.userName}
      </TableCell>
      <TableCell className={classes.contentPadding} tabIndex={1}>
        {user.firstName}
      </TableCell>
      <TableCell className={classes.contentPadding} tabIndex={1}>
        {user.lastName}
      </TableCell>
      {viewerIsCmtAdmin && (
        <TableCell className={classes.contentPadding} tabIndex={3}>
          {customerName}
        </TableCell>
      )}
      <RolesColumn
        user={user}
        contentPadding={classes.contentPadding}
        tabIndex={4}
      />
      <AresRolesColumn
        user={user}
        contentPadding={classes.contentPadding}
        tabIndex={4}
      />
      <TableCell className={classes.contentPadding} tabIndex={5}>
        {user.lastInviteTime}
      </TableCell>
    </TableRow>
  );
}

export const styles = theme => ({
  pagination: {
    width: '100%',
    padding: '0px',
    position: 'sticky',
    left: '0px',
    bottom: '0px'
  },
  searchBar: {
    margin: 10,
    width: 300
  },
  customerSelect: {
    marginTop: '-6px',
    marginRight: '10px',
    width: 300
  },
  iconButtonStyle: {
    color: theme.palette.primary.white,
    display: 'inline-block',
    paddingLeft: '5px',
    paddingRight: '5px'
  },
  inactiveButtonStyle: {
    fill: theme.palette.primary.darkTurquoise
  },
  contentPadding: {
    paddingLeft: '55px'
  },
  iconColumn: {
    minWidth: '140px'
  }
});

export default withStyles(styles)(UserRow);
