/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import React from 'react';

const SvgOrion = props => (
  <svg
    id="essentials_svg__Layer_1"
    data-name="Layer 1"
    viewBox="0 0 16.62 17.27"
    {...props}
  >
    <defs>
      <style />
    </defs>
    <path
      className="cls-1"
      d="M7.27,17.08l-5-2.88A1.39,1.39,0,0,1,1.58,13V7.22a1.58,1.58,0,0,1-.38,0,1.91,1.91,0,0,1-.68-.13V13a2.44,2.44,0,0,0,1.22,2.11l5.14,3A1.7,1.7,0,0,1,7.27,17.08Z"
    />
    <path
      className="cls-1"
      d="M14.37,14.52,9.93,17.08a1.7,1.7,0,0,1,.39,1L15,15.36A1.73,1.73,0,0,1,14.37,14.52Z"
    />
    <path
      className="cls-1"
      d="M9.88,2.38l5,2.91a1.39,1.39,0,0,1,.69,1.2v5.74a2.21,2.21,0,0,1,.37,0,1.78,1.78,0,0,1,.69.15V6.49a2.44,2.44,0,0,0-1.22-2.11l-5.14-3A1.73,1.73,0,0,1,9.88,2.38Z"
    />
    <path
      className="cls-1"
      d="M6.89,1.41,2.18,4.12A1.78,1.78,0,0,1,2.83,5l4.49-2.6A1.6,1.6,0,0,1,6.89,1.41Z"
    />
    <path
      className="cls-1"
      d="M10,8.65a1.48,1.48,0,0,1,.19.35l1.39-.8a.92.92,0,0,0,.47.29.93.93,0,0,0,.73-.1,1,1,0,0,0,.36-1.32,1,1,0,0,0-.59-.45l-.25,0a1,1,0,0,0-.49.13,1,1,0,0,0-.43,1.14Z"
    />
    <path
      className="cls-1"
      d="M9.61,8.87h0a1.32,1.32,0,0,0-.71-.46l-.09,0h0l-.25,0H8.42a1.45,1.45,0,0,0-.41.1H8l-.09,0,0,0a.91.91,0,0,0-.16.11l-.08.07,0,0A1.34,1.34,0,1,0,9.9,9.7a.66.66,0,0,0,0-.14v0s0,0,0-.08a2.17,2.17,0,0,0-.07-.24h0A1.17,1.17,0,0,0,9.61,8.87Z"
    />
    <path
      className="cls-1"
      d="M7.39,1.33a.13.13,0,0,0,0,.06s0,.06,0,.09a.09.09,0,0,1,0,.05.14.14,0,0,1,0,.06l0,0a.25.25,0,0,0,0,.07l0,0a.93.93,0,0,0,0,.1A1,1,0,0,0,7.64,2l0,0a.63.63,0,0,0,.1.12h0a1.25,1.25,0,0,0,.7.32l-.05,5.5h.14l.25,0L8.87,2.4a1.19,1.19,0,0,0,.34-.13A1.3,1.3,0,0,0,9.52,2h0l.07-.09v0l.07-.11h0A1.21,1.21,0,0,0,8.6,0,1.32,1.32,0,0,0,8,.16,1.22,1.22,0,0,0,7.43.9a.85.85,0,0,0,0,.22v.1s0,.05,0,.07Z"
    />
    <path
      className="cls-1"
      d="M9.81,18.06s0,0,0-.06a.11.11,0,0,0,0-.05.07.07,0,0,0,0-.05.14.14,0,0,0,0-.06.43.43,0,0,0,0-.05l0-.07v0l-.06-.1a.83.83,0,0,0-.08-.12A1.21,1.21,0,0,0,8.73,17l0-5.53H8.39L8.33,17a1.58,1.58,0,0,0-.34.13,1.28,1.28,0,0,0-.5.56h0l0,.12h0a1.14,1.14,0,0,0,0,.55,1.18,1.18,0,0,0,.15.42,1.17,1.17,0,0,0,.74.56,1.21,1.21,0,0,0,1.51-1h0c0-.06,0-.11,0-.17v0s0,0,0-.06Z"
    />
    <path
      className="cls-1"
      d="M7.12,8.67,2.36,6a1.23,1.23,0,0,0,0-.76h0s0-.09-.05-.13h0l-.07-.14-.08-.12,0,0-.09-.1,0,0L2,4.59l0,0L1.83,4.5l0,0-.1-.05a1.28,1.28,0,0,0-.47-.1,1.22,1.22,0,0,0-.61.17A1.22,1.22,0,0,0,0,5.24,1.21,1.21,0,0,0,.52,6.55h0l.09,0h0l.09,0h0l.07,0,.1,0,.16,0h.18l.17,0h0a1.25,1.25,0,0,0,.41-.14,1.32,1.32,0,0,0,.33-.29L6.93,9A2,2,0,0,1,7.12,8.67Z"
    />
    <path
      className="cls-1"
      d="M17,13.28a1.16,1.16,0,0,0-.51-.47l0,0-.09,0-.08,0h-.05l-.12,0h-.43a1.11,1.11,0,0,0-.34.14,1.14,1.14,0,0,0-.35.3L10.18,10.4a1.45,1.45,0,0,1-.2.34l4.86,2.74,0,.1a1.21,1.21,0,0,0,0,.68h0l0,.12h0l.06.12.06.1,0,0,.05.06,0,0,0,0,0,0,0,0a.1.1,0,0,0,0,0l0,0,.07,0,0,0,.08,0h0l.09,0a1.21,1.21,0,0,0,1-.1A1.22,1.22,0,0,0,17,13.28Z"
    />
    <path
      className="cls-1"
      d="M7.15,10.75a1.45,1.45,0,0,1-.2-.34l-1.4.81a1,1,0,0,0-.48-.29,1,1,0,0,0-.25,0,.91.91,0,0,0-.48.13.94.94,0,0,0-.45.58,1,1,0,0,0,.1.74.94.94,0,0,0,.58.45,1,1,0,0,0,1.17-1.24Z"
    />
  </svg>
);

export default SvgOrion;
