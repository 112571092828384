import { IconButton, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import _isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';

export const AddGroupForm = props => {
  const {
    values: { groupName },
    errors,
    touched,
    handleSubmit,
    handleChange,
    isValid,
    existingGroups,
    setFieldTouched,
    classes
  } = props;

  const [customError, setCustomError] = useState('');

  const checkUnique = (range, property, value) =>
    !range.some(item => item[property] === value);

  const change = (name, e) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
    if (!checkUnique(existingGroups, 'displayName', e.target.value)) {
      setCustomError('Group names must be unique.');
    } else {
      setCustomError('');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.flexContainer}>
        <TextField
          id="groupName"
          name="groupName"
          placeholder={'Enter New Group Name'}
          margin="normal"
          onChange={change.bind(null, 'groupName')}
          error={
            (touched.groupName && Boolean(errors.groupName)) ||
            Boolean(customError)
          }
          helperText={
            (touched.groupName && Boolean(errors.groupName)) ||
            Boolean(customError)
              ? touched.groupName && Boolean(errors.groupName)
                ? errors.groupName
                : customError
              : ''
          }
          data-test={`newGroupName`}
          value={groupName}
          className={classes.newGroupNameTextField}
        />
        <IconButton
          type="submit"
          className={classes.addButton}
          classes={{ disabled: classes.disabled }}
          disabled={!isValid || _isEmpty(touched)}
        >
          <AddIcon />
        </IconButton>
      </div>
    </form>
  );
};
