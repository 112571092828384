import CyberVaultEssentials from '../Assets/Views/essentials/cybervault.jpg';
import CylitaireEssentials from '../Assets/Views/essentials/cylitaire.jpg';
import CyqualEssentials from '../Assets/Views/essentials/cyqual.jpg';
import PortflowEssentials from '../Assets/Views/essentials/portflow.jpg';
import RegexileEssentials from '../Assets/Views/essentials/regexile.jpg';
import TriviaLootEssentials from '../Assets/Views/essentials/trivialoot.jpg';
import NexagentEssentials from '../Assets/Views/essentials/nexagent.jpg';

export const getImageImport = name => {
  switch (name.toLowerCase().replace(/\.[^.]*$/, '')) {
    case 'cybervault':
      return CyberVaultEssentials;
    case 'cylitaire':
      return CylitaireEssentials;
    case 'cyqual':
      return CyqualEssentials;
    case 'portflow':
      return PortflowEssentials;
    case 'regexile':
      return RegexileEssentials;
    case 'trivialoot':
      return TriviaLootEssentials;
    case 'nexagent':
      return NexagentEssentials;
    default:
      return '';
  }
};
