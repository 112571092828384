/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

//@flow
import React from 'react';
import _map from 'lodash/map';
import {
  IconButton,
  TableCell,
  TableHead,
  TableRow,
  withStyles
} from '@material-ui/core';
import { cmtUserColumns, SORT, userColumns } from './constants';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';

export const styles = theme => ({
  headerCell: {
    minWidth: '110px',
    position: 'sticky',
    top: '-25px',
    backgroundColor: theme.palette.background.default,
    zIndex: '1'
  },
  iconButton: {
    margin: '-6px',
    padding: '0px'
  },
  iconDiv: {
    verticalAlign: 'middle',
    width: '15px',
    float: 'left',
    padding: '8px'
  },
  columnLabel: {
    whiteSpace: 'nowrap',
    float: 'left',
    height: '1em',
    color: theme.palette.primary.darkTurquoise
  }
});

function UserTableHead({
  order,
  orderBy,
  isCmtAdmin,
  onRequestSort,
  classes
}: Props) {
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="none" className={classes.headerCell} role="cell" />
        {_map(isCmtAdmin ? cmtUserColumns : userColumns, column => (
          <TableCell
            className={classes.headerCell}
            aria-label={`${column.label}`}
            tabIndex={0}
            key={column.key}
            align={'center'}
          >
            <div
              className={classes.iconDiv}
              style={
                column.sortable
                  ? { visibility: 'visible' }
                  : { visibility: 'hidden' }
              }
            >
              <IconButton
                key={'sort-ascending'}
                aria-label="sort ascending"
                className={classes.iconButton}
              >
                <ArrowDropUp
                  data-id={column.key}
                  data-order={SORT.ASC}
                  data-test={`userTableHeaderSort-up-${column.key}`}
                  onClick={onRequestSort}
                  color={
                    order === SORT.ASC && column.key === orderBy
                      ? 'secondary'
                      : 'primary'
                  }
                />
              </IconButton>
              <IconButton
                key={'sort-descending'}
                aria-label="sort descending"
                className={classes.iconButton}
              >
                <ArrowDropDown
                  data-id={column.key}
                  data-order={SORT.DESC}
                  data-test={`userTableHeaderSort-down-${column.key}`}
                  onClick={onRequestSort}
                  color={
                    order === SORT.DESC && column.key === orderBy
                      ? 'secondary'
                      : 'primary'
                  }
                />
              </IconButton>
            </div>

            <p className={classes.columnLabel}>{column.label}</p>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

UserTableHead.ROLE_SORT = 'role';

export default withStyles(styles)(UserTableHead);
