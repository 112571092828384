/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

/* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup';

export default isRoleCmtAdmin => {
  const yupSchema = Yup.object({
    firstName: Yup.string('Enter a first name.')
      .required('First name is required')
      .max(32, 'A first name can have at most ${max} characters')
      .min(1, 'A first name must have at least ${min} character'),
    lastName: Yup.string('Enter a last name.')
      .required('Last name is required')
      .max(32, 'A last name can have at most ${max} characters')
      .min(1, 'A last name must have at least ${min} character'),
    userName: Yup.string()
      .email('Field must be an email address.')
      .required('Username is required.'),
    roles: Yup.array()
      .min(1, 'Choose one role')
      .of(Yup.string())
      .required('One role is required.'),
    aresRoles: Yup.array()
      .min(1, 'Choose one Ares role')
      .of(Yup.string())
      .required('One Ares role is required.')
  });

  return isRoleCmtAdmin
    ? yupSchema.concat(
        Yup.object({
          customerId: Yup.string().required('A customer is required')
        })
      )
    : yupSchema;
};
