/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import * as fn from './functions';
import type {
  AnyLicenseManagementAction,
  LicenseManagementState
} from '../types';

export { REDUCER_NAME } from '../types';

const initialState: LicenseManagementState = {
  licenses: undefined,
  customers: undefined,
  licensesByCustomer: undefined,
  lastFailure: undefined
};

export default (
  state: LicenseManagementState = initialState,
  action: AnyLicenseManagementAction
): LicenseManagementState => {
  let changes: $Shape<LicenseManagementState>;
  switch (action.type) {
    default:
      return state;

    case 'LICENSE_MANAGEMENT/CREATE_LICENSE':
      changes = fn.createLicense(state, action);
      break;

    case 'LICENSE_MANAGEMENT/QUERY_LICENSES':
      changes = fn.queryLicenses(state, action);
      break;

    case 'LICENSE_MANAGEMENT/READ_LICENSE':
      changes = fn.readLicense(state, action);
      break;

    case 'LICENSE_MANAGEMENT/UPDATE_LICENSE':
      changes = fn.updateLicense(state, action);
      break;

    case 'LICENSE_MANAGEMENT/DELETE_LICENSE':
      changes = fn.deleteLicense(state, action);
      break;

    case 'LICENSE_MANAGEMENT/DISMISS_ERROR':
      changes = fn.dismissError();
      break;

    case 'LICENSE_MANAGEMENT/SET_LAST_FAILURE':
      changes = fn.setLastFailure(state, action);
      break;
  }

  return { ...state, ...changes };
};
