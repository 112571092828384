/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import React from 'react';

const SvgDendrite = props => (
  <svg
    id="dendrite_svg__Layer_1"
    data-name="Layer 1"
    viewBox="0 0 459.42 382.18"
    {...props}
  >
    <defs>
      <style />
    </defs>
    <path
      className="cls-1"
      d="M517.88,118.84a13.3,13.3,0,0,0-10.46-4.26h-40.5l-6.12,6.11v94.9h46.62a13.3,13.3,0,0,0,10.46-4.24L529,200.27A12.89,12.89,0,0,0,533.36,190V140.23A12.91,12.91,0,0,0,529,129.91Zm-4.11,69a1.13,1.13,0,0,1-.36.89l-8.51,8.45a1.14,1.14,0,0,1-.89.36H480.4V132.68H504a1.15,1.15,0,0,1,.89.37l8.51,8.44a1.14,1.14,0,0,1,.36.89Z"
    />
    <path
      className="cls-1"
      d="M571.9,114.58a13.29,13.29,0,0,0-10.47,4.26L556,124.23a12.94,12.94,0,0,0-4.34,10.31v61.1A12.94,12.94,0,0,0,556,206l5.39,5.39a13.27,13.27,0,0,0,10.46,4.25h37.45l6.12-6.1v-12H575.31a1.14,1.14,0,0,1-.9-.37l-2.75-2.76a1.15,1.15,0,0,1-.37-.89V174.14H608V156H571.29V136.69a1.15,1.15,0,0,1,.37-.89l2.75-2.75a1.12,1.12,0,0,1,.89-.37h40.16v-12l-6.12-6.12Z"
    />
    <polygon
      className="cls-1"
      points="699.96 215.59 706.08 209.49 706.08 114.58 686.48 114.58 686.48 186.72 686.04 186.72 658.29 114.58 636.47 114.58 630.35 120.69 630.35 215.59 649.95 215.59 649.95 143.45 650.54 143.45 678.82 215.59 699.96 215.59"
    />
    <path
      className="cls-1"
      d="M784.23,211.35l11.15-11.08A12.89,12.89,0,0,0,799.72,190V140.23a12.91,12.91,0,0,0-4.34-10.32l-11.15-11.07a13.28,13.28,0,0,0-10.45-4.26h-40.5l-6.12,6.11v94.9h46.61A13.27,13.27,0,0,0,784.23,211.35ZM770.37,197.5H746.76V132.68h23.61a1.15,1.15,0,0,1,.89.37l8.5,8.44a1.12,1.12,0,0,1,.37.89V187.8a1.1,1.1,0,0,1-.37.89l-8.5,8.45A1.14,1.14,0,0,1,770.37,197.5Z"
    />
    <path
      className="cls-1"
      d="M838.13,174.11h15.42L865,198.28l16.88-9.53-7.94-16.67.38-.24a13.53,13.53,0,0,0,2.34-1.89l5.39-5.38a13,13,0,0,0,4.33-10.32V134.54A12.93,12.93,0,0,0,882,124.23l-5.39-5.39a13.31,13.31,0,0,0-10.47-4.27H824.65l-6.12,6.11v94.9h15.74l3.86-2.17Zm0-41.43h24.61a1.15,1.15,0,0,1,.89.37l2.77,2.75a1.14,1.14,0,0,1,.36.89v15.42a1.14,1.14,0,0,1-.36.89l-2.76,2.75a1.15,1.15,0,0,1-.89.37H838.13Z"
    />
    <polygon
      className="cls-1"
      points="922.81 114.58 909.32 114.58 903.21 120.69 903.21 176.71 922.81 165.65 922.81 114.58"
    />
    <polygon
      className="cls-1"
      points="980.78 132.68 981.24 132.68 1006.33 118.53 1002.38 114.58 939.61 114.58 933.5 120.69 933.5 132.68 961.18 132.68 961.18 144 980.78 132.94 980.78 132.68"
    />
    <polygon
      className="cls-1"
      points="873.15 215.59 882.9 215.59 890.87 207.69 881.85 188.75 864.97 198.28 873.15 215.59"
    />
    <polygon
      className="cls-1"
      points="838.13 213.41 834.27 215.59 838.13 215.59 838.13 213.41"
    />
    <polygon
      className="cls-1"
      points="922.81 215.59 922.81 165.65 903.21 176.71 903.21 215.59 922.81 215.59"
    />
    <polygon
      className="cls-1"
      points="980.78 215.59 980.78 132.94 961.18 144 961.18 215.59 980.78 215.59"
    />
    <polygon
      className="cls-1"
      points="1008.5 132.68 1008.5 120.69 1006.33 118.53 981.24 132.68 1008.5 132.68"
    />
    <path
      className="cls-1"
      d="M1041.41,114.58a13.3,13.3,0,0,0-10.47,4.26l-5.39,5.39a13,13,0,0,0-4.33,10.31v61.1a13,13,0,0,0,4.33,10.31l5.39,5.39a13.3,13.3,0,0,0,10.47,4.25h37.45l6.12-6.1v-12h-40.16a1.12,1.12,0,0,1-.89-.37l-2.75-2.76a1.12,1.12,0,0,1-.37-.89V174.14h36.72V156h-36.72V136.69a1.12,1.12,0,0,1,.37-.89l2.75-2.75a1.12,1.12,0,0,1,.89-.37H1085v-12l-6.12-6.12Z"
    />
    <path
      className="cls-1"
      d="M371.06,73.06l-93.19-53.8a50.82,50.82,0,0,0-17.3-6.15v18A33.43,33.43,0,0,1,269,34.57l93.19,53.8a33.59,33.59,0,0,1,16.75,29v50.88l.56.35-.56.91V225a33.61,33.61,0,0,1-16.74,29L269,307.79a33.47,33.47,0,0,1-33.49,0L142.36,254a33.6,33.6,0,0,1-16.75-29V166.5l-17.68-10.08V225a51.31,51.31,0,0,0,25.59,44.32l93.18,53.8a51.16,51.16,0,0,0,51.18,0l93.19-53.8A51.33,51.33,0,0,0,396.65,225V117.38A51.3,51.3,0,0,0,371.06,73.06Z"
    />
    <path
      className="cls-1"
      d="M357.46,96.61l-93.18-53.8a24.38,24.38,0,0,0-3.71-1.72v8l-14.38-8.69-1.07-.65c0-.15.06-.29.08-.44a6.64,6.64,0,0,0,.11-1.18V21.88a7.18,7.18,0,0,0-3.62-6.24l-2.63-1.5-11.75-6.7a7.18,7.18,0,0,0-7.12,0L207,15l-1.18.68a7.19,7.19,0,0,0-3.63,6.24V38.12a7.73,7.73,0,0,0,.11,1.18,7.16,7.16,0,0,0,3.52,5.06L207,45l5.24,3,8,4.53a7.24,7.24,0,0,0,7.12,0l14.38-8.2c.09,0,.17-.12.27-.17l18.61,11.25v.08l2.18,1.24,10.93,6.6a4.81,4.81,0,0,1,1,3v10.9a4.28,4.28,0,0,1-.08.81l94.82,57.32v-18A24,24,0,0,0,357.46,96.61Z"
    />
    <path
      className="cls-1"
      d="M282.34,122.29V129a5.67,5.67,0,0,1,4.82.37l11.38,6.49a5.68,5.68,0,0,1,2.87,4.94v12.85a5.69,5.69,0,0,1-1,3.22l-8.55-5.19V146a2.86,2.86,0,0,0-1.43-2.47l-5.7-3.24a2.8,2.8,0,0,0-2.38-.18,2.77,2.77,0,0,0-.43.18l-4.58,2.61-8.95-5.43-32-19.39V93.93a10.83,10.83,0,0,0-2.57-7l-.26-.3a10.77,10.77,0,0,0-2.66-2.16l-8.16-4.65-13.61-7.76a11.08,11.08,0,0,0-10.77,0l-13.63,7.77-5.83,3.33-4.16-2.52L165.49,75,148.25,64.55,133,55.3V50.09a2.82,2.82,0,0,0-1.44-2.47l-5.69-3.25a2.82,2.82,0,0,0-2.81,0l-5.69,3.25a2.82,2.82,0,0,0-1.44,2.47v6.42A2.84,2.84,0,0,0,117.37,59l5.69,3.25a2.82,2.82,0,0,0,2.81,0L130,59.86,139,65.28l3.91,2.37,17.24,10.46,9.28,5.64L174,86.56l0,0,15.36,9.31,2.28,1.37,43.48,26.36h0l18.22,11.06,8.27,5,1.92,1.16h0l3.74,2.27,7.51,4.55v4.72a2.85,2.85,0,0,0,1.44,2.47l5.69,3.24a2.05,2.05,0,0,0,.43.18,2.8,2.8,0,0,0,2.38-.18l3.74-2.13,7.1,4.31-8.4,4.79a5.67,5.67,0,0,1-4.16.58,5.53,5.53,0,0,1-.66-.2,6,6,0,0,1-.81-.38l-8.19-4.66a10.84,10.84,0,0,0-8.84.84L256.15,166l-44.08-27.31L230.93,128c.14-.08.27-.18.4-.26l-45.07-27.32L184,99,171.44,91.4a11.23,11.23,0,0,0-.31,2.53v19.43l-3.54-2.19-.26-.16-.35-.22L145.62,97.56,137,92.22l-3.93-2.44-11.51-7.12-15.09-9.35a9.25,9.25,0,0,0,.38-2.58V50.12a9.12,9.12,0,0,0-4.61-7.93L84,31.78a9.16,9.16,0,0,0-9,0L56.7,42.19c-.08,0-.15.1-.23.15l-26-16.09-7.91-4.9,0-7.14A3.75,3.75,0,0,0,20.62,11L13.08,6.72a3.77,3.77,0,0,0-3.72,0L1.88,11.07A3.74,3.74,0,0,0,0,14.34l.05,8.49A3.76,3.76,0,0,0,2,26.08L9.5,30.32a3.77,3.77,0,0,0,3.72,0l7-4,7.51,4.65,4.53,2.81h0L52.85,46.49l50.59,31.33,14.62,9.06,7.6,4.7,7.5,4.65,8.17,5.07,4.69,2.9h0l16.23,10.06,2.21,1.36,3.75,2.32,82.57,51.15-8,4.59a10.88,10.88,0,0,0-4.61,5.16l21.29,12.87,39.64,24,54,32.63,4.42-2.56a24,24,0,0,0,12-20.76V168.76L280.3,113.54A19.78,19.78,0,0,1,282.34,122.29Zm32,51v9a4,4,0,0,1-2,3.46l-8,4.54a4,4,0,0,1-1.79.5,4,4,0,0,1-2.16-.5l-8-4.54a4,4,0,0,1-2-3.46v-9a4,4,0,0,1,1.12-2.76,3.83,3.83,0,0,1,.89-.7l8-4.54a4,4,0,0,1,2.7-.43,4.12,4.12,0,0,1,1.25.43l3,1.71,5,2.83A4,4,0,0,1,314.29,173.29Z"
    />
    <path
      className="cls-1"
      d="M262.62,86.94a4.84,4.84,0,0,1-4.41.19h0l-5.45-3.38-6.46-4-2.7-1.68L212.54,58.84,207,55.4l-3.63-2.25L186.3,42.58l-7.88-4.88a3.42,3.42,0,0,0,0-.46V25.82a5.07,5.07,0,0,0-2.55-4.39l-4.57-2.6-5.54-3.16a5,5,0,0,0-5,0l-10.11,5.76a5.05,5.05,0,0,0-2.55,4.39V37.24a5.05,5.05,0,0,0,2.55,4.39l10.11,5.76a5,5,0,0,0,5,0l4.53-2.58,4.81-2.74,5.82,3.6.38.24L198,56.24l9,5.56.21.13L241,82.83,343,146l0,0,17,10.55,9.4,5.83V141.72L271,82.19Z"
    />
    <path
      className="cls-1"
      d="M297.74,280.22l49.92-28.82-53.72-32.47L254,194.79l-16.76-10.13v6.67l-27.31-16.91-10-6.22a5.81,5.81,0,0,0,.07-.83V156a5,5,0,0,0-2.54-4.37l-10.07-5.75a5,5,0,0,0-5,0l-9.64,5.5L167,147.83l-3.71-2.3-.61-.38L142.09,132.4v-7.1a3.32,3.32,0,0,0-1.68-2.89l-5.28-3-1.37-.79a3.35,3.35,0,0,0-3.3,0l-4.85,2.77-1.81,1a3.33,3.33,0,0,0-1.67,2.89v7.51a3.33,3.33,0,0,0,1.67,2.89l.65.37,1.16.66,4.85,2.77a3.35,3.35,0,0,0,3.3,0l1.37-.78,3.56-2,20.87,12.93,3.73,2.31.29.18,6.18,3.83v11.43a5,5,0,0,0,2.54,4.37l10.07,5.74a5,5,0,0,0,5,0l9-5.1,10.1,6.25,30.83,19.1v6.62l-4.6,2.62A19.85,19.85,0,0,1,213,207l-32.3-18.42-.06,0-17.11-9.75a8.54,8.54,0,0,1-4.31-7.43v-5.89L150.12,160l0-.05h0l-15-9.28-9.52-5.89-7.83-4.85-2.47-1.53a7.71,7.71,0,0,0,.34-2.25V118.61a7.74,7.74,0,0,0-3.92-6.74L108.48,110,101,105.74,96.2,103a7.81,7.81,0,0,0-7.69,0l-11.87,6.77L73,111.87a7.75,7.75,0,0,0-3.91,6.74v17.54A7.75,7.75,0,0,0,73,142.89l10.42,5.94,5.11,2.92a7.76,7.76,0,0,0,7.69,0l2.58-1.47,9.15-5.22,3.8-2.17.25-.16.45.28,13.18,8.17,9.52,5.89,9.59,6-6.66,3.79a12,12,0,0,1-2.93,1.19v57a24,24,0,0,0,12,20.76l93.18,53.8a24,24,0,0,0,24,0l28.08-16.21L194.54,224.2l-4.27,2.43a2.81,2.81,0,0,1-2.78,0l-5.6-3.2a2.8,2.8,0,0,1-1.42-2.43v-6.34a2.77,2.77,0,0,1,1.42-2.43l5.6-3.2a2.81,2.81,0,0,1,2.78,0l5.61,3.2a2.77,2.77,0,0,1,1.42,2.43v4.85Z"
    />
    <path
      className="cls-1"
      d="M58.88,14.77,64.49,18a2.81,2.81,0,0,0,2.78,0l2.18-1.24,3.43-2a2.81,2.81,0,0,0,1.41-2.43V6a2.79,2.79,0,0,0-1.41-2.43L67.27.37a2.81,2.81,0,0,0-2.78,0l-5.61,3.2A2.79,2.79,0,0,0,57.47,6v6.34A2.81,2.81,0,0,0,58.88,14.77Z"
    />
    <path
      className="cls-1"
      d="M84,89.06,98.19,81,52.09,52.41V70.73a9.13,9.13,0,0,0,4.61,7.93L75,89.06A9.11,9.11,0,0,0,84,89.06Z"
    />
  </svg>
);

export default SvgDendrite;
