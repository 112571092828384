import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TableBody,
  withStyles,
  Table,
  TableCell,
  TableRow,
  TableHead,
  ListItem
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _uniqBy from 'lodash/uniqBy';
import _includes from 'lodash/includes';
import _reduce from 'lodash/reduce';
import userInvitationSchema from '../AccountManagement/UserInvitationSchema';
import * as Yup from 'yup';
import EditableImportGridRow from './EditableImportGridRow';
import _sortBy from 'lodash/sortBy';
import _filter from 'lodash/filter';
import _isEqual from 'lodash/isEqual';
import { Link, Redirect } from 'react-router-dom';
import ErrorPopup from './ErrorPopup';
import * as roleConstants from '../../../../constants/roles';

const isRoleCmtAdmin = userRoles => {
  let isCmtRole = false;
  userRoles.forEach(element => {
    isCmtRole = isCmtRole || _includes(element, roleConstants.CMT_ADMIN);
  });
  return isCmtRole;
};

export const styles = theme => ({
  headerCell: {
    minWidth: '110px'
  },
  columnLabel: {
    whiteSpace: 'nowrap',
    float: 'left',
    height: '1em'
  },
  autoOverflow: {
    maxHeight: '750px'
  },
  flexDisplay: {
    display: 'flex'
  },
  flexBlock: {
    display: 'block'
  },
  errorText: {
    color: theme.palette.primary.red
  },
  listItemPadding: {
    paddingBottom: '0px',
    paddingTop: '0px'
  }
});

export class EditableImportGrid extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.validationSchema = userInvitationSchema(
      isRoleCmtAdmin(this.props.sessionUserRoles)
    );
    this.state = {
      data: this.load(this.props.data),
      redirectSuccess: false
    };
  }

  componentDidUpdate = prevProps => {
    if (!_isEqual(prevProps.data, this.props.data)) {
      this.setState({ data: this.load(this.props.data) });
    }
  };

  load = rows => {
    const newData = rows.map(row => ({
      ...row,
      validation: {}
    }));
    const duplicateUserNames = _filter(newData, (val, i, iteratee) =>
      _includes(
        iteratee.map(e => e.userName),
        val.userName,
        i + 1
      )
    ).map(e => e.userName);
    const validatedData = newData.map(row =>
      this.validateRow(row, duplicateUserNames)
    );
    const sortedData = _sortBy(validatedData, e => this.isRowValid(e));
    return sortedData;
  };

  validateRow = (row, duplicateUserNames) => {
    const newRow = { ...row };
    const validationForfirstName = this.validate('firstName', row.firstName);
    newRow.validation.firstName =
      validationForfirstName !== true ? validationForfirstName : '';
    const validationForlastName = this.validate('lastName', row.lastName);
    newRow.validation.lastName =
      validationForlastName !== true ? validationForlastName : '';
    const validationForUsername = this.validate('userName', row.userName);
    newRow.validation.userName =
      validationForUsername !== true ? validationForUsername : '';
    newRow.validation.duplicateUserName =
      duplicateUserNames.indexOf(row.userName) > -1;
    const validationForroles = this.validate('roles', row.roles);
    newRow.validation.roles =
      validationForroles !== true ? validationForroles : '';
    const validationForaresRoles = this.validate('aresRoles', row.aresRoles);
    newRow.validation.aresRoles =
      validationForaresRoles !== true ? validationForaresRoles : '';
    return newRow;
  };

  handleConfirm = () => {
    const { sessionCustomerId, handleBulkInviteUsers } = this.props;
    const { data } = this.state;

    const isValid = this.isValid(this.load(data));
    if (!isValid) {
      const err = {
        errorType: 'failedBatchRowValidation',
        errors:
          'Invalid Rows - Please check your rows for the correct information.'
      };
      this.setState({ bulkInviteError: err });
    } else {
      handleBulkInviteUsers(sessionCustomerId, data);
      this.setState({
        redirectSuccess: true
      });
    }
  };

  isValid(rows) {
    const areDuplicates = _uniqBy(rows, 'userName').length !== rows.length;
    const areRowsValid = _reduce(
      rows,
      (valid, row) => valid && this.isRowValid(row),
      true
    );
    return !areDuplicates && areRowsValid;
  }

  handleCloseBulkInviteError = () => {
    this.setState({ bulkInviteError: undefined });
  };
  isRowValid(row) {
    return _reduce(
      Object.values(row.validation),
      (valid, value) => (value === '' || value === false) && valid,
      true
    );
  }

  handleChange = row => {
    const { data } = this.state;
    const updatedRowIndex = data.findIndex(obj => obj.id === row.id);
    const updatedUsers = [...data];
    updatedUsers[updatedRowIndex] = row;
    this.setState({
      data: updatedUsers
    });
  };

  validate(fieldName, value) {
    try {
      return (
        Yup.reach(this.validationSchema, fieldName).validateSync(value) ===
        value
      );
    } catch (e) {
      return e.message;
    }
  }

  renderRow(row) {
    const { isCmt } = this.props;
    return (
      <EditableImportGridRow
        key={row.id}
        row={row}
        handleChange={this.handleChange}
        validationSchema={this.validationSchema}
        customerOptions={
          isCmt
            ? this.props.customers
            : {
                [this.props.sessionCustomerId]: this.props.customers[
                  this.props.sessionCustomerId
                ]
              }
        }
        customerId={row.customer}
        groupOptions={this.props.groupOptions}
      />
    );
  }

  render() {
    const { classes } = this.props;
    const { data, redirectSuccess } = this.state;
    return (
      <div>
        <DialogTitle disableTypography>
          <div>Bulk Invitations</div>
        </DialogTitle>
        <DialogContent className={classes.autoOverflow}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.headerCell}>
                  <p className={classes.columnLabel}>First Name</p>
                </TableCell>
                <TableCell className={classes.headerCell}>
                  <p className={classes.columnLabel}>Last Name</p>
                </TableCell>
                <TableCell className={classes.headerCell}>
                  <p className={classes.columnLabel}>Username</p>
                </TableCell>
                <TableCell className={classes.headerCell}>
                  <p className={classes.columnLabel}>Role</p>
                </TableCell>
                <TableCell className={classes.headerCell}>
                  <p className={classes.columnLabel}>Ares Roles</p>
                </TableCell>
                <TableCell className={classes.headerCell}>
                  <p className={classes.columnLabel}>Customer</p>
                </TableCell>
                <TableCell className={classes.headerCell}>
                  <p className={classes.columnLabel}>Group</p>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{data.map(row => this.renderRow(row))}</TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button>
            <ListItem
              component={Link}
              className={classes.listItemPadding}
              to={`/portal/account-management`}
              button
            >
              Cancel
            </ListItem>
          </Button>
          <Button
            aria-label={`save changes to bulk import and go back to table`}
            onClick={this.handleConfirm}
            disabled={false}
          >
            Save
          </Button>
        </DialogActions>
        {redirectSuccess && <Redirect to={'/portal/account-management'} />}
        <ErrorPopup
          open={this.state.bulkInviteError !== undefined}
          error={this.state.bulkInviteError}
          closeError={this.handleCloseBulkInviteError}
        />
      </div>
    );
  }
}

EditableImportGrid.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  data: PropTypes.array,
  handleImportUsers: PropTypes.func,
  duplicateRows: PropTypes.number
};

export default withStyles(styles)(EditableImportGrid);
