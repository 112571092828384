/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import React, { Component } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

export class NewCardDialog extends Component {
  render() {
    const { open, handleDialogClose, displayCreateCard } = this.props;
    return (
      <Dialog open={open} onClose={handleDialogClose}>
        <div>
          <IconButton
            style={{ position: 'absolute', top: 0, right: 0 }}
            onClick={this.props.handleDialogClose}
            data-test={`Catalog-Icon-Close`}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent>
          <DialogTitle disableTypography>Create New</DialogTitle>
          {displayCreateCard()}
        </DialogContent>
      </Dialog>
    );
  }
}

export default NewCardDialog;
