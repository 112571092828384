/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import React from 'react';
const SvgProductBanner = props => (
  <img
    style={{ maxWidth: '100%', width: '100%' }}
    src={props.img}
    alt={'product-banner'}
  />
);
export default SvgProductBanner;
