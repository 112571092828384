/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

// @flow
/*  eslint no-underscore-dangle: 0 */
import type { Dispatch } from 'redux';
import type {
  SetLastFailureAction,
  SetLoadingStateAction,
  User,
  Group,
  DeleteUserAction,
  CreateUserAction,
  ImportUsersAction,
  UpdateUserAction,
  BatchUpdateStatusAction,
  StatusCodeValue,
  FetchRolesOptionsAction,
  SendSetPasswordLinkAction,
  QueryUsersByCustomerAction,
  FetchLicensedContentAction,
  FetchLicensedProductsAction,
  CreateGroupAction,
  UpdateGroupAction,
  DeleteGroupAction
} from './types';
import * as services from './services';
import * as licenseServices from '../../components/Dashboard/LicenseManagement/services';

/*******************
 * Private actions - these are actions that should not be directly invoked by the user but are part of other
 * async actions.
 *******************/

export const _createUserAction = (user: User): CreateUserAction => ({
  type: 'ACCOUNT_MANAGEMENT/CREATE_USER',
  payload: {
    user
  }
});

export const _invitedUser = (user: User[]): InvitedUserAction => ({
  type: 'ACCOUNT_MANAGEMENT/INVITED_USER',
  payload: user
});

export const _resendInvite = (user: User[]): ResendInviteAction => ({
  type: 'ACCOUNT_MANAGEMENT/RESEND_INVITE',
  payload: user
});

export const _deleteUser = (userId: string): DeleteUserAction => ({
  type: 'ACCOUNT_MANAGEMENT/DELETE_USER',
  payload: userId
});

export const _searchUsers = (users: User[], pagination): SearchUsersAction => ({
  type: 'ACCOUNT_MANAGEMENT/SEARCH_USERS',
  payload: { users, pagination }
});

export const _importUsersAction = (users: User[]): ImportUsersAction => ({
  type: 'ACCOUNT_MANAGEMENT/IMPORT_USERS',
  payload: {
    users
  }
});

export const _updateUserAction = (user: User): UpdateUserAction => ({
  type: 'ACCOUNT_MANAGEMENT/UPDATE_USER',
  payload: user
});

export const _createGroupAction = (group: Group): CreateGroupAction => ({
  type: 'ACCOUNT_MANAGEMENT/CREATE_GROUP',
  payload: group
});

export const _updateGroupAction = (group: Group): UpdateGroupAction => ({
  type: 'ACCOUNT_MANAGEMENT/UPDATE_GROUP',
  payload: group
});

export const _deleteGroupAction = (group: Group): DeleteGroupAction => ({
  type: 'ACCOUNT_MANAGEMENT/DELETE_GROUP',
  payload: group
});

export const _batchUpdateUsersAction = (
  userIds: string[],
  status: StatusCodeValue
): BatchUpdateStatusAction => ({
  type: 'ACCOUNT_MANAGEMENT/BATCH_UPDATE_USERS',
  payload: { userIds, status }
});

export const _sendSetPasswordLinkAction = (
  ids: string[]
): SendSetPasswordLinkAction => ({
  type: 'ACCOUNT_MANAGEMENT/SEND_SET_PASSWORD_LINK',
  payload: { ids }
});

export const _completeFetchRolesOptionsAction = (
  roleOptions: string[]
): FetchRolesOptionsAction => ({
  type: 'ACCOUNT_MANAGEMENT/FETCH_ROLES_OPTIONS_ACTION',
  payload: roleOptions
});

export const _completeFetchLicensedContentAction = (
  licensedContent
): FetchLicensedContentAction => ({
  type: 'ACCOUNT_MANAGEMENT/FETCH_LICENSED_CONTENT_ACTION',
  payload: licensedContent
});

export const _completeFetchLicensedProductsAction = (
  licensedProducts
): FetchLicensedProductsAction => ({
  type: 'ACCOUNT_MANAGEMENT/FETCH_LICENSED_PRODUCTS_ACTION',
  payload: licensedProducts
});

export const _setLoadingStateAction = (
  value: boolean
): SetLoadingStateAction => ({
  type: 'ACCOUNT_MANAGEMENT/SET_LOADING_STATE',
  payload: value
});

export const _completeQueryUsersByCustomerAction = (
  users: User[],
  pagination
): QueryUsersByCustomerAction => ({
  type: 'ACCOUNT_MANAGEMENT/QUERY_USERS_BY_CUSTOMER',
  payload: { users, pagination }
});

export const _getConfigurationForUser = config => ({
  type: 'ACCOUNT_MANAGEMENT/GET_CONFIGS_FOR_USER',
  payload: config
});

export const _getSessionUserDetails = userDetails => ({
  type: 'SESSION_USER/SET_USER_DETAILS',
  payload: userDetails
});

export const _completeFetchGroupsForCustomer = (customerId, groups) => ({
  type: 'ACCOUNT_MANAGEMENT/GET_CUSTOMER_GROUPS',
  payload: { customerId, groups }
});

export const _completeFetchGroupsForAllCustomers = groups => ({
  type: 'ACCOUNT_MANAGEMENT/GET_ALL_CUSTOMER_GROUPS',
  payload: groups
});

/*****************
 * Actions
 *****************/

export const dismissErrorAction = () => ({
  type: 'ACCOUNT_MANAGEMENT/DISMISS_ERROR',
  payload: {}
});

export const setLastFailureAction = (
  message: ?string
): SetLastFailureAction => ({
  type: 'ACCOUNT_MANAGEMENT/SET_LAST_FAILURE',
  payload: message
});

/*****************
 * Async actions
 *****************/

const asyncTemplate = async (
  dispatch: Dispatch<*>,
  logic: () => Promise<*>,
  onErr: ?(Error) => Promise<*>
) => {
  dispatch(_setLoadingStateAction(true));
  try {
    await logic();
  } catch (err) {
    const message = err.message || `${err}`;
    dispatch(setLastFailureAction({ errorType: 'generic', errors: [message] }));
    onErr && onErr(message);
    console.error('error from service client: ', message);
  }
  dispatch(_setLoadingStateAction(false));
};

//TODO implement getConfigurationForUser action
export const getConfigurationForUser = (b2cId: string) => async dispatch => {
  await asyncTemplate(dispatch, async () => {
    const config = await services.getConfigurationForUser(b2cId);
    dispatch(_getConfigurationForUser(config));
  });
};

export const getSessionUserDetails = (b2cId: string) => async dispatch => {
  await asyncTemplate(dispatch, async () => {
    const userDetails = await services.readUserDetails(b2cId);
    dispatch(_getSessionUserDetails(userDetails));
  });
};

export const queryUsersByCustomer = (
  customerId: string,
  page,
  perPage,
  orderBy,
  order
) => async (dispatch: Dispatch<*>) => {
  await asyncTemplate(dispatch, async () => {
    const users = await services.queryUserDetailsForCustomer(
      customerId,
      [],
      page,
      perPage,
      orderBy,
      order
    );
    dispatch(
      _completeQueryUsersByCustomerAction(users.users, users.pagination)
    );
  });
};

export const fetchLicensedContent = customerIds => async (
  dispatch: Dispatch<*>
) => {
  await asyncTemplate(dispatch, async () => {
    const licensedContentByCustomer = {};
    if (Array.isArray(customerIds)) {
      customerIds.forEach(async id => {
        licensedContentByCustomer[
          id
        ] = await licenseServices.getLicensedContentByContentType(id);
      });
    }
    dispatch(_completeFetchLicensedContentAction(licensedContentByCustomer));
  });
};

export const fetchLicensedProducts = customerIds => async (
  dispatch: Dispatch<*>
) => {
  await asyncTemplate(dispatch, async () => {
    const licensedProductsByCustomer = {};
    if (Array.isArray(customerIds)) {
      customerIds.forEach(async id => {
        licensedProductsByCustomer[
          id
        ] = await licenseServices.getLicensedProducts(id);
      });
    }
    dispatch(_completeFetchLicensedProductsAction(licensedProductsByCustomer));
  });
};

export const fetchGroups = customerId => async dispatch => {
  await asyncTemplate(dispatch, async () => {
    const groups = await services.queryGroups(customerId);
    if (customerId === '') {
      //cmt_admin
      dispatch(_completeFetchGroupsForAllCustomers(groups));
    } else {
      //customer_admin
      dispatch(_completeFetchGroupsForCustomer(customerId, groups));
    }
  });
};

export const inviteUser = (user, authentication) => async dispatch => {
  await asyncTemplate(dispatch, async () => {
    const userDetails = await services.inviteUser(user, authentication);
    dispatch(_invitedUser(userDetails));
  });
};

export const inviteUsers = (customerId, users) => async dispatch => {
  await asyncTemplate(dispatch, async () => {
    const invitedUsers = await services.inviteUsers(customerId, users);
    let erroredUsers = [];
    Object.keys(invitedUsers).forEach(key => {
      if (invitedUsers[key].errorMessage) {
        erroredUsers = [...erroredUsers, invitedUsers[key]];
      }
    });

    const bulkInviteError = {
      errorType: 'failedBatchInvitations',
      errors: erroredUsers
    };

    if (erroredUsers.length > 0) {
      dispatch(setLastFailureAction(bulkInviteError));
    }
  });
};

export const resendInvite = user => async dispatch => {
  await asyncTemplate(dispatch, async () => {
    const userDetails = await services.resendInvite(user);
    dispatch(_resendInvite(userDetails));
  });
};

export const deleteUser = userId => async dispatch => {
  await asyncTemplate(dispatch, async () => {
    await services.deleteUser(userId);
    dispatch(_deleteUser(userId));
  });
};

export const searchUsers = (
  customerId,
  groupIds,
  searchStr,
  options,
  page,
  perPage,
  orderBy,
  order
) => async dispatch => {
  await asyncTemplate(dispatch, async () => {
    const userDetails = await services.searchUsers(
      customerId,
      groupIds,
      searchStr,
      options,
      page,
      perPage,
      orderBy,
      order
    );
    dispatch(_searchUsers(userDetails.users, userDetails.pagination));
  });
};

// /**
//  * Creates a user under a given group.
//  *
//  * @param user
//  * @return {Function}
//  */
// export const createUser = (user: User) => async (dispatch: Dispatch<*>) => {
//   //todo temp code until we figure out what to do here for group ids
//   await asyncTemplate(dispatch, async () => {
//     const validUser = await services.createUser(user);
//     dispatch(_createUserAction(validUser));
//   });
// };

// export const importUsers = (users: User[]) => async (dispatch: Dispatch<*>) => {
//   await asyncTemplate(dispatch, async () => {
//     const [importedUsers, errs] = await services.importUsers(users);
//     dispatch(_importUsersAction(importedUsers));
//     if (errs && errs.length > 0) {
//       dispatch(setLastFailureAction({ errorType: 'massImport', errors: errs }));
//     }
//   });
// };

/**
 *Causes the properties associated with a given user to be updated. Does not
 * change relationship or group structure.
 *
 * @param user
 * @return {Function}
 */
export const updateUser = (user: User) => async (dispatch: Dispatch<*>) => {
  await asyncTemplate(dispatch, async () => {
    await services.updateUser(user);
    dispatch(_updateUserAction(user));
  });
};

export const createGroup = (groupName, customer) => async (
  dispatch: Dispatch<*>
) => {
  await asyncTemplate(dispatch, async () => {
    const newGroup = await services.createGroup(groupName, customer);
    dispatch(_createGroupAction(newGroup));
  });
};

export const updateGroup = group => async (dispatch: Dispatch<*>) => {
  await asyncTemplate(dispatch, async () => {
    await services.updateGroup(group.id, group.displayName);
    dispatch(_updateGroupAction(group));
  });
};

export const deleteGroup = group => async (dispatch: Dispatch<*>) => {
  await asyncTemplate(dispatch, async () => {
    await services.deleteGroup(group.id);
    dispatch(_deleteGroupAction(group));
  });
};
