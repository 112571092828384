/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import React, { useState, useEffect } from 'react';
import { Button, withStyles, Typography, Checkbox } from '@material-ui/core';
import { FieldArray } from 'formik';
import Lock from '@material-ui/icons/Lock';
import _isEmpty from 'lodash/isEmpty';

const styles = theme => ({
  content: {
    marginLeft: '50px'
  },
  productTitle: {
    display: 'inline'
  },
  contentDiv: {
    display: 'flex'
  },
  text: {
    color: theme.palette.primary.white,
    paddingTop: '15px'
  }
});

export const AssignContentForm = props => {
  const {
    classes,
    handleSubmit,
    setFieldTouched,
    handleChange,
    handleProductChange,
    handleContentChange,
    isValid,
    values: { productRights, contentRightsByProductAndType }
  } = props;
  const accessibleContent = Object.values(productRights).filter(
    product => product.userAccessType === 1
  );
  const [selectedContent, setSelectedContent] = useState(accessibleContent);
  const contentAvailable = accessibleContent.length !== 0;

  useEffect(() => {
    const content = Object.values(productRights).filter(
      product => product.userAccessType === 1
    );
    setSelectedContent(content);
  }, [contentAvailable, productRights]);

  const change = (type, product, contentTypeName, contentRight, e) => {
    e.persist();
    switch (type) {
      case 'product':
        handleProductChange(e, product.id);
        handleChange(e);
        setFieldTouched(product.name, true, false);
        break;
      case 'content':
        handleContentChange(e, product.id, contentTypeName, contentRight);
        handleChange(e);
        setFieldTouched(contentRight.name, true, false);
        break;
      default:
        handleChange(e);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <FieldArray
        name="products"
        render={() => (
          <div>
            {Object.values(productRights).map((product, productIndex) => (
              <div key={productIndex}>
                <Checkbox
                  name={product.name}
                  onChange={change.bind(null, 'product', product, null, null)}
                  checked={product.userAccessType === 1}
                  data-test={`product-checkbox-${product.userAccessType === 1}`}
                />
                <Typography
                  className={classes.productTitle}
                  variant="h4"
                  component="h4"
                >
                  {product.name}
                </Typography>
                {!_isEmpty(contentRightsByProductAndType[product.id]) && (
                  <div>
                    {Object.entries(contentRightsByProductAndType[product.id])
                      .sort()
                      .map(([name, contentRights]) => (
                        <div key={name}>
                          {!_isEmpty(contentRights) && (
                            <FieldArray
                              name={name}
                              render={() => (
                                <div className={classes.content}>
                                  <Typography variant="h4" component="h4">
                                    {name}
                                  </Typography>
                                  {Object.values(contentRights).map(
                                    contentRight => (
                                      <div
                                        key={contentRight.name}
                                        className={classes.contentDiv}
                                      >
                                        <Checkbox
                                          name={contentRight.name}
                                          checked={
                                            contentRight.userAccessType === 1
                                          }
                                          onChange={change.bind(
                                            null,
                                            'content',
                                            product,
                                            name,
                                            contentRight
                                          )}
                                          indeterminate={
                                            contentRight.userAccessType === 2
                                          }
                                          indeterminateIcon={
                                            <Lock color="error" />
                                          }
                                          disabled={
                                            contentRight.accessType === 2 ||
                                            product.userAccessType === 0
                                          }
                                          data-test={`mission-checkbox-${contentRight.userAccessType}`}
                                        />
                                        <span className={classes.text}>
                                          {contentRight.name}
                                        </span>
                                      </div>
                                    )
                                  )}
                                </div>
                              )}
                            />
                          )}
                        </div>
                      ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      />
      <Button type="submit" fullWidth color="primary" disabled={!isValid}>
        {selectedContent.length ? 'Submit' : 'Remove All Content'}
      </Button>
    </form>
  );
};

export default withStyles(styles)(AssignContentForm);
