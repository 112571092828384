/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

// @flow
import type {
  AccountManagementState,
  AnyAccountManagementAction
} from '../types';
import * as fn from './functions';
import * as roleConstants from '../../../constants/roles';

export { REDUCER_NAME } from '../types';

const initialState: AccountManagementState = {
  isLoading: false,
  lastFailure: undefined,
  groups: {},
  roleOptions: [roleConstants.CUSTOMER_ADMIN, roleConstants.PLAYER],
  users: {},
  licensedContent: undefined,
  licensedProducts: undefined,
  pagination: {
    page: 1,
    perPage: 25,
    count: 0,
    defaultSortCategory: 'userNameSortable',
    defaultSortOrder: 'asc'
  }
};

export default (
  state: AccountManagementState = initialState,
  action: AnyAccountManagementAction
): AccountManagementState => {
  let changes: $Shape<AccountManagementState>;
  switch (action.type) {
    case 'ACCOUNT_MANAGEMENT/QUERY_USERS_BY_CUSTOMER':
      changes = fn.queryUsersByCustomer(state, action);
      break;

    case 'ACCOUNT_MANAGEMENT/SET_LAST_FAILURE':
      changes = fn.setLastFailure(state, action);
      break;

    case 'ACCOUNT_MANAGEMENT/DISMISS_ERROR':
      changes = fn.dismissError();
      break;

    case 'ACCOUNT_MANAGEMENT/FETCH_ROLES_OPTIONS_ACTION':
      changes = fn.completeFetchRolesOptions(state, action);
      break;

    case 'ACCOUNT_MANAGEMENT/SET_LOADING_STATE':
      changes = fn.setLoadingState(state, action);
      break;

    case 'ACCOUNT_MANAGEMENT/CREATE_USER':
      changes = fn.createUser(state, action);
      break;

    case 'ACCOUNT_MANAGEMENT/IMPORT_USERS':
      changes = fn.importUsers(state, action);
      break;

    case 'ACCOUNT_MANAGEMENT/UPDATE_USER':
      changes = fn.updateUser(state, action);
      break;

    case 'ACCOUNT_MANAGEMENT/BATCH_UPDATE_USERS':
      changes = fn.batchUpdateUsers(state, action);
      break;

    case 'ACCOUNT_MANAGEMENT/DELETE_USER':
      changes = fn.deleteUser(state, action);
      break;

    case 'ACCOUNT_MANAGEMENT/FETCH_LICENSED_CONTENT_ACTION':
      changes = fn.completeFetchLicensedContent(state, action);
      break;

    case 'ACCOUNT_MANAGEMENT/FETCH_LICENSED_PRODUCTS_ACTION':
      changes = fn.completeFetchLicensedProducts(state, action);
      break;

    case 'ACCOUNT_MANAGEMENT/GET_CUSTOMER_GROUPS':
      changes = fn.completeFetchCustomerGroups(state, action.payload);
      break;

    case 'ACCOUNT_MANAGEMENT/GET_ALL_CUSTOMER_GROUPS':
      changes = fn.completeFetchAllCustomerGroups(state, action.payload);
      break;

    case 'ACCOUNT_MANAGEMENT/INVITED_USER':
      changes = fn.addNewInvitedUser(state, action.payload);
      break;

    case 'ACCOUNT_MANAGEMENT/RESEND_INVITE':
      changes = fn.updateUser(state, action);
      break;

    case 'ACCOUNT_MANAGEMENT/SEARCH_USERS':
      changes = fn.searchUsers(state, action.payload);
      break;

    case 'ACCOUNT_MANAGEMENT/CREATE_GROUP':
      changes = fn.createGroup(state, action);
      break;

    case 'ACCOUNT_MANAGEMENT/UPDATE_GROUP':
      changes = fn.updateGroup(state, action);
      break;

    case 'ACCOUNT_MANAGEMENT/DELETE_GROUP':
      changes = fn.deleteGroup(state, action);
      break;

    default:
      return state;
  }
  return { ...state, ...changes };
};
