/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import React from 'react';
import { Formik } from 'formik';
import CustomerInfoForm from './CustomerInfoForm';
import AdminForm from './AdminForm';
import { editAdminSchema } from '../../../../modules/accountManagement/ui/AccountManagement/UserYupSchemas';
import { customerValidationSchema } from './CustomerValidationSchema';
import {
  Typography,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  withStyles
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import _map from 'lodash/map';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

export const CustomerForm = ({
  updateCustomer,
  customer,
  admins,
  classes,
  licenses,
  defaultLicense,
  setDefaultLicense,
  roleOptions
}) =>
  customer !== undefined && (
    <div className={classes.root}>
      <div className={classes.expansionDiv}>
        <ExpansionPanel
          defaultExpanded
          classes={{ root: classes.expansionPanel }}
          data-test={`expansionPanel-customer-${customer.id}`}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            data-id="edit-customer"
          >
            <Typography className={classes.header} variant="h4" component="h4">
              Edit Customer Info
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Formik
              key={'formik-customer'}
              validationSchema={customerValidationSchema}
              initialValues={customer}
              onSubmit={updateCustomer}
              enableReinitialize
            >
              {props => <CustomerInfoForm editable={false} {...props} />}
            </Formik>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          defaultExpanded
          classes={{ root: classes.expansionPanel }}
          data-test={`expansionPanel-default-license-${customer.id}`}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            data-id="edit-default-license"
          >
            <Typography className={classes.header} variant="h4" component="h4">
              Default License
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <FormControl className={classes.defaultLicenseSelect}>
              <InputLabel id="select-customer">Default License</InputLabel>
              <Select
                className={classes.defaultLicenseSelect}
                id="default-license"
                name="defaultLicense"
                value={
                  defaultLicense ? defaultLicense.id : 'no-default-license'
                }
                data-customer={customer.id}
                placeholder={'Select a default license.'}
                onChange={setDefaultLicense}
                fullWidth
              >
                {_map(licenses, license => (
                  <MenuItem
                    data-customer={customer.id}
                    key={license.id}
                    label={license.id}
                    value={license.id}
                  >
                    {license.name}
                  </MenuItem>
                ))}
                <MenuItem
                  data-customer={customer.id}
                  key={'no-default-license'}
                  label={'no-default-license'}
                  value={'no-default-license'}
                >
                  None
                </MenuItem>
              </Select>
            </FormControl>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          defaultExpanded
          classes={{ root: classes.expansionPanel }}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            data-id="edit-customer-admins"
          >
            <Typography className={classes.header} variant="h4" component="h4">
              Customer Admins
            </Typography>
          </ExpansionPanelSummary>
          {admins && (
            <ExpansionPanelDetails className={classes.formDetails}>
              {admins.map(admin => (
                <Formik
                  key={`formik-admin-${admin.id}`}
                  enableReinitialize
                  validationSchema={editAdminSchema}
                  initialValues={admin}
                >
                  {props => (
                    <AdminForm
                      {...props}
                      roleOptions={roleOptions}
                      data-test={`adminForm-customer-${customer.id}-admin-${admin.id}`}
                    />
                  )}
                </Formik>
              ))}
            </ExpansionPanelDetails>
          )}
        </ExpansionPanel>
      </div>
    </div>
  );

const styles = theme => ({
  formDetails: {
    display: 'block'
  },
  root: {
    marginRight: '10px',
    marginBottom: '10px',
    width: '100%',
    maxHeight: '90vh',
    overflowY: 'scroll'
  },
  expansionDiv: {
    position: 'sticky',
    top: '0px'
  },
  expansionPanel: {
    padding: '0px',
    margin: '0px',
    marginBottom: '25px'
  },
  defaultLicenseSelect: {
    marginTop: '-10px',
    marginRight: '10px',
    width: 300
  },
  header: {
    color: theme.palette.primary.darkTurquoise
  }
});

export default withStyles(styles)(CustomerForm);
