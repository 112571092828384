/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import type { Dispatch } from 'redux';
import * as services from './services';
import type {
  GetContentAction,
  Product,
  QueryProductsAction,
  SetLoadingStateAction,
  SetLastFailureAction
} from './types';
import type { Content } from '../LicenseManagement/types';
import _isEmpty from 'lodash/isEmpty';
/*******************
 * Private actions - these are actions that should not be directly invoked by the user but are part of other
 * async actions.
 *******************/
export const _queryProductsAction = (
  products: Product[]
): QueryProductsAction => ({
  type: 'CONTENT_ACCESS/QUERY_PRODUCTS',
  payload: products
});

export const _queryContentAction = (
  content: Content[],
  productId: string
): GetContentAction => ({
  type: 'CONTENT_ACCESS/GET_CONTENT',
  payload: { content, productId }
});

export const _queryAllContentAction = (
  content: Content[]
): GetContentAction => ({
  type: 'CONTENT_ACCESS/GET_ALL_CONTENT',
  payload: { content }
});

export const _setLoadingStateAction = (
  value: boolean
): SetLoadingStateAction => ({
  type: 'CONTENT_ACCESS/SET_LOADING_STATE',
  payload: value
});

export const dismissErrorAction = () => ({
  type: 'CONTENT_ACCESS/DISMISS_ERROR',
  payload: {}
});

export const setLastFailureAction = (
  message: ?string
): SetLastFailureAction => ({
  type: 'CONTENT_ACCESS/SET_LAST_FAILURE',
  payload: message
});

/*****************
 * Async actions
 *****************/

const asyncTemplate = async (
  dispatch: Dispatch<*>,
  logic: () => Promise<*>,
  onErr: ?(Error) => Promise<*>
) => {
  dispatch(_setLoadingStateAction(true));
  try {
    await logic();
  } catch (err) {
    const message = err.message || `${err}`;
    dispatch(setLastFailureAction([message]));
    onErr && onErr(message);
    console.error('error from service client: ', message);
  }
  dispatch(_setLoadingStateAction(false));
};

export const queryProducts = () => async (dispatch: Dispatch<*>) => {
  await asyncTemplate(dispatch, async () => {
    const products = await services.queryProducts();
    dispatch(_queryProductsAction(products));
  });
};

export const queryProductsAndContent = () => async (dispatch: Dispatch<*>) => {
  await asyncTemplate(dispatch, async () => {
    const products = await services.queryProducts();

    const content = await services.queryContentByProductId('');
    if (!_isEmpty(content)) {
      dispatch(_queryAllContentAction(content));
    }

    dispatch(_queryProductsAction(products));
  });
};

export const queryContentByProductId = productId => async (
  dispatch: Dispatch<*>
) => {
  await asyncTemplate(dispatch, async () => {
    const content = await services.queryContentByProductId(productId);
    if (!_isEmpty(content)) {
      dispatch(_queryContentAction(content, productId));
    }
  });
};
