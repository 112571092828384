import { Redirect } from 'react-router-dom';
import { Paper, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { getConfigurationForUser } from '../../modules/accountManagement/services';
import Typography from '@material-ui/core/Typography';
import {
  cacheSettings,
  createMsalUserAgentApplication,
  checkAuthenticated
} from '../../utilities/thirdParty/b2c';
import LogoIcon from '../../Assets/Logo';
import CircadenceWhiteLogo from '../../Assets/CircadenceWhiteLogo';
import LoginForm from './LoginForm';
import { Formik } from 'formik';
import { loginValidationSchema } from './LoginValidationSchema';
import ErrorPopup from '../../modules/accountManagement/ui/AccountManagement/ErrorPopup';
import { readUserDetailsFromUsername } from '../../modules/accountManagement/services';
import { readCustomer } from '../Dashboard/CustomerManagement/services';
import splashImage from '../../Assets/PA-dark-splash.jpg';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRegistered: undefined,
      b2cConfig: undefined,
      initialLoginValues: {
        username: localStorage.getItem('username')
          ? localStorage.getItem('username')
          : '',
        isSaveUsernameChecked: !!localStorage.getItem('username')
      },
      loginError: undefined
    };
  }

  getFederatedAndRegistered = username =>
    new Promise(resolve => {
      readUserDetailsFromUsername(username).then(userDetails => {
        const { customerId, id } = userDetails;
        localStorage.setItem('customerId', customerId);
        const isRegistered = !(
          id.includes('FederatedUnregistered') ||
          id.includes('NonFederatedUnregistered')
        );
        readCustomer(customerId).then(customer => {
          const isFederated = customer.authenticationType === 'NON_LOCAL';
          this.setState({ isRegistered });
          resolve({ isRegistered, isFederated });
        });
      });
    });

  handleUserConfiguration = formValues => {
    const { username, isSaveUsernameChecked } = formValues;
    if (isSaveUsernameChecked) {
      localStorage.setItem('username', username);
    } else {
      localStorage.removeItem('username');
    }
    getConfigurationForUser(username)
      .then(b2cConfig => {
        this.setState({ b2cConfig });
        this.getFederatedAndRegistered(username)
          .then(response => {
            const { authority, clientId, appScope, domainHint } = b2cConfig;
            const domain = domainHint ? domainHint : '';
            let redirectUri = '';
            if (response.isRegistered) {
              //if the user isRegistered, take them to portal after logging in
              redirectUri = `${window.location.protocol}//${window.location.host}/portal`;
            } else if (!response.isRegistered && response.isFederated) {
              //if the user is not registered and isFederated take them to register after they log in
              redirectUri = `${window.location.protocol}//${window.location.host}/register`;
            }
            const userAgent = createMsalUserAgentApplication(
              clientId,
              authority,
              appScope,
              redirectUri
            );
            // Necessary for msal library. Do not remove.
            userAgent.handleRedirectCallback(() => {});
            // Cache these before we go bye, bye.
            cacheSettings(clientId, authority, appScope);
            const loginConfig = {
              extraQueryParameters: { domain_hint: domain }
            };
            userAgent.loginRedirect(loginConfig);
          })
          .catch(e => {
            console.error(
              'error getting isRegistered or isFederated',
              e.message
            );
            this.setState({
              loginError: {
                errorType: e.message
              }
            });
          });
      })
      .catch(e => {
        localStorage.removeItem('username');
        console.error('could not getConfigurationForUser', e.message);
        this.setState({
          loginError: {
            errorType: e.message
          }
        });
      });
  };

  validateInitialUsername = () => {
    try {
      if (this.state.initialLoginValues.username !== '')
        loginValidationSchema.validateSync(this.state.initialLoginValues);
    } catch (e) {
      return { username: e.message };
    }
    return undefined;
  };

  handleCloseLoginError = () => {
    this.setState({ loginError: undefined });
  };

  render() {
    const {
      b2cConfig,
      isRegistered,
      initialLoginValues,
      loginError
    } = this.state;
    const { classes } = this.props;
    // If we have the config, redirect the user to msal login
    // if the user is registered, return url should be login
    // if the user is NOT registered and federated, return urls should be /register
    // if the user is NOT registered and NOT federated, they should have clicked on a link in their email to register and theres nothing we can do, so give them an error
    // else have them enter username to get config and then redirect to page.
    if ((isRegistered && b2cConfig) || checkAuthenticated()) {
      return <Redirect to="/portal" />;
    } else {
      const usernameError = this.validateInitialUsername();
      return (
        <div className={classes.root}>
          <div
            className={classes.appFrame}
            style={{
              backgroundImage: `url(${splashImage})`,
              backgroundPosition: `center`,
              backgroundRepeat: `no-repeat`,
              backgroundSize: `cover`
            }}
          >
            <div className={classes.circadenceLogo}>
              <CircadenceWhiteLogo />
            </div>
            <Paper className={classes.dialog}>
              <div className={classes.logo}>
                <LogoIcon />
              </div>
              <div>
                <Formik
                  enableReinitialize
                  validationSchema={loginValidationSchema}
                  initialValues={initialLoginValues}
                  onSubmit={this.handleUserConfiguration}
                  initialErrors={usernameError ? usernameError : undefined}
                >
                  {props => <LoginForm {...props} />}
                </Formik>
                <div>
                  <Typography variant="h6">
                    If you do not have a username, contact your customer admin
                    for an id to SIGN UP.
                  </Typography>
                </div>
              </div>
            </Paper>
          </div>
          <ErrorPopup
            open={loginError !== undefined}
            error={loginError}
            closeError={this.handleCloseLoginError}
          />
        </div>
      );
    }
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  appFrame: {
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    height: '100vh',
    width: '100vw',
    backgroundColor: theme.palette.background.default
  },
  circadenceLogo: {
    '&>svg': {
      width: 'auto',
      height: '4em',
      float: 'center',
      margin: '.5em .55em'
    },
    alignSelf: 'flex-end'
  },
  logo: {
    textAlign: 'center',
    width: '100%',

    '&>svg': {
      width: 'auto',
      height: '7em',
      margin: '.5em .55em',

      '& .st0': {
        fill: theme.palette.primary.main
      },
      '& .st1': {
        fill: theme.palette.primary.logo
      }
    }
  },
  dialog: {
    boxShadow:
      '-5px -5px 20px 0px ' + theme.palette.primary.lightGradientBackground,
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    textAlign: 'left',
    height: 'fit-content',
    marginTop: '10%',
    padding: '20px 60px 60px 60px'
  },
  checkbox: {
    width: '100%'
  }
});

export default withStyles(styles)(Login);
