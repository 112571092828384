/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import store from './store';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { dashboard } from './styles/themes';
import configureAuthorizedClient from './utilities/thirdParty/grpc';

configureAuthorizedClient();

ReactDOM.render(
  <div>
    <MuiThemeProvider theme={dashboard}>
      <Provider store={store}>
        <App />
      </Provider>
    </MuiThemeProvider>
  </div>,
  document.getElementById('root')
);
