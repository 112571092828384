/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import { withStyles, Tooltip, Typography } from '@material-ui/core';
import Help from '@material-ui/icons/Help';
import React from 'react';
import SampleCsvTable from './SampleCsvTable';

export const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#FFFFFF',
    color: '#000000',
    maxWidth: 660,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9'
  },
  popper: {
    opacity: 1
  }
}))(Tooltip);

export default function CustomizedTooltips() {
  return (
    <HtmlTooltip
      title={
        <React.Fragment>
          <Typography align="center" color="inherit">
            <strong> Sample CSV File </strong>
            <br /> CSV file format should follow the below example. Multiple
            roles supported, Separate with a comma.
          </Typography>
          <SampleCsvTable />
        </React.Fragment>
      }
    >
      <Help />
    </HtmlTooltip>
  );
}
