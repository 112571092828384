/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import {
  Dialog,
  DialogTitle,
  DialogContent,
  withStyles
} from '@material-ui/core';
import React, { Component } from 'react';
import { Formik } from 'formik';
import UserForm from './UserForm';
import { editUserSchema } from './UserYupSchemas';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';

const styles = (theme: any) => ({
  textFieldMock: {
    minWidth: '166px',
    maxWidth: '166px',
    textAlign: 'left',
    overflow: 'auto'
  },
  title: {
    marginLeft: '0em',
    marginTop: '0em'
  },
  progress: {
    margin: '100px'
  },
  select: {
    colors: {
      primary: theme.palette.secondary.main
    }
  },
  wideSelect: {
    colors: {
      primary: theme.palette.secondary.main
    },
    width: '400px'
  },
  gridPadding: {
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '0px',
    paddingBottom: '0px'
  },
  dialogBoxPaper: {
    padding: '30px',
    margin: '20vh'
  },
  crossIcon: {
    position: 'absolute',
    top: 0,
    right: 0
  }
});

const getProductNames = productOptions => {
  const productNames = {};
  if (productOptions) {
    Object.keys(productOptions).forEach(item => {
      productNames[item] = item;
    });
  }
  const productArray = Object.values(productNames);
  return productArray;
};

export class UserDialog extends Component<Props, State> {
  handleSubmit = ({
    firstName,
    lastName,
    userName,
    roles,
    aresRoles,
    products,
    customerId,
    groupIds
  }) => {
    const newUser = {
      ...this.props.model,
      firstName,
      lastName,
      userName,
      roles,
      aresRoles,
      products,
      productRoles: aresRoles,
      customerId,
      groupIds
    };
    this.props.handleUserSave(newUser);
  };

  render() {
    const {
      classes,
      open,
      model,
      roleOptions,
      productOptions,
      productRoleOptions,
      content,
      groupOptions
    } = this.props;

    return (
      <Dialog
        onClose={this.props.handleUserClose}
        open={open}
        aria-hidden={!open}
        PaperProps={{ classes: { root: classes.dialogBoxPaper } }}
      >
        <div>
          <IconButton
            className={classes.crossIcon}
            onClick={this.props.handleUserClose}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent>
          <DialogTitle className={classes.title} disableTypography>
            {'Edit User'}
          </DialogTitle>
          <Formik
            initialValues={model}
            validationSchema={editUserSchema}
            onSubmit={this.handleSubmit}
          >
            {props => (
              <UserForm
                {...props}
                roleOptions={roleOptions}
                productOptions={productOptions}
                content={content}
                productNames={getProductNames(productOptions)}
                productRoleOptions={productRoleOptions}
                groupsOptions={groupOptions}
              />
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles)(UserDialog);
