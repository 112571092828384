/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

// @flow
import _reduce from 'lodash/reduce';
import _omit from 'lodash/omit';
import _fromPairs from 'lodash/fromPairs';

import type {
  AccountManagementState,
  SetLoadingStateAction,
  SetLastFailureAction,
  CreateUserAction,
  ImportUsersAction,
  UpdateUserAction,
  BatchUpdateStatusAction,
  DeleteUserAction,
  FetchRolesOptionsAction,
  QueryUsersByCustomerAction,
  FetchLicensedContentAction,
  InvitedUserAction,
  SearchUsersAction,
  CreateGroupAction,
  UpdateGroupAction,
  DeleteGroupAction
} from '../types';
import { getGroups, getUsers } from './getters';

export const queryUsersByCustomer = (
  state: AccountManagementState,
  action: QueryUsersByCustomerAction
): $Shape<AccountManagementState> => {
  const { users, pagination } = action.payload;
  return {
    users: users
      ? _reduce(
          users,
          (r, v) => {
            r[v.id] = v;
            return r;
          },
          {}
        )
      : {},
    pagination
  };
};

export const addNewInvitedUser = (
  state: AccountManagementState,
  action: InvitedUserAction
): $Shape<AccountManagementState> => ({
  users: { ...state.users, action }
});

export const searchUsers = (
  state: AccountManagementState,
  action: SearchUsersAction
): $Shape<AccountManagementState> => {
  const { users, pagination } = action;
  const searchedUsers = _fromPairs(users.map(user => [user.id, user]));

  return {
    users: { ...searchedUsers },
    pagination
  };
};

export const setLoadingState = (
  state: AccountManagementState,
  action: SetLoadingStateAction
): $Shape<AccountManagementState> => ({
  isLoading: action.payload
});

export const setLastFailure = (
  state: AccountManagementState,
  action: SetLastFailureAction
): $Shape<AccountManagementState> => ({
  lastFailure: action.payload
});

export const dismissError = (): $Shape<AccountManagementState> => ({
  lastFailure: undefined
});

export const completeFetchRolesOptions = (
  state: AccountManagementState,
  action: FetchRolesOptionsAction
): $Shape<AccountManagementState> => ({
  roleOptions: action.payload
});

export const completeFetchLicensedContent = (
  state: AccountManagementState,
  action: FetchLicensedContentAction
): $Shape<AccountManagementState> => ({
  licensedContent: action.payload
});

export const completeFetchLicensedProducts = (
  state: AccountManagementState,
  action: FetchLicensedProductsAction
): $Shape<AccountManagementState> => ({
  licensedProducts: action.payload
});

export const createUser = (
  state: AccountManagementState,
  action: CreateUserAction
): $Shape<AccountManagementState> => {
  const { user } = action.payload;
  user.status = 'P';
  const users = getUsers(state);
  return {
    //users plus new user
    users: { ...users, [user.id]: user }
  };
};

export const importUsers = (
  state: AccountManagementState,
  action: ImportUsersAction
): $Shape<AccountManagementState> => {
  const newUsers = _fromPairs(
    action.payload.users.map(user => [user.id, user])
  );
  const users = getUsers(state);
  return {
    users: { ...users, ...newUsers }
  };
};

export const updateUser = (
  state: AccountManagementState,
  action: UpdateUserAction
): $Shape<AccountManagementState> => {
  const users = getUsers(state);
  const { payload: user } = action;
  return {
    users: { ...users, [user.id]: user }
  };
};

export const batchUpdateUsers = (
  state: AccountManagementState,
  action: BatchUpdateStatusAction
): $Shape<AccountManagementState> => {
  const users = getUsers(state);
  const { userIds, status } = action.payload;
  const userObjects = _reduce(
    userIds,
    (r, id) => ({ ...r, [id]: { ...users[id], status } }),
    {}
  );
  return {
    users: {
      ...users,
      ...userObjects
    }
  };
};

export const deleteUser = (
  state: AccountManagementState,
  action: DeleteUserAction
): $Shape<AccountManagementState> => {
  const { payload: deletedUserId } = action;
  const usersWithoutDeleted = _omit(getUsers(state), deletedUserId);
  return {
    users: usersWithoutDeleted
  };
};

export const completeFetchCustomerGroups = (state, payload) => {
  //customer admin
  const { customerId, groups } = payload;
  const groupsById = _reduce(
    groups,
    (r, v) => {
      r[v.id] = v;
      return r;
    },
    {}
  );
  return {
    groups: { [customerId]: groupsById }
  };
};

export const completeFetchAllCustomerGroups = (state, groups) => {
  const groupsByCustomerIdAndGroupId = _reduce(
    groups,
    (r, group) => {
      r[group.customer] = {
        ...r[group.customer],
        [group.id]: group
      };
      return r;
    },
    {}
  );
  return {
    groups: groupsByCustomerIdAndGroupId
  };
};

export const createGroup = (
  state: AccountManagementState,
  action: CreateGroupAction
): $Shape<AccountManagementState> => {
  const group = action.payload;
  const allGroups = getGroups(state);
  const customerId = group.customer;
  const customerGroups = allGroups[customerId];
  const newGroupsPerCustomer = { ...customerGroups, [group.id]: group };
  const newAllGroups = { ...allGroups, [customerId]: newGroupsPerCustomer };
  return {
    //groups plus new group
    groups: newAllGroups
  };
};

export const updateGroup = (
  state: AccountManagementState,
  action: UpdateGroupAction
): $Shape<AccountManagementState> => {
  const group = action.payload;
  const allGroups = getGroups(state);
  const customerId = group.customer;
  const customerGroups = allGroups[customerId];
  const newGroupsPerCustomer = { ...customerGroups, [group.id]: group };
  const newAllGroups = { ...allGroups, [customerId]: newGroupsPerCustomer };
  return {
    //groups plus new group
    groups: newAllGroups
  };
};

export const deleteGroup = (
  state: AccountManagementState,
  action: DeleteGroupAction
): $Shape<AccountManagementState> => {
  const group = action.payload;
  const allGroups = getGroups(state);
  const customerId = group.customer;
  const customerGroups = allGroups[customerId];
  const groupWithoutDeleted = _omit(customerGroups, group.id);
  const newAllGroups = { ...allGroups, [customerId]: groupWithoutDeleted };
  return {
    groups: newAllGroups
  };
};
