/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

// @flow
import {
  Dialog,
  DialogTitle,
  DialogContent,
  withStyles,
  Select,
  MenuItem,
  Tooltip,
  Typography,
  InputLabel
} from '@material-ui/core';
import React, { Component } from 'react';
import { Formik } from 'formik';
import AssignContentForm from './AssignContentForm';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import _map from 'lodash/map';

const styles = () => ({
  title: {
    marginLeft: '0em',
    marginTop: '0em',
    marginBottom: '1em',
    position: 'static',
    zIndex: '1',
    whiteSpace: 'nowrap',
    padding: '0px'
  },
  dialogBoxPaper: {
    padding: '30px',
    maxHeight: '60vh'
  },
  dialogBody: {
    marginTop: '1.5em'
  },
  crossIcon: {
    position: 'absolute',
    top: 0,
    right: 0
  }
});

export class AssignContentDialog extends Component {
  render() {
    const {
      classes,
      open,
      content,
      groupOptions,
      handleSetGroupsForAssignContent,
      groups,
      handleProductChange,
      handleContentChange,
      handleSubmit,
      type
    } = this.props;
    return (
      <Dialog
        onClose={this.props.handleAssignContentClose}
        open={open}
        aria-hidden={!open}
        PaperProps={{ classes: { root: classes.dialogBoxPaper } }}
      >
        <div>
          {type !== 'group' && (
            <DialogTitle className={classes.title} disableTypography>
              Assign Content to {type}
            </DialogTitle>
          )}
          {type === 'group' && (
            <div>
              <DialogTitle className={classes.title} disableTypography>
                Assign Content to All Users
              </DialogTitle>
              <InputLabel id="group-label">Groups</InputLabel>
              <Tooltip
                placement={'top-start'}
                title={
                  <Typography>
                    {
                      'If Group(s) is not selected then content will be assigned to all users and users who are in multiple Groups will receive the most recent Group changes applied'
                    }
                  </Typography>
                }
              >
                <Select
                  multiple
                  name="groups"
                  label="groups"
                  value={groups}
                  onChange={handleSetGroupsForAssignContent}
                  fullWidth
                >
                  {_map(groupOptions, group => (
                    <MenuItem key={group.id} label={group.id} value={group.id}>
                      {group.displayName}
                    </MenuItem>
                  ))}
                </Select>
              </Tooltip>
            </div>
          )}
          <IconButton
            className={classes.crossIcon}
            onClick={this.props.handleAssignContentClose}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent className={classes.dialogBody}>
          <Formik
            initialValues={content}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {props => (
              <AssignContentForm
                {...props}
                handleProductChange={handleProductChange}
                handleContentChange={handleContentChange}
              />
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles)(AssignContentDialog);
