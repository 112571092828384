import React, { Component } from 'react';
import {
  Button,
  MenuItem,
  Select,
  TextField,
  withStyles,
  Tooltip,
  Typography
} from '@material-ui/core';
import { FormControl, InputLabel, FormHelperText } from '@material-ui/core';
import _map from 'lodash/map';

const styles = () => ({
  dropdown: {
    maxHeight: 250
  },
  submitInvite: {
    marginTop: '15px'
  }
});

export class InviteUserForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCustomerSet: false
    };
  }

  componentDidMount = () => {
    const { customerOptions } = this.props;
    if (Object.keys(customerOptions).length === 1) {
      this.setState({
        isCustomerSet: true
      });
    }
  };

  getProductRoles = () => {
    let productRoleOptions = {};
    if (this.props.productOptions) {
      Object.keys(this.props.productOptions).forEach(item => {
        const listValues = this.getProductRolesByProductName(item);
        if (listValues) {
          productRoleOptions = { ...productRoleOptions, ...listValues };
        }
      });
    }
    return _map(productRoleOptions, (productRole, key) => (
      <MenuItem key={productRole} value={key}>
        {productRole}
      </MenuItem>
    ));
  };

  getProductRolesByProductName = productName => {
    const productRolesList = {};
    if (productName) {
      if (this.props.productOptions) {
        const productInfo = this.props.productOptions[productName];
        if (productInfo) {
          if (productInfo.roles && productInfo.roles.length > 0) {
            // eslint-disable-next-line no-plusplus
            for (let a = 0; a < productInfo.roles.length; a++) {
              productRolesList[
                productInfo.roles[a].array[0]
              ] = `${productInfo.title} ${productInfo.roles[a].array[1]}`;
            }
          }
        }
      }
    }
    return productRolesList;
  };

  render() {
    const {
      values: {
        firstName,
        lastName,
        userName,
        roles,
        customer,
        groups,
        aresRoles
      },
      customerOptions,
      errors,
      touched,
      handleSubmit,
      handleChange,
      isValid,
      setFieldTouched,
      roleOptions,
      classes,
      handleCustomerChange,
      groupOptions
    } = this.props;
    const { isCustomerSet } = this.state;
    const change = (name, e) => {
      if (name === 'customer') {
        handleCustomerChange(e.target.value);
        this.setState({
          isCustomerSet: true
        });
      }
      e.persist();
      handleChange(e);
      setFieldTouched(name, true, false);
    };
    return (
      <form onSubmit={handleSubmit}>
        <FormControl key="customer" id="customer" fullWidth>
          <InputLabel id="select-customer">Customer</InputLabel>
          <Select
            MenuProps={{ classes: { paper: classes.dropdown } }}
            id="InvitationForm_customer"
            name="customer"
            value={customer}
            label="Customer"
            error={touched.customer && Boolean(errors.customer)}
            onChange={change.bind(null, 'customer')}
            fullWidth
          >
            {_map(customerOptions, customer => (
              <MenuItem key={customer.id} label={customer.id} value={customer}>
                {customer.name}
              </MenuItem>
            ))}
          </Select>
          {touched.customer && Boolean(errors.customer) && (
            <FormHelperText
              id="customer"
              error={touched.customer && Boolean(errors.customer)}
            >
              {errors.customer}
            </FormHelperText>
          )}
        </FormControl>
        <div>
          <TextField
            id="firstName"
            key="firstName"
            name="firstName"
            helperText={touched.firstName ? errors.firstName : ''}
            error={touched.firstName && Boolean(errors.firstName)}
            label="First Name"
            value={firstName}
            onChange={change.bind(null, 'firstName')}
            fullWidth
          />
          <TextField
            id="lastName"
            key="lastName"
            name="lastName"
            helperText={touched.lastName ? errors.lastName : ''}
            error={touched.lastName && Boolean(errors.lastName)}
            label="Last Name"
            value={lastName}
            onChange={change.bind(null, 'lastName')}
            fullWidth
          />
          <Tooltip
            title={
              <Typography>
                username must have the format: exampleusername@domain.com
              </Typography>
            }
          >
            <TextField
              id="userName"
              key="userName"
              name="userName"
              helperText={touched.userName ? errors.userName : ''}
              error={touched.userName && Boolean(errors.userName)}
              label="Email or Username"
              value={userName}
              onChange={change.bind(null, 'userName')}
              fullWidth
            />
          </Tooltip>
          <FormControl key="roles" id="roles" fullWidth>
            <InputLabel>Roles</InputLabel>
            <Select
              multiple
              MenuProps={{ classes: { paper: classes.dropdown } }}
              name="roles"
              label="roles"
              error={touched.roles && Boolean(errors.roles)}
              value={roles}
              onChange={change.bind(null, 'roles')}
              fullWidth
            >
              {_map(roleOptions, (role, key) => (
                <MenuItem key={key} value={key}>
                  {role}
                </MenuItem>
              ))}
            </Select>
            {touched.roles && Boolean(errors.roles) && (
              <FormHelperText
                id="roles"
                error={touched.roles && Boolean(errors.roles)}
              >
                {errors.roles}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl
            key="groups"
            id="groups"
            fullWidth
            disabled={!isCustomerSet}
          >
            <InputLabel id="select-groups">Groups</InputLabel>
            <Select
              multiple
              MenuProps={{ classes: { paper: classes.dropdown } }}
              id="InvitationForm_groups"
              name="groups"
              value={groups}
              label="groups"
              error={touched.groups && Boolean(errors.groups)}
              onChange={change.bind(null, 'groups')}
              fullWidth
            >
              {isCustomerSet &&
                _map(groupOptions, group => (
                  <MenuItem key={group.id} label={group.id} value={group.id}>
                    {group.displayName}
                  </MenuItem>
                ))}
            </Select>
            {touched.groups && Boolean(errors.groups) && (
              <FormHelperText
                id="groups"
                error={touched.groups && Boolean(errors.groups)}
              >
                {errors.groups}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl key={'aresRoles'} fullWidth>
            <InputLabel htmlFor="EditUser_productRolesRoles">
              Ares Roles
            </InputLabel>
            <Select
              multiple
              MenuProps={{ classes: { paper: classes.rolesDropdown } }}
              name="aresRoles"
              label="aresRoles"
              value={aresRoles}
              error={touched.aresRoles && Boolean(errors.aresRoles)}
              onChange={change.bind(null, 'aresRoles')}
              fullWidth
            >
              {this.getProductRoles()}
            </Select>
            {touched.aresRoles && Boolean(errors.aresRoles) && (
              <FormHelperText
                id="aresRoles"
                error={touched.aresRoles && Boolean(errors.aresRoles)}
              >
                {errors.aresRoles}
              </FormHelperText>
            )}
          </FormControl>
          <Button
            type="submit"
            fullWidth
            color="primary"
            disabled={!isValid}
            className={classes.submitInvite}
          >
            SUBMIT
          </Button>
        </div>
      </form>
    );
  }
}

export default withStyles(styles)(InviteUserForm);
