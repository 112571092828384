/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { colorDarkGrey, colorLightest } from './colors';

export const renderSvgToDataUrl = component =>
  `data:image/svg+xml,${encodeURIComponent(renderToStaticMarkup(component))}`;

export const renderSvgToCssDataUrl = component =>
  `url(${renderSvgToDataUrl(component)})`;

//BACKGROUND IMAGES
export const raisedButton = (e, d, f) =>
  renderSvgToCssDataUrl(
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 9">
      <path
        d="M8.5 8.5H1v-8h5.74L8.5 2.22z"
        strokeWidth=".5"
        stroke={d}
        fill="none"
        strokeMiterlimit="10"
      />
      <path
        stroke={e}
        strokeWidth="1"
        fill="none"
        strokeMiterlimit="10"
        d="M1 5.75v-2.5"
      />
      <path fill={f} d="M9 1.83V0H7.19L9 1.83z" />
    </svg>
  );

export const inputBG = (e, d, f) =>
  renderSvgToCssDataUrl(
    <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 9">
      <g fill="none">
        <path d="M8 8.5H1v-8h7z" stroke={d} strokeWidth=".5" />
        <path strokeWidth="1.5" stroke={e} d="M1 5.75v-2.5" />
        <path stroke={f} strokeWidth="1.5" d="M8 3.25v2.5" />
      </g>
    </svg>
  );

export const hexBG = renderSvgToCssDataUrl(
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6 6">
    <path
      fill={colorLightest}
      stroke="#fc9a1b"
      strokeMiterlimit="10"
      strokeWidth=".25"
      d="M5.47 4.42V1.57L3 .15.53 1.57v2.85L3 5.85l2.47-1.43z"
    />
  </svg>
);

export const navBG = renderSvgToCssDataUrl(
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 9 9"
    strokeWidth=".5"
  >
    <path
      fill="none"
      stroke={colorDarkGrey}
      strokeMiterlimit="10"
      d="M6.5.5h2v2M8.5 3.5v2M8.5 6.5v2h-2M5.5 8.5h-2M2.5 8.5h-2v-2M.5 2.5v-2h2M3.5.5h2M.5 5.5v-2"
    />
  </svg>
);

export const gridBG =
  'url(/assets/images/Square-Grid-Black.png),linear-gradient(-29.8deg, #ffffff 0%, #e0e0e0 100%)';
