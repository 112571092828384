/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import type { CustomerManagementState } from '../types';

export const isLoading = (state: CustomerManagementState) => state.isLoading;

export const getLastFailure = (state: CustomerManagementState) =>
  state.lastFailure;

export const getCustomers = (state: CustomerManagementState) => state.customers;

export const getAdminsByCustomer = (state: CustomerManagementState) =>
  state.adminsByCustomer;
