/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import React, { Component } from 'react';
import { Typography, Paper, withStyles } from '@material-ui/core';
import ProductBanner from '../../../Assets/ProductBanner.js';
import ProductsTab from '../../ProductsTab/index';
import MediaQuery from 'react-responsive';
import OrionIcon from '../../../Assets/Orion';
import orionBanner from '../../../Assets/orionBanner.jpg';
export const styles = theme => ({
  root: {
    boxShadow:
      '-5px -5px 20px 0px ' + theme.palette.primary.lightGradientBackground,
    marginLeft: '25px',
    paddingTop: '25px',
    paddingLeft: '25px',
    width: '100%'
  },
  container: {
    position: 'relative'
  },
  bottomleft: {
    width: '50%',
    height: '99.5%',
    top: '0em',
    position: 'absolute',
    display: 'flex',
    background:
      'linear-gradient(90deg, ' +
      theme.palette.primary.gradientBackground +
      ' 0%,' +
      theme.palette.primary.lightGradientBackground +
      ', ' +
      theme.palette.primary.darker +
      ' 100%)',
    right: 0,
    paddingLeft: '50%',
    flexDirection: 'column'
  },

  borderStyle: {
    color: theme.palette.primary.colorBorder,
    borderColor: theme.palette.primary.colorBorder
  },
  icon: {
    paddingRight: '0px',
    fill: theme.palette.primary.orange,
    width: '2.5em',
    height: '2.5em'
  },
  header: {
    color: theme.palette.primary.darkTurquoise
  }
});
class Orion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: undefined
    };
  }

  handleChange = (event, value) => {
    this.setState({ selectedTab: value });
  };
  windowResize = () => {
    const { classes } = this.props;
    this.eldisplay = window.innerWidth <= 768 ? classes.dispnone : '';
  };
  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.root}>
        <div>
          <Typography className={classes.header} variant="h4" component="h2">
            {'Orion'}
          </Typography>
          <hr className={classes.borderStyle} />
          <div className={classes.container}>
            <ProductBanner img={orionBanner} />
            <MediaQuery minWidth={768}>
              <div className={`${classes.bottomleft}`}>
                <ProductsTab
                  description={
                    'Orion, the custom battle room builder, provides course designers in enterprises, government, and academic institutions the tools to build or tailor Project Ares battle room exercises. Training curriculum can be aligned to organization strategy and unique industry threat scenarios.'
                  }
                  icon={<OrionIcon className={classes.icon} />}
                  launchMessage={'Launch'}
                  data-test={`productsTab-orion`}
                  clickUrl={'/games/orion'}
                ></ProductsTab>
              </div>
            </MediaQuery>
          </div>
          <hr className={classes.borderStyle} />
        </div>
      </Paper>
    );
  }
}
export default withStyles(styles)(Orion);
