/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import { combineReducers } from 'redux';
import accountManagement from '../modules/accountManagement/reducer';
import customerManagement from '../components/Dashboard/CustomerManagement/reducer';
import licenseManagement from '../components/Dashboard/LicenseManagement/reducer';
import sessionUser from '../modules/session-user/reducer';
import contentAccess from '../components/Dashboard/ContentAccess/reducer';
import dendrite from '../components/Dashboard/Dendrite/reducer';
import { msal } from '@circadence/auth';

export default combineReducers({
  accountManagement,
  customerManagement,
  licenseManagement,
  sessionUser,
  contentAccess,
  dendrite,
  [msal.REDUCER_KEY]: msal.reducer
});
