/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import _map from 'lodash/map';
import {
  Typography,
  Checkbox,
  TextField,
  Grid,
  Tooltip,
  withStyles
} from '@material-ui/core';
import Lock from '@material-ui/icons/Lock';
import React, { Component } from 'react';
import { accessType } from './constants';
import _omit from 'lodash/omit';
import _debounce from 'lodash/debounce';
import _isEqual from 'lodash/isEqual';

export class ContentSubscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentSubscription: props.contentSubscription
    };
  }

  componentDidUpdate = prevProps => {
    if (
      !_isEqual(prevProps.contentSubscription, this.props.contentSubscription)
    ) {
      this.setState({
        contentSubscription: this.props.contentSubscription
      });
    }
  };

  debounceUpdateContentSubscription = _debounce(
    this.props.updateContentSubscription,
    500
  );

  isWithinRange = number => number <= 1000000000 && number >= 0;

  updateTextField = (target, contentId) => {
    const contents = this.state.contentSubscription;
    const { doesExceedMaxNumUsers, product } = this.props;
    const onlyNumbers = target.value ? target.value.replace(/[^0-9]/g, '') : '';

    if (
      doesExceedMaxNumUsers(onlyNumbers, product.id) &&
      this.isWithinRange(Number(onlyNumbers))
    ) {
      contents[contentId].maxNumOfUsers = onlyNumbers;
      this.setState({
        contentSubscription: contents
      });
    }

    this.debounceUpdateContentSubscription(
      this.state.contentSubscription,
      product
    );
  };

  updateCheckbox = target => {
    let contents = this.state.contentSubscription;
    const { product } = this.props;

    // setting from unavailable to available
    if (!contents[target.id]) {
      contents = {
        ...contents,
        [target.id]: {
          contentId: target.id,
          maxNumOfUsers: '0',
          accessType: accessType.available
        }
      };
    }
    // setting from available to locked or locked to unavailable
    else {
      // setting from locked to unavailable
      if (contents[target.id].accessType === accessType.locked)
        contents = _omit(contents, target.id);
      // setting from available to locked
      else contents[target.id].accessType = accessType.locked;
    }

    this.setState(
      {
        contentSubscription: contents
      },
      () => {
        this.debounceUpdateContentSubscription(
          this.state.contentSubscription,
          product
        );
      }
    );
  };

  render() {
    const { classes, contentByType, isEditable } = this.props;
    const { contentSubscription } = this.state;
    const contentsList = contentSubscription;
    return (
      <Grid container spacing={0}>
        <Grid item xs={12} align="center">
          <Typography className={classes.header} variant="h4" component="h4">
            Content Subscription
          </Typography>
        </Grid>
        {_map(Object.keys(contentByType), key => {
          let contentCount = 0;
          return (
            <Grid key={key} item xs={12}>
              <Typography className={classes.header} variant="h4">
                {key}
              </Typography>

              {_map(contentByType[key], content => {
                const checkBoxState = !!contentsList[content.id]
                  ? contentsList[content.id].accessType
                  : accessType.unavailable;
                if (!isEditable && checkBoxState === accessType.unavailable) {
                  return;
                } else {
                  contentCount += 1;
                  return (
                    <Grid container spacing={0} key={content.id}>
                      <Grid key={content.id} item xs={1} align="center">
                        <Tooltip
                          title={
                            checkBoxState === accessType.available
                              ? 'available'
                              : 'locked'
                          }
                          data-test={`tooltip-${checkBoxState}`}
                        >
                          <Checkbox
                            id={content.id}
                            checked={checkBoxState === accessType.available}
                            indeterminate={checkBoxState === accessType.locked}
                            indeterminateIcon={<Lock color="error" />}
                            role="checkbox"
                            className={classes.checkbox}
                            onChange={event =>
                              this.updateCheckbox(event.currentTarget)
                            }
                            data-test={`checkbox-${content.id}`}
                            disabled={!isEditable}
                          />
                        </Tooltip>
                      </Grid>

                      <Grid item xs={3} align="center">
                        <TextField
                          id={`${content.id}-maxNumOfUsers`}
                          name="maxNumOfUsers"
                          label="Max Num of Users"
                          type={'text'}
                          disabled={
                            checkBoxState === accessType.locked ||
                            checkBoxState === accessType.unavailable ||
                            !isEditable
                          }
                          value={
                            contentsList[content.id]
                              ? contentsList[content.id].maxNumOfUsers
                              : ''
                          }
                          onChange={event =>
                            this.updateTextField(
                              event.currentTarget,
                              content.id
                            )
                          }
                          data-test={`maxNumOfUsers-textfield-${content.id}`}
                        />
                      </Grid>

                      <Grid item xs className={classes.contentName}>
                        {content.name}
                      </Grid>
                    </Grid>
                  );
                }
              })}
              {contentCount === 0 && !isEditable && (
                <Grid item xs={1} align="center">
                  <Typography>None</Typography>
                </Grid>
              )}
            </Grid>
          );
        })}
      </Grid>
    );
  }
}

export const styles = (theme: any) => ({
  contentName: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '2% 0 0 2%',
    color: theme.palette.primary.white
  },
  checkbox: {
    marginTop: '10%'
  },
  lockIcon: {
    fill: theme.palette.secondary.darker
  },
  header: {
    color: theme.palette.primary.darkTurquoise
  }
});

export default withStyles(styles)(ContentSubscription);
