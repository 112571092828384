/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import { composeWithDevTools } from 'redux-devtools-extension';
import { applyMiddleware, createStore } from 'redux';
// import initialState from './initialState';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

const middleware = composeWithDevTools(applyMiddleware(thunk));
export default createStore(rootReducer, {}, middleware);

export const configureStore = initialState =>
  createStore(rootReducer, initialState, middleware);
