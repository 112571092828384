/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import type { AnyContentAccessAction, ContentAccessState } from '../types';
import * as fn from '../reducer/functions';

export { REDUCER_NAME } from '../types';

const initialState: ContentAccessState = {
  isLoading: false,
  lastFailure: undefined,
  products: undefined,
  contentByProductId: {}
};

export default (
  state: ContentAccessState = initialState,
  action: AnyContentAccessAction
): ContentAccessState => {
  let changes: $Shape<ContentAccessState>;
  switch (action.type) {
    default:
      return state;

    case 'CONTENT_ACCESS/QUERY_PRODUCTS':
      changes = fn.queryProducts(state, action);
      break;

    case 'CONTENT_ACCESS/GET_ALL_CONTENT':
      changes = fn.getAllContent(state, action);
      break;

    case 'CONTENT_ACCESS/GET_CONTENT':
      changes = fn.getContent(state, action);
      break;

    case 'CONTENT_ACCESS/SET_LAST_FAILURE':
      changes = fn.setLastFailure(state, action);
      break;

    case 'CONTENT_ACCESS/DISMISS_ERROR':
      changes = fn.dismissError();
      break;

    case 'CONTENT_ACCESS/SET_LOADING_STATE':
      changes = fn.setLoadingState(state, action);
      break;
  }
  return { ...state, ...changes };
};
