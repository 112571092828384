/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Divider,
  Drawer,
  List,
  ListItem,
  Typography,
  withStyles
} from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import classNames from 'classnames';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import IconButton from '@material-ui/core/IconButton';
import * as constants from './constants';
import Icon from '@material-ui/core/Icon';
import { connect } from 'react-redux';
import AresIcon from '../../../Assets/Ares.js';
import InCytIcon from '../../../Assets/InCyt.js';
import OrionIcon from '../../../Assets/Orion.js';
import CustomersIcon from '../../../Assets/Customers.js';
import UsersIcon from '../../../Assets/Users.js';
import LicensesIcon from '../../../Assets/Licenses.js';
import InvitesIcon from '../../../Assets/Invites.js';
import EditProfileIcon from '../../../Assets/Profile.js';
import LogoIcon from '../../../Assets/Logo.js';
import LogoutIcon from '../../../Assets/Logout.js';
import EssentialsIcon from '../../../Assets/Essentials';
import DendriteIcon from '../../../Assets/Dendrite.js';

import { logout } from '../../../utilities/thirdParty/b2c';
import { fetchPlayerProducts } from '../ContentAccess/services';
import {
  selectSessionUserRoles,
  selectSessionUserId,
  selectIsLoading
} from '../../../modules/session-user/selectors';
import * as roleConstants from '../../../constants/roles';
const drawerWidth = 275;

export const calculateClassName = (pattern, pathname, icon) => {
  let className;
  if (icon) {
    className = pathname.endsWith(pattern) ? 'selectedIcon' : 'icon';
  } else {
    className = pathname.endsWith(pattern) ? 'selectedListItem' : 'listItem';
  }
  return className;
};

export const setActivelinkClass = (pattern, pathname, icon) => {
  let className;
  if (icon) {
    className = pathname.endsWith(pattern) ? 'activelink' : '';
  }
  return className;
};

export const renderIcon = (item, classes, route, pathname) => {
  if (item.customIcon) {
    switch (item.icon) {
      case 'ares':
        return (
          <AresIcon
            width="1em"
            height="1em"
            className={classes[calculateClassName(route, pathname, true)]}
          />
        );
      case 'incyt':
        return (
          <InCytIcon
            width="1em"
            height="1em"
            className={classes[calculateClassName(route, pathname, true)]}
          />
        );
      case 'orion':
        return (
          <OrionIcon
            width="1em"
            height="1em"
            className={classes[calculateClassName(route, pathname, true)]}
          />
        );
      case 'customers':
        return (
          <CustomersIcon
            width="1em"
            height="1em"
            className={classes[calculateClassName(route, pathname, true)]}
          />
        );
      case 'invites':
        return (
          <InvitesIcon
            width="1em"
            height="1em"
            className={classes[calculateClassName(route, pathname, true)]}
          />
        );
      case 'users':
        return (
          <UsersIcon
            width="1em"
            height="1em"
            className={classes[calculateClassName(route, pathname, true)]}
          />
        );
      case 'licenses':
        return (
          <LicensesIcon
            width="1em"
            height="1em"
            className={classes[calculateClassName(route, pathname, true)]}
          />
        );
      case 'editprofile':
        return (
          <EditProfileIcon
            width="1em"
            height="1em"
            className={classes[calculateClassName(route, pathname, true)]}
          />
        );
      case 'essentials':
        return (
          <EssentialsIcon
            width="1em"
            height="1em"
            className={classes[calculateClassName(route, pathname, true)]}
          />
        );
      case 'dendrite':
        return (
          <DendriteIcon
            width="1em"
            height="1em"
            className={classes[calculateClassName(route, pathname, true)]}
          />
        );
      default:
        break;
    }
  }
  return <Icon className={classes.icon}>{item.icon}</Icon>;
};

const logoutOfApp = () => {
  logout();
};

export const MenuView = ({
  match: { url },
  classes,
  roles,
  identitySubject,
  isLoading
}) => {
  const [menuOpen, setMenuOpen] = useState(true);
  const [products, setProducts] = useState([]);
  const isCmtAdmin = roles.includes(roleConstants.CMT_ADMIN);
  const isCustomerAdmin = roles.includes(roleConstants.CUSTOMER_ADMIN);
  const isPlayer = roles.includes(roleConstants.PLAYER);
  const handleDrawer = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    if (identitySubject) {
      fetchPlayerProducts(identitySubject).then(products => {
        const essentialsMatch = products.some(
          prod => constants.essentials[prod]
        );
        if (essentialsMatch) {
          setProducts(['essentials', ...products]);
        } else {
          setProducts(products);
        }
      });
    }
  }, [identitySubject, isLoading]);

  return (
    <div className={classes.wrapper}>
      <Drawer
        variant="permanent"
        classes={{
          paper: classNames(
            classes.drawerPaper,
            !menuOpen && classes.drawerPaperClose
          )
        }}
        open={menuOpen}
      >
        <div className={classes.toolbar}>
          <IconButton
            onClick={handleDrawer}
            className={`${menuOpen === true ? '' : 'offset'} ${
              classes.slideButton
            }`}
          >
            {menuOpen === true ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <div className={classes.logo}>
          <LogoIcon />
        </div>
        <Divider />
        <List className={classes.list}>
          <div className={classes.topList}>
            {constants
              .getMenuItems(isCmtAdmin, isCustomerAdmin, isPlayer, products)
              .map(item => (
                <ListItem
                  className={classes[setActivelinkClass(item.route, url, true)]}
                  data-test={`menu-listItem-${item.route}`}
                  component={Link}
                  to={`${url}/${item.route}`}
                  key={item.route}
                  button
                >
                  {renderIcon(item, classes, item.route, url)}
                  <Typography
                    internalDeprecatedVariant
                    variant="h6"
                    className={classes[calculateClassName(item.route, url)]}
                  >
                    {item.label.toUpperCase()}
                  </Typography>
                </ListItem>
              ))}
            <Divider />
          </div>
          <div className={classes.bottomList}>
            <ListItem
              component={Link}
              to={'edit-profile'}
              key={'edit-profile'}
              button
              className={classes[setActivelinkClass('edit-profile', url, true)]}
              data-test={`menu-listItem-editProfile`}
            >
              <EditProfileIcon
                width="1em"
                height="1em"
                className={
                  classes[calculateClassName('edit-profile', url, true)]
                }
              />
              <Typography
                internalDeprecatedVariant
                variant="h6"
                className={classes[calculateClassName('edit-profile', url)]}
              >
                {'Edit Profile'.toUpperCase()}
              </Typography>
            </ListItem>
            <ListItem
              data-test={`menu-listItem-logOut`}
              className={classes.bottom}
              button
              onClick={logoutOfApp}
            >
              <LogoutIcon width="1em" height="1em" className={classes.icon} />
              <Typography
                internalDeprecatedVariant
                variant="h6"
                className={classes.listItem}
              >
                {'Log Out'.toUpperCase()}
              </Typography>
            </ListItem>
          </div>
        </List>
      </Drawer>
    </div>
  );
};

MenuView.propTypes = {
  //withStyles
  classes: PropTypes.object.isRequired,
  //withRouter
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.shape.isRequired
    })
  })
};

const styles = theme => ({
  '@global': {
    '*::-webkit-scrollbar': {
      width: '0.2em',
      height: '0.2em',
      background: fade(theme.palette.primary.contrastText, 0.05)
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px ' + theme.palette.primary.whiteShadow
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.contrastText
    }
  },
  drawerPaper: {
    background: theme.palette.primary.greyDark + '!important',
    position: 'initial',
    overflowX: 'hidden',
    height: 'calc(100vh)',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 6
    }
  },
  wrapper: {
    position: 'relative',
    'border-right': '1px solid',
    color: theme.palette.primary.colorBorder,
    borderColor: theme.palette.primary.colorBorder
  },
  bottomList: {
    'align-self': 'flex-end',
    width: '100%',
    '&>a:hover, &>div:hover': {
      'background-color': theme.palette.primary.colorBorder
    }
  },
  listItem: {
    color: theme.palette.text.white
  },
  selectedListItem: {
    color: theme.palette.primary.white
  },
  logo: {
    width: '100%',

    '&>svg': {
      width: 'auto',
      height: '5em',
      float: 'left',
      margin: '0.2em 0em 0em -1em',

      '& .st0': {
        fill: theme.palette.primary.main
      },
      '& .st1': {
        fill: theme.palette.primary.logo
      }
    }
  },
  icon: {
    paddingRight: '20px',
    fill: theme.palette.primary.white,
    fontSize: '24px'
  },
  selectedIcon: {
    paddingRight: '20px',
    fill: theme.palette.primary.white,
    fontSize: '24px',
    filter:
      'drop-shadow(0px 0px 10px ' + theme.palette.primary.iconShadow + '))',
    '&>path': {
      stroke: theme.palette.primary.iconStroke
    }
  },

  list: {
    height: 'calc(100% - 3em)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '280px'
  },
  topList: {
    display: 'block',
    'border-bottom': '1px solid',
    color: theme.palette.primary.colorBorder,
    borderColor: theme.palette.primary.colorBorder,
    '&>a h6': { 'letter-spacing': 'normal' },
    '&>a:hover': {
      'background-color': theme.palette.primary.colorBorder
    }
  },
  activelink: {
    background: theme.palette.primary.colorBorder,
    '&:after': {
      top: 0,
      right: 0,
      width: '.5em',
      height: '100%',
      display: 'block',
      content: 'open-quote',
      position: 'absolute',
      background: 'white',
      'box-shadow':
        'inset 0px 0px 10px ' +
        theme.palette.primary.darkTurquoise +
        ', 0px 0px 20px ' +
        theme.palette.primary.darkTurquoise +
        ' , 0px 0px 10px ' +
        theme.palette.primary.darkTurquoise +
        ';'
    }
  },
  toolbar: {
    zIndex: '1',
    '&>button': {
      transform: 'scale(.7)',
      background: theme.palette.primary.darkTurquoise,
      transition: '.3s all '
    },
    '&>button:hover': {
      transform: 'scale(1)',
      transition: '.3s all ',
      background: theme.palette.primary.lighterGray
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    position: 'absolute',
    right: '0',
    top: '50%',
    transform: 'translateX(50%) translateY(-50%)',
    ...theme.mixins.toolbar
  },
  bottom: {
    margin: 'auto 0 0 0'
  },
  slideButton: {
    '&>span': {
      '&>svg': { fontSize: '25px' }
    }
  }
});

const mapStateToProps = state => ({
  roles: selectSessionUserRoles(state),
  identitySubject: selectSessionUserId(state),
  isLoading: selectIsLoading(state)
});

export default withStyles(styles)(
  withRouter(connect(mapStateToProps)(MenuView))
);
