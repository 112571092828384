/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import { createMuiTheme } from '@material-ui/core/styles';
import palette from './palette';
import overrides from './overrides.js';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: ['Barlow Condensed', 'Roboto', 'Arial', 'sans-serif'].join(',')
  },
  palette,
  overrides
});
export default theme;
