/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

// @flow
import type { SessionUserState, AnySessionUserAction } from '../types';
import * as fn from './functions';
export { REDUCER_NAME } from '../types';

const initialState: SessionUserState = {
  userId: undefined,
  customerId: undefined,
  userDetails: { roles: [] },
  isLoading: true,
  playFabData: undefined,
  lastFailure: undefined
};

export default (
  state: SessionUserState = initialState,
  action: AnySessionUserAction
): SessionUserState => {
  let changes: $Shape<SessionUserState>;

  switch (action.type) {
    default:
      return state;

    case 'SESSION_USER/SET_SESSION_USER_PLAYFAB_INFO':
      changes = fn.getSessionUserPlayfabInfo(state, action);
      break;

    case 'SESSION_USER/SET_LAST_FAILURE':
      changes = fn.setLastFailure(state, action);
      break;

    case 'SESSION_USER/SET_LOADING_STATE':
      changes = fn.setLoadingState(state, action);
      break;

    case 'SESSION_USER/SET_USER_DETAILS':
      changes = fn.setSessionUserDetails(state, action);
      break;

    case 'SESSION_USER/GET_USER_DETAILS':
      changes = fn.getSessionUserDetails(state, action);
      break;
  }
  return { ...state, ...changes };
};
