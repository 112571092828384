/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import React from 'react';

const SvgProfile = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <svg viewBox="0 0 16.62 16.66" {...props}>
      <path
        className="profile_svg__cls-1"
        d="M10.56 8.17a4.41 4.41 0 10-4.5 0 8.31 8.31 0 00-6.06 8 .5.5 0 00.5.5.5.5 0 00.5-.5 7.31 7.31 0 1114.62 0 .5.5 0 00.5.5.5.5 0 00.5-.5 8.31 8.31 0 00-6.06-8zM4.9 4.4a3.41 3.41 0 113.41 3.41A3.41 3.41 0 014.9 4.4z"
      />
    </svg>
  </svg>
);

export default SvgProfile;
