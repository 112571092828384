/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

// eslint-disable-next-line no-unused-vars
import {
  ProductServicePromiseClient,
  QueryProductRequest
} from 'product_grpc_web_pb';
// eslint-disable-next-line no-unused-vars
import {
  ContentServicePromiseClient,
  GetContentRequest
} from 'content_grpc_web_pb';

import {
  ApplyDefaultLicenseToUserRequest,
  BatchUpdateContentAccessRequest,
  ContentAccessServicePromiseClient,
  ContentRight as PbContentRight,
  GetContentAccessRequest,
  GetDefaultLicenseRequest,
  GetProductAccessRequest,
  ProductRight as PbProductRight,
  SetDefaultLicenseRequest,
  UnsetDefaultLicenseRequest,
  UpdateContentAccessRequest
} from 'contentaccess_grpc_web_pb';

import _map from 'lodash/map';
import _reduce from 'lodash/reduce';
import type { Product } from './types';
import type { Content } from '../LicenseManagement/types';

const { protocol, host } = window.location;

const productServiceclient = new ProductServicePromiseClient(
  `${protocol}//${host}`,
  null,
  null
);
const contentServiceClient = new ContentServicePromiseClient(
  `${protocol}//${host}`,
  null,
  null
);
const contentAccessServiceClient = new ContentAccessServicePromiseClient(
  `${protocol}//${host}`,
  null,
  null
);

export const queryProducts = (
  client: ProductServiceClient = productServiceclient
): Promise<Product[]> => {
  const query = new QueryProductRequest();
  return client
    .queryProduct(query)
    .then(response => _map(response.getProductsList(), pbProductToProduct));
};

export const queryContentByProductId = (
  productId: string,
  client: ContentServiceClient = contentServiceClient
): Promise<Content[]> => {
  const query = new GetContentRequest();
  query.setProductId(productId);
  return client.getContent(query).then(response => {
    const contentArray = _map(response.getContentList(), pbContentToContent);
    return contentArray.sort((a, b) =>
      a.name.localeCompare(b.name, 'en', { numeric: true })
    );
  });
};

export const getProductRightsForUserId = (
  userId: string,
  client: ContentAccessServiceClient = contentAccessServiceClient
): Promise<ProductRight[]> => {
  const request = new GetProductAccessRequest();
  request.setUserId(userId);
  return client
    .getProductAccess(request)
    .then(response =>
      _map(response.getProductRightsList(), pbProductRightToProductId)
    );
};

export const fetchPlayerProducts = async userId => {
  let products = await getProductRightsForUserId(userId);
  if (products) {
    products = _reduce(
      products,
      (r, v) => {
        v.productId !== '' && r.push(v.productId);
        return r;
      },
      []
    );
  } else {
    products = [];
  }
  return new Promise(resolve => resolve(products));
};

export const getContentRightsByUserId = (
  userId: string,
  contentFilterList: string[],
  client: ContentServiceClient = contentAccessServiceClient
) => {
  const query = new GetContentAccessRequest();
  query.setUserId(userId);
  query.setContentFilterListList(contentFilterList);
  return client
    .getContentAccess(query)
    .then(response =>
      _map(response.getContentRightsList(), pbContentRightToContentRight)
    );
};

export const updateContentAccess = (
  userId: string,
  contentRights,
  productRights,
  client: ContentServiceClient = contentAccessServiceClient
) => {
  const update = new UpdateContentAccessRequest();
  update.setUserId(userId);
  update.setContentRightsList(
    _map(contentRights, contentRight =>
      contentRightToPbContentRight(contentRight)
    )
  );
  update.setProductRightsList(
    _map(productRights, productRight =>
      productRightToPbProductRight(productRight)
    )
  );
  return client.updateContentAccess(update);
};

export const batchUpdateContentAccess = (
  userIds: string[],
  contentRights,
  productRights,
  customerId,
  client: ContentServiceClient = contentAccessServiceClient
) => {
  const update = new BatchUpdateContentAccessRequest();
  update.setUserIdsList(userIds);
  update.setContentRightsList(
    _map(contentRights, contentRight =>
      contentRightToPbContentRight(contentRight)
    )
  );
  update.setProductRightsList(
    _map(productRights, productRight =>
      productRightToPbProductRight(productRight)
    )
  );
  update.setCustomerId(customerId);
  return client.batchUpdateContentAccess(update);
};

export const getDefaultLicenseForCustomer = (
  customerId: string,
  client: ContentServiceClient = contentAccessServiceClient
) => {
  const query = new GetDefaultLicenseRequest();
  query.setCustomerId(customerId);
  return client
    .getDefaultLicenseForCustomer(query)
    .then(response => response.getLicenseId());
};

export const setDefaultLicenseForCustomer = (
  customerId: string,
  licenseId: string,
  client: ContentServiceClient = contentAccessServiceClient
) => {
  const query = new SetDefaultLicenseRequest();
  query.setCustomerId(customerId);
  query.setLicenseId(licenseId);
  return client.setDefaultLicenseForCustomer(query);
};

export const unsetDefaultLicenseForCustomer = (
  customerId: string,
  client: ContentServiceClient = contentAccessServiceClient
) => {
  const query = new UnsetDefaultLicenseRequest();
  query.setCustomerId(customerId);
  return client.unsetDefaultLicenseForCustomer(query);
};

export const applyDefaultLicenseToUser = (
  userId: string,
  customerId: string,
  client: ContentServiceClient = contentAccessServiceClient
) => {
  const query = new ApplyDefaultLicenseToUserRequest();
  query.setUserId(userId);
  query.setCustomerId(customerId);
  return client
    .applyDefaultLicenseToUser(query)
    .then(response => response.getLicenseId());
};

const pbProductToProduct = pbProduct => ({
  id: pbProduct.getId(),
  title: pbProduct.getTitle(),
  subtitle: pbProduct.getSubtitle(),
  thumbUrl: pbProduct.getThumbUrl(),
  roles: pbProduct.getRolesList(),
  contentTypes: _map(
    pbProduct.getContentTypesList(),
    pbContentTypeToContentType
  ),
  productType: pbProduct.getProducttype()
});

const pbContentTypeToContentType = pbContentType => ({
  id: pbContentType.getId(),
  name: pbContentType.getName()
  //title: pbContentType.getTitle(), coming soon
});

const pbContentToContent = pbContent => ({
  id: pbContent.getId(),
  name: pbContent.getName(),
  contentType: Number(pbContent.getContentType()),
  imageUrl: pbContent.getImageUrl(),
  productId: pbContent.getProductId()
});

const pbContentRightToContentRight = pbContentRight => ({
  contentId: pbContentRight.getContentId(),
  accessType: pbContentRight.getAccessType()
});

// const pbContentRightToProductId = pbContentRight => ({
//   productId: pbContentRight.getProductId()
// });

const pbProductRightToProductId = pbProductRight => ({
  productId: pbProductRight.getProductId()
});

const contentRightToPbContentRight = contentRight => {
  const pbContentRight = new PbContentRight();
  pbContentRight.setContentId(contentRight.contentId);
  pbContentRight.setAccessType(contentRight.accessType);
  return pbContentRight;
};

const productRightToPbProductRight = productRight => {
  const pbProductRight = new PbProductRight();
  pbProductRight.setProductId(productRight.productId);
  return pbProductRight;
};
