/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import { default as AccountManagement } from '../../modules/accountManagement/ui/AccountManagement/index';
import { default as CustomerManagement } from './CustomerManagement/ui/index';
import { default as LicenseManagement } from './LicenseManagement/ui/index';
import { default as Essentials } from '../../views/Essentials/index';
import { default as Orion } from './Orion/index';
import { default as Menu } from './Menu';
import { default as Main } from './Main';
import { default as Dendrite } from './Dendrite/ui/index';
import { default as InvitationPage } from '../../modules/accountManagement/ui/AccountManagement/InvitationPage/index';
import Ares from './Ares';
import InCyt from './InCyt';
import EditProfile from './EditProfile';
import CookieConsent from 'react-cookie-consent';
import SessionUser from '../SessionUser';
import { watchLoggedIn } from '../../utilities/thirdParty/b2c';

const mainComponents = {
  AccountManagement,
  LicenseManagement,
  CustomerManagement,
  Ares,
  InCyt,
  Essentials,
  Orion,
  EditProfile,
  Dendrite,
  InvitationPage
};

class DashboardView extends Component {
  constructor(props) {
    super(props);
    window.addEventListener('storage', watchLoggedIn, false);
  }
  componentWillUnmount() {
    window.removeEventListener('storage', watchLoggedIn, false);
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.appFrame}>
          <CookieConsent
            location="bottom"
            buttonText="I agree"
            cookieName="privacyPolicy"
            style={{ background: '#2B373B' }}
            buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
            expires={150}
          >
            This site uses cookies to enable your digital experience. By
            continuing to use this site, you agree to the use of cookies as
            described in our{' '}
            <a
              target="_blank"
              href="https://circadence.com/privacy-policy/"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </CookieConsent>
          <SessionUser />
          <Menu />
          <Main className={classes.main} components={mainComponents} />
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  main: {
    width: '100%'
  },
  appFrame: {
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    height: '100vh',
    width: '100vw',
    backgroundColor: theme.palette.background.default
  }
});

export default withStyles(styles)(DashboardView);
