import {
  GetReportTokenRequest,
  GetReportsRequest,
  ReportingServicePromiseClient
} from 'reporting_grpc_web_pb';
import _map from 'lodash/map';

const { protocol, host } = window.location;

const reportingServiceClient = new ReportingServicePromiseClient(
  `${protocol}//${host}`,
  null,
  null
);

export function getReportToken(
  reportId: string,
  client = reportingServiceClient
) {
  const request = new GetReportTokenRequest();
  request.setReportId(reportId);
  return client
    .getReportToken(request)
    .then(response => pbReportTokenToReportToken(response));
}

export function getReports(
  userId: string,
  customerId: string,
  client = reportingServiceClient
) {
  const request = new GetReportsRequest();
  request.setUserId();
  request.setCustomerId();
  return client
    .getReports(request)
    .then(response =>
      _map(response.getReportInfosList(), pbReportInfoToReportInfo)
    );
}

const pbReportTokenToReportToken = pbReportToken => ({
  accessToken: pbReportToken.getAccessToken(),
  embedUrl: pbReportToken.getEmbedUrl(),
  embedReportId: pbReportToken.getEmbedReportId()
});

const pbReportInfoToReportInfo = pbReportInfo => ({
  id: pbReportInfo.getId(),
  name: pbReportInfo.getName(),
  webUrl: pbReportInfo.getWebUrl(),
  embedUrl: pbReportInfo.getEmbedUrl(),
  dataSetId: pbReportInfo.getDatasetId()
});
