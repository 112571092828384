import React, { Component } from 'react';
import _flow from 'lodash/flow';
import { connect } from 'react-redux';
import { Paper, withStyles } from '@material-ui/core';
import store from '../../store';
import { AzureAD } from 'react-aad-msal';
import {
  uncacheReactAADProvider,
  getIdToken,
  createMsalUserAgentApplication,
  cacheSettings
} from '../../utilities/thirdParty/b2c';
import { msal } from '@circadence/auth';
import {
  getConfigurationForUser,
  readUserDetails,
  registerUser,
  registerUserLocal,
  verifyToken
} from '../../modules/accountManagement/services';
import { Redirect, Route } from 'react-router-dom';
import ErrorPopup from '../../modules/accountManagement/ui/AccountManagement/ErrorPopup';
import RegistrationForm from './RegistrationForm';
import LogoIcon from '../../Assets/Logo';
import { Formik } from 'formik';
import {
  inviteRegistrationValidationSchema,
  registrationValidationSchema
} from './RegistrationValidationSchema';
import _isEqual from 'lodash/isEqual';

class Registration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // needed for Formik initial values to be in controlled input state
      initialUser: {
        userName: '',
        password: '',
        confirmPassword: '',
        isAdult: false,
        agreedTerms: false,
        agreedPrivacyPolicy: false
      },
      view: '',
      registerLocalError: false
    };
    this.provider = uncacheReactAADProvider();
    this.registerLocalErrorMsg = {
      errorType: 'register-local-error',
      errors: 'This activation link is no longer valid.'
    };
  }

  componentDidMount = () => {
    const { signedtoken } = this.props.match.params;
    if (signedtoken && signedtoken.length > 0) {
      this.setState(
        {
          view: 'register-local'
        },
        this.handleVerifyToken(signedtoken)
      );
    }
  };

  componentDidUpdate = prevProps => {
    if (!_isEqual(prevProps, this.props)) {
      this.handleIsRegistered();
      this.setState({
        initialUser: {
          id: this.props.userId,
          userName: this.props.username || '',
          roles: ['player'],
          aresRoles: ['player'],
          customerId: localStorage.getItem('customerId'),
          isAdult: false,
          agreedTerms: false,
          agreedPrivacyPolicy: false
        },
        view: ''
      });
    }
  };

  handleIsRegistered = () => {
    const { userId } = this.props;
    if (userId !== undefined)
      readUserDetails(userId)
        .then(user => {
          if (user) {
            this.setState({
              ...this.state.initialUser,
              view: 'isRegistered'
            });
          }
        })
        .catch(e => console.error(`Error reading user: ${e.message}`));
  };

  handleRegisterUser = user => {
    registerUser(user)
      .then(() => {
        getIdToken(true).then(() => {
          this.setState({
            view: 'isRegistered'
          });
        });
      })
      .catch(error => {
        const err = { errorType: 'generic', errors: error.message };
        console.error(err);
        this.setState({ registrationError: err });
      });
  };

  handleRegisterLocal = user => {
    registerUserLocal(user, this.props.match.params.signedtoken)
      .then(() => {
        getConfigurationForUser(user.userName)
          .then(b2cConfig => {
            this.setState({ b2cConfig, hasExistingAccount: true });
            const { authority, clientId, appScope, domainHint } = b2cConfig;

            const domain = domainHint ? domainHint : '';
            const userAgent = createMsalUserAgentApplication(
              clientId,
              authority,
              appScope,
              `${window.location.protocol}//${window.location.host}/portal`
            );
            // Necessary for msal library. Do not remove.
            userAgent.handleRedirectCallback(() => {});
            // Cache these before we go bye, bye.
            cacheSettings(clientId, authority, appScope);

            const loginConfig = {
              extraQueryParameters: { domain_hint: domain }
            };
            userAgent.loginRedirect(loginConfig);
          })
          .catch(error => {
            localStorage.removeItem('username');
            console.error('could not getConfigurationForUser: ', error);
            this.setState({
              loginError: {
                errorType: 'generic',
                errors: 'A user with this username could not be found.'
              }
            });
          });
      })
      .catch(error => {
        const err = { errorType: 'generic', errors: error.message };
        console.error(err);
        this.setState({ registrationError: err });
      });
  };

  handleCloseRegisterLocalError = () => {
    this.setState({
      registerLocalError: false,
      view: 'login'
    });
  };

  handleCloseRegistrationError = () => {
    this.setState({ registrationError: undefined });
  };

  handleVerifyToken = token => {
    verifyToken(token)
      .then(response => {
        this.setState({
          initialUser: {
            customerId: response.customerId,
            userName: response.username,
            password: '',
            confirmPassword: '',
            isAdult: false,
            agreedTerms: false,
            agreedPrivacyPolicy: false
          }
        });
      })
      .catch(error => {
        console.error(error.message);
        this.setState({
          view: 'register-local-error',
          registerLocalError: true
        });
      });
  };

  render() {
    const {
      initialUser,
      view,
      registrationError,
      registerLocalError
    } = this.state;
    const { classes } = this.props;

    switch (view) {
      case 'isRegistered':
        return <Redirect to={'/portal'} />;
      // nonfederated registration page
      case 'register-local':
        return (
          <>
            <div className={classes.root}>
              <div className={classes.appFrame}>
                <Paper className={classes.dialog}>
                  <div className={classes.logo}>
                    <LogoIcon className={classes.logoCenter} />
                  </div>
                  <Route path="/register-local">
                    <Formik
                      enableReinitialize
                      validationSchema={inviteRegistrationValidationSchema}
                      initialValues={initialUser}
                      onSubmit={this.handleRegisterLocal}
                    >
                      {props => <RegistrationForm {...props} password={true} />}
                    </Formik>
                  </Route>
                </Paper>
              </div>
            </div>
            <ErrorPopup
              open={registrationError !== undefined}
              error={registrationError}
              closeError={this.handleCloseRegistrationError}
            />
          </>
        );
      case 'register-local-error':
        return (
          <ErrorPopup
            open={registerLocalError}
            error={this.registerLocalErrorMsg}
            closeError={this.handleCloseRegisterLocalError}
          />
        );
      case 'login':
        return <Redirect to={'/portal'} />;
      // default federated registration view
      default:
        return (
          <AzureAD
            provider={this.provider}
            forceLogin={true}
            reduxStore={store}
          >
            <div className={classes.root}>
              <div className={classes.appFrame}>
                <Paper className={classes.dialog}>
                  <div className={classes.logo}>
                    <LogoIcon className={classes.logoCenter} />
                  </div>
                  <Route path="/register">
                    <Formik
                      enableReinitialize
                      validationSchema={registrationValidationSchema}
                      initialValues={initialUser}
                      onSubmit={this.handleRegisterUser}
                    >
                      {props => (
                        <RegistrationForm {...props} password={false} />
                      )}
                    </Formik>
                  </Route>
                </Paper>
              </div>
            </div>
            <ErrorPopup
              open={registrationError !== undefined}
              error={registrationError}
              closeError={this.handleCloseRegistrationError}
            />
          </AzureAD>
        );
    }
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  appFrame: {
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    height: '100vh',
    width: '100vw',
    backgroundColor: theme.palette.background.default
  },
  dialog: {
    boxShadow:
      '-5px -5px 20px 0px ' + theme.palette.primary.lightGradientBackground,
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    textAlign: 'left',
    height: 'fit-content',
    marginTop: '10%',
    padding: '60px'
  },
  logo: {
    alignItems: 'center',
    paddingTop: '20px',
    paddingBottom: '20px',
    '&>svg': {
      width: 'auto',
      height: '7em',
      margin: '.5em .55em',
      '& .st0': {
        fill: theme.palette.primary.main
      },
      '& .st1': {
        fill: theme.palette.primary.logo
      }
    }
  },
  logoCenter: {
    alignItems: 'center',
    textAlign: 'center',
    width: '100%'
  }
});

function mapStateToProps(state) {
  return {
    userId: msal.selectIdentitySubject(state),
    username: msal.selectEmails(state) ? msal.selectEmails(state)[0] : '',
    customerId: msal.selectIdentityCustomerId(state)
  };
}

export default _flow(
  connect(mapStateToProps),
  withStyles(styles)
)(Registration);
