import * as fn from './functions';
export const REDUCER_NAME = 'dendrite';

const initialState = {
  isLoading: false,
  lastFailure: undefined,
  reports: undefined,
  reportToken: undefined
};

export default (state = initialState, action) => {
  let changes;
  switch (action.type) {
    default:
      return state;
    case 'DENDRITE/SET_LAST_FAILURE':
      changes = fn.setLastFailure(state, action);
      break;
    case 'DENDRITE/SET_LOADING_STATE':
      changes = fn.setLoadingState(state, action);
      break;
    case 'DENDRITE/GET_REPORT_TOKEN':
      changes = fn.getReportToken(state, action);
      break;
    case 'DENDRITE/GET_REPORTS':
      changes = fn.getReports(state, action);
      break;
  }
  return { ...state, ...changes };
};
