/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import _uniqBy from 'lodash/uniqBy';

const LicenseManagementText = 'Manage Licences';
const CustomerManagementText = 'Manage Customers';
const AccountManagementText = 'Account Management';
const aresText = 'Project Ares';
const inCytText = 'InCyt';
const orionText = 'Orion';
const essentialsText = 'Essentials';
const dendriteText = 'Dendrite';

// Menu items for CMT Admin users
const menuItemsCmtAdmin = [
  {
    route: 'customer-management',
    label: CustomerManagementText,
    icon: 'customers',
    customIcon: true
  },
  {
    route: 'license-management',
    label: LicenseManagementText,
    icon: 'licenses',
    customIcon: true
  },
  {
    route: 'account-management',
    label: AccountManagementText,
    icon: 'users',
    customIcon: true
  },
  { route: 'incyt', label: inCytText, icon: 'incyt', customIcon: true },
  { route: 'ares', label: aresText, icon: 'ares', customIcon: true },
  { route: 'orion', label: orionText, icon: 'orion', customIcon: true },
  {
    route: 'essentials',
    label: essentialsText,
    icon: 'essentials',
    customIcon: true
  }
];

// Menu items for Customer Admin users
const menuItemsCustomerAdmin = [
  {
    route: 'account-management',
    label: AccountManagementText,
    icon: 'users',
    customIcon: true
  },
  {
    route: 'license-management',
    label: LicenseManagementText,
    icon: 'licenses',
    customIcon: true
  },
  {
    route: 'dendrite',
    label: dendriteText,
    icon: 'dendrite',
    customIcon: true
  }
];

// Menu items for Player users
const menuItemsPlayer = [
  { route: 'incyt', label: inCytText, icon: 'incyt', customIcon: true },
  { route: 'ares', label: aresText, icon: 'ares', customIcon: true },
  { route: 'orion', label: orionText, icon: 'orion', customIcon: true },
  {
    route: 'essentials',
    label: essentialsText,
    icon: 'essentials',
    customIcon: true
  }
];

export const essentials = {
  regexile: {
    name: 'regexile'
  },
  cylitaire: {
    name: 'cylitaire'
  },
  trivialoot: {
    name: 'trivialoot'
  },
  portflow: {
    name: 'portflow'
  },
  cybervault: {
    name: 'cybervault'
  },
  cyqual: {
    name: 'cyqual'
  },
  nexagent: {
    name: 'nexagent'
  }
};

export const getMenuItems = (
  isCmtAdmin,
  isCustomerAdmin,
  isPlayer,
  playerProducts
) => {
  const menu = [];
  if (isCmtAdmin) {
    menu.push(...menuItemsCmtAdmin);
  }

  if (isCustomerAdmin) {
    menu.push(...menuItemsCustomerAdmin);
  }

  if (isPlayer) {
    const updatedMenuItemsPlayer = menuItemsPlayer.filter(menuItem =>
      playerProducts.includes(menuItem.route)
    );
    menu.push(...updatedMenuItemsPlayer);
  }
  return _uniqBy(menu, 'route');
};
