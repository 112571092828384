/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  withStyles,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Typography,
  ListItem
} from '@material-ui/core';
import CheckCircle from '@material-ui/icons/CheckCircle';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CSVReader from 'react-csv-reader';
import ToolTip from './ToolTip';
import _uniqBy from 'lodash/uniqBy';
import _reject from 'lodash/reject';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _pick from 'lodash/pick';
import _isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';
import * as roleConstants from '../../../../constants/roles';

export const styles = () => ({
  capitalized: {
    textTransform: 'uppercase'
  },
  dropdown: {
    maxWidth: '98%',
    marginBottom: '8px'
  },
  tableDivStyle: {
    overflow: 'auto',
    maxHeight: '500px',
    width: '500px',

    backgroundColor: 'transparent !important'
  },
  divider: {
    backgroundColor: '#999',
    marginTop: '15px',
    marginBottom: '15px',
    maxWidth: '98%'
  },
  progress: {
    margin: '10px'
  },
  successIcon: {
    color: 'green'
  },
  tip: {
    color: 'red'
  },
  errorBadge: {
    display: 'inline-block',
    borderRadius: '50%',
    width: '30px',
    height: '30px',
    color: 'white',
    backgroundColor: 'red',
    fontWeight: 'bold',
    lineHeight: '30px',
    fontSize: '20px',
    textAlign: 'center'
  },
  records: {
    color: 'white'
  },
  showError: {
    color: 'red'
  },
  listItemPadding: {
    paddingBottom: '0px',
    paddingTop: '0px'
  },
  toolTip: {
    paddingLeft: '4px'
  },
  csvInput: {
    paddingTop: '8px',
    paddingBottom: '8px'
  }
});

export class BulkImportDialog extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      importedFileName: '',
      importedRecords: [],
      showSuccessBadge: false,
      showProgress: false,
      failedRows: 0,
      editGridOpen: false,
      validFile: false,
      showErrMsg: false,
      selectedCustomer: '',
      selectedGroups: [],
      customerGroupOptions: {}
    };
  }

  componentDidMount = () => {
    const { customerOptions, groupOptions } = this.props;
    if (Object.keys(customerOptions).length === 1) {
      this.setState({
        selectedCustomer: Object.values(customerOptions)[0].id,
        customerGroupOptions: _isEmpty(groupOptions)
          ? {}
          : Object.values(groupOptions[Object.keys(groupOptions)[0]])
      });
    }
  };
  //handle if file is csv
  handleFileLoad = (importedRecords, importedFileName) => {
    if (importedFileName.split('.').pop() !== 'csv') {
      this.setState({
        validFile: false,
        importedRecords: [],
        showSuccessBadge: false,
        failedRows: 0,
        showErrMsg: true
      });
      return;
    }

    const keyNames = Object.keys(importedRecords[0]);

    importedRecords = importedRecords.map(item => {
      item = {
        firstName: item[keyNames[0]],
        lastName: item[keyNames[1]],
        email: item[keyNames[2]],
        roles: item[keyNames[3]],
        aresRoles: item[keyNames[4]]
          ? item[keyNames[4]]
              .replace('ares.player', roleConstants.ARES_PLAYER)
              .replace('ares.trainer', roleConstants.ARES_TRAINER)
          : ''
      };

      return item;
    });

    let failedRows = 0;
    importedRecords = _reject(
      importedRecords,
      item =>
        item.firstName.trim() === '' &&
        item.lastName.trim() === '' &&
        item.email.trim() === '' &&
        item.roles.trim() === ''
    );
    const duplicateEntries = _uniqBy(importedRecords, 'email');
    const filterdRows = duplicateEntries.filter(
      entry =>
        entry.firstName === '' ||
        entry.lastName === '' ||
        entry.email === '' ||
        entry.roles === ''
    );

    failedRows =
      filterdRows.length + (importedRecords.length - duplicateEntries.length);

    for (let i = 0; i < importedRecords.length; i++) {
      importedRecords[i].roles = importedRecords[i].roles
        ? importedRecords[i].roles.split(',')
        : [];
      importedRecords[i].roles = importedRecords[i].roles.filter(
        role => role !== roleConstants.CMT_ADMIN
      );
      importedRecords[i].aresRoles = importedRecords[i].aresRoles
        ? importedRecords[i].aresRoles.split(',')
        : [];
    }
    this.setState({
      importedFileName,
      importedRecords,
      showSuccessBadge: failedRows <= 0,
      failedRows,
      validFile: true,
      showErrMsg: false
    });
  };

  handleFileLoadError = error => {
    this.setState({ showErrMsg: true });
    console.error(error);
  };

  handleConfirm = () => {
    const {
      importedRecords,
      failedRows,
      selectedCustomer,
      selectedGroups
    } = this.state;
    this.props.handleBulkInviteSubmit(
      importedRecords,
      failedRows,
      selectedCustomer,
      selectedGroups
    );
  };

  handleCustomerChange = event => {
    const { isCmt, isCustomerAdmin, groupOptions } = this.props;
    const customerId = event.target.value;
    if (isCmt) {
      const filteredGroups = _pick(groupOptions, customerId);
      const arrayOfGroups = _isEmpty(filteredGroups)
        ? {}
        : Object.values(filteredGroups[Object.keys(filteredGroups)]);
      this.setState({
        customerGroupOptions: arrayOfGroups,
        selectedCustomer: customerId,
        selectedGroup: ''
      });
    } else if (isCustomerAdmin) {
      this.setState({
        customerGroupOptions: _isEmpty(groupOptions)
          ? {}
          : Object.values(groupOptions[Object.keys(groupOptions)[0]]),
        selectedCustomer: customerId,
        selectedGroups: []
      });
    }
  };

  handleGroupSelect = event => {
    this.setState({
      selectedGroups: event.target.value
    });
  };

  render() {
    const { open, classes, customerOptions } = this.props;
    const localCustomerOptions = _filter(
      customerOptions,
      customer => customer.authenticationType === 'LOCAL'
    );

    const {
      importedRecords,
      showSuccessBadge,
      failedRows,
      validFile,
      showErrMsg,
      selectedCustomer,
      selectedGroups,
      customerGroupOptions
    } = this.state;

    return (
      <Dialog open={open} aria-hidden={!open}>
        <DialogTitle className={classes.capitalized}>
          <div>
            Bulk Import
            <span className={classes.toolTip}>
              <ToolTip />
            </span>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className={classes.tableDivStyle}>
            <div className={classes.dropdown}>
              <InputLabel id="select-customer">Customer</InputLabel>
              <Select
                MenuProps={{ classes: { paper: classes.dropdown } }}
                id="InvitationForm_customer"
                name="customer"
                value={selectedCustomer}
                label="Customer"
                onChange={event => this.handleCustomerChange(event)}
                fullWidth
              >
                {_map(localCustomerOptions, customer => (
                  <MenuItem
                    key={customer.id}
                    label={customer.id}
                    value={customer.id}
                  >
                    {customer.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className={classes.dropdown}>
              <InputLabel id="select-group">Group</InputLabel>
              <Select
                multiple
                MenuProps={{ classes: { paper: classes.dropdown } }}
                id="InvitationForm_group"
                name="groups"
                value={selectedGroups}
                label="groups"
                onChange={event => this.handleGroupSelect(event)}
                fullWidth
                disabled={_isEmpty(customerGroupOptions)}
              >
                {_map(customerGroupOptions, group => (
                  <MenuItem key={group.id} label={group.id} value={group.id}>
                    {group.displayName}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className={classes.csvInput}>
              <CSVReader
                cssClass="csvInput"
                onFileLoaded={this.handleFileLoad}
                onError={this.handleFileLoadError}
                parserOptions={{ header: true, skipEmptyLines: true }}
                inputStyle={{ color: 'white' }}
              />
            </div>
            {showSuccessBadge && (
              <CheckCircle className={classes.successIcon} fontSize="large" />
            )}
            {failedRows > 0 && (
              <div className={classes.errorBadge}>{failedRows}</div>
            )}
            <Divider
              className={classes.divider}
              variant="inset"
              component="div"
            />
            <Typography className={classes.records}>
              Total Imported Records: <span> {importedRecords.length}</span>{' '}
            </Typography>
            <Typography className={classes.tip}>
              *Max of 250 records per file
            </Typography>

            {showErrMsg ? 'The selected file is not a proper csv file.' : ''}
          </div>
          <Divider className={classes.divider} variant="inset" />
        </DialogContent>
        <DialogActions>
          <Button>
            <ListItem
              className={classes.listItemPadding}
              component={Link}
              to={`/portal/account-management`}
              button
            >
              Cancel
            </ListItem>
          </Button>
          <Button
            onClick={this.handleConfirm}
            disabled={
              !(
                validFile &&
                !(selectedCustomer.length === 0) &&
                !(selectedGroups.length === 0) &&
                importedRecords.length <= 250
              )
            }
            aria-label={`save changes to bulk import and go back to table`}
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

BulkImportDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  onSaveAndClose: PropTypes.func,
  open: PropTypes.bool
};
export default withStyles(styles)(BulkImportDialog);
