import PropTypes from 'prop-types';
import React from 'react';
import { withStyles, Button } from '@material-ui/core';

const styles = theme => ({
  button: {
    backgroundColor: theme.palette.primary.darkTurquoise,
    zIndex: '3'
  },
  link: {
    textDecoration: 'none'
  }
});

export const Launch = ({ classes, link, description }) => (
  <a
    className={classes.link}
    href={link}
    target="_blank"
    rel="noopener noreferrer"
  >
    <Button
      style={{ margin: '0 3em 2em 0' }}
      variant="contained"
      color="primary"
      className={classes.button}
      data-test={`launch-button-${link}`}
    >
      {description ? description : 'Launch Game'}
    </Button>
  </a>
);

export default withStyles(styles)(Launch);

Launch.propTypes = {
  classes: PropTypes.object,
  description: PropTypes.string,
  link: PropTypes.string
};
