/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */
const reactAad = require('react-aad-msal');
const msal = require('msal');
let provider;

const logger = new msal.Logger(
  (logLevel, message) => {
    console.log('[aadb2c]', message);
  },
  {
    level: msal.LogLevel.Verbose,
    piiLoggingEnabled: false
  }
);

const CLIENT_ID_KEY = 'clientId';
const AUTHORITY_KEY = 'authority';
const APP_SCOPE_KEY = 'scopes';
const LOGGED_IN_KEY = `loggedIn`;

function uncacheSettings() {
  const settings = {
    [CLIENT_ID_KEY]: localStorage.getItem(CLIENT_ID_KEY),
    [AUTHORITY_KEY]: localStorage.getItem(AUTHORITY_KEY),
    [APP_SCOPE_KEY]: localStorage.getItem(APP_SCOPE_KEY)
  };
  return settings;
}

export function cacheSettings(clientId, authority, appScope) {
  localStorage.setItem(CLIENT_ID_KEY, clientId);
  localStorage.setItem(AUTHORITY_KEY, authority);
  localStorage.setItem(APP_SCOPE_KEY, appScope);
  localStorage.setItem(LOGGED_IN_KEY, true);
}

export function uncacheConfig() {
  const { clientId, authority, scopes } = uncacheSettings();
  return [createMsalConfig(clientId, authority, scopes), scopes];
}

export function createMsalConfig(clientId, authority) {
  const auth = {
    validateAuthority: false,
    clientId,
    authority,
    // This defaults to true. If set to true it redirects to "start page" whatever that is
    // but we set it to false to prevent this from happening.
    navigateToLoginRequestUrl: false,
    postLogoutRedirectUri: `${window.location.protocol}//${window.location.host}/login`
  };

  return {
    auth,
    system: {
      logger
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false
    }
  };
}

export function createMsalUserAgentApplication(
  clientId,
  authority,
  scopes,
  redirectUri
) {
  const config = createMsalConfig(clientId, authority, scopes);
  config.auth.redirectUri = redirectUri;
  return new msal.UserAgentApplication(config);
}

export function uncacheMsalUserAgentApplication() {
  const [msalConfig] = uncacheConfig();
  return new msal.UserAgentApplication(msalConfig);
}

export function uncacheReactAADProvider() {
  const [config, scopes] = uncacheConfig();
  return initializeReactAADProvider(config, scopes);
}

export function initializeReactAADProvider(configuration, appScope) {
  if (provider) {
    logger.info('initializeProvider: Returning cached provider.');
    return provider;
  }

  logger.info(`initializeProvider: Creating new provider.`);
  // https://docs.microsoft.com/en-us/azure/active-directory/develop/msal-js-initializing-client-applications#configuration-options
  provider = new reactAad.MsalAuthProvider(
    configuration,
    {
      scopes: [appScope]
    },
    {
      loginType: reactAad.LoginType.Redirect
    }
  );

  return provider;
}

export function getProvider() {
  return provider;
}

export function getAccessToken() {
  return provider.getAccessToken();
}

/**
 *
 * @return {Promise<IdTokenResponse>}
 */
export function getIdToken(forceRefresh = false) {
  return provider.getIdToken({ forceRefresh });
}

export function logout() {
  localStorage.removeItem(CLIENT_ID_KEY);
  localStorage.removeItem(AUTHORITY_KEY);
  localStorage.removeItem(APP_SCOPE_KEY);
  localStorage.removeItem(LOGGED_IN_KEY);
  provider.logout();
}

export const watchLoggedIn = event => {
  //if (event.key === LOGGED_IN_KEY && event.oldValue === 'true') {
  if (event.key === LOGGED_IN_KEY) {
    logout();
  }
};

export function checkAuthenticated() {
  const msalProvider = provider ?? uncacheReactAADProvider();
  return msalProvider && msalProvider.getAccount() !== null;
}
