/*
 * Copyright 2015-2020, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */
import _get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from 'react-router-dom';
import { Dashboard } from './components';
import Login from './components/Login';
import CircularProgress from '@material-ui/core/CircularProgress';
import Registration from './components/Registration';
import { AzureAD } from 'react-aad-msal';
import store from './store';
import { withStyles } from '@material-ui/core';
import {
  checkAuthenticated,
  getProvider,
  logout,
  uncacheReactAADProvider
} from './utilities/thirdParty/b2c';

const { host } = window.location;

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  main: {
    width: '100%'
  },
  spinnerBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: theme.palette.background.default
  },
  appFrame: {
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    height: '100vh',
    width: '100vw',
    backgroundColor: theme.palette.background.default
  }
});

const AuthenticatedDashboard = ({ classes }) => {
  const [isWaiting, setWaiting] = useState(true);
  const [handledRedirect, setHandledRedirect] = useState(false);
  const [forceReload, setForceReload] = useState('1');

  useEffect(() => {
    const provider = uncacheReactAADProvider();
    provider.handleRedirectCallback((error, response) => {
      // This happens pretty much immediately here and will catch special statuses
      // and error codes when returning from B2C.
      const redirectUri = `${window.location.protocol}//${window.location.host}/portal`;
      let authority;
      if (
        host === 'cyberbridge.circadence.com' || // not registered as an application,TODO do we need another user flow for cyberbridge??
        host === 'cyberbridge2.circadence.com'
      ) {
        authority =
          'https://circadenceprd.b2clogin.com/tfp/circadenceprd.onmicrosoft.com/B2C_1_pwreset';
      } else {
        authority =
          'https://circadencedev.b2clogin.com/tfp/circadencedev.onmicrosoft.com/B2C_1_Reset';
      }

      if (error) {
        // Check/handle password reset.
        if (
          error.errorMessage &&
          error.errorMessage.startsWith('AADB2C90118')
        ) {
          //"loginRedirect" with the password reset authority set.
          console.warn('Redirecting to change password.');
          provider.loginRedirect({ authority, redirectUri });
          return; // return immediately with waiting turned on.
        }

        // Check/handle user cancelled password reset, force logout.
        if (
          error.errorMessage &&
          error.errorMessage.startsWith('AADB2C90091')
        ) {
          logout();
        }
        // Handle other errors that come back here. The default will be to send
        // them to /login
        console.error(`Error from B2C: ${error}. Redirecting to Login...`);
        logout();
        setWaiting(false);
        return;
      }

      // After a password reset, B2C logs us in but the authority (policy) in the
      // identity token reflects the password reset policy not the login policy
      // for the app so this freaks out the provider and it craps the bed. So, here
      // we have to detect this condition and log the user out. Terrible.
      const policy = (_get(response, 'idToken.claims.tfp') || '').toLowerCase();
      const providerAuthority = provider.getAuthorityInstance()
        .CanonicalAuthority;
      if (
        response.tokenType === 'id_token' &&
        !providerAuthority.includes(policy)
      ) {
        console.warn('Identity token has the wrong authority. Correcting.');
        //have reset password authority but not default login authority
        const authority = _get(
          provider.getAuthorityInstance(),
          'canonicalAuthority'
        );
        if (provider.clientId && authority) {
          //Blank the screen while redirect happens.
          setWaiting(true);
          const loginHint = _get(provider.getAccount(), 'name');
          provider.loginRedirect({ authority, redirectUri, loginHint });
          return; // Return immediately with waiting turned on.
        }
      }
      console.log('Non-actionable MSAL redirect callback.');
      setWaiting(false);
      setHandledRedirect(true);
    });
    // skip isWaiting Spinner
    // if the user is logged in and has an account
    // AND if not in the provider redirect loop
    let timeout;
    if (
      (provider.account && !handledRedirect) ||
      provider.authenticationState === 'Unauthenticated'
    ) {
      setWaiting(false);
      timeout = setTimeout(() => {
        setForceReload('2');
      });
    }

    if (provider.authenticationState === 'InProgress') {
      setWaiting(false);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [handledRedirect]);

  if (isWaiting) {
    return (
      <div className={classes.spinnerBlock}>
        <CircularProgress color="secondary" />
      </div>
    );
  }

  if (!checkAuthenticated()) {
    return <Redirect to={'/login'} />;
  }

  return (
    <AzureAD provider={getProvider()} forceLogin={true} reduxStore={store}>
      <Dashboard key={forceReload} />
    </AzureAD>
  );
};

export const App = () => (
  <Router>
    <Switch>
      <Route path="/login" component={Login} />
      <Route
        path="/portal"
        component={withStyles(styles)(AuthenticatedDashboard)}
      />
      <Route path="/register" component={Registration} />
      <Route path="/register-local/:signedtoken" component={Registration} />
      <Redirect to="/portal" />
    </Switch>
  </Router>
);

export default connect()(withStyles(styles)(App));
