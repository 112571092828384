/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { MainViewPropsType } from './Main.types';
import { connect } from 'react-redux';
import rolesRoutesMapping from '../../../utilities/roles-routes-mapping.json';
import _uniq from 'lodash/uniq';
import _sortBy from 'lodash/sortBy';
import _find from 'lodash/find';
import _split from 'lodash/split';
import _last from 'lodash/last';
import * as constants from '../Menu/constants';
import { fetchPlayerProducts } from '../ContentAccess/services';
import {
  selectSessionUserId,
  selectSessionUserRoles
} from '../../../modules/session-user/selectors';
import * as roleConstants from '../../../constants/roles';

const hasAccess = (roles, routePath, availableProducts) => {
  if (roles) {
    let availableRoutes = [];
    roles.forEach(userRole => {
      const roleMapping = rolesRoutesMapping.filter(r => r.role === userRole);
      if (roleMapping !== undefined && roleMapping.length > 0) {
        availableRoutes.push(...roleMapping[0].routes);
      }
    });
    availableRoutes = _uniq(availableRoutes);
    const isCmt = roles.includes(roleConstants.CMT_ADMIN);
    if (availableProducts && availableProducts.length >= 0) {
      let idx = null;
      if (!isCmt && availableProducts.indexOf('ares') === -1) {
        idx = availableRoutes.indexOf('ares');
        if (idx !== -1) availableRoutes.splice(idx, 1);
      }
      if (!isCmt && availableProducts.indexOf('essentials') === -1) {
        idx = availableRoutes.indexOf('essentials');
        if (idx !== -1) availableRoutes.splice(idx, 1);
      }
      if (!isCmt && availableProducts.indexOf('incyt') === -1) {
        idx = availableRoutes.indexOf('incyt');
        if (idx !== -1) availableRoutes.splice(idx, 1);
      }
      if (!isCmt && availableProducts.indexOf('orion') === -1) {
        idx = availableRoutes.indexOf('orion');
        if (idx !== -1) availableRoutes.splice(idx, 1);
      }
    }
    const path = _last(_split(routePath, '/'));
    return availableRoutes.indexOf(path) > -1;
  } else {
    return false;
  }
};

const RoleRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      hasAccess(rest.roles, rest.path, rest.products) ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      )
    }
  />
);

const getDefaultRoute = (roles, match, availableProducts) => {
  if (roles && roles.length > 0) {
    const orderedRoles = _sortBy(roles, role => {
      const roleMapping = _find(rolesRoutesMapping, ['role', role]);
      return roleMapping ? roleMapping.order : 999;
    });
    for (let i = 0; i < orderedRoles.length; i = i + 1) {
      const userRole = orderedRoles[i];
      const roleMapping = rolesRoutesMapping.filter(r => r.role === userRole);
      if (roleMapping && roleMapping.length > 0) {
        // remove products not available for the results
        let idx = null;
        const availableRoutes = [...roleMapping[0].routes];
        if (availableProducts.indexOf('ares') === -1) {
          idx = availableRoutes.indexOf('ares');
          if (idx !== -1) availableRoutes.splice(idx, 1);
        }
        if (availableProducts.indexOf('essentials') === -1) {
          idx = availableRoutes.indexOf('essentials');
          if (idx !== -1) availableRoutes.splice(idx, 1);
        }
        if (availableProducts.indexOf('incyt') === -1) {
          idx = availableRoutes.indexOf('incyt');
          if (idx !== -1) availableRoutes.splice(idx, 1);
        }
        if (availableProducts.indexOf('orion') === -1) {
          idx = availableRoutes.indexOf('orion');
          if (idx !== -1) availableRoutes.splice(idx, 1);
        }
        return <Redirect to={`${match.url}/${availableRoutes[0]}`} />;
      }
    }
    return <Redirect to={`${match.url}/edit-profile`} />;
  }
};

export const MainView = ({
  components,
  match,
  roles,
  userId
}: MainViewPropsType) => {
  const [products, setProducts] = useState(undefined);
  useEffect(() => {
    if (userId) {
      fetchPlayerProducts(userId).then(products => {
        const essentialsMatch = products.some(
          prod => constants.essentials[prod]
        );
        if (essentialsMatch) {
          setProducts(['essentials', ...products]);
        } else {
          setProducts(products);
        }
      });
    }
  }, [userId]);
  if (products && roles && roles.length > 0) {
    return (
      <Switch>
        // CMT Admin View
        <RoleRoute
          path={`${match.url}/license-management`}
          component={components.LicenseManagement}
          roles={roles}
          products={products}
        />
        <RoleRoute
          path={`${match.url}/customer-management`}
          component={components.CustomerManagement}
          roles={roles}
          products={products}
        />
        <RoleRoute
          path={`${match.url}/invitation-management`}
          component={components.InvitationManagement}
          roles={roles}
        />
        // Customer Admin View
        <RoleRoute
          path={`${match.url}/account-management`}
          component={components.AccountManagement}
          roles={roles}
          products={products}
        />
        <RoleRoute
          path={`${match.url}/dendrite`}
          component={components.Dendrite}
          roles={roles}
          products={products}
        />
        // Player View
        <RoleRoute
          path={`${match.url}/ares`}
          component={components.Ares}
          roles={roles}
          products={products}
        />
        <RoleRoute
          path={`${match.url}/incyt`}
          component={components.InCyt}
          roles={roles}
          products={products}
        />
        <RoleRoute
          path={`${match.url}/orion`}
          component={components.Orion}
          roles={roles}
          products={products}
        />
        <RoleRoute
          path={`${match.url}/essentials`}
          component={components.Essentials}
          roles={roles}
          products={products}
        />
        <Route path={`${match.url}/edit-profile`}>
          <components.EditProfile />
        </Route>
        <Route path={`${match.url}/invitation-page`}>
          <components.InvitationPage />
        </Route>
        {getDefaultRoute(roles, match, products)}
      </Switch>
    );
  } else {
    return <div></div>;
  }
};

function createRoleList(rolesList) {
  const roles = [];
  if (rolesList.includes(roleConstants.CMT_ADMIN)) {
    roles.push(roleConstants.CMT_ADMIN);
  }

  if (rolesList.includes(roleConstants.CUSTOMER_ADMIN)) {
    roles.push(roleConstants.CUSTOMER_ADMIN);
  }

  if (rolesList.includes(roleConstants.PLAYER)) {
    roles.push(roleConstants.PLAYER);
  }
  return roles;
}

const mapStateToProps = state => ({
  roles: createRoleList(selectSessionUserRoles(state)),
  userId: selectSessionUserId(state)
});

export default withRouter(connect(mapStateToProps)(MainView));
