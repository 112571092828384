/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import React from 'react';
import {
  Button,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  withStyles
} from '@material-ui/core';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

const styles = () => ({
  rolesDropdown: {
    maxHeight: 250
  }
});

export const UserForm = props => {
  const {
    values: { userName, roles, aresRoles, groupIds, firstName, lastName },
    errors,
    touched,
    handleSubmit,
    handleChange,
    isValid,
    setFieldTouched,
    roleOptions,
    productOptions,
    classes,
    content,
    groupsOptions
  } = props;
  const change = (name, e) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  const getProductRoles = () => {
    let productRoleOptions = {};
    if (productOptions) {
      Object.keys(productOptions).forEach(item => {
        const listValues = getProductRolesByProductName(item);
        if (listValues) {
          productRoleOptions = { ...productRoleOptions, ...listValues };
        }
      });
    }
    return _map(productRoleOptions, (productRole, key) => (
      <MenuItem key={productRole} value={key}>
        {productRole}
      </MenuItem>
    ));
  };

  const getProductRolesByProductName = productName => {
    const productRolesList = {};
    if (productName) {
      if (productOptions) {
        const productInfo = productOptions[productName];
        if (productInfo) {
          if (productInfo.roles && productInfo.roles.length > 0) {
            // eslint-disable-next-line no-plusplus
            for (let a = 0; a < productInfo.roles.length; a++) {
              productRolesList[
                productInfo.roles[a].array[0]
              ] = `${productInfo.title} ${productInfo.roles[a].array[1]}`;
            }
          }
        }
      }
    }
    return productRolesList;
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        key="userName"
        name="userName"
        helperText={touched.userName ? errors.userName : ''}
        error={touched.userName && Boolean(errors.userName)}
        label="Username"
        value={userName}
        onChange={change.bind(null, 'userName')}
        fullWidth
        disabled={true}
      />
      <TextField
        id="firstName"
        name="firstName"
        label="First Name"
        value={firstName}
        onChange={change.bind(null, 'firstName')}
        error={touched.firstName && Boolean(errors.firstName)}
        helperText={touched.firstName ? errors.firstName : ''}
        fullWidth
        disabled={false}
        data-test={`editProfile-textField-${firstName}`}
      />
      <TextField
        id="lastName"
        name="lastName"
        label="Last Name"
        value={lastName}
        onChange={change.bind(null, 'lastName')}
        error={touched.lastName && Boolean(errors.lastName)}
        helperText={touched.lastName ? errors.lastName : ''}
        fullWidth
        disabled={false}
        data-test={`editProfile-textField-${lastName}`}
      />
      <FormControl key={'roles'} fullWidth>
        <InputLabel htmlFor="EditUser_roles">Roles</InputLabel>
        <Select
          multiple
          MenuProps={{ classes: { paper: classes.rolesDropdown } }}
          name="roles"
          label="roles"
          value={roles}
          error={touched.roles && Boolean(errors.roles)}
          onChange={change.bind(null, 'roles')}
          fullWidth
        >
          {_map(roleOptions, (role, key) => (
            <MenuItem key={key} value={key}>
              {role}
            </MenuItem>
          ))}
        </Select>
        {touched.roles && Boolean(errors.roles) && (
          <FormHelperText
            id="roles"
            error={touched.roles && Boolean(errors.roles)}
          >
            {errors.roles}
          </FormHelperText>
        )}
      </FormControl>
      {!_isEmpty(content.productRights) && content.productRights.ares && (
        <FormControl key={'aresRoles'} fullWidth>
          <InputLabel htmlFor="EditUser_productRolesRoles">
            Ares Roles
          </InputLabel>
          <Select
            multiple
            MenuProps={{ classes: { paper: classes.rolesDropdown } }}
            name="aresRoles"
            label="aresRoles"
            value={aresRoles}
            error={touched.aresRoles && Boolean(errors.aresRoles)}
            onChange={change.bind(null, 'aresRoles')}
            fullWidth
          >
            {getProductRoles()}
          </Select>
          {touched.aresRoles && Boolean(errors.aresRoles) && (
            <FormHelperText
              id="aresRoles"
              error={touched.aresRoles && Boolean(errors.aresRoles)}
            >
              {errors.aresRoles}
            </FormHelperText>
          )}
        </FormControl>
      )}
      <FormControl key={'groupIds'} fullWidth>
        <InputLabel htmlFor="EditUser_groups">Groups</InputLabel>
        <Select
          multiple
          MenuProps={{ classes: { paper: classes.rolesDropdown } }}
          name="groupIds"
          label="groupIds"
          value={groupIds}
          error={touched.groupIds && Boolean(errors.groupIds)}
          onChange={change.bind(null, 'groupIds')}
          fullWidth
        >
          {_map(groupsOptions, item => (
            <MenuItem key={item.id} value={item.id}>
              {item.displayName}
            </MenuItem>
          ))}
        </Select>
        {touched.groupIds && Boolean(errors.groupIds) && (
          <FormHelperText
            id="groupIds"
            error={touched.groupIds && Boolean(errors.groupIds)}
          >
            {errors.groupIds}
          </FormHelperText>
        )}
      </FormControl>
      <Button
        type="submit"
        fullWidth
        color="primary"
        disabled={!isValid || _isEmpty(touched)}
      >
        SAVE
      </Button>
    </form>
  );
};

export default withStyles(styles)(UserForm);
