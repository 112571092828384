/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import React from 'react';
import { Button, TextField, Select, MenuItem } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

export const LicenseInfoForm = props => {
  const {
    values: { name, startTime, endTime, customerId },
    errors,
    touched,
    handleSubmit,
    handleChange,
    isValid,
    setFieldTouched,
    customerOptions,
    isCmt
  } = props;

  const change = (name, e) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        id="name"
        name="name"
        helperText={touched.name ? errors.name : ''}
        error={touched.name && Boolean(errors.name)}
        label="Name"
        value={name}
        onChange={change.bind(null, 'name')}
        fullWidth
        disabled={!isCmt}
      />
      <TextField
        id="startTime"
        label="Start Time"
        type="date"
        value={startTime}
        inputProps={{ min: '1970-01-01', max: '2099-12-31' }}
        InputLabelProps={{
          shrink: true
        }}
        onChange={change.bind(null, 'startTime')}
        fullWidth
        disabled={!isCmt}
      />
      <TextField
        id="endTime"
        label="End Time"
        type="date"
        value={endTime}
        inputProps={{ min: '1970-01-01', max: '2099-12-31' }}
        InputLabelProps={{
          shrink: true
        }}
        onChange={change.bind(null, 'endTime')}
        fullWidth
        disabled={!isCmt}
      />
      <FormControl fullWidth>
        <InputLabel htmlFor="customer">Customer</InputLabel>
        <Select
          id="customerId"
          name="customerId"
          value={customerId || ''}
          error={touched.customerId && Boolean(errors.customerId)}
          onChange={change.bind(null, 'customerId')}
          fullWidth
          disabled={!isCmt}
        >
          {customerOptions.map(([value, text]) => (
            <MenuItem key={value} label={value} value={value}>
              {text}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {isCmt && (
        <Button
          style={{ marginBottom: '20px' }}
          type="submit"
          fullWidth
          color="primary"
          disabled={!isValid}
        >
          Save
        </Button>
      )}
    </form>
  );
};

export default LicenseInfoForm;
