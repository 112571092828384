import * as services from './services';
import { Dispatch } from "redux";

/*******************
 * Private actions - these are actions that should not be directly invoked by the user but are part of other
 * async actions.
 *******************/
export const _getReportTokenAction = (accessToken, embedUrl, embedReportId) => ({
  type: 'DENDRITE/GET_REPORT_TOKEN',
  payload: {accessToken, embedUrl, embedReportId}
});

export const _getReportsAction = (reports) => ({
  type: 'DENDRITE/GET_REPORTS',
  payload: reports
});

export const _setLastFailureAction = (
  message: ?string
) => ({
  type: 'DENDRITE/SET_LAST_FAILURE',
  payload: message
});

export const _setLoadingStateAction = (
  value: boolean
) => ({
  type: 'DENDRITE/SET_LOADING_STATE',
  payload: value
});

/*****************
 * Async actions
 *****************/

const asyncTemplate = async (
  dispatch: Dispatch<*>,
  logic: () => Promise<*>,
  onErr: ?(Error) => Promise<*>
) => {
  dispatch(_setLoadingStateAction(true));
  try {
    await logic();
  } catch (err) {
    const message = err.message || `${err}`;
    dispatch(_setLastFailureAction({ errorType: 'generic', errors: [message] }));
    onErr && onErr(message);
    console.error('error from service client: ', message);
  }
  dispatch(_setLoadingStateAction(false));
};

export const queryReportToken = (reportId: string) => async (
  dispatch: Dispatch<*>
) => {
  await asyncTemplate(dispatch, async () => {
    const {accessToken, embedUrl, embedReportId} = await services.getReportToken(reportId);
    dispatch(_getReportTokenAction(accessToken, embedUrl, embedReportId));
  });
};

export const queryReports = (userId:string, customerId: string) =>  async (
  dispatch: Dispatch<*>
) => {
  await asyncTemplate(dispatch, async () => {
    const reports = await services.getReports(userId, customerId);
    dispatch(_getReportsAction(reports));
  });
};