/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import type { SessionUserState } from './types';
import { REDUCER_NAME } from './reducer';
import * as getters from './reducer/getters';

type GlobalState = {
  [REDUCER_NAME]: SessionUserState
};

export const selectSessionUserPlayFabData = (state: GlobalState) =>
  getters.getSessionUserPlayFabData(state[REDUCER_NAME]);

export const selectLastFailure = (state: GlobalState): ?string =>
  getters.getLastFailure(state[REDUCER_NAME]);

export const selectIsLoading = (state: GlobalState): boolean =>
  getters.getIsLoading(state[REDUCER_NAME]);

export const selectSessionUserRoles = (state: GlobalState): ?string =>
  getters.getSessionUserRoles(state[REDUCER_NAME]);

export const selectSessionUserId = (state: GlobalState): ?string =>
  getters.getSessionUserId(state[REDUCER_NAME]);

export const selectUsername = (state: GlobalState): ?string =>
  getters.getUsername(state[REDUCER_NAME]);

export const selectCustomerId = (state: GlobalState): ?string =>
  getters.getCustomerId(state[REDUCER_NAME]);

export const selectSessionUserDetails = (state: GlobalState): ?string =>
  getters.getSessionUserDetails(state[REDUCER_NAME]);
