/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import type { LicensesById, LicenseManagementState } from './types';
import { REDUCER_NAME } from './reducer';
import * as getters from '../LicenseManagement/reducer/getters';

type GlobalState = {
  [REDUCER_NAME]: LicenseManagementState
};

export const getLicenses = (state: GlobalState): LicensesById =>
  getters.getLicenses(state[REDUCER_NAME]);

export const getLastFailure = (state: GlobalState): ?string =>
  getters.getLastFailure(state[REDUCER_NAME]);
