/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

/* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup';

const minimumChars = 1;
const maximumChars = 64;

export const editAdminSchema = Yup.object({
  userName: Yup.string('Enter a display name')
    .required('Display name is required')
    .max(maximumChars, 'A display name can have at most ${max} characters')
    .min(minimumChars, 'A display name must have at least ${min} character')
});

export const editUserSchema = Yup.object({
  roles: Yup.array()
    .min(1, 'Choose one role')
    .of(Yup.string())
    .required('At least one role is required.'),
  groupIds: Yup.array()
    .min(1, 'Choose one group')
    .of(Yup.string())
    .required('At least one role is required.'),
  firstName: Yup.string('Enter a first name.')
    .trim()
    .required('First name is required')
    .max(maximumChars, 'A first name can have at most ${max} characters')
    .min(minimumChars, 'A first name must have at least ${min} character'),
  lastName: Yup.string('Enter a last name.')
    .trim()
    .required('Last name is required')
    .max(maximumChars, 'A last name can have at most ${max} characters')
    .min(minimumChars, 'A last name must have at least ${min} character')
});

export const inviteUserSchema = Yup.object({
  firstName: Yup.string('Enter a first name.')
    .trim()
    .required('First name is required')
    .max(maximumChars, 'A first name can have at most ${max} characters')
    .min(minimumChars, 'A first name must have at least ${min} character'),
  lastName: Yup.string('Enter a last name.')
    .trim()
    .required('Last name is required')
    .max(maximumChars, 'A last name can have at most ${max} characters')
    .min(minimumChars, 'A last name must have at least ${min} character'),
  userName: Yup.string()
    .email()
    .required('Email is required'),
  roles: Yup.array()
    .min(1, 'Choose one role')
    .of(Yup.string())
    .required('At least one role is required.'),
  customer: Yup.string().required('Choose a Customer'),
  groups: Yup.array().required('Please select a group')
});
