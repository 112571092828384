import React from 'react';
import {
  FormControlLabel,
  TextField,
  Checkbox,
  Button,
  Typography,
  withStyles
} from '@material-ui/core';

export const LoginForm = props => {
  const {
    values,
    errors,
    initialErrors,
    handleSubmit,
    handleChange,
    setFieldTouched,
    touched,
    isValid,
    classes
  } = props;

  const change = (name, e) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        id="username"
        name="username"
        helperText={errors.username}
        error={
          (!touched.username && initialErrors.username) ||
          (touched.username && Boolean(errors.username))
        }
        label="Username or Email"
        value={values.username}
        onChange={change.bind(null, 'username')}
        fullWidth
      />
      {isValid && (
        <FormControlLabel
          key={'isSaveUsernameChecked'}
          className={classes.checkbox}
          control={
            <Checkbox
              id={'isSaveUsernameChecked'}
              color="primary"
              onChange={change.bind(null, 'isSaveUsernameChecked')}
              disabled={!isValid}
              checked={isValid && values.isSaveUsernameChecked}
              value={'isSaveUsernameChecked'}
            />
          }
          label={<Typography>Remember this username</Typography>}
        />
      )}
      <Button
        style={{ marginBottom: '20px' }}
        type="submit"
        fullWidth
        color="primary"
        disabled={!isValid}
        onClick={e => {
          if (e.key === 'Enter') {
            handleSubmit();
          }
        }}
      >
        Login
      </Button>
    </form>
  );
};

const styles = () => ({
  checkbox: {
    width: '100%'
  }
});

export default withStyles(styles)(LoginForm);
