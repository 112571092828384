/*
 * Copyright 2015-2019, Circadence Corporation.  All Rights Reserved.
 * This document contains confidential information of Circadence Corporation and may not be duplicated or disclosed to
 * parties other than the intended recipient without the prior written consent of Circadence Corporation.
 */

import type { ContentAccessState, ProductsById, ContentById } from '../types';

export const isLoading = (state: ContentAccessState) => state.isLoading;

export const getLastFailure = (state: ContentAccessState) => state.lastFailure;

export const getContentByProductId = (state: ContentAccessState): ContentById =>
  state.contentByProductId;

export const getProducts = (state: ContentAccessState): ProductsById =>
  state.products;
